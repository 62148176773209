import FormBuilderConfig from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ISelectBuilder from "../../../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import ITextBuilder from "../../../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import IClubSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iClubSelectorBuilder/IClubSelectorBuilder";
import IUserSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";
import * as validators from "./DAutorizacion.validators";
import { userHasPermission } from "../../../../../utils/accessTree";
import permissionCodes from "../../../../../utils/permissionCodes";

const {
  COMISIONES_V2_AUTORIZAR: AUTORIZAR,
  COMISIONES_V2_MULTICLUB: MULTICLUB
} = permissionCodes;

export type Inputs =
  | "tipoBusqueda"
  | "idClub"
  | "periodo"
  | "idEmpleado"
  | "folioFactura"
  | "estatus";
export type Buttons = "autorizar" | "buscar";

export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "tipoBusqueda",
      label: "* Tipo de búsqueda",
      bts: {
        mCol: 3
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Periodo",
        data: [{ value: "FACTURA", label: "Factura" }]
      }
    },
    {
      name: "idClub",
      label: "* Club",
      bts: {
        mCol: 3
      },
      component: IClubSelectorBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaPeriodo,
        useSessionClub: true
      }
    },
    {
      name: "periodo",
      label: "* Periodo",
      bts: {
        mCol: 3
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Seleccione",
        // data: getPeriodos(new Date()),
        hidden: validators.hiddenIfNotBusquedaPeriodo
      }
    },
    {
      name: "estatus",
      label: "Estatus",
      bts: {
        mCol: 3
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Seleccione",
        data: [
          { value: "AUTORIZADA", label: "Autorizadas" },
          { value: "RECHAZADA", label: "Rechazadas" },
          { value: "PENDIENTE", label: "Pendientes" },
          { value: "PAGADA", label: "Pagadas" },
          { value: "ERRONEA", label: "Erróneas" }
        ],
        hidden: validators.hiddenIfNotBusquedaPeriodo
      }
    },
    {
      name: "idEmpleado",
      label: "Empleado",
      bts: {
        mCol: 12
      },
      component: IUserSelectorBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaPeriodo
      }
    },
    {
      name: "folioFactura",
      label: "* Folio de factura",
      bts: {
        mCol: 3
      },
      component: ITextBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaFactura
      }
    }
  ],
  buttons: [
    {
      name: "buscar",
      label: "Buscar",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        mCol: 3
      },
      props: {
        addTopMargin: false
      }
    },
    {
      name: "autorizar",
      label: "Autorizar",
      component: IButtonBuilder,
      bts: {
        mOff: 6,
        mCol: 3
      },
      props: {
        disabled: () => !userHasPermission(AUTORIZAR),
        addTopMargin: false
      }
    }
  ],
  validations: {
    idClub: {
      requiredIfBusquedaPeriodo: validators.requiredIfBusquedaPeriodo
    },
    periodo: {
      requiredIfBusquedaPeriodo: validators.requiredIfBusquedaPeriodo
    },
    folioFactura: {
      requiredIfBusquedaFactura: validators.requiredIfBusquedaFactura
    }
  },
  rows: [
    ["tipoBusqueda", "idClub", "periodo", "estatus", "folioFactura"],
    ["idEmpleado"],
    ["autorizar", "buscar"]
  ]
};

export function getPeriodos(fecha: Date) {
  const periodos = [];
  const primerDia = fecha.getDate() >= 16 ? 16 : 1;

  for (let i = -1; i < 3; i++) {
    if (primerDia === 1) {
      periodos.push(
        optionPeriodo(new Date(fecha.setMonth(fecha.getMonth() - i, 16)))
      );
      periodos.push(
        optionPeriodo(new Date(fecha.setMonth(fecha.getMonth() - (i + 1), 1)))
      );
    } else {
      periodos.push(
        optionPeriodo(new Date(fecha.setMonth(fecha.getMonth() - i, 1)))
      );
      periodos.push(
        optionPeriodo(new Date(fecha.setMonth(fecha.getMonth() - i, 16)))
      );
    }
  }
  return periodos;
}

function optionPeriodo(firstDay: Date) {
  const lastDay = new Date(
    firstDay.getFullYear(),
    firstDay.getMonth(),
    firstDay.getDate() === 1 ? 15 : 0
  );
  const mes = firstDay.toLocaleDateString("es-MX", { month: "long" });

  return {
    value: firstDay,
    label: `Del ${firstDay.getDate()} al ${lastDay.getDate()} de ${mes} de ${firstDay.getFullYear()}`
  };
}
