export default {
  RESTART_APP: "RESTART_APP",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CLEAR_SINGLE_CODE: "CLEAR_SINGLE_CODE",
  GET_SINGLE_CODE: "GET_SINGLE_CODE",
  GET_DOCUMENTS_GUEST: "GET_DOCUMENTS_GUEST",
  GET_DISCOUNT_CODES_LTE_MEMBRSH: "GET_DISCOUNT_CODES_LTE_MEMBRSH",
  GET_DISCOUNT_CODES_LIST_MEMBRSH: "GET_DISCOUNT_CODES_LIST_MEMBRSH",
  SAVE_DEMAND: "SAVE_DEMAND",
  GET_DEMANDS: "GET_DEMANDS",
  GET_DEMANDS_FILE: "GET_DEMANDS_FILE",
  GET_DEMAND_BY_ID: "GET_DEMAND_BY_ID",
  SAVE_DEMAND_OBSERVATION: "SAVE_DEMAND_OBSERVATION",
  UPDATE_DEMAND: "UPDATE_DEMAND",
  GET_CREDIT_NOTE_REPORT_FILE: "GET_CREDIT_NOTE_REPORT_FILE",
  SAVE_LOCKERS: "SAVE_LOCKERS",
  GET_LOCKERS: "GET_LOCKERS",
  SAVE_FOLLOW_UP_SURVEY: "SAVE_FOLLOW_UP_SURVEY",
  GET_TOKEN_FOLLOW_UP_SURVEY: "GET_TOKEN_FOLLOW_UP_SURVEY",
  GET_DOCUMENTS_LOCKER: "GET_DOCUMENTS_LOCKER",
  GET_PAYMENT_MOVEMENTS: "GET_PAYMENT_MOVEMENTS",
  GET_MOVEMENTS_CLUB_MEMBERSHIP: "GET_MOVEMENTS_CLUB_MEMBERSHIP",
  SEND_EMAIL_MOVEMENTS: "SEND_EMAIL_MOVEMENTS",
  OPEN_TAB_DOCUMENT_INSTANCE: "OPEN_TAB_DOCUMENT_INSTANCE",
  GET_CASHBOX_BY_ID: "GET_CASHBOX_BY_ID",
  GET_CHECKBOXES_BY_PERSON_ID: "GET_CHECKBOXES_BY_PERSON_ID",
  GET_CASHBOXES_BY_MEMBERSHIP: "GET_CASHBOXES_BY_MEMBERSHIP",
  GET_USERS_IN_EVENT: "GET_USERS_IN_EVENT",
  POST_MAINTENANCES_SCHEDULE: "POST_MAINTENANCES_SCHEDULE",
  POST_QUIZ_ADD: "POST_QUIZ_ADD",
  GET_QUIZZES: "GET_QUIZZES",
  GET_QUIZZES_QUESTIONS: "GET_QUIZZES_QUESTIONS",
  GET_SUBCATEGORIES: "GET_SUBCATEGORIES",
  GET_MEMBER_BY_CONFIGURATION: "GET_MEMBER_BY_CONFIGURATION",
  GET_DOCUMENTS_SPECIAL_GUEST: "GET_DOCUMENTS_SPECIAL_GUEST",
  SET_PROSPECT_TO_EDIT: "SET_PROSPECT_TO_EDIT",
  GET_MAINTENANCES_SCHEDULE: "GET_MAINTENANCES_SCHEDULE",
  RIGHTS_CHANGE: "RIGHTS_CHANGE",
  GET_GOALS: "GET_GOALS",
  GET_BILL: "GET_BILL",
  GET_ACCRUAL: "GET_ACCRUAL",
  GET_RETURN_INVOICE: "GET_RETURN_INVOICE",
  GET_TRANSACTION_BY_REFERENCE: "GET_TRANSACTION_BY_REFERENCE",
  UPDATE_ACCRUAL: "UPDATE_ACCRUAL",
  CANCEL_BILL: "CANCEL_BILL",
  REMAKE_BILL: "REMAKE_BILL",
  VALIDATE_REFERAL_CODE: "VALIDATE_REFERAL_CODE",
  PERSON_UPDATE_PHOTO: "PERSON_UPDATE_PHOTO",
  SAVE_RESPONSABLE: "SAVE_RESPONSABLE",
  GET_REPORT_ACCOUNTING_ACCOUNT: "GET_REPORT_ACCOUNTING_ACCOUNT",
  GET_TOTAL_SALES_REPORT: "GET_TOTAL_SALES_REPORT",
  GET_AMOUNT_SALES_REPORT: "GET_AMOUNT_SALES_REPORT",
  GET_CASH_CUT: "GET_CASH_CUT",
  CLEAR_CASH_CUT: "CLEAR_CASH_CUT",
  GET_SHIFT_DELIVERY: "GET_SHIFT_DELIVERY",
  GET_CREDIT_NOTES: "GET_CREDIT_NOTES",
  GET_SALES_PAYABLE: "GET_SALES_PAYABLE",
  PROCESS_CREDIT_NOTE: "PROCESS_CREDIT_NOTE",
  REJECT_CREDIT_NOTE_DEMAND: "REJECT_CREDIT_NOTE_DEMAND",
  RETRY_CREDIT_NOTE_REQUEST: "RETRY_CREDIT_NOTE_REQUEST",
  GET_CUSTOMIZABLE_RECEIPT: "GET_CUSTOMIZABLE_RECEIPT",
  GET_REFERENCE_SANTANDER_REPORT: "GET_REFERENCE_SANTANDER_REPORT",
  GET_REFERENCE_CONSECUTIVE_CLUB: "GET_REFERENCE_CONSECUTIVE_CLUB",
  CREATE_REFERENCES_BY_CLUB: "CREATE_REFERENCES_BY_CLUB",
  GET_REFERENCE_SANTANDER_REPORT_DA: "GET_REFERENCE_SANTANDER_REPORT_DA",
  GET_REPORT_EMPLOYEES: "GET_REPORT_EMPLOYEES",
  GET_REPORT_EMPLOYEES_CSV: "GET_REPORT_EMPLOYEES_CSV",
  GET_SANTANDER_REFERENCE_CSV_DA: "GET_SANTANDER_REFERENCE_CSV_DA",
  HISTORICAL_REBILLING: "HISTORICAL_REBILLING",
  REFUSE_CUSTOMIZABLE_RECEIPT: "REFUSE_CUSTOMIZABLE_RECEIPT",
  VALIDATE_CUSTOMIZABLE_RECEIPT: "VALIDATE_CUSTOMIZABLE_RECEIPT",
  PDF_CASH_CUT: "PDF_CASH_CUT",
  PDF_SHIFT_DELIVERY: "PDF_SHIFT_DELIVERY",
  PDF_PROVITIONAL_RECEIPT: "PDF_PROVITIONAL_RECEIPT",
  PDF_REFERENCED_DEPOSIT: "PDF_REFERENCED_DEPOSIT",
  GET_REPORT_WAY_TO_PAY: "GET_REPORT_WAY_TO_PAY",
  GET_FILE_ACCOUNTING_ACCOUNT: "GET_FILE_ACCOUNTING_ACCOUNT",
  GET_SANTANDER_REFERENCE_CSV: "GET_SANTANDER_REFERENCE_CSV",
  GET_FILE_WAY_TO_PAY: "GET_FILE_WAY_TO_PAY",
  GET_FILE_MONTHLY_ACCRUAL: "GET_FILE_MONTHLY_ACCRUAL",
  GET_FILE_ITEMS_TO_ACCRUAL_BY_SALE_DATE:
    "GET_FILE_ITEMS_TO_ACCRUAL_BY_SALE_DATE",
  GET_FILE_CAT_PRELIMINAR: "GET_FILE_CAT_PRELIMINAR",
  GET_FILE_PRICES_GRID: "GET_FILE_PRICES_GRID",
  GET_RESPONSABLES: "GET_RESPONSABLES",
  UPDATE_RESPONSABLE: "UPDATE_RESPONSABLE",
  DELETE_PERSON_PHONE: "DELETE_PERSON_PHONE",
  UPDATE_PERSON_PHONE: "UPDATE_PERSON_PHONE",
  DELETE_PERSON_CONTACT: "DELETE_PERSON_CONTACT",
  UPDATE_PERSON_CONTACT: "UPDATE_PERSON_CONTACT",
  DELETE_PERSON_ADDRESS: "DELETE_PERSON_ADDRESS",
  UPDATE_PERSON_ADDRESS: "UPDATE_PERSON_ADDRESS",
  DELETE_PERSON_EMAIL: "DELETE_PERSON_EMAIL",
  UPDATE_PERSON_EMAIL: "UPDATE_PERSON_EMAIL",

  GET_REFUNDS_REPORT: "GET_REFUNDS_REPORT",
  GET_REFUNDS_CSV: "GET_REFUNDS_CSV",
  AUTHORIZE_REFOUND: "AUTHORIZE_REFOUND",
  REFUSE_REFOUND: "REFUSE_REFOUND",
  CHARGEBACK_REFOUND: "CHARGEBACK_REFOUND",
  // pass day
  ACTIVATE_DAY_PASS: "ACTIVATE_DAY_PASS",
  GET_PASS_DAY_LIST: "GET_PASS_DAY_LIST",
  SAVE_DEMAND_DETAILS: "SAVE_DEMAND_DETAILS",

  // code creation
  CREATE_DISCOUT_CODES: "CREATE_DISCOUT_CODES",
  GET_DISCOUT_CODES_LIST: "GET_DISCOUT_CODES_LIST",
  CLEAR_DISCOUT_CODES: "CLEAR_DISCOUT_CODES",
  SET_PRODUCT_PHOTO: "SET_PRODUCT_PHOTO",
  SEND_PHOTO_PRODUCT_FILE: "SEND_PHOTO_PRODUCT_FILE",
  GET_QUOTE_BY_PERSON_ID: "GET_QUOTE_BY_PERSON_ID",
  GET_QUOTE_BY_MEMBERSHIP: "GET_QUOTE_BY_MEMBERSHIP",
  GET_QUOTE_FILTERED: "GET_QUOTE_FILTERED",
  GET_DISCOUNT_CODES_LIST_FILE: "GET_DISCOUNT_CODES_LIST_FILE",
  GET_DISCOUNT_CODES_LTE: "GET_DISCOUNT_CODES_LTE",

  //send individual billing
  SEND_BILLING: "SEND_BILLING",
  GET_BILLING_CATALOGS: "GET_BILLING_CATALOGS",
  GET_PRODUCTS_CLASSIFICATION_BY_LEVEL: "GET_PRODUCTS_CLASSIFICATION_BY_LEVEL",
  SEND_ACCRUAL: "SEND_ACCRUAL",
  SAVE_PAID_COMPLEMENT: "SAVE_PAID_COMPLEMENT",
  CLEAR_BILLING_REDUX: "CLEAR_BILLING_REDUX",
  SAVE_RETURN_INVOICE: "SAVE_RETURN_INVOICE",

  // send lte sells
  SEND_LTE_SELLS: "SEND_LTE_SELLS",
  SEND_LTE_SELLS_CLEAR: "SEND_LTE_SELLS_CLEAR",
  // InboxCalendar
  GET_INBOX_CALENDAR: "GET_INBOX_CALENDAR",
  GET_CALENDAR_TYPES: "GET_CALENDAR_TYPES",
  SAVE_INBOX_CALENDAR_BLOCKED: "SAVE_INBOX_CALENDAR_BLOCKED",

  // Reports
  GET_REPORT_COMISSION: "GET_REPORT_COMISSION",
  DOWNLOAD_REPORT_COMISSION: "DOWNLOAD_REPORT_COMISSION",
  GET_REPORT_REACTIVATION_COMISSION: "GET_REPORT_REACTIVATION_COMISSION",
  CLEAR_REPORT_REACTIVATION_COMISSION: "CLEAR_REPORT_REACTIVATION_COMISSION",
  GET_REPORT_MEMBERSHIP_SOLD: "GET_REPORT_MEMBERSHIP_SOLD",
  GET_REPORT_SPORTS_PROGRAM_COMMISSIONS:
    "GET_REPORT_SPORTS_PROGRAM_COMMISSIONS",
  REASIGN_MEMBERSHIP: "REASIGN_MEMBERSHIP",
  CLEAR_REASIGN_MEMBERSHIP: "CLEAR_REASIGN_MEMBERSHIP",
  AUTHORIZE_COMISSION: "AUTHORIZE_COMISSION",
  GET_FILE_SPORTS_PROGRAM_COMMISSIONS: "GET_FILE_SPORTS_PROGRAM_COMMISSIONS",
  GET_DEPOSITO_REFERENCIADO: "GET_DEPOSITO_REFERENCIADO",
  GET_REPORT_COMISSION_SALE: "GET_REPORT_COMISSION_SALE",
  GET_MONTH_PERIODS: "GET_MONTH_PERIODS",

  //Benefits Schemas
  GET_INDICATORS: "GET_INDICATORS",
  GET_BENEFITS_SCHEMAS: "GET_BENEFITS_SCHEMAS",
  CREATE_COMISSIONS_SCHEMA: "CREATE_COMISSIONS_SCHEMA",
  GET_SCHEMA_BY_ID: "GET_SCHEMA_BY_ID",
  CLEAN_SCHEMA_BY_ID: "CLEAN_SCHEMA_BY_ID",
  GET_SCHEMAS: "GET_SCHEMAS",
  SAVE_POSTS_BYSCHEMA: "SAVE_POSTS_BYSCHEMA",
  GET_POSTS_BYSCHEMA: "GET_POSTS_BYSCHEMA",
  CLEAN_PETICION_DATA: "CLEAN_PETICION_DATA",

  //Messages
  GET_MESSAGES_BY_MEMBRESIA: "GET_MESSAGES_BY_MEMBRESIA",

  //Dealers
  GET_DEALERS_LIST: "GET_DEALERS_LIST",
  GET_VARIABLES_CHARGE_DEALERS: "GET_VARIABLES_CHARGE_DEALERS",
  GENERATE_VARIABLE_CHARGE: "GENERATE_VARIABLE_CHARGE",
  ADD_DEALER_CONFIG_CHARGE: "ADD_DEALER_CONFIG_CHARGE",
  UPDATE_DEALER_CONFIG_CHARGE: "UPDATE_DEALER_CONFIG_CHARGE",
  GET_DEALERS: "GET_DEALERS",
  ADD_DEALERS: "ADD_DEALERS",
  GET_CLUB_DEALER: "GET_CLUB_DEALER",
  UPDATE_CLUB_DEALER: "UPDATE_CLUB_DEALER",
  DELETE_DEALER: "DELETE_DEALER",
  DELETE_CHARGE_DEALER: "DELETE_CHARGE_DEALER",
  GET_SELECTED_DEALER_CHARGE: "GET_SELECTED_DEALER_CHARGE",
  GET_DEALERS_MOVEMENTS: "GET_DEALERS_MOVEMENTS",
  GET_DEALERS_STATUS_REPORT: "GET_DEALERS_STATUS_REPORT",
  CLEAR_DEALERS_REDUX: "CLEAR_DEALERS_REDUX",
  ACCEPT_CHARGE: "ACCEPT_CHARGE",
  GET_DEALERS_CHARGES: "GET_DEALERS_CHARGES",

  //Periods
  GET_PERIODS: "GET_PERIODS",
  GET_PAST_PERIODS: "GET_PAST_PERIODS",
  SCHEMAS_BY_PERIOD: "SCHEMAS_BY_PERIOD",
  GET_PERIOD_BY_ID: "GET_PERIOD_BY_ID",
  PROMOTION_PRICE_CELL: "PROMOTION_PRICE_CELL",
  GET_MEMBERSHIP_MEMBERS_TYPES: "GET_MEMBERSHIP_MEMBERS_TYPES",
  AGREEMENT_PRICE_CELL: "AGREEMENT_PRICE_CELL",
  CONFIRM_CHANGES_BENEFITS_MODAL: "CONFIRM_CHANGES_BENEFITS_MODAL",
  CHECK_LAST_MONTH: "CHECK_LAST_MONTH",
  CHECK_LAST_MONTH_AGREEMENT: "CHECK_LAST_MONTH_AGREEMENT",
  CHECK_ABSOLUTE: "CHECK_ABSOLUTE",
  CHECK_ABSOLUTE_AGREEMENT: "CHECK_ABSOLUTE_AGREEMENT",
  PROMOTION_MEMBERSHIP_VALUE: "PROMOTION_MEMBERSHIP_VALUE",
  AGREEMENT_MEMBERSHIP_VALUE: "AGREEMENT_MEMBERSHIP_VALUE",
  PROMOTION_MEMBERSHIP_VALUE_TYPE: "PROMOTION_MEMBERSHIP_VALUE_TYPE",
  AGREEMENT_MEMBERSHIP_VALUE_TYPE: "AGREEMENT_MEMBERSHIP_VALUE_TYPE",
  PROMOTION_MEMBERSHIP_VALUE_TYPE_MONTH:
    "PROMOTION_MEMBERSHIP_VALUE_TYPE_MONTH",
  AGREEMENT_MEMBERSHIP_VALUE_TYPE_MONTH:
    "AGREEMENT_MEMBERSHIP_VALUE_TYPE_MONTH",
  PROMOTION_BY_ID: "PROMOTION_BY_ID",
  SAVE_QUOTE_SPORTS_PRODUCTS: "SAVE_QUOTE_SPORTS_PRODUCTS",
  LOAD_QUOTE_SPORTS_PRODUCTS: "LOAD_QUOTE_SPORTS_PRODUCTS",
  PENDING_PAYMENT: "PENDING_PAYMENT",
  SEND_MEMBERS_RULES: "SEND_MEMBERS_RULES",
  LINK_PARTNER_TOGGLE: "LINK_PARTNER_TOGGLE",
  LINK_FREE_PARTNER_TOGGLE: "LINK_FREE_PARTNER_TOGGLE",
  LINK_CHANGE_FREE_PARTNER_TOGGLE: "LINK_CHANGE_FREE_PARTNER_TOGGLE",
  LINK_FREE_CHILD_PARTNER_TOGGLE: "LINK_FREE_CHILD_PARTNER_TOGGLE",
  ADD_PERSON_TOGGLE: "ADD_PERSON_TOGGLE",
  GET_SURVEYS: "GET_SURVEYS",
  GET_MEMBERS_RULES: "GET_MEMBERS_RULES",
  GET_PAYROLL_CONCEPT: "GET_PAYROLL_CONCEPT",
  POST_PAYROLL_CONCEPT_SAVE: "POST_PAYROLL_CONCEPT_SAVE",
  GET_PAYROLL_CONCEPT_SELECT: "GET_PAYROLL_CONCEPT_SELECT",
  GET_MAINTENANCES_MEMBERSHIP: "GET_MAINTENANCES_MEMBERSHIP",
  GET_HISTORY_AUT_CHARGES: "GET_HISTORY_AUT_CHARGES",
  GET_PROMOTIONS_FILTERED: "GET_PROMOTIONS_FILTERED",
  GET_MEMBERSHIP_MAINTENANCE: "GET_MEMBERSHIP_MAINTENANCE",
  SAVE_CHANGES_BENEFITS: "SAVE_CHANGES_BENEFITS",
  SEARCH_AGREEMENTS_BY_ATTRIBUTES: "SEARCH_AGREEMENTS_BY_ATTRIBUTES",
  GET_AGREEMENTS_PROMOTION: "GET_AGREEMENTS_PROMOTION",
  ADD_AGREEMENTS_PROMOTION: "ADD_AGREEMENTS_PROMOTION",
  SAVE_CHANGES_BENEFITS_AGREEMENT: "SAVE_CHANGES_BENEFITS_AGREEMENT",
  GET_MEMBERSHIP_BY_ID: "GET_MEMBERSHIP_BY_ID",
  SAVE_ROLE: "SAVE_ROLE",
  PERMISSION_TREE: "PERMISSION_TREE",
  MEMBERSHIP_PURCHASE: "MEMBERSHIP_PURCHASE",
  MEMBERSHIP_CHANGED: "MEMBERSHIP_CHANGED",
  MEMBERSHIP_CHANGED_AGREEMENT: "MEMBERSHIP_CHANGED_AGREEMENT",
  CLUB_CHANGED: "CLUB_CHANGED",
  CLUB_CHANGED_AGREEMENT: "CLUB_CHANGED_AGREEMENT",
  DELETE_MONTH_PRICES: "DELETE_MONTH_PRICES",
  DELETE_MONTH_PRICES_AGREEMENT: "DELETE_MONTH_PRICES_AGREEMENT",
  CANCEL_BENEFITS_CHANGES: "CANCEL_BENEFITS_CHANGES",
  CONFIRM_BENEFITS_CHANGES: "CONFIRM_BENEFITS_CHANGES",
  ADD_MONTH_PRICES: "ADD_MONTH_PRICES",
  ADD_MONTH_PRICES_AGREEMENT: "ADD_MONTH_PRICES_AGREEMENT",
  CHECK_PROPORTIONAL: "CHECK_PROPORTIONAL",
  PROMOTION_BENEFIT_FREE_USER_MIRROR: "PROMOTION_BENEFIT_FREE_USER_MIRROR",
  PROMOTION_BENEFIT_FREE_USER_BY_BIRTHDAY:
    "PROMOTION_BENEFIT_FREE_USER_BY_BIRTHDAY",
  PROMOTION_BENEFIT_FREE_USER_NUMBER: "PROMOTION_BENEFIT_FREE_USER_NUMBER",
  PROMOTION_BENEFIT_FREE_USER_INITIAL_DATE:
    "PROMOTION_BENEFIT_FREE_USER_INITIAL_DATE",
  PROMOTION_BENEFIT_FREE_USER_FINAL_DATE:
    "PROMOTION_BENEFIT_FREE_USER_FINAL_DATE",
  PROMOTION_BENEFIT_FREE_USER_MONTHS: "PROMOTION_BENEFIT_FREE_USER_MONTHS",
  PROMOTION_BENEFIT_FREE_GUESTS_PASSES_NUMBER:
    "PROMOTION_BENEFIT_FREE_GUESTS_PASSES_NUMBER",
  PROMOTION_BENEFIT_FREE_CHILDREN_NUMBER:
    "PROMOTION_BENEFIT_FREE_CHILDREN_NUMBER",
  CHECK_PROPORTIONAL_AGREEMENT: "CHECK_PROPORTIONAL_AGREEMENT",
  GET_ROLE_BY_ID: "GET_ROLE_BY_ID",
  GET_MEMBERSHIPS_PROMOTION: "GET_MEMBERSHIPS_PROMOTION",
  GET_MEMBERSHIPS_AGREEMENT: "GET_MEMBERSHIPS_AGREEMENT",
  GET_PRICES_PROMOTION: "GET_PRICES_PROMOTION",
  CREATE_PROSPECT_TOGGLE: "CREATE_PROSPECT_TOGGLE",
  SAVE_BASIC_PROMOTION: "SAVE_BASIC_PROMOTION",
  UPDATE_BASIC_PROMOTION: "UPDATE_BASIC_PROMOTION",
  ADD_MAINTENANCES_PROMOTION: "ADD_MAINTENANCES_PROMOTION",
  GET_MAINTENANCES_PROMOTION: "GET_MAINTENANCES_PROMOTION",
  GET_BASIC_PROMOTION: "GET_BASIC_PROMOTION",
  SEND_PRICES_FILE: "SEND_PRICES_FILE",
  SET_PRICES_FILE: "SET_PRICES_FILE",
  DELETE_PROSPECT_TOGGLE: "DELETE_PROSPECT_TOGGLE",
  AUTHORIZE_MEMBERSHIP_PRICES: "AUTHORIZE_MEMBERSHIP_PRICES",
  AUTHORIZE_MAINTENANCES_PRICES: "AUTHORIZE_MAINTENANCES_PRICES",
  FUNCTIONALITY_TREE: "FUNCTIONALITY_TREE",
  ACTIONS_PROSPECT_TOGGLE: "ACTIONS_PROSPECT_TOGGLE",
  CREATE_AGREEMENT_TOGGLE: "CREATE_AGREEMENT_TOGGLE",
  STATUS_ACTIVITY_TOGGLE: "STATUS_ACTIVITY_TOGGLE",
  SHOW_PROMOTION_DETAIL_TOGGLE: "SHOW_PROMOTION_DETAIL_TOGGLE",
  SHOW_AGREEMENT_DETAIL_TOGGLE: "SHOW_AGREEMENT_DETAIL_TOGGLE",
  CREATE_CLUB_TOGGLE: "CREATE_CLUB_TOGGLE",
  ACTIONS_LOG_PROSPECT_TOGGLE: "ACTIONS_LOG_PROSPECT_TOGGLE",
  STATUS_PROSPECT_TOGGLE: "STATUS_PROSPECT_TOGGLE",
  CREATE_PROSPECT: "CREATE_PROSPECT",
  CLEAR_CREATE_PROSPECT_VARIABLE: "CLEAR_CREATE_PROSPECT_VARIABLE",
  CREATE_AGREEMENT: "CREATE_AGREEMENT",
  CREATE_CLUB: "CREATE_CLUB",
  PATCH_PROSPECT: "PATCH_PROSPECT",
  GET_PRODUCTS_CATEGORIES: "GET_PRODUCTS_CATEGORIES",
  GET_ACCESS_PRODUCT: "GET_ACCESS_PRODUCT",
  ADD_ACCESS_PRODUCT: "ADD_ACCESS_PRODUCT",
  CREATE_ACTIVITY: "CREATE_ACTIVITY",
  ADD_CLUBS_PROMOTION: "ADD_CLUBS_PROMOTION",
  GET_CLUBS_PROMOTION: "GET_CLUBS_PROMOTION",
  DELETE_PROSPECT: "DELETE_PROSPECT",
  GET_MASSIVE_PRICES_FILE: "GET_MASSIVE_FILE",
  GET_AGREEMENTS: "GET_AGREEMENTS",
  GET_BASIC_AGREEMENTS: "GET_BASIC_AGREEMENTS",
  FILTER_AGREEMENTS: "FILTER_AGREEMENTS",
  GET_PROSPECTS_ACTIONS_LOG: "GET_PROSPECTS_ACTIONS_LOG",
  GET_PROSPECTS_REPEATED: "GET_PROSPECTS_REPEATED",
  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
  GET_ITEM_LIST: "GET_ITEM_LIST",
  GET_USERS: "GET_USERS",
  GET_GENERAL_MANAGERS: "GET_GENERAL_MANAGERS",
  SET_USER_CONTEXT: "SET_USER_CONTEXT",
  SET_EDITABLE_USER: "SET_EDITABLE_USER",
  EDIT_ACTIVITY_STATUS: "EDIT_ACTIVITY_STATUS",
  GET_USER_SSP: "GET_USER_SSP",
  LOGOUT_USER: "LOGOUT_USER",
  LOGIN_USER_SSP: "LOGIN_USER_SSP",
  RESEND_ACTIVATION_EMAIL: "RESEND_ACTIVATION_EMAIL",
  ACTIVATE_ACCOUNT: "ACTIVATE_ACCOUNT",
  CREATE_USER: "CREATE_USER",
  SAVE_BASIC_PRODUCT: "SAVE_BASIC_PRODUCT",
  REQUEST_RESET_PASSWORD: "REQUEST_RESET_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  ADD_CLUBS_PRODUCT: "ADD_CLUBS_PRODUCT",
  ADD_PRICES_PRODUCT: "ADD_PRICES_PRODUCT",
  GET_PRICES_PRODUCT: "GET_PRICES_PRODUCT",
  CREATE_ITEM: "CREATE_ITEM",
  EDIT_ITEM: "EDIT_ITEM",
  DELETE_ITEM: "DELETE_ITEM",
  SET_EDITABLE_ITEM: "SET_EDITABLE_ITEM",
  WHO_AM_I: "WHO_AM_I",
  GET_PROSPECTS: "GET_PROSPECTS",
  GET_CLUBS: "GET_CLUBS",
  GET_ALL_CLUBS: "GET_ALL_CLUBS",
  GET_ALL_CLUBS_BASIC_DATA: "GET_ALL_CLUBS_BASIC_DATA",
  GET_ALLOWED_CLUBS: "GET_ALLOWED_CLUBS",
  CLEAN_GLOBAL_STATE: "CLEAN_GLOBAL_STATE",
  DISABLE_AGREEMENT_CREATED: "DISABLE_AGREEMENT_CREATED",
  DISABLE_BASIC_AGREEMENT_SAVED: "DISABLE_BASIC_AGREEMENT_SAVED",
  GET_CLUB_SELLERS: "GET_CLUB_SELLERS",
  GET_CLUB_GENERAL_MANAGERS: "GET_CLUB_GENERAL_MANAGERS",
  CLEAR_ALLOWED_CLUBS: "CLEAR_ALLOWED_CLUBS",
  DISABLE_CLUB_CREATED: "DISABLE_CLUB_CREATED",
  GET_BI_INDICATORS: "GET_BI_INDICATORS",
  UPDATE_PROSPECT_DATA_BY_ID: "UPDATE_PROSPECT_DATA_BY_ID",
  GET_PROSPECT_BY_ID: "GET_PROSPECT_BY_ID",
  CLEAN_PROSPECT_BY_ID: "CLEAN_PROSPECT_BY_ID",
  GET_BASIC_PRODUCT: "GET_BASIC_PRODUCT",
  GET_QUOTES_BY_SELLER: "GET_QUOTES_BY_SELLER",
  UPDATE_BASIC_PRODUCT: "UPDATE_BASIC_PRODUCT",
  GET_PRODUCT_CONFIG: "GET_PRODUCT_CONFIG",
  SAVE_PRODUCT_CONFIG: "SAVE_PRODUCT_CONFIG",
  UPDATE_PRODUCT_CONFIG: "UPDATE_PRODUCT_CONFIG",
  GET_MEMBERSHIPS: "GET_MEMBERSHIPS",
  GET_PRODUCTS: "GET_PRODUCTS",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  GET_CLUBS_PRODUCT: "GET_CLUBS_PRODUCT",
  GET_PRODUCT_ACCOUNTING_ACCOUNTS: "GET_PRODUCT_ACCOUNTING_ACCOUNTS",
  ADD_PRICES_MAINTENANCE: "ADD_PRICES_MAINTENANCE",
  GET_PRICES_MAINTENANCE: "GET_PRICES_MAINTENANCE",
  GET_PRICES_MAINTENANCES: "GET_PRICES_MAINTENANCES",
  SAVE_REASSIGNMENT: "SAVE_REASSIGNMENT",
  SEARCH_MAINTENANCES_BY_ATTRIBUTES: "SEARCH_MAINTENANCES_BY_ATTRIBUTES",
  GET_PRICES_AGREEMENT: "GET_PRICES_AGREEMENT",
  GET_PROMOTIONS: "GET_PROMOTIONS",
  GET_PROMOTION_DETAIL: "GET_PROMOTION_DETAIL",
  GET_AGREEMENT_DETAIL: "GET_AGREEMENT_DETAIL",
  GET_QUOTATIONS: "GET_QUOTATIONS",
  GET_ACTUAL_QUOTE: "GET_ACTUAL_QUOTE",
  GET_FUTURE_QUOTE: "GET_FUTURE_QUOTE",
  SWITCH_MEMBERSHIP: "SWITCH_MEMBERSHIP",
  GET_ROLES: "GET_ROLES",
  SAVE_BASIC_USER: "SAVE_BASIC_USER",
  CLEAR_BASIC_USER: "CLEAR_BASIC_USER",
  UPDATE_BASIC_USER: "UPDATE_BASIC_USER",
  GET_BASIC_USER: "GET_BASIC_USER",
  GET_USER_BY_ID: "GET_USER_BY_ID",
  GET_KITS: "GET_KITS",
  GET_RESPONSIBLES_KITS: "GET_RESPONSIBLES_KITS",
  ASSIGN_TAG: "ASSIGN_TAG",
  DELLOCATION_TAG: "DELLOCATION_TAG",
  SAVE_ROLES_USER: "SAVE_ROLES_USER",
  GET_ROLES_USER: "GET_ROLES_USER",
  CLEAR_ROLES_USER: "CLEAR_ROLES_USER",
  GET_AGREEMENT_BY_ID: "GET_AGREEMENT_BY_ID",
  SAVE_BASIC_AGREEMENT: "SAVE_BASIC_AGREEMENT",
  UPDATE_BASIC_AGREEMENT: "UPDATE_BASIC_AGREEMENT",
  ADD_CLUBS_AGREEMENT: "ADD_CLUBS_AGREEMENT",
  GET_CLUBS_AGREEMENT: "GET_CLUBS_AGREEMENT",
  ADD_MAINTENANCES_AGREEMENT: "ADD_MAINTENANCES_AGREEMENT",
  GET_MAINTENANCES_AGREEMENT: "GET_MAINTENANCES_AGREEMENT",
  CLEAN_SUCCESSFULL_PURCHASE: "CLEAN_SUCCESSFULL_PURCHASE",
  MEMBERS_TYPE_MEMBERSHIP: "MEMBERS_TYPE_MEMBERSHIP",
  SAVE_USER_PERMITS: "SAVE_USER_PERMITS",
  GET_USER_PERMITS: "GET_USER_PERMITS",
  GET_PRODUCTS_ALL_TYPES: "GET_PRODUCTS_ALL_TYPES",
  ACTIVATE_PRODUCTS: "ACTIVATE_PRODUCTS",
  SAVE_USER_MIT_TERMINAL_CREDENTIALS: "SAVE_USER_MIT_TERMINAL_CREDENTIALS",
  GET_USER_MIT_TERMINAL_CREDENTIALS: "GET_USER_MIT_TERMINAL_CREDENTIALS",
  //Partners
  GET_PARTNER: "GET_PARTNER",
  CLEAN_DOCUMENT: "CLEAN_DOCUMENT",
  SEND_SCANNED_FILE: "SEND_SCANNED_FILE",
  GET_PARTNERS_MEMBERSHIP: "GET_PARTNERS_MEMBERSHIP",
  GET_PARTNER_TYPES: "GET_PARTNER_TYPES",
  LINK_PARTNER: "LINK_PARTNER",
  ADD_MESSAGE: "ADD_MESSAGE",
  UPDATE_MESSAGE: "UPDATE_MESSAGE",
  UPDATE_TITULAR: "UPDATE_TITULAR",
  UPDATE_CONVENIO: "UPDATE_CONVENIO",
  CREATE_PARTNER: "CREATE_PARTNER",
  SAVE_SURVEYS: "SAVE_SURVEYS",
  GET_DOCUMENT: "GET_DOCUMENT",
  SIGN_DOCUMENT: "SIGN_DOCUMENT",
  GET_DOCUMENT_TO_SIGN: "GET_DOCUMENT_TO_SIGN",
  PRINT_DOCUMENT: "PRINT_DOCUMENT",
  PARTNER_UPDATE_PHOTO: "PARTNER_UPDATE_PHOTO",
  PARTNER_UPDATE_PAW_PRINT: "PARTNER_UPDATE_PAW_PRINT",
  PARTNER_ACCESS_REGISTER: "PARTNER_ACCESS_REGISTER",
  GET_MASSIVE_PRICES_MEMBERSHIP: "GET_MASSIVE_PRICES_MEMBERSHIP",
  PARTNER_SAVE_BANK_CARD: "PARTNER_SAVE_BANK_CARD",
  PARTNER_UPDATE_BANK_CARD: "PARTNER_UPDATE_BANK_CARD",
  PARTNER_DELETE_BANK_CARD: "PARTNER_DELETE_BANK_CARD",
  GET_PARTNER_BANK_CARDS: "GET_PARTNER_BANK_CARDS",
  PARTNER_ADD_BANK_CARD_TOGGLE: "PARTNER_ADD_BANK_CARD_TOGGLE",
  GET_DOCUMENT_SIGNATURE: "GET_DOCUMENT_SIGNATURE",
  AUTHORIZE_EXPEDIENT: "AUTHORIZE_EXPEDIENT",
  DELETE_PARTNER: "DELETE_PARTNER",
  DELETE_PARTNER_MODAL: "DELETE_PARTNER_MODAL",
  CHANGE_MEMBERSHIP_MODAL: "CHANGE_MEMBERSHIP_MODAL",
  CHANGE_MEMBERSHIP: "CHANGE_MEMBERSHIP",
  CREATE_MAINTENANCE_CHARGES: "CREATE_MAINTENANCE_CHARGES",
  CREATE_MAINTENANCE_DISCOUNT: "CREATE_MAINTENANCE_DISCOUNT",
  GET_MEDIOS_PAGO_ELIMINADOS: "GET_MEDIOS_PAGO_ELIMINADOS",
  GET_MEDIOS_PAGO_ELIMINADOS_REPORTE: "GET_MEDIOS_PAGO_ELIMINADOS_REPORTE",
  // Catalogs
  GET_CATALOG_CONCEPTS: "GET_CATALOG_CONCEPTS",
  GET_CATALOG_STATUS: "GET_CATALOG_STATUS",
  GET_CATALOG_ACCOUNTING_ACCOUNTS: "GET_CATALOG_ACCOUNTING_ACCOUNTS",
  GET_CATALOG_ACCRUAL: "GET_CATALOG_ACCRUAL",
  GET_CATALOG_PAYMENT_TYPES: "GET_CATALOG_PAYMENT_TYPES",
  GET_CATALOG_CLUBS: "GET_CATALOG_CLUBS",
  GET_CATALOG_CLUBS_MIGRADO_ACTIVO: "GET_CATALOG_CLUBS_MIGRADO_ACTIVO",
  GET_CATALOG_CLUBS_MIGRADO_INACTIVO: "GET_CATALOG_CLUBS_MIGRADO_INACTIVO",
  GET_CATALOG_SELLERS: "GET_CATALOG_SELLERS",
  GET_CATALOG_MEMBERSHIPS_TYPES: "GET_CATALOG_MEMBERSHIPS_TYPES",
  GET_CATALOG_AGREEMENTS: "GET_CATALOG_AGREEMENTS",
  GET_CATALOG_MAINTENANCES: "GET_CATALOG_MAINTENANCES",
  GET_CATALOG_PROMOTIONS: "GET_CATALOG_PROMOTIONS",
  GET_CATALOG_ROLES: "GET_CATALOG_ROLES",
  GET_CATALOG_DOMAINS: "GET_CATALOG_DOMAINS",
  GET_CATALOG_SPORTS_PRODUCTS_TYPES: "GET_CATALOG_SPORTS_PRODUCTS_TYPES",
  GET_CATALOG_SPORTS_PRODUCTS: "GET_CATALOG_SPORTS_PRODUCTS",
  GET_CATALOG_PERSON_TYPES: "GET_CATALOG_PERSON_TYPES",
  GET_CATALOG_SAT_PRODUCTS: "GET_CATALOG_SAT_PRODUCTS",
  GET_CATALOG_SAT_UNITS: "GET_CATALOG_SAT_UNITS",
  GET_CATALOG_PRODUCT_ACCOUNTS: "GET_CATALOG_PRODUCT_ACCOUNTS",
  GET_CATALOG_MEMBERSHIPS: "GET_CATALOG_MEMBERSHIPS",
  GET_CATALOG_BANKS_MONTHS_WITHOUT_INTEREST:
    "GET_CATALOG_BANKS_MONTHS_WITHOUT_INTEREST",
  GET_CATALOG_WAYS_PAY: "GET_CATALOG_WAYS_PAY",
  GET_CATALOG_POSITIONS: "GET_CATALOG_POSITIONS",
  GET_CATALOG_BANK_PAY_TYPES: "GET_CATALOG_BANK_PAY_TYPES",
  GET_CATALOG_CARD_TYPES: "GET_CATALOG_CARD_TYPES",
  GET_CATALOG_BANKS: "GET_CATALOG_BANKS",
  GET_CATALOG_SURVEY_TYPES: "GET_CATALOG_SURVEY_TYPES",
  GET_CATALOG_ANNUITY_TYPES: "GET_CATALOG_ANNUITY_TYPES",
  GET_CATALOG_COMPANIES: "GET_CATALOG_COMPANIES",
  GET_CATALOG_USERS: "GET_CATALOG_USERS",
  REACTIVATE_MEMBERSHIP: "REACTIVATE_MEMBERSHIP",

  //Persons
  GET_PERSONS: "GET_PERSONS",
  GET_PERSON_BY_ID: "GET_PERSON_BY_ID",
  CREATE_PERSON: "CREATE_PERSON",
  SET_SELECTED_PERSON: "SET_SELECTED_PERSON",
  CLEAR_SELECTED_PERSON: "CLEAR_SELECTED_PERSON",

  //Documents
  GET_DOCUMENTS: "GET_DOCUMENTS",
  SAVE_BASIC_DOCUMENT: "SAVE_BASIC_DOCUMENT",
  UPDATE_BASIC_DOCUMENT: "UPDATE_BASIC_DOCUMENT",
  GET_BASIC_DOCUMENT: "GET_BASIC_DOCUMENT",
  GET_MEMBERSHIPS_DOCUMENT: "GET_MEMBERSHIPS_DOCUMENT",
  ADD_MEMBERSHIPS_DOCUMENT: "ADD_MEMBERSHIPS_DOCUMENT",
  SEND_EMAIL_FILE: "SEND_EMAIL_FILE",
  RECREATE_FILE: "RECREATE_FILE",
  GET_PARTNER_DOCUMENTS: "GET_PARTNER_DOCUMENTS",
  GET_DOCUMENTS_EXPEDIENTS: "GET_DOCUMENTS_EXPEDIENTS",
  GET_DOCUMENTS_EXPEDIENTS_PAGINATION: "GET_DOCUMENTS_EXPEDIENTS_PAGINATION",
  AUTHORIZE_DOCUMENT: "AUTHORIZE_DOCUMENT",

  // SportsProducts
  GET_SPORTS_PRODUCTS: "GET_SPORTS_PRODUCTS",
  SAVE_BASIC_SPORTS_PRODUCTS: "SAVE_BASIC_SPORTS_PRODUCTS",
  GET_BASIC_SPORTS_PRODUCTS: "GET_BASIC_SPORTS_PRODUCTS",
  GET_GROUPS_SPORTS_PRODUCTS: "GET_GROUPS_SPORTS_PRODUCTS",
  UPDATE_GROUPS_SPORTS_PRODUCTS: "UPDATE_GROUPS_SPORTS_PRODUCTS",
  GET_PRICES_SPORTS_PRODUCTS: "GET_PRICES_SPORTS_PRODUCTS",
  GET_PRICES_SPORTS_PRODUCTS_BY_DATE: "GET_PRICES_SPORTS_PRODUCTS_BY_DATE",
  ADD_PRICES_SPORTS_PRODUCTS: "ADD_PRICES_SPORTS_PRODUCTS",
  AUTHORIZE_SPORTS_PRODUCTS_PRICES: "AUTHORIZE_SPORTS_PRODUCTS_PRICES",
  SAVE_CONFIG_SPORTS_PRODUCTS: "SAVE_CONFIG_SPORTS_PRODUCTS",
  GET_CONFIG_SPORTS_PRODUCTS: "GET_CONFIG_SPORTS_PRODUCTS",
  GET_PROMOTIONS_SPORTS_PRODUCTS: "GET_PROMOTIONS_SPORTS_PRODUCTS",
  SAVE_PROMOTION_SPORTS_PRODUCTS: "SAVE_PROMOTION_SPORTS_PRODUCTS",
  UPDATE_PROMOTION_SPORTS_PRODUCTS: "UPDATE_PROMOTION_SPORTS_PRODUCTS",
  GET_SPORTS_PRODUCTS_BY_FILTERS: "GET_SPORTS_PRODUCTS_BY_FILTERS",
  GET_SPORT_PRODUCT_QUOTE: "GET_SPORT_PRODUCT_QUOTE",
  CLEAN_SPORTS_PRODUCTS: "CLEAN_SPORTS_PRODUCTS",
  CLEAN_PROMOTIONS: "CLEAN_PROMOTIONS",
  CLEAN_QUOTATION: "CLEAN_QUOTATION",
  GET_PROMOTION_BENEFITS_SPORTS_PRODUCTS:
    "GET_PROMOTION_BENEFITS_SPORTS_PRODUCTS",
  SAVE_COMISSIONS_SPORTS_PRODUCTS: "SAVE_COMISSIONS_SPORTS_PRODUCTS",
  AUTORIZE_COMISSIONS: "AUTORIZE_COMISSIONS",
  GET_COMISSIONS_SPORTS_PRODUCTS: "GET_COMISSIONS_SPORTS_PRODUCTS",
  GET_COMISSIONS_SPORTS_PRODUCTS_COORDINATION:
    "GET_COMISSIONS_SPORTS_PRODUCTS_COORDINATION",
  GET_COMISSIONS_SPORTS_PRODUCTS_OPERATION:
    "GET_COMISSIONS_SPORTS_PRODUCTS_OPERATION",
  GET_OPERATION_COMISSIONS_SPORTS_PRODUCTS:
    "GET_OPERATION_COMISSIONS_SPORTS_PRODUCTS",
  SAVE_OPERATION_COMISSIONS_SPORTS_PRODUCTS:
    "SAVE_OPERATION_COMISSIONS_SPORTS_PRODUCTS",
  GET_SPORT_PRODUCT_BY_ID: "GET_SPORT_PRODUCT_BY_ID",
  GET_CLUBS_SPORTS_PRODUCTS: "GET_CLUBS_SPORTS_PRODUCTS",
  GET_SPORT_PRODUCT_QUOTE_BY_ID: "GET_SPORT_PRODUCT_QUOTE_BY_ID",
  SPORTS_PRODUCTS_PAY_QUOTE: "SPORTS_PRODUCTS_PAY_QUOTE",
  PAY_PENDING: "PAY_PENDING",
  //Banks
  GET_BANKS: "GET_BANKS",
  GET_MONTHS_WITHOUT_INTEREST: "GET_MONTHS_WITHOUT_INTEREST",
  CREATE_BANK_TOGGLE: "CREATE_BANK_TOGGLE",
  EDIT_BANK_MONTHS_WITHOUT_INTEREST_TOGGLE:
    "EDIT_BANK_MONTHS_WITHOUT_INTEREST_TOGGLE",
  CREATE_BANK: "CREATE_BANK",
  DISABLE_BANK_CREATED: "DISABLE_BANK_CREATED",
  CREATE_MONTHS_WITHOUT_INTEREST: "CREATE_MONTHS_WITHOUT_INTEREST",
  CLEAR_BASICDATASAVED_SPORTS_PRODUCTS: "CLEAR_BASICDATASAVED_SPORTS_PRODUCTS",
  CREATE_CREDIT_NOTE: "CREATE_CREDIT_NOTE",

  //Annuities
  GET_ANNUITY: "GET_ANNUITY",
  SAVE_ANNUITY: "SAVE_ANNUITY",
  ADD_PHASE_TOGGLE: "ADD_PHASE_TOGGLE",
  GET_ANNUITY_QUOTE: "GET_ANNUITY_QUOTE",
  SAVE_ANNUITY_QUOTE: "SAVE_ANNUITY_QUOTE",
  GET_ANNUITY_QUOTE_BY_ID: "GET_ANNUITY_QUOTE_BY_ID",
  PAY_ANNUITY_QUOTE_BY_ID: "PAY_ANNUITY_QUOTE_BY_ID",
  GET_ANNUITY_GUEST_PASSES: "GET_ANNUITY_GUEST_PASSES",

  //GOALS
  SAVE_GOALS: "SAVE_GOALS",

  //LOCKERS
  GET_ASSIGNED_LOCKERS: "GET_ASSIGNED_LOCKERS",
  GET_LOCKERS_WALLS_BY_CLUB_ID: "GET_LOCKERS_WALLS_BY_CLUB_ID",
  GET_LOCKERS_BY_WALL_ID: "GET_LOCKERS_BY_WALL_ID",
  GET_LOCKERS_PRODUCTS: "GET_LOCKERS_PRODUCTS",
  GET_LOCKER_PRODUCT: "GET_LOCKER_PRODUCT",
  SAVE_LOCKER_ASSIGNMENT: "SAVE_LOCKER_ASSIGNMENT",
  PUT_LOCKER_EXTENSION: "PUT_LOCKER_EXTENSION",
  GET_LOCKERS_SUMMARY_BY_CLUB: "GET_LOCKERS_SUMMARY_BY_CLUB",
  GET_LOCKERS_FILE: "GET_LOCKERS_FILE",

  //EVENTS
  GET_EVENTS: "GET_EVENTS",
  CLEAR_EVENTS: "CLEAR_EVENTS",
  GET_EVENT_DETAILS: "GET_EVENT_DETAILS",
  ADD_ATTENDANT_TO_EVENT: "ADD_ATTENDANT_TO_EVENT",
  DELETE_ATTENDANT_TO_EVENT: "DELETE_ATTENDANT_TO_EVENT",
  DELETE_SESSION: "DELETE_SESSION",
  ADD_SESSION: "ADD_SESSION",
  EDIT_SESSION: "EDIT_SESSION",
  EDIT_SESSION_MODAL: "EDIT_SESSION_MODAL",
  NEW_SESSION_ID: "NEW_SESSION_ID",
  GET_SPORTS_PROGRAMS_INSTANCES: "GET_SPORTS_PROGRAMS_INSTANCES",

  //SPORTS PROGRAMS
  SPORTS_PROGRAMS_GET_VALID_CUSTOMERS: "SPORTS_PROGRAMS_GET_VALID_CUSTOMERS",

  //CLUB INGRESS REPORT
  GET_INGRESS_BY_CLUB_REPORT: "GET_INGRESS_BY_CLUB_REPORT",
  DOWNLOAD_INGRESS_BY_CLUB_REPORT: "DOWNLOAD_INGRESS_BY_CLUB_REPORT",
  GET_INGRESS_BY_PARTNER_REPORT: "GET_INGRESS_BY_PARTNER_REPORT",
  CLEAR_INGRESS_BY_PARTNER_REPORT: "CLEAR_INGRESS_BY_PARTNER_REPORT",

  //SPECIAL GUESTS
  GET_SPECIAL_GUESTS: "GET_SPECIAL_GUEST",
  GET_SPECIAL_GUEST_TYPES: "GET_SPECIAL_GUEST_TYPES",
  CLEAR_SPECIAL_GUESTS: "CLEAR_SPECIAL_GUESTS",
  SAVE_SPECIAL_GUEST: "SAVE_SPECIAL_GUEST",
  GET_SPECIAL_GUEST_BY_ID: "GET_SPECIAL_GUEST_BY_ID",
  GET_SPECIAL_GUESTS_FILE: "GET_SPECIAL_GUESTS_FILE",
  GET_FILE_MOVEMENT_STATUS: "GET_FILE_MOVEMENT_STATUS",
  DOWNLOAD_PDF_DEALERS_STATUS: "DOWNLOAD_PDF_DEALERS_STATUS",
  DOWNLOAD_CSV_DEALERS_STATUS: "DOWNLOAD_CSV_DEALERS_STATUS",

  //TERMINAL DATA
  ADD_LOGUEO_REDUX: "ADD_LOGUEO_REDUX",
  CLEAR_LOGUEO_REDUX: "CLEAR_LOGUEO_REDUX",
  GET_DATA_LOGUEO: "GET_DATA_LOGUEO",
  GET_REFERENCE_SANTANDER_CARGO: "GET_REFERENCE_SANTANDER_CARGO",
  GET_REFERENCE_SANTANDER_COMPLEMENTO: "GET_REFERENCE_SANTANDER_COMPLEMENTO",
  GET_REFERENCE_NOT_USED_SANTANDER: "GET_REFERENCE_NOT_USED_SANTANDER",
  CLEAR_REFERENCE_REDUX: "CLEAR_REFERENCE_REDUX",

  //TalentoYCultura
  GET_PRODUCTS_WITH_COMISSION_CONFIG_TO_AUTH:
    "GET_PRODUCTS_WITH_COMISSION_CONFIG_TO_AUTH",
  CLEAR_PRODUCTS_WITH_COMISSION_CONFIG_TO_AUTH:
    "CLEAR_PRODUCTS_WITH_COMISSION_CONFIG_TO_AUTH",
  GET_PRODUCT_POSITIONS_GENERATE_COMISSIONS:
    "GET_PRODUCT_POSITIONS_GENERATE_COMISSIONS",
  GET_MEMB_SALES_COMISSIONS_REPORT_FILE:
    "GET_MEMB_SALES_COMISSIONS_REPORT_FILE",
  GET_MEMB_REACT_COMISSIONS_REPORT_FILE:
    "GET_MEMB_REACT_COMISSIONS_REPORT_FILE",

  // Grupos de convenios
  GROUP_GET_AGREEMENTS: "GROUP_GET_AGREEMENTS",
  GROUP_FILTER_AGREEMENTS: "GROUP_FILTER_AGREEMENTS",
  GROUP_CREATE_AGREEMENT: "GROUP_CREATE_AGREEMENT",
  GROUP_SEARCH_AGREEMENTS_BY_ATTRIBUTES:
    "GROUP_SEARCH_AGREEMENTS_BY_ATTRIBUTES",
  GROUP_DISABLE_AGREEMENT_CREATED: "GROUP_DISABLE_AGREEMENT_CREATED",
  GROUP_DISABLE_BASIC_AGREEMENT_SAVED: "GROUP_DISABLE_BASIC_AGREEMENT_SAVED",
  GROUP_GET_PRICES_AGREEMENT: "GROUP_GET_PRICES_AGREEMENT",
  GROUP_SAVE_BASIC_AGREEMENT: "GROUP_SAVE_BASIC_AGREEMENT",
  GROUP_UPDATE_BASIC_AGREEMENT: "GROUP_UPDATE_BASIC_AGREEMENT",
  GROUP_ADD_CLUBS_AGREEMENT: "GROUP_ADD_CLUBS_AGREEMENT",
  GROUP_GET_CLUBS_AGREEMENT: "GROUP_GET_CLUBS_AGREEMENT",
  GROUP_ADD_MAINTENANCES_AGREEMENT: "GROUP_ADD_MAINTENANCES_AGREEMENT",
  GROUP_GET_MAINTENANCES_AGREEMENT: "GROUP_GET_MAINTENANCES_AGREEMENT",
  GROUP_GET_MEMBERSHIPS_AGREEMENT: "GROUP_GET_MEMBERSHIPS_AGREEMENT",
  GROUP_CHECK_PROPORTIONAL_AGREEMENT: "GROUP_CHECK_PROPORTIONAL_AGREEMENT",
  GROUP_CHECK_ABSOLUTE_AGREEMENT: "GROUP_CHECK_ABSOLUTE_AGREEMENT",
  GROUP_AGREEMENT_MEMBERSHIP_VALUE: "GROUP_AGREEMENT_MEMBERSHIP_VALUE",
  GROUP_AGREEMENT_MEMBERSHIP_VALUE_TYPE:
    "GROUP_AGREEMENT_MEMBERSHIP_VALUE_TYPE",
  GROUP_AGREEMENT_MEMBERSHIP_VALUE_TYPE_MONTH:
    "GROUP_AGREEMENT_MEMBERSHIP_VALUE_TYPE_MONTH",
  GROUP_AGREEMENT_PRICE_CELL: "GROUP_AGREEMENT_PRICE_CELL",
  GROUP_CHECK_LAST_MONTH_AGREEMENT: "GROUP_CHECK_LAST_MONTH_AGREEMENT",
  GROUP_DELETE_MONTH_PRICES_AGREEMENT: "GROUP_DELETE_MONTH_PRICES_AGREEMENT",
  GROUP_ADD_MONTH_PRICES_AGREEMENT: "GROUP_ADD_MONTH_PRICES_AGREEMENT",
  GROUP_MEMBERSHIP_CHANGED_AGREEMENT: "GROUP_MEMBERSHIP_CHANGED_AGREEMENT",
  GROUP_CLUB_CHANGED_AGREEMENT: "GROUP_CLUB_CHANGED_AGREEMENT",
  GROUP_SAVE_CHANGES_BENEFITS_AGREEMENT:
    "GROUP_SAVE_CHANGES_BENEFITS_AGREEMENT",
  GROUP_GET_AGREEMENT_DETAIL: "GROUP_GET_AGREEMENT_DETAIL",
  GROUP_GET_AGREEMENT_BY_ID: "GROUP_GET_AGREEMENT_BY_ID",
  GROUP_CONFIRM_BENEFITS_CHANGES: "GROUP_CONFIRM_BENEFITS_CHANGES",

  // Refactorización de convenios
  CLEAR_CONVENIO_CAROUSEL: "CLEAR_CONVENIO_CAROUSEL",
  GET_CONVENIOS_LIST: "GET_CONVENIOS_LIST",
  GET_CONVENIO_DATOS_BASICOS: "GET_CONVENIO_DATOS_BASICOS",
  POST_CONVENIO_DATOS_BASICOS: "POST_CONVENIO_DATOS_BASICOS",
  PUT_CONVENIO_DATOS_BASICOS: "PUT_CONVENIO_DATOS_BASICOS",
  GET_CONVENIO_EMPRESAS_ASIGNABLES: "GET_CONVENIO_EMPRESAS_ASIGNABLES",
  GET_CONVENIO_EMPRESAS_ACTIVAS: "GET_CONVENIO_EMPRESAS_ACTIVAS",
  GET_CONVENIO_EMPRESAS_CONVENIO: "GET_CONVENIO_EMPRESAS_CONVENIO",
  GET_CONVENIO_CLUBES_ASIGNABLES: "GET_CONVENIO_CLUBES_ASIGNABLES",
  GET_CONVENIO_CLUBES_ASOCIADOS: "GET_CONVENIO_CLUBES_ASOCIADOS",
  PUT_CONVENIO_CLUBES_ASOCIADOS: "PUT_CONVENIO_CLUBES_ASOCIADOS",
  GET_CONVENIO_MANTENIMIENTOS_ASIGNABLES:
    "GET_CONVENIO_MANTENIMIENTOS_ASIGNABLES",
  GET_CONVENIO_MANTENIMIENTOS_ASOCIADOS:
    "GET_CONVENIO_MANTENIMIENTOS_ASOCIADOS",
  PUT_CONVENIO_MANTENIMIENTOS_ASOCIADOS:
    "PUT_CONVENIO_MANTENIMIENTOS_ASOCIADOS",
  GET_CONVENIO_MEMBRESIAS_ASOCIADAS: "GET_CONVENIO_MEMBRESIAS_ASOCIADAS",

  //COMPANIES
  GET_COMPANIES: "GET_COMPANIES",
  GET_COMPANIE: "GET_COMPANIE",
  CREATE_COMPANIE: "CREATE_COMPANIES",
  UPDATE_COMPANIE: "UPDATE_COMPANIE",
  DELETE_COMPANIE: "DELETE_COMPANIE",

  // EMPRESAS DE CONVENIO
  GET_EMPRESAS_CONVENIO: "GET_EMPRESAS_CONVENIO",
  GET_EMPRESA_CONVENIO: "GET_EMPRESA_CONVENIO",
  CREATE_EMPRESA_CONVENIO: "CREATE_EMPRESA_CONVENIO",
  UPDATE_EMPRESA_CONVENIO: "UPDATE_EMPRESA_CONVENIO",
  GET_EMPRESA_CONVENIO_INVITADO: "UPDATE_EMPRESA_CONVENIO_INVITADO",
  UPDATE_EMPRESA_CONVENIO_INVITADO: "UPDATE_EMPRESA_CONVENIO_INVITADO",
  DELETE_EMPRESA_CONVENIO: "DELETE_EMPRESA_CONVENIO",

  //PERSONAL_DOCUMENTS_HISTORY
  GET_PERSONAL_DOCUMENTS_HISTORY: "GET_PERSONAL_DOCUMENTS_HISTORY",

  //ACTIVITY_LOGS
  GET_ACTIVITY_LOGS_BY_PARTNER: "GET_ACTIVITY_LOGS_BY_PARTNER",
  GET_ACTIVITY_LOGS_BY_PARTNER_MEMBERSHIP:
    "GET_ACTIVITY_LOGS_BY_PARTNER_MEMBERSHIP",

  // Refactorización de documentos
  GET_EXPEDIENTE_DATOS: "GET_EXPEDIENTE_DATOS",
  POST_EXPEDIENTES_ARCHIVO_REFRESH: "POST_EXPEDIENTES_ARCHIVO_REFRESH",
  GET_EXPEDIENTES_ARCHIVO_DATOS: "GET_EXPEDIENTES_ARCHIVO_DATOS",
  GET_EXPEDIENTES_ARCHIVO_VIGENTE: "GET_EXPEDIENTES_ARCHIVO_VIGENTE",
  POST_EXPEDIENTES_ENVIO_CORREO: "POST_EXPEDIENTES_ENVIO_CORREO",
  GET_EXPEDIENTE_MEMBRESIA_DATOS: "GET_EXPEDIENTE_MEMBRESIA_DATOS",
  GET_EXPEDIENTE_INVITADO_DATOS: "GET_EXPEDIENTE_INVITADO_DATOS",
  PUT_EXPEDIENTES_ARCHIVO_STATUS: "PUT_EXPEDIENTES_ARCHIVO_STATUS",
  GET_EXPEDIENTES_ARCHIVO_FIRMAS: "GET_EXPEDIENTES_ARCHIVO_FIRMAS",
  GET_EXPEDIENTES_CONDENSADOS: "GET_EXPEDIENTES_CONDENSADOS",
  PUT_EXPEDIENTES_AUTORIZAR: "PUT_EXPEDIENTES_AUTORIZAR",
  PUT_EXPEDIENTE_ARCHIVO_CINCEL_FIRMAS: "PUT_EXPEDIENTE_ARCHIVO_CINCEL_FIRMAS",
  POST_CINCEL_PREGENERACION: "POST_CINCEL_PREGENERACION",

  // Trabajos de comisiones dependientes a CRM1, tenderán a desaparecer
  GET_COMISIONES_DEPORTIVA_CRM1_PENDIENTES:
    "GET_COMISIONES_DEPORTIVA_CRM1_PENDIENTES",
  PUT_COMISIONES_DEPORTIVA_CRM1_PROCESAR:
    "PUT_COMISIONES_DEPORTIVA_CRM1_PROCESAR",
  GET_CLASES_SIN_COMISION: "GET_CLASES_SIN_COMISION",
  POST_CLASES_COMISION_GENERAR: "POST_CLASES_COMISION_GENERAR",

  // Consulta de membresías en CRM1
  CLEAR_MEMBRESIAS_CRM1: "CLEAR_MEMBRESIAS_CRM1",
  GET_MEMBRESIAS_CRM1_ACCESO: "GET_MEMBRESIAS_CRM1_ACCESO",

  // Notas de Crédito (solo para bloqueo)
  GET_NOTA_CREDITO_BLOQUEADAS: "GET_NOTA_CREDITO_BLOQUEADAS",
  POST_NOTA_CREDITO_BLOQUEADA: "POST_NOTA_CREDITO_BLOQUEADA",
  DELETE_NOTA_CREDITO_BLOQUEADA: "DELETE_NOTA_CREDITO_BLOQUEADA",

  // Invitados
  CLEAR_INVITADOS: "CLEAR_INVITADOS",
  CLEAR_INVITADO: "CLEAR_INVITADO",
  GET_INVITADOS: "GET_INVITADOS",
  GET_INVITADO: "GET_INVITADO",
  POST_INVITADO: "POST_INVITADO",
  PUT_INVITADO: "PUT_INVITADO",
  DELETE_INVITADO: "DELETE_INVITADO",
  PUT_INVITADO_ACTIVAR: "PUT_INVITADO_ACTIVAR",
  PUT_INVITADO_DESACTIVAR: "PUT_INVITADO_DESACTIVAR",
  CLEAR_MOVIMIENTOS_INVITADO: "CLEAR_MOVIMIENTOS_INVITADO",
  GET_MOVIMIENTOS_INVITADO: "GET_MOVIMIENTOS_INVITADO",
  CLEAR_BITACORA_INVITADO: "CLEAR_BITACORA_INVITADO",
  GET_BITACORA_INVITADO: "GET_BITACORA_INVITADO",
  POST_HUELLA_INVITADO: "POST_HUELLA_INVITADO",

  // Versión 2 de comisiones - Información de venta
  GET_COMISIONES_V2_PRODUCTOS_VENTA: "GET_COMISIONES_V2_PRODUCTOS_VENTA",
  GET_COMISIONES_V2_VENTAS: "GET_COMISIONES_V2_VENTAS",
  PUT_COMISIONES_V2_VENDEDOR: "PUT_COMISIONES_V2_VENDEDOR",
  PUT_COMISIONES_V2_INSTRUCTOR_PRINCIPAL:
    "GET_COMISIONES_V2_INSTRUCTOR_PRINCIPAL",
  PUT_COMISIONES_V2_COORDINADOR: "PUT_COMISIONES_V2_COORDINADOR",

  // Versión 2 de comisiones - Asignación de clases y programas deportivos
  GET_COMISIONES_V2_PRODUCTOS_OPERACION:
    "GET_COMISIONES_V2_PRODUCTOS_OPERACION",
  GET_COMISIONES_V2_INSTRUCTOR_COMISIONABLE:
    "GET_COMISIONES_V2_INSTRUCTOR_COMISIONABLE",
  GET_COMISIONES_V2_CLASES_ASIGNABLES: "GET_COMISIONES_V2_CLASES_ASIGNABLES",
  POST_COMISIONES_V2_ASIGNACION: "POST_COMISIONES_V2_ASIGNACION",

  // Versión 2 de comisiones - Impartición de clases
  GET_COMISIONES_V2_PRODUCTOS_ASIGNADOS:
    "GET_COMISIONES_V2_PRODUCTOS_ASIGNADOS",
  GET_COMISIONES_V2_CLASES_ASIGNADAS: "GET_COMISIONES_V2_CLASES_ASIGNADAS",
  PUT_COMISIONES_V2_EVENTO_IMPARTICION: "PUT_COMISIONES_V2_EVENTO_IMPARTICION",
  PUT_COMISIONES_V2_EVENTO_REASIGNACION:
    "PUT_COMISIONES_V2_EVENTO_REASIGNACION",

  // Versión 2 de comisiones - Autorización de comisiones
  GET_COMISIONES_V2_DEPORTIVA_AUTORIZACION:
    "GET_COMISIONES_V2_DEPORTIVA_AUTORIZACION",
  GET_COMISIONES_V2_DEPORTIVA_PERIODO: "GET_COMISIONES_V2_DEPORTIVA_PERIODO",
  PUT_COMISIONES_V2_DEPORTIVA_AUTORIZAR:
    "PUT_COMISIONES_V2_DEPORTIVA_AUTORIZAR",
  PUT_COMISIONES_V2_DEPORTIVA_RECHAZAR: "PUT_COMISIONES_V2_DEPORTIVA_RECHAZAR",
  PUT_COMISIONES_V2_DEPORTIVA_REASIGNAR:
    "PUT_COMISIONES_V2_DEPORTIVA_REASIGNAR",

  // Versión 2 de comisiones - Administación de datos de archivos de nómina (maquila)
  GET_COMISIONES_MAQUILA_V2_PERIODO_APLICABLE:
    "GET_COMISIONES_MAQUILA_V2_PERIODO_APLICABLE",
  GET_COMISIONES_MAQUILA_V2_ARCHIVOS: "GET_COMISIONES_MAQUILA_V2_ARCHIVOS",
  POST_COMISIONES_MAQUILA_V2_ARCHIVO: "POST_COMISIONES_MAQUILA_V2_ARCHIVO",
  GET_COMISIONES_MAQUILA_V2_GARANTIAS_PUESTOS:
    "GET_COMISIONES_MAQUILA_V2_GARANTIAS_PUESTOS",
  GET_COMISIONES_MAQUILA_V2_GARANTIAS_EMPLEADOS:
    "GET_COMISIONES_MAQUILA_V2_GARANTIAS_EMPLEADOS",
  GET_COMISIONES_MAQUILA_V2_GARANTIAS_CLUBES:
    "GET_COMISIONES_MAQUILA_V2_GARANTIAS_CLUBES",
  POST_COMISIONES_MAQUILA_V2_GARANTIAS_PUESTOS:
    "POST_COMISIONES_MAQUILA_V2_GARANTIAS_PUESTOS",
  POST_COMISIONES_MAQUILA_V2_GARANTIAS_EMPLEADOS:
    "POST_COMISIONES_MAQUILA_V2_GARANTIAS_EMPLEADOS",
  POST_COMISIONES_MAQUILA_V2_GARANTIAS_CLUBES:
    "POST_COMISIONES_MAQUILA_V2_GARANTIAS_CLUBES",
  DELETE_COMISIONES_MAQUILA_V2_GARANTIAS:
    "DELETE_COMISIONES_MAQUILA_V2_GARANTIAS"
};
