import FormBuilderConfig from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ISelectBuilder from "../../../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import IUserSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";

export type Inputs = "idInstructor" | "anio" | "mes";
export type Buttons = "aceptar" | "cancelar";

const anioActual = new Date().getFullYear();

export const formConfigPrograma: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "idInstructor",
      label: "* Instructor",
      bts: {
        mCol: 12
      },
      component: IUserSelectorBuilder
    },
    {
      name: "anio",
      label: "* Año del Periodo",
      bts: {
        mCol: 6
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Año",
        data: [
          { value: anioActual - 1, label: anioActual - 1 },
          { value: anioActual, label: anioActual },
          { value: anioActual + 1, label: anioActual + 1 }
        ]
      }
    },
    {
      name: "mes",
      label: "* Mes del Periodo",
      bts: {
        mCol: 6
      },
      component: ISelectBuilder,
      props: {
        data: [
          monthOption(-3),
          monthOption(-2),
          monthOption(-1),
          monthOption(0),
          monthOption(1)
        ]
      }
    }
  ],
  buttons: [
    {
      name: "aceptar",
      label: "Aceptar",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        mOff: 4,
        mCol: 4
      }
    },
    {
      name: "cancelar",
      label: "Cancelar",
      component: IButtonBuilder,
      bts: {
        mCol: 4
      }
    }
  ],
  validations: {
    idInstructor: {
      required: true
    },
    anio: {
      required: true
    },
    mes: {
      required: true
    }
  },
  rows: [["idInstructor"], ["mes", "anio"], ["aceptar", "cancelar"]]
};

function monthOption(months: number) {
  const fechaNueva = new Date();
  fechaNueva.setMonth(fechaNueva.getMonth() + months);
  const monthNumber = fechaNueva.getMonth() + 1;
  const value = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
  return {
    value,
    label: fechaNueva.toLocaleString("es-MX", { month: "long" }).toUpperCase()
  };
}
