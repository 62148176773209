import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import IUserSelectorBuilder from "../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";

export type Inputs = "idEmpleado" | "monto";
export type Buttons = "aceptar" | "cancelar";

export const formConfigEmpleado: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "idEmpleado",
      label: "* Empleado",
      bts: {
        mCol: 12
      },
      component: IUserSelectorBuilder
    },
    {
      name: "monto",
      label: "* Monto",
      bts: {
        mCol: 12
      },
      component: ITextBuilder
    }
  ],
  buttons: [
    {
      name: "aceptar",
      label: "Aceptar",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        mOff: 4,
        mCol: 4
      }
    },
    {
      name: "cancelar",
      label: "Cancelar",
      component: IButtonBuilder,
      bts: {
        mCol: 4
      }
    }
  ],
  validations: {
    idEmpleado: {
      required: true
    },
    monto: {
      required: true
    }
  },
  rows: [["idEmpleado"], ["monto"], ["aceptar", "cancelar"]]
};
