import {
  GetCMaqArchivosMaquila,
  GetCMaqPeriodoAplicable,
  PostCMaqArchivoMaquila
} from "../../reducers/comisionesMaquilaV2";

export interface ComisionesDeportivaMaquilaStore
  extends GetCMaqArchivosMaquila,
    GetCMaqPeriodoAplicable,
    PostCMaqArchivoMaquila {}

export const comisionesDeportivaMaquilaStore: (
  state
) => ComisionesDeportivaMaquilaStore = state => {
  const { comisionesMaquilaV2 } = state;
  return {
    cmaqPeriodoAplicable: comisionesMaquilaV2.cmaqPeriodoAplicable,
    gettingCMaqPeriodoAplicable:
      comisionesMaquilaV2.gettingCMaqPeriodoAplicable,
    gettingCMaqPeriodoAplicableError:
      comisionesMaquilaV2.gettingCMaqPeriodoAplicableError,

    cmaqArchivosMaquila: comisionesMaquilaV2.cmaqArchivosMaquila,
    gettingCMaqArchivosMaquila: comisionesMaquilaV2.gettingCMaqArchivosMaquila,
    gettingCMaqArchivosMaquilaError:
      comisionesMaquilaV2.gettingCMaqArchivosMaquilaError,

    postingCMaqArchivoMaquila: comisionesMaquilaV2.postingCMaqArchivoMaquila,
    postingCMaqArchivoMaquilaError:
      comisionesMaquilaV2.postingCMaqArchivoMaquilaError
  };
};
