exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "span.SIaht{cursor:pointer}span._2hntU,span.SIaht{font-size:16px;margin:0 8px}.ZN6vr{background:#f9fad2}._1VUuo{color:#700;font-weight:700;background:#f3d8d8}._1FU79{padding:0!important}._2Xzqp{padding:7px 3px}.s3Jl5 div{overflow-x:hidden!important}.s3Jl5 div div div div{overflow:hidden!important}", ""]);

// exports
exports.locals = {
	"btn": "SIaht",
	"info": "_2hntU",
	"noComisionable": "ZN6vr",
	"errorComision": "_1VUuo",
	"tdClear": "_1FU79",
	"field": "_2Xzqp",
	"hidden": "s3Jl5"
};