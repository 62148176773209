exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "span._6qMhF{cursor:pointer}span._6qMhF,span._14F-u{font-size:16px;margin:0 8px}._17gS5{background:#f9fad2}._14HQ9{color:#700;font-weight:700;background:#f3d8d8}._3YpYv{padding:0!important}._3Ef3Q{padding:7px 3px}._1tEUA div{overflow-x:hidden!important}._1tEUA div div div div{overflow:hidden!important}", ""]);

// exports
exports.locals = {
	"btn": "_6qMhF",
	"info": "_14F-u",
	"noComisionable": "_17gS5",
	"errorComision": "_14HQ9",
	"tdClear": "_3YpYv",
	"field": "_3Ef3Q",
	"hidden": "_1tEUA"
};