import React from "react";
import { ReduxConnect } from "../../../toolkit/decorators/Artifact";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import {
  comisionesDeportivaGarantiasDispatcher,
  ComisionesDeportivaGarantiasDispatcher
} from "./ComisionesDeportivaGarantias.dispatcher";
import {
  comisionesDeportivaGarantiasStore,
  ComisionesDeportivaGarantiasStore
} from "./ComisionesDeportivaGarantias.store";
import ComisionesDeportivaGarantias from "../../components/comisionesDeportivaGarantias";

interface Props
  extends ComisionesDeportivaGarantiasStore,
    ComisionesDeportivaGarantiasDispatcher {}

interface State {}

@ReduxConnect(
  comisionesDeportivaGarantiasStore,
  comisionesDeportivaGarantiasDispatcher
)
export default class ComisionesDeportivaGarantiasPage extends React.Component<
  Props,
  State
> {
  render() {
    return (
      <main>
        <Helmet title="Agreements" />
        <Layout>
          <ComisionesDeportivaGarantias {...this.props} />
        </Layout>
      </main>
    );
  }
}
