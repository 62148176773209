import React, { Component } from "react";
import {
  RouterProps,
  Styled
} from "../../../../../toolkit/decorators/Artifact";
import { DAutorizacionDispatcher } from "../../../../containers/comisionesDeportivaV2Carrusel/steps/DAutorizacion.dispatcher";
import { DAutorizacionStore } from "../../../../containers/comisionesDeportivaV2Carrusel/steps/DAutorizacion.store";
import { Container } from "reactstrap";
import FormBuilder from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder";
import { Inputs, Buttons, formConfig } from "./DAutorizacion.form";
import { MemoTable } from "./DAutorizacion.table";
import DeportivaComision from "../../../../../types/DeportivaComision";
import { errorNotification } from "../../../../../utils/notifications";
import s from "../../styles.scss";
import FormBuilderModal from "../../../common/formBuilderModal/formBuilderModal";
import { formConfigRechazar } from "./DAutorizacionRechazar.form";
import { formConfigReasignar } from "./DAutorizacionReasignar.form";
import ConfirmModal from "../../../common/confirmModal/confirmModal";
import ISelectBuilder from "../../../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import IButtonBuilder from "../../../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import {
  downloadRelacionArchivoMaquila,
  getLogsComision
} from "../../../../actions/comisionesV2";
import DeportivaComisionLog from "../../../../../types/DeportivaComisionLog";
import DAutorizacionLog from "./DAutorizacionLog.component";

interface Props
  extends DAutorizacionDispatcher,
    DAutorizacionStore,
    RouterProps {}

interface State {
  downloading: boolean;
  waiting: boolean;
  filters: any;

  // Actualización de empleado
  rowPopup: DeportivaComision;
  autorizarPopupOpen: boolean;
  autorizarPopupTitle: string;
  autorizarPopupText: string;
  rechazarPopupOpen: boolean;
  rechazarPopupTitle: string;
  rechazarPopupText: string;
  reasignarPopupOpen: boolean;
  reasignarPopupTitle: string;
  reasignarPopupText: string;

  selected: number[];

  logs: DeportivaComisionLog[];
  logsPopupOpen: boolean;
}

@Styled(s)
export default class DAutorizacionComponent extends Component<Props, State> {
  state = {
    downloading: false,
    waiting: false,
    filters: {} as any,

    // Actualización de empleado
    rowPopup: null as DeportivaComision,
    autorizarPopupOpen: false,
    autorizarPopupTitle: "",
    autorizarPopupText: "",
    rechazarPopupOpen: false,
    rechazarPopupTitle: "",
    rechazarPopupText: "",
    reasignarPopupOpen: false,
    reasignarPopupTitle: "",
    reasignarPopupText: "",

    selected: [],

    logs: [] as DeportivaComisionLog[],
    logsPopupOpen: false
  };

  componentDidMount(): void {
    this.props.getCmsDeportivaPeriodo();
  }

  submit = (values: any) => {
    // Datos del formulario
    if (values && values.periodo) {
      const periodo = this.props.cmsDeportivaPeriodo.find(
        p => p.id == values.periodo
      );

      if (periodo) {
        values.fechaInicio = periodo.fechaInicio;
        values.fechaFin = periodo.fechaFin;
      }
    }

    // Búsqueda de datos en página 1
    this.fetchData(values);
  };

  // Carga de datos para poblar la tabla
  fetchData = async (ownFilters?) => {
    const filters = ownFilters || this.state.filters;
    this.setState({ filters });
    const preview = await this.props.getCmsDeportivaAutorizacion(filters);

    if (!preview.fulfilled) {
      errorNotification(
        preview.gettingCmsDeportivaAutorizacion ||
          "Error al obtener las comisiones"
      );
    }
  };

  onExport = () => {
    this.setState({ downloading: true });
    downloadRelacionArchivoMaquila(this.state.filters, () =>
      this.setState({ downloading: false })
    );
  };

  onCheck = (id: number, checked: boolean) => {
    const selected = checked
      ? this.state.selected.includes(id)
        ? [...this.state.selected]
        : [...this.state.selected, id]
      : this.state.selected.filter(sel => sel != id);

    this.setState({ selected });
  };

  onCheckAll = (checked: boolean) => {
    const comisiones = this.props.cmsDeportivaAutorizacion || [];
    this.setState({
      selected: checked
        ? comisiones.filter(c => c.estatus == "PENDIENTE").map(c => c.id)
        : []
    });
  };

  onRechazar = (row: DeportivaComision) => {
    this.setState({
      rowPopup: row,
      rechazarPopupOpen: true,
      rechazarPopupTitle: "Rechazar comisión",
      rechazarPopupText:
        "Confirme el rechazo de la comisión, esta operación no se puede deshacer."
    });
  };

  onReasignar = (row: DeportivaComision) => {
    this.setState({
      rowPopup: row,
      reasignarPopupOpen: true,
      reasignarPopupTitle: "Reasignar comisión",
      reasignarPopupText: "Confirme la reasignación de la comisión."
    });
  };

  onViewLog = async (row: DeportivaComision) => {
    const logs = await getLogsComision(row.id);
    if (logs.length) {
      this.setState({ logs, logsPopupOpen: true });
    } else {
      errorNotification("No se encontraron registros de log");
    }
  };

  onAutorizar = () => {
    if (!this.state.selected || !this.state.selected.length) {
      errorNotification("Seleccione las comisiones que debe autorizar");
      return;
    }

    this.setState({
      rowPopup: null,
      autorizarPopupOpen: true,
      autorizarPopupTitle: "Autorizar comisiones",
      autorizarPopupText:
        "Confirme la autorización de las comisiones seleccionadas, esta operación no se puede deshacer."
    });
  };

  rechazar = async () => {
    const preview = await this.props.putCmsDeportivaRechazo(
      this.state.rowPopup.id
    );
    if (preview.fulfilled) {
      this.setState({ rechazarPopupOpen: false });
      this.fetchData();
    } else {
      errorNotification(
        preview.puttingCmsDeportivaRechazo || "Error al rechazar la comisión"
      );
    }
  };

  reasignar = async value => {
    const preview = await this.props.putCmsDeportivaReasignacion({
      idComision: this.state.rowPopup.id,
      ...value
    });
    if (preview.fulfilled) {
      this.setState({ reasignarPopupOpen: false });
      this.fetchData();
    } else {
      errorNotification(
        preview.puttingCmsDeportivaReasignacionError ||
          "Error al reasignar la comisión"
      );
    }
  };

  autorizar = async () => {
    const preview = await this.props.putCmsDeportivaAutorizar(
      this.state.selected
    );
    if (preview.fulfilled) {
      this.setState({ autorizarPopupOpen: false });
      this.fetchData();
    } else {
      errorNotification(
        preview.puttingCmsDeportivaAutorizar || "Error al autorizar la comisión"
      );
    }
  };

  render() {
    return (
      <Container>
        <FormBuilderModal
          isOpen={this.state.rechazarPopupOpen}
          title={this.state.rechazarPopupTitle}
          message={this.state.rechazarPopupText}
          ok={this.rechazar}
          cancel={() => this.setState({ rechazarPopupOpen: false })}
          formConfig={formConfigRechazar}
          size={"md"}
        />
        <FormBuilderModal
          isOpen={this.state.reasignarPopupOpen}
          title={this.state.reasignarPopupTitle}
          message={this.state.reasignarPopupText}
          ok={this.reasignar}
          cancel={() => this.setState({ reasignarPopupOpen: false })}
          formConfig={formConfigReasignar}
          size={"md"}
        />
        <ConfirmModal
          isOpen={this.state.autorizarPopupOpen}
          title={this.state.autorizarPopupTitle}
          message={this.state.autorizarPopupText}
          ok={this.autorizar}
          cancel={() => this.setState({ autorizarPopupOpen: false })}
          size={"md"}
          waiting={this.props.puttingCmsDeportivaAutorizar}
        />
        <DAutorizacionLog
          logs={this.state.logs}
          open={this.state.logsPopupOpen}
          onClose={() => this.setState({ logsPopupOpen: false })}
        />

        <FormBuilder<Inputs, Buttons>
          config={formConfig}
          submit={this.submit}
          processing={
            this.props.gettingCmsDeportivaAutorizacion ||
            this.props.puttingCmsDeportivaAutorizar
          }
        >
          <ISelectBuilder
            name="periodo"
            data={this.props.cmsDeportivaPeriodo || []}
            mapOption={p => ({ value: p.id, label: p.descripcion })}
          />
          <IButtonBuilder
            name="autorizar"
            onClick={this.onAutorizar}
            processing={this.props.puttingCmsDeportivaAutorizar}
          />
        </FormBuilder>

        <hr className="row" />

        <MemoTable
          data={this.props.cmsDeportivaAutorizacion || []}
          loading={
            this.state.downloading ||
            this.props.gettingCmsDeportivaAutorizacion ||
            this.props.puttingCmsDeportivaAutorizar
          }
          onExport={this.onExport}
          onRechazar={this.onRechazar}
          onReasignar={this.onReasignar}
          onViewLog={this.onViewLog}
          onCheck={this.onCheck}
          onCheckAll={this.onCheckAll}
          selected={this.state.selected}
        />

        <hr className="row" />
      </Container>
    );
  }
}
