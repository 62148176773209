import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Container, Col, Button, Row, UncontrolledTooltip } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import DatePicker from "../common/DatePicker";
import ReactTable from "react-table";
import ModalFrame from "../../../toolkit/modalFrame";
import ConfirmModal from "../../components/common/confirmModal/confirmModal";
import MexicanCurrency from "../common/currency";
import s from "./styles.scss";
import IAction from "../../../types/IAction";
import EmailBill from "./EmailBill";
import {
  faFilePdf,
  faEnvelope,
  faCoins,
  faTimesCircle,
  faFileExport,
  faEye,
  faQuestion,
  faUser,
  faSpinner,
  faRedo
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  downloadCreditNotesReport,
  getCreditNoteReqItemsDevolucionById,
  postConfirmarCancelacion,
  postRefacturar,
  postVerificarCancelacion
} from "../../actions/reports";
import { userHasPermission } from "../../../utils/accessTree";
import { getCreditNoteReqByIdRequest } from "../../actions/reports";
import isNil from "lodash.isnil";
import BaseButtonWithIconAndTooltip from "../../../toolkit/baseButtonWithIconAndTooltip";
import {
  clubCatalogAsOptions,
  handleRequestError
} from "../../../utils/helpers";
import debounce from "lodash.debounce";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import permissionCodes from "../../../utils/permissionCodes";
import TextWithLineBreaks from "../common/TextWithLineBreaks";

const {
  AUTORIZAR_NOTA_CREDITO_DATOS_FISCALES,
  AUTORIZAR_NOTA_CREDITO_COBRANZA_ERRONEA,
  RECHAZAR_SOLICITUD_NOTA_CREDITO_DATOS_FISCALES,
  RECHAZAR_SOLICITUD_NOTA_CREDITO_COBRANZA_ERRONEA,
  AUTORIZAR_CANCELACION_DATOS_FISCALES,
  AUTORIZAR_CANCELACION_COBRANZA_ERRONEA,
  RECHAZAR_SOLICITUD_CANCELACION_DATOS_FISCALES,
  RECHAZAR_SOLICITUD_CANCELACION_COBRANZA_ERRONEA,
  CONFIRMAR_CANCELACION_MANUALMENTE,
  REENVIAR_SOLICITUD_CANCELACION
} = permissionCodes;

interface Props {
  clubs: object[];
  reportCreditNotes: any;
  sendMovementEmail: (id: number, email: string) => any;
  toggleBill: (storageId: number) => any;
  getCreditNotes: (
    clubId: string,
    fechaInicio: string,
    fechaFin: string,
    fechaInicioAutorizacion: string,
    fechaFinAutorizacion: string,
    estatus: string,
    tipo: string
  ) => IAction;
  processCreditNote: (solicitudId: string) => any;
  rejectCreditNoteDemand: (notaCreditoSolicitudId: string) => any;
  rejectCreditNote: boolean;
  userContext: any;
  loadingCreditNotes: boolean;
  retryCreditNoteRequest: (uuid: string) => IAction;
  postReenvioSolicitud: any;
  postingReenvioSolicitud: boolean;
  postingReenvioSolicitudError: string;
}

interface State {
  filters: object;
  idModalEmail: number;
  modalEmail: boolean;
  reloadTable: boolean;

  viewReqDataModalOpen: boolean;
  reqData: any;

  processing: boolean;
  confirmAuthorizeModalIsOpen: boolean;
  idToAuthorize: number;

  confirmRejectModalIsOpen: boolean;
  idToReject: number;

  viewItemsDevModalIsOpen: boolean;
  viewItemsDevModalItems: any[];

  confirmRetryModalIsOpen: boolean;
  uuidToRetry: string;
}

class CreditNotesReport extends React.Component<Props, State> {
  state = {
    filters: {
      idClub: null,
      fechaInicio: null,
      fechaFin: null,
      fechaInicioAutorizacion: null,
      fechaFinAutorizacion: null,
      estatus: "",
      tipo: "NOTA_CREDITO"
    },
    idModalEmail: null,
    modalEmail: false,
    reloadTable: false,

    viewReqDataModalOpen: false,
    reqData: null,

    processing: false,
    confirmAuthorizeModalIsOpen: false,
    idToAuthorize: null,

    confirmRejectModalIsOpen: false,
    idToReject: null,

    viewItemsDevModalIsOpen: false,
    viewItemsDevModalItems: [],

    confirmRetryModalIsOpen: false,
    uuidToRetry: null
  };

  componentDidMount() {
    if (this.props.userContext) {
      const { filters } = this.state;
      filters.idClub = this.props.userContext.clubSessionId || null;
      this.setState({ filters: { ...filters } });
    }
  }

  componentDidUpdate() {
    if (this.state.reloadTable && this.props.rejectCreditNote) {
      const {
        idClub,
        fechaInicio,
        fechaFin,
        fechaInicioAutorizacion,
        fechaFinAutorizacion,
        estatus,
        tipo
      } = this.state.filters;
      this.props.getCreditNotes(
        idClub,
        fechaInicio,
        fechaFin,
        fechaInicioAutorizacion,
        fechaFinAutorizacion,
        estatus,
        tipo
      );
      this.setState({ reloadTable: false });
    }
  }

  toggleModalEmail = idModalEmail => {
    this.setState({
      idModalEmail,
      modalEmail: !this.state.modalEmail
    });
  };

  toggleModalViewReqData = () => {
    this.setState({
      reqData: null,
      viewReqDataModalOpen: !this.state.viewReqDataModalOpen
    });
  };

  viewNotaCreditoSolicitudItemsDevolucion = ncsId => {
    infoNotification("Consultando datos...");

    getCreditNoteReqItemsDevolucionById(ncsId)
      .then(({ data }) => {
        this.setState({
          viewItemsDevModalIsOpen: true,
          viewItemsDevModalItems: [...data]
        });
      })
      .catch(handleRequestError);
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Club facturación</p>,
      id: 1,
      width: 160,
      accessor: "itemClubVentaNombre",
      className: "text-center",
      Cell: row => {
        return <span>{row.original.itemClubVentaNombre}</span>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Club dispersión</p>,
      id: 2,
      width: 160,
      accessor: "itemClubDispNombre",
      className: "text-center",
      Cell: row => {
        return <span>{row.original.itemClubDispNombre}</span>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Club membresía</p>,
      id: 3,
      width: 160,
      accessor: "itemClubMembresiaNombre",
      className: "text-center",
      Cell: row => {
        return <span>{row.original.itemClubMembresiaNombre}</span>;
      }
    },
    {
      Header: () => <p className="font-weight-bold"># Membresía</p>,
      id: 4,
      width: 120,
      accessor: "itemMembresiaIdPublico",
      className: "text-center",
      Cell: row => {
        return <span>{row.original.itemMembresiaIdPublico}</span>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha solicitud</p>,
      id: 5,
      width: 160,
      accessor: "fechaSolicitud",
      className: "text-center"
    },
    {
      Header: () => <p className="font-weight-bold">Causa</p>,
      id: 6,
      width: 160,
      accessor: "causa",
      className: "text-center"
    },

    {
      Header: () => <p className="font-weight-bold">Estatus</p>,
      id: 7,
      width: 160,
      accessor: "estatus",
      className: "text-center"
    },
    {
      Header: () => (
        <p className="font-weight-bold">Fecha autorización / rechazo</p>
      ),
      id: 8,
      width: 160,
      accessor: "fechaAutorizacion",
      className: "text-center"
    },
    {
      Header: () => <p className="font-weight-bold">UUID nota de crédito</p>,
      id: 9,
      width: 160,
      accessor: "notaCreditoUUID",
      className: "text-center"
    },
    {
      Header: () => <p className="font-weight-bold">Folio nota de crédito</p>,
      id: 9,
      width: 160,
      accessor: "folioNotaCredito",
      className: "text-center"
    },
    {
      Header: () => <p className="font-weight-bold">Item Subtotal</p>,
      id: 10,
      width: 160,
      className: "text-center",
      accessor: "itemVentaSubtotal",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.itemVentaSubtotal} />;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Item IVA</p>,
      id: 101,
      width: 160,
      className: "text-center",
      accessor: "itemVentaIva",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.itemVentaIva} />;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Item Total</p>,
      id: 10,
      width: 160,
      className: "text-center",
      accessor: "itemVentaTotal",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.itemVentaTotal} />;
      }
    },
    {
      Header: () => (
        <p className="font-weight-bold">Fecha fact. nota de crédito</p>
      ),
      id: 11,
      width: 160,
      className: "text-center",
      accessor: "notaCreditoFechaFacturacion"
    },
    {
      Header: () => <p className="font-weight-bold">Fecha fact. de venta</p>,
      id: 111,
      width: 160,
      className: "text-center",
      accessor: "fechaFacturacion"
    },
    {
      Header: () => <p className="font-weight-bold">Folio factura original</p>,
      id: 12,
      width: 160,
      accessor: "folioFactura",
      className: "text-center"
    },

    {
      Header: () => <p className="font-weight-bold">Factura UUID</p>,
      id: 13,
      width: 160,
      accessor: "facturaUUID"
    },
    {
      Header: () => <p className="font-weight-bold">Factura Importe</p>,
      id: 14,
      width: 160,
      accessor: "importe",
      className: "text-right",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.importe} />;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Factura Formas de pago</p>,
      id: 15,
      width: 160,
      accessor: "itemFormasPago"
    },
    {
      Header: () => <p className="font-weight-bold">Razón social</p>,
      id: 16,
      width: 160,
      accessor: "razonSocial"
    },
    {
      Header: () => <p className="font-weight-bold">RFC</p>,
      id: 17,
      width: 160,
      accessor: "rfc",
      className: "text-center"
    },
    {
      Header: () => <p className="font-weight-bold">Item</p>,
      id: 18,
      width: 260,
      accessor: "itemNombre"
    },
    {
      Header: () => <p className="font-weight-bold">Cuenta devengo</p>,
      id: 19,
      width: 120,
      accessor: "itemCuentaContableDevengo",
      className: "text-center"
    },
    {
      Header: () => <p className="font-weight-bold">Cuenta devengo nombre</p>,
      id: 20,
      width: 160,
      accessor: "itemCuentaContableDevengoDesc"
    },
    {
      Header: () => <p className="font-weight-bold">Cuenta ingresos</p>,
      id: 21,
      width: 120,
      accessor: "itemCuentaContableIngreso",
      className: "text-center"
    },
    {
      Header: () => <p className="font-weight-bold">Cuenta ingresos nombre</p>,
      id: 22,
      width: 160,
      accessor: "itemCuentaContableIngresoDesc"
    },
    {
      Header: () => <p className="font-weight-bold">Devolución Parcial</p>,
      id: 505,
      width: 160,
      accessor: "devolucionParcial",
      Cell: row => {
        return (
          <div className="text-center">
            {!!row.original.devolucionParcial ? "SI" : "NO"}
          </div>
        );
      }
    },
    {
      Header: () => (
        <p className="font-weight-bold">Motivo devolución Parcial</p>
      ),
      id: 505,
      width: 160,
      accessor: "motivoDevolucionParcial"
    },
    {
      Header: () => {
        return <p className="font-weight-bold">Opciones</p>;
      },
      id: -300,
      width: 200,
      accessor: "actions",
      Cell: row => {
        return !!row.original && !!row.original.first ? (
          <Container className="p-0">
            <Row className="m-0">{this.renderOptions(row)}</Row>
          </Container>
        ) : (
          <div></div>
        );
      }
    }
  ];

  renderOptions = row => {
    // REQ: Erika Jaime: Diferencia entre cancelaciones y notas de crédito
    const AUTORIZAR_DATOS_FISCALES =
      row.tipo == "NOTA_CREDITO"
        ? AUTORIZAR_NOTA_CREDITO_DATOS_FISCALES
        : AUTORIZAR_CANCELACION_DATOS_FISCALES;
    const AUTORIZAR_COBRANZA_ERRONEA =
      row.tipo == "NOTA_CREDITO"
        ? AUTORIZAR_NOTA_CREDITO_COBRANZA_ERRONEA
        : AUTORIZAR_CANCELACION_COBRANZA_ERRONEA;
    const RECHAZAR_SOLICITUD_DATOS_FISCALES =
      row.tipo == "NOTA_CREDITO"
        ? RECHAZAR_SOLICITUD_NOTA_CREDITO_DATOS_FISCALES
        : RECHAZAR_SOLICITUD_CANCELACION_DATOS_FISCALES;
    const RECHAZAR_SOLICITUD_COBRANZA_ERRONEA =
      row.tipo == "NOTA_CREDITO"
        ? RECHAZAR_SOLICITUD_NOTA_CREDITO_COBRANZA_ERRONEA
        : RECHAZAR_SOLICITUD_CANCELACION_COBRANZA_ERRONEA;

    const options = [];
    const causa = row.original.causa;
    const estatus = row.original.estatus;
    const esDevolucionParcial = !!row.original.devolucionParcial;

    const permisoAutorizar =
      (causa === "DATOS_FISCALES" &&
        userHasPermission(AUTORIZAR_DATOS_FISCALES)) ||
      (causa === "COBRANZA_ERRONEA" &&
        userHasPermission(AUTORIZAR_COBRANZA_ERRONEA));
    const permisoRechazar =
      (causa === "DATOS_FISCALES" &&
        userHasPermission(RECHAZAR_SOLICITUD_DATOS_FISCALES)) ||
      (causa === "COBRANZA_ERRONEA" &&
        userHasPermission(RECHAZAR_SOLICITUD_COBRANZA_ERRONEA));

    if (!!esDevolucionParcial) {
      options.push(
        <Col className="px-1" key={1}>
          <BaseButtonWithIconAndTooltip
            id={`ites-devolucion-${row.original.id}`}
            icon={faEye}
            tooltipTitle="Ver items correspondientes a la devolución"
            onClick={() =>
              this.viewNotaCreditoSolicitudItemsDevolucion(row.original.id)
            }
          />
        </Col>
      );
    }

    if (estatus == "AUTORIZADA" && row.original.notaCreditoPdfStorageId) {
      options.push(
        <Col className="px-1" key={1}>
          <BaseButtonWithIconAndTooltip
            id={`email${row.original.notaCreditoFacturaId}`}
            icon={faEnvelope}
            tooltipTitle="Enviar por email"
            onClick={() =>
              this.toggleModalEmail(row.original.notaCreditoFacturaId)
            }
          />
        </Col>
      );
      options.push(
        <Col className="px-1" key={2}>
          <BaseButtonWithIconAndTooltip
            id={`factura${row.original.notaCreditoPdfStorageId}`}
            icon={faFilePdf}
            tooltipTitle="Ver PDF"
            onClick={() =>
              this.props.toggleBill(row.original.notaCreditoPdfStorageId)
            }
          />
        </Col>
      );
    }

    if (
      estatus == "NO_AUTORIZADA" &&
      permisoAutorizar &&
      !this.state.processing
    ) {
      options.push(
        <Col className="px-1" key={3}>
          <BaseButtonWithIconAndTooltip
            id={`autorizar${row.original.id}`}
            icon={faCoins}
            tooltipTitle="Autorizar"
            onClick={() => {
              this.setState({
                idToAuthorize: row.original.id,
                idToReject: null,
                confirmAuthorizeModalIsOpen: true
              });
            }}
          />
        </Col>
      );
    }

    if (
      estatus == "NO_AUTORIZADA" &&
      permisoRechazar &&
      !this.state.processing
    ) {
      options.push(
        <Col className="px-1">
          <BaseButtonWithIconAndTooltip
            id={`rechazar${row.original.id}`}
            icon={faTimesCircle}
            tooltipTitle="Rechazar"
            onClick={() => {
              this.setState({
                idToReject: row.original.id,
                idToAuthorize: null,
                confirmRejectModalIsOpen: true
              });
            }}
          />
        </Col>
      );
    }

    if (causa == "DATOS_FISCALES") {
      options.push(
        <Col className="px-1">
          <BaseButtonWithIconAndTooltip
            id={`verSolicitud${row.original.id}`}
            icon={faEye}
            tooltipTitle="Ver nuevos datos fiscales"
            onClick={() =>
              getCreditNoteReqByIdRequest(row.original.id).then(({ data }) => {
                this.setState({
                  reqData: data,
                  viewReqDataModalOpen: true
                });
              })
            }
          />
        </Col>
      );
    }

    if (estatus == "PROCESO_CANCELACION") {
      options.push(
        <Col className="px-1">
          <BaseButtonWithIconAndTooltip
            id={`verificar${row.original.id}`}
            icon={faQuestion}
            tooltipTitle="Verificar cancelación"
            onClick={async () => {
              infoNotification("Verificando cancelación");

              postVerificarCancelacion(row.original.facturaUUID)
                .then(() => {
                  successNotification("La factura ha sido cancelada");
                })
                .catch(() => {
                  errorNotification("La factura no ha sido cancelada");
                });
            }}
          />
        </Col>
      );

      if (userHasPermission(CONFIRMAR_CANCELACION_MANUALMENTE)) {
        options.push(
          <Col className="px-1">
            <BaseButtonWithIconAndTooltip
              id={`cancelar${row.original.id}`}
              icon={faUser}
              tooltipTitle="Confirmar cancelación manualmente"
              onClick={() => {
                infoNotification("Confirmando cancelación manualmente");

                postConfirmarCancelacion(row.original.facturaUUID)
                  .then(() => {
                    successNotification("Cancelación confirmada correctamente");
                  })
                  .catch(() => {
                    errorNotification(
                      "No se pudo confirmar la cancelación, comuníquese con sistemas."
                    );
                  });
              }}
            />
          </Col>
        );
      }

      if (userHasPermission(REENVIAR_SOLICITUD_CANCELACION)) {
        options.push(
          <Col className="px-1">
            <BaseButtonWithIconAndTooltip
              id={`reenviar${row.original.id}`}
              icon={faRedo}
              tooltipTitle="Reenviar solicitud de cancelación"
              onClick={() => {
                this.setState({
                  uuidToRetry: row.original.facturaUUID,
                  confirmRetryModalIsOpen: true
                });
              }}
            />
          </Col>
        );
      }
    }

    if (estatus == "PROCESO_REFACTURACION" && causa == "DATOS_FISCALES") {
      options.push(
        <Col className="px-1">
          <BaseButtonWithIconAndTooltip
            id={`verSolicitud${row.original.id}`}
            icon={faTimesCircle}
            tooltipTitle="Intentar refacturación nuevamente"
            onClick={() => {
              infoNotification("Solicitando refacturación...");

              postRefacturar(estatus.id)
                .then(() => {
                  successNotification("Refacturación correcta");
                })
                .catch(() => {
                  errorNotification(
                    "No se pudo confirmar la cancelación, comuníquese con sistemas."
                  );
                });
            }}
          />
        </Col>
      );
    }

    return options;
  };

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeDate = fieldName => {
    return event => {
      const date = event ? moment(event).format("YYYY-MM-DD") : null;
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  handleDisabledButton = () => {
    let valReturn = false;
    const { filters } = this.state;
    if (
      isNil(filters.idClub) ||
      isNil(filters.fechaFin) ||
      isNil(filters.fechaInicio)
    ) {
      valReturn = true;
    }
    return valReturn;
  };

  search = e => {
    e.preventDefault();
    const {
      idClub,
      fechaInicio,
      fechaFin,
      fechaInicioAutorizacion,
      fechaFinAutorizacion,
      estatus,
      tipo
    } = this.state.filters;
    this.props.getCreditNotes(
      idClub,
      fechaInicio,
      fechaFin,
      fechaInicioAutorizacion,
      fechaFinAutorizacion,
      estatus,
      tipo
    );
  };

  //AUTHORIZE CREDIT NOTE
  autorizeCreditNote = async () => {
    const { idToAuthorize } = this.state;

    this.props
      .processCreditNote(idToAuthorize)
      .then(() => {
        successNotification("Operación autorizada correctamente.");
        this.setState({
          processing: false,
          confirmAuthorizeModalIsOpen: false,
          idToAuthorize: null,
          idToReject: null
        });
      })
      .catch(error => {
        this.setState({ processing: false });
        handleRequestError(error);
      });
  };

  debouncedAuthorizeCreditNote = debounce(this.autorizeCreditNote, 3000);

  //REJECT CREDIT NOTE
  rejectCreditNote = async () => {
    const { idToReject } = this.state;

    this.props
      .rejectCreditNoteDemand(idToReject)
      .then(() => {
        successNotification("Operación rechazada correctamente.");
        this.setState({
          processing: false,
          confirmRejectModalIsOpen: false,
          idToReject: null,
          idToAuthorize: null
        });
      })
      .catch(error => {
        this.setState({ processing: false });
        handleRequestError(error);
      });
  };

  debouncedRejectCreditNote = debounce(this.rejectCreditNote, 3000);

  // Reenviar solicitud de cancelación
  retryCreditNoteRequest = async () => {
    const { uuidToRetry } = this.state;

    this.props
      .retryCreditNoteRequest(uuidToRetry)
      .then(() => {
        this.setState({
          processing: false,
          confirmRetryModalIsOpen: false,
          uuidToRetry: null
        });
      })
      .catch(error => {
        this.setState({ processing: false });
        handleRequestError(error);
      });
  };

  debouncedRetryCreditNoteRequest = debounce(this.retryCreditNoteRequest, 3000);

  render() {
    const { idClub, estatus, tipo } = this.state.filters;
    const { clubs, reportCreditNotes } = this.props;

    let suma = 0;
    reportCreditNotes
      ? reportCreditNotes.map(cn => {
          if (cn.first && cn.estatus == "AUTORIZADA") {
            suma += cn.importe;
          }
        })
      : 0;

    return (
      <Container className="p-0">
        <ModalFrame
          title={"Mandar Factura por Mail"}
          isOpen={this.state.modalEmail}
          toggle={this.toggleModalEmail}
          size="lg"
        >
          <EmailBill
            id={this.state.idModalEmail}
            sendEmail={this.props.sendMovementEmail}
          />
        </ModalFrame>

        <ModalFrame
          title={"Items correspondientes a la devolución"}
          isOpen={this.state.viewItemsDevModalIsOpen}
          toggle={() => {
            this.setState({
              viewItemsDevModalIsOpen: false,
              viewItemsDevModalItems: []
            });
          }}
          size="lg"
        >
          <div className={s.customDistanciaCheck}>
            <ReactTable
              data={this.state.viewItemsDevModalItems || []}
              columns={[
                {
                  Header: () => <p className="font-weight-bold">Item</p>,
                  id: 100,
                  width: "450",
                  accessor: "descripcionItem",
                  className: "text-wrap"
                },
                {
                  Header: () => (
                    <p className="font-weight-bold">Monto de la devolución</p>
                  ),
                  id: 200,
                  width: "200",
                  accessor: "montoDevolucion",
                  className: "text-center",
                  Cell: row => {
                    return (
                      <MexicanCurrency
                        quantity={row.original.montoDevolucion}
                      />
                    );
                  }
                },
                {
                  Header: () => (
                    <p className="font-weight-bold">Cuenta contable</p>
                  ),
                  id: 200,
                  width: "100",
                  accessor: "cuentaContable",
                  className: "text-center"
                }
              ]}
              className="mt-5 -striped -highlight"
              sortable={false}
              resizable={false}
              showPagination={false}
              pageSize={(this.state.viewItemsDevModalItems || []).length + 1}
            />
          </div>
        </ModalFrame>

        <ConfirmModal
          title="Autorizar la cancelación o nota de crédito"
          message="¿Desea autorizar la cancelación o nota de crédito seleccionada?"
          isOpen={this.state.confirmAuthorizeModalIsOpen}
          ok={() => {
            infoNotification("Autorizando. Por favor espere. ");

            this.setState({
              reloadTable: true,
              processing: true,
              confirmAuthorizeModalIsOpen: false
            });

            this.debouncedAuthorizeCreditNote();
          }}
          cancel={() => {
            this.setState({
              idToAuthorize: null,
              confirmAuthorizeModalIsOpen: false
            });
          }}
        />

        <ConfirmModal
          title="Rechazar"
          message="¿Desea rechazar la cancelación o nota de crédito seleccionada?"
          isOpen={this.state.confirmRejectModalIsOpen}
          ok={() => {
            infoNotification("Rechazando. Por favor espere. ");

            this.setState({
              reloadTable: true,
              processing: true,
              confirmRejectModalIsOpen: false
            });

            this.debouncedRejectCreditNote();
          }}
          cancel={() => {
            this.setState({
              idToReject: null,
              confirmRejectModalIsOpen: false
            });
          }}
        />

        <ConfirmModal
          title="Reenviar solicitud"
          message="Se reenviará una solicitud de cancelación al cliente. ¿Desea continuar?"
          isOpen={this.state.confirmRetryModalIsOpen}
          ok={() => {
            infoNotification("Reenviando solicitud. Por favor espere...");

            this.setState({
              reloadTable: true,
              processing: true,
              confirmRetryModalIsOpen: false
            });

            this.debouncedRetryCreditNoteRequest();
          }}
          cancel={() => {
            this.setState({
              idToReject: null,
              confirmRetryModalIsOpen: false
            });
          }}
        />

        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>
              Reporte de notas de crédito
            </p>
          </div>
        </div>
        <Row>
          <Col xs={12} md={4} lg={3}>
            <BaseInput
              type="select"
              id="club"
              name="club"
              label="Club *"
              value={idClub}
              onChange={this.onChangeFilter("idClub")}
              options={clubCatalogAsOptions(clubs)}
              disabled={
                this.props.userContext && this.props.userContext.clubSessionId
              }
            />
          </Col>
          <Col xs={12} md={4} lg={2}>
            <div
              style={{ display: "inline-block", width: "calc(100% - 24px)" }}
            >
              <DatePicker
                label="Solicitud desde *"
                name="fechaInicio"
                id="fechaInicio"
                selected={this.state.filters["fechaInicio"]}
                onChange={this.onChangeDate("fechaInicio")}
              />
            </div>
            <UncontrolledTooltip placement="top" target="fechaInicioBorrar">
              Borrar fecha
            </UncontrolledTooltip>
            <div
              id="fechaInicioBorrar"
              onClick={() => this.onChangeDate("fechaInicio")(null)}
              style={{
                cursor: "pointer",
                textAlign: "right",
                display: "inline-block",
                width: "24px"
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </div>
          </Col>

          <Col xs={12} md={4} lg={2}>
            <div
              style={{ display: "inline-block", width: "calc(100% - 24px)" }}
            >
              <DatePicker
                label="Solicitud hasta *"
                name={"fechaFin"}
                id={"fechaFin"}
                selected={this.state.filters["fechaFin"]}
                onChange={this.onChangeDate("fechaFin")}
              />
            </div>
            <UncontrolledTooltip placement="top" target="fechaFinBorrar">
              Borrar fecha
            </UncontrolledTooltip>
            <div
              id="fechaFinBorrar"
              onClick={() => this.onChangeDate("fechaFin")(null)}
              style={{
                cursor: "pointer",
                textAlign: "right",
                display: "inline-block",
                width: "24px"
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </div>
          </Col>

          <Col xs={12} md={4} lg={2}>
            <div
              style={{ display: "inline-block", width: "calc(100% - 24px)" }}
            >
              <DatePicker
                label="Autorizado desde *"
                name="fechaInicioAutorizacion"
                id="fechaInicioAutorizacion"
                selected={this.state.filters["fechaInicioAutorizacion"]}
                onChange={this.onChangeDate("fechaInicioAutorizacion")}
              />
            </div>
            <UncontrolledTooltip
              placement="top"
              target="fechaInicioAutorizacionBorrar"
            >
              Borrar fecha
            </UncontrolledTooltip>
            <div
              id="fechaInicioAutorizacionBorrar"
              onClick={() => this.onChangeDate("fechaInicioAutorizacion")(null)}
              style={{
                cursor: "pointer",
                textAlign: "right",
                display: "inline-block",
                width: "24px"
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </div>
          </Col>

          <Col xs={12} md={4} lg={2}>
            <div
              style={{ display: "inline-block", width: "calc(100% - 24px)" }}
            >
              <DatePicker
                label="Autorizado hasta *"
                name={"fechaFinAutorizacion"}
                id={"fechaFinAutorizacion"}
                selected={this.state.filters["fechaFinAutorizacion"]}
                onChange={this.onChangeDate("fechaFinAutorizacion")}
              />
            </div>
            <UncontrolledTooltip
              placement="top"
              target="fechaFinAutorizacionBorrar"
            >
              Borrar fecha
            </UncontrolledTooltip>
            <div
              id="fechaFinAutorizacionBorrar"
              onClick={() => this.onChangeDate("fechaFinAutorizacion")(null)}
              style={{
                cursor: "pointer",
                textAlign: "right",
                display: "inline-block",
                width: "24px"
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </div>
          </Col>
          <Col xs={12} md={3} lg={3}>
            <BaseInput
              type="select"
              id="tipo"
              name="tipo"
              label="Tipo *"
              value={tipo}
              onChange={this.onChangeFilter("tipo")}
              size={"sm"}
              options={[
                {
                  value: "NOTA_CREDITO",
                  label: "Notas de crédito"
                },
                {
                  value: "CANCELACION",
                  label: "Cancelaciones"
                }
              ]}
            />
          </Col>
          <Col xs={12} md={3} lg={3}>
            <BaseInput
              type="select"
              id="estatus"
              name="estatus"
              label="Estatus"
              value={estatus}
              onChange={this.onChangeFilter("estatus")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar estatus" }].concat(
                (this.state.filters.tipo == "CANCELACION"
                  ? [
                      "AUTORIZADA",
                      "NO_AUTORIZADA",
                      "PROCESO_CANCELACION",
                      "PROCESO_REFACTURACION",
                      "RECHAZADA"
                    ]
                  : ["AUTORIZADA", "NO_AUTORIZADA", "RECHAZADA"]
                ).map(option => ({
                  value: option,
                  label: option
                }))
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={2} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
              disabled={this.props.loadingCreditNotes}
            >
              {this.props.loadingCreditNotes ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  style={{ color: "#000", fontSize: "1.1em" }}
                  pulse={true}
                />
              ) : (
                "Buscar"
              )}
            </Button>
          </Col>
        </Row>
        {!!(reportCreditNotes || []).length && (
          <Row className={"mt-5"}>
            <Col sm="6">
              Resultados de la búsqueda:{" "}
              <b
                style={{ fontWeight: "bolder", display: "inline-block" }}
                className="ml-3"
              >
                {(reportCreditNotes || []).length}
              </b>
            </Col>
            <Col sm="6" className="text-right">
              <span>Exportar a CSV: </span>
              <Button
                id="btnReportExportCsv"
                color="link"
                onClick={e => {
                  e.preventDefault();
                  const {
                    idClub,
                    fechaInicio,
                    fechaFin,
                    fechaInicioAutorizacion,
                    fechaFinAutorizacion,
                    estatus,
                    tipo
                  } = this.state.filters;
                  downloadCreditNotesReport(
                    idClub,
                    fechaInicio,
                    fechaFin,
                    fechaInicioAutorizacion,
                    fechaFinAutorizacion,
                    estatus,
                    tipo
                  );
                }}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        )}
        <ReactTable
          data={reportCreditNotes || []}
          columns={this.columns}
          className="w-100"
          showPagination={true}
          defaultPageSize={20}
        />
        <Row>
          <Col xs={12} md={6} lg={6} className="mt-4">
            Notas de crédito aplicadas
          </Col>
          <Col xs={12} md={6} lg={6} className="mt-4">
            <MexicanCurrency quantity={suma} />
          </Col>
        </Row>

        <ModalFrame
          title={"Nuevos datos fiscales"}
          isOpen={this.state.viewReqDataModalOpen}
          toggle={this.toggleModalViewReqData}
        >
          {this.state.reqData && this.state.reqData.datosFiscales && (
            <Container>
              <div className="mt-3">
                <span style={{ fontWeight: "bold" }}>
                  Nombre / Razon social
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.reqData.datosFiscales.razonSocial ||
                  `${this.state.reqData.datosFiscales.nombre || ""} ${this.state
                    .reqData.datosFiscales.segundoNombre || ""} ${this.state
                    .reqData.datosFiscales.apellidoPaterno || ""} ${this.state
                    .reqData.datosFiscales.apellidoMaterno || ""} `}
              </div>
              <div className="mt-3">
                <span style={{ fontWeight: "bold" }}>RFC</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.reqData.datosFiscales.rfc}
              </div>
              <div className="mt-3">
                <span style={{ fontWeight: "bold" }}>Calle y número</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {`${this.state.reqData.datosFiscales.calle || ""} ${this.state
                  .reqData.datosFiscales.numero || ""}`}
              </div>
              <div className="mt-3">
                <span style={{ fontWeight: "bold" }}>Colonia</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.reqData.datosFiscales.colonia || ""}
              </div>
              <div className="mt-3">
                <span style={{ fontWeight: "bold" }}>CP</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.reqData.datosFiscales.cp || ""}
              </div>
              <div className="mt-3">
                <span style={{ fontWeight: "bold" }}>Estado</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.reqData.datosFiscales.estado || ""}
              </div>
              <div className="mt-3">
                <span style={{ fontWeight: "bold" }}>Municipio</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.reqData.datosFiscales.municipio || ""}
              </div>
              <div className="mt-3">
                <span style={{ fontWeight: "bold" }}>Clave Uso CFDI</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.reqData.datosFiscales.claveUsoCfdi || ""}
              </div>
            </Container>
          )}
        </ModalFrame>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(CreditNotesReport));
