import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { Button } from "reactstrap";
import s from "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import ConvenioMembresia from "../../../types/ConvenioMembresia";

export interface TableProps {
  page: Page<ConvenioMembresia>;
  loading: boolean;
  onFetchData: ({ page, pageSize }) => void;
  onExport: () => void;
}

export const MemoTable = React.memo(
  ({ page, loading, onFetchData, onExport }: TableProps) => {
    // State de las columnas de la tabla
    const [columns, setColumns] = useState(columnsFormatter());
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);

    // Actualización de estilos
    useEffect(() => {
      setColumns(columnsFormatter());
    }, []);

    // Actualización de datos de paginación
    useEffect(() => {
      if (page) {
        setPageNumber(page.number);
        setTotalPages(page.totalPages);
      }
    }, [page]);

    const mostrados = page && page.numberOfElements;
    const totales = page && page.totalElements;
    return (
      <>
        {/* Botón para exportar */}
        <div className="row">
          {!!page && !!page.content && !!page.content.length && (
            <>
              <div className="col-6 p-0" style={{ lineHeight: "40px" }}>
                <span style={{ fontWeight: "bold" }}>
                  Membresías mostradas: {mostrados} de {totales}
                </span>
              </div>
              <div
                className="col-6 p-0 text-right"
                style={{ lineHeight: "40px" }}
              >
                <Button
                  color="primary"
                  onClick={onExport}
                  disabled={loading}
                  className="mr-2"
                >
                  <FontAwesomeIcon icon={faFileExport} />
                  {!loading ? " Exportar" : " Espere..."}
                </Button>
              </div>
            </>
          )}
        </div>

        {/* Tabla */}
        <div className="row mt-2">
          <div style={{ width: "100%" }}>
            <ReactTable
              manual
              data={(page && page.content) || []}
              columns={columns}
              loading={loading}
              defaultPageSize={20}
              className="-striped -highlight"
              onFetchData={onFetchData}
              page={pageNumber}
              pages={totalPages}
              showPagination={true}
              showPageSizeOptions={false}
              getTrProps={() => ({ style: { height: "30px" } })}
              sortable={false}
            />
          </div>
        </div>
      </>
    );
  }
);

// TODO: Definir permisos para los botones
function columnsFormatter() {
  return [
    {
      Header: "Club",
      id: "club",
      width: 88,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("club")
    },
    {
      Header: "Membresía",
      id: "idPublico",
      width: 88,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("idPublico")
    },
    {
      Header: "Tipo",
      id: "tipoMembresia",
      width: 128,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("tipoMembresia")
    },
    {
      Header: "Mantenimiento",
      id: "mantenimiento",
      width: 128,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("mantenimiento")
    },
    {
      Header: "Empresa",
      id: "empresa",
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("empresa")
    },
    {
      Header: "Responsable",
      id: "responsable",
      width: 128,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("responsable")
    },
    {
      Header: "Fecha",
      id: "fechaActivacion",
      width: 88,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: dateAccessor("fechaActivacion")
    },
    {
      Header: "Estatus",
      id: "estatus",
      width: 88,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: enumAccessor("estatus")
    },
    {
      Header: "Titular",
      id: "titular",
      width: 255,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("titular")
    },
    {
      Header: "Último mantenimiento",
      id: "utltimoMantenimiento",
      width: 88,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: dateAccessor("utltimoMantenimiento")
    }
  ];
}

function simpleAccessor(key: keyof ConvenioMembresia) {
  return (row: ConvenioMembresia) => {
    return <div className={fieldClassName(row)}>{row[key]}&nbsp;</div>;
  };
}
function enumAccessor(key: keyof ConvenioMembresia) {
  return row => {
    const str = row[key] || "";
    const sentence = str.toLowerCase().split("_");

    for (var i = 0; i < sentence.length; i++) {
      if (sentence[i]) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1) + " ";
      }
    }

    return <div className={fieldClassName(row)}>{sentence}</div>;
  };
}

function dateAccessor(key: keyof ConvenioMembresia) {
  return (row: ConvenioMembresia) => {
    return (
      <div className={fieldClassName(row)}>
        {formatDate(row[key] as string)}&nbsp;
      </div>
    );
  };
}

function fieldClassName(row: ConvenioMembresia) {
  return `${s.field}`;
}

function formatDate(isoDate: string): string {
  try {
    const [year, month, day] = isoDate.split("-");

    // Formato de la fecha:
    const fmtDate = `${day}/${month}/${year} `;
    return fmtDate;
  } catch (error) {
    return isoDate;
  }
}
