import React, { useState } from "react";
import BaseInput from "../../../toolkit/baseInput";
import BaseAlphabeticInput from "../../../toolkit/baseAlphabeticInput";
import BaseNumericInput from "../../../toolkit/baseNumericInput";
import DatePicker from "../../../Home/components/common/DatePicker";
import moment from "moment";
import s from "./styles.scss";
import { Alert, Label } from "reactstrap";
import { MedioProspeccion } from "../../../types-business/User";
import { Typeahead } from "react-bootstrap-typeahead";
import { putExtranjeroBandera } from "../../../Home/actions/partners";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import BaseButtonWithIconAndTooltip from "../../../toolkit/baseButtonWithIconAndTooltip";
import { faBan, faEdit } from "@fortawesome/free-solid-svg-icons";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";
import { getValidarCurp } from "../../../Home/actions/persons";
import BaseAlphanumericInput from "../../../toolkit/baseAlphanumericInput";

const regexCurp = /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9A-Z]{1}$/;
const getOption = medioProspeccion => medioProspeccion.replace(/_/g, " ");

const mpOptions = [
  {
    value: "",
    label: "Seleccione una opción"
  }
];

for (const key in MedioProspeccion) {
  mpOptions.push({
    value: MedioProspeccion[key],
    label: getOption(MedioProspeccion[key])
  });
}

const MALE = "MASCULINO";
const FEMALE = "FEMENINO";
const CONCESIONARIO_TIPO = "CONCESIONARIO";
const PROSPECTO_TIPO = "PROSPECTO";

export const getCleanFields = () => {
  return {
    correoElectronico: "",
    nombre: "",
    primerApellido: "",
    segundoApellido: "",
    fechaNacimiento: "",
    medioProspeccion: null,
    codigoPostal: "",
    colonia: "",
    numeroTelefono: "",
    extension: "",
    sexo: FEMALE,
    convenioId: "",
    empresaId: "",
    convenioData: { value: "", label: "Seleccionar" },
    clubData: { value: "", label: "Seleccionar" },
    securitySpace: "",
    esExtranjero: false,
    curp: "",
    folio: null,
    precioMembresia: null,
    mantenimiento: null,
    precioMantenimiento: null
  };
};

const genreOptions = [
  { value: FEMALE, label: "FEMENINO" },
  { value: MALE, label: "MASCULINO" }
];
const extranjeroOptions = [
  { value: "0", label: "No" },
  { value: "1", label: "Sí" }
];

export default ({
  fields,
  onChangeFactory,
  re,
  onChangeFechaNacimiento,
  creatingProspectError,
  onChangeNumber,
  isMoralPersonType,
  viewMode,
  empresasConvenio,
  onChangeCurp
}) => {
  const agreementOptions = empresasConvenio.map(agreement => ({
    id: `${agreement.convenioId}-${agreement.empresaId}`,
    label: agreement.empresa
  }));
  const selected = agreementOptions.filter(
    opt => opt.id == fields.convenioId + "-" + fields.empresaId
  );

  const [curpError, setCurpError] = useState("");
  const [gettingCurp, setGettingCurp] = useState(false);
  const [hasCurpData, setHasCurpData] = useState(false);
  const [curpRequired, setCurpRequired] = useState(
    !fields.esExtranjero || fields.esExtranjero != "0"
  );

  return (
    <form>
      <div className="form-row">
        <div className="col">
          <BaseAlphanumericInput
            label={"Folio"}
            name="prospectoFolio"
            id="prospectoFolio"
            placeholder="Folio"
            value={fields.folio}
            onChange={onChangeFactory("folio", true)}
            uppercase={true}
            disabled={!!viewMode}
            maxlength={"10"}
          />
        </div>
        {!fields.personaId && (
          <div className="col">
            <BaseInput
              label={"Es extranjero"}
              name="esExtranjero"
              type="select"
              options={extranjeroOptions}
              id="esExtranjero"
              placeholder="Es extranjero"
              value={fields.esExtranjero ? "1" : "0"}
              onChange={evt => {
                if (evt.target.value == "1") {
                  setCurpError("");
                  setCurpRequired(false);
                } else {
                  setCurpRequired(true);
                }
                onChangeFactory("esExtranjero")(evt);
              }}
              errors={re && re.mapFieldErrors("esExtranjero")}
              disabled={!!viewMode}
            />
          </div>
        )}
        <div className="col">
          <BaseInput
            label={"CURP"}
            name="curp"
            id="curp"
            placeholder="CURP"
            value={fields.curp}
            onChange={onChangeFactory("curp", true)}
            maxlength={18}
            onBlur={async evt => {
              try {
                const curp = evt.target.value;
                if (!fields.personaId && !curp) {
                  setCurpError("");
                  setHasCurpData(false);
                  return;
                }

                // Comprobación del CURP
                if (!regexCurp.test(curp)) {
                  setCurpError("CURP inválida");
                  return;
                }

                setGettingCurp(true);
                const curpDataResponse = await getValidarCurp(
                  fields.personaId,
                  curp
                );
                const curpData = curpDataResponse.data;

                if (curpData.apellidoPaterno) {
                  onChangeCurp(curpData, fields.curp);
                  setHasCurpData(true);
                  setGettingCurp(false);
                } else {
                  onChangeCurp(null, fields.curp);
                  setGettingCurp(false);
                  setHasCurpData(false);
                }
                setCurpError("");
              } catch (err) {
                onChangeCurp(null, fields.curp);
                setGettingCurp(false);
                setHasCurpData(false);
                setCurpError(
                  (err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.message) ||
                    "Error al validar la CURP"
                );
              }
            }}
            errors={curpError ? [curpError] : null}
            uppercase={true}
            disabled={!!viewMode || !curpRequired || gettingCurp}
          />
        </div>
        <div className="col">
          <BaseAlphabeticInput
            label={"Nombre"}
            name="nombre"
            id="nombre"
            placeholder="Nombre"
            value={fields.nombre}
            onChange={onChangeFactory("nombre", true)}
            errors={re && re.mapFieldErrors("nombre")}
            uppercase={true}
            disabled={!!viewMode || gettingCurp || hasCurpData}
          />
        </div>
        <div className="col">
          <BaseAlphabeticInput
            label={"Apellido Paterno"}
            name="primerApellido"
            type="text"
            id="primerApellido"
            placeholder="Apellido Paterno"
            value={fields.primerApellido}
            onChange={onChangeFactory("primerApellido", true)}
            errors={re && re.mapFieldErrors("primerApellido")}
            uppercase={true}
            disabled={!!viewMode || gettingCurp || hasCurpData}
          />
        </div>
        <div className="col">
          <BaseAlphabeticInput
            label={"Apellido Materno"}
            name="segundoApellido"
            type="text"
            id="segundoApellido"
            placeholder="Apellido Materno"
            value={fields.segundoApellido}
            onChange={onChangeFactory("segundoApellido", true)}
            errors={re && re.mapFieldErrors("segundoApellido")}
            uppercase={true}
            disabled={!!viewMode || gettingCurp || hasCurpData}
          />
        </div>
      </div>
      <div className="form-row">
        <div className=" col-sm-6 col-md-4">
          <DatePicker
            label={
              !isMoralPersonType ? "Fecha de Nacimiento" : "Fecha de Registro"
            }
            name="fechaNacimiento"
            id="fechaNacimiento"
            selected={fields.fechaNacimiento || moment().subtract(1, "years")}
            onChange={onChangeFechaNacimiento}
            errors={re && re.mapFieldErrors("fechaNacimiento")}
            maxDate={moment().add(2, "years")}
            minDate={moment().subtract(100, "years")}
            disabled={!!viewMode || gettingCurp || hasCurpData}
          />
        </div>
        <div className=" col-sm-6 col-md-4">
          <BaseInput
            label={"Correo Electrónico"}
            name="correoElectronico"
            type="correoElectronico"
            id="correoElectronico"
            placeholder="Correo Electrónico"
            value={fields.correoElectronico}
            onChange={onChangeFactory("correoElectronico")}
            errors={re && re.mapFieldErrors("correoElectronico")}
            disabled={!!viewMode}
          />
        </div>
        <div className=" col-sm-6 col-md-4">
          <BaseNumericInput
            label={"Número de Teléfono"}
            name="numeroTelefono"
            type="text"
            id="numeroTelefono"
            placeholder="Número de Teléfono"
            value={fields.numeroTelefono}
            onChange={onChangeNumber("numeroTelefono")}
            errors={re && re.mapFieldErrors("numeroTelefono")}
            maxlength={"10"}
            disabled={!!viewMode}
          />
        </div>
        <div className=" col-sm-6 col-md-4">
          <BaseNumericInput
            label={"Extensión"}
            name="extension"
            type="text"
            id="extension"
            placeholder="Extensión"
            value={fields.extension}
            onChange={onChangeNumber("extension")}
            errors={re && re.mapFieldErrors("extension")}
            maxlength={"5"}
            disabled={!!viewMode}
          />
        </div>
        {(fields.tipo || PROSPECTO_TIPO) == PROSPECTO_TIPO && (
          <div className=" col-sm-6 col-md-4">
            <BaseInput
              label={"Medio de Prospección"}
              name="medioProspeccion"
              type="select"
              options={mpOptions}
              id="medioProspeccion"
              placeholder="Medio de Prospección"
              value={fields.medioProspeccion}
              onChange={onChangeFactory("medioProspeccion")}
              errors={re && re.mapFieldErrors("medioProspeccion")}
              disabled={!!viewMode}
            />
          </div>
        )}

        <div className=" col-sm-6 col-md-4">
          <BaseInput
            label={"Sexo"}
            name="sexo"
            type="select"
            options={genreOptions}
            id="sexo"
            placeholder="Sexo"
            value={fields.sexo}
            onChange={onChangeFactory("sexo")}
            errors={re && re.mapFieldErrors("sexo")}
            disabled={!!viewMode}
          />
        </div>

        {!!fields.personaId && (
          <div
            className=" col-sm-6 col-md-4 mt-4"
            style={{ fontSize: "0.8em" }}
          >
            <span>
              {fields.esExtranjero ? "Es extranjero" : "No es extranjero"}
            </span>
            {userHasPermission(
              permissionCodes.PROSPECT_EDITION_BANDERA_EXTRANJERO
            ) && (
              <BaseButtonWithIconAndTooltip
                id={`esExtranjero`}
                icon={fields.esExtranjero ? faBan : faEdit}
                color={fields.esExtranjero ? "#710" : "#000"}
                tooltipTitle={
                  fields.esExtranjero
                    ? "Configurar como NO extranjero"
                    : "Configurar como extranjero"
                }
                onClick={() => {
                  infoNotification("Aplicando la configuración de extranjeros");
                  putExtranjeroBandera(fields.personaId, !fields.esExtranjero)
                    .then(resp => {
                      onChangeFactory("esExtranjero")(!fields.esExtranjero);
                      successNotification(
                        "Configuración aplicada exitosamente"
                      );
                    })
                    .catch(err => {
                      errorNotification(
                        "No es posible aplicar la configuración de extranjero"
                      );
                    });
                }}
              />
            )}
          </div>
        )}

        {(fields.tipo || PROSPECTO_TIPO) == PROSPECTO_TIPO && (
          <div className=" col-sm-12 col-md-12">
            <Label className={s.label}>Convenio:</Label>
            <Typeahead
              label={"Convenio"}
              name="convenioId"
              type="select"
              id="convenioId"
              promptText={"Convenio"}
              bsSize={"sm"}
              emptyLabel={"Sin coincidencias"}
              selected={selected}
              options={agreementOptions}
              onChange={sel => {
                const fx = onChangeFactory("convenioId");
                const fxEmp = onChangeFactory("empresaId");
                const convenioId =
                  sel.length > 0 ? sel[0]["id"].split("-")[0] : null;
                const empresaId =
                  sel.length > 0 ? sel[0]["id"].split("-")[1] : null;

                fx({
                  target: {
                    value: convenioId
                  }
                });

                fxEmp({
                  target: {
                    value: empresaId
                  }
                });
              }}
            />
          </div>
        )}
      </div>

      {!!fields && (fields.tipo || PROSPECTO_TIPO) == PROSPECTO_TIPO && (
        <div>
          <div className="form-row mt-4 mb-4">
            <div className=" col-sm-6 col-md-4">
              <BaseNumericInput
                type="text"
                label={"Precio membresía"}
                name="prospectoPrecioMembresia"
                id="prospectoPrecioMembresia"
                placeholder="Precio membresía"
                value={fields.precioMembresia}
                onChange={onChangeNumber("precioMembresia")}
                errors={
                  re &&
                  fields.hasOwnProperty("precioMembresia") &&
                  re.mapFieldErrors("precioMembresia")
                }
                disabled={!!viewMode}
                maxlength={8}
              />
            </div>
            <div className=" col-sm-6 col-md-4">
              <BaseAlphanumericInput
                type="text"
                label={"Mantenimiento"}
                name="prospectoMantenimiento"
                id="prospectoMantenimiento"
                placeholder="Mantenimiento"
                value={fields.mantenimiento}
                onChange={onChangeFactory("mantenimiento", true)}
                errors={
                  re &&
                  fields.hasOwnProperty("mantenimiento") &&
                  re.mapFieldErrors("mantenimiento")
                }
                uppercase={true}
                disabled={!!viewMode}
                maxlength={49}
              />
            </div>
            <div className=" col-sm-6 col-md-4">
              <BaseNumericInput
                type="text"
                label={"Precio mantenimiento"}
                name="prospectoPrecioMantenimiento"
                id="prospectoPrecioMantenimiento"
                placeholder="Precio mantenimiento"
                value={fields.precioMantenimiento}
                onChange={onChangeNumber("precioMantenimiento")}
                errors={
                  re &&
                  fields.hasOwnProperty("precioMantenimiento") &&
                  re.mapFieldErrors("precioMantenimiento")
                }
                disabled={!!viewMode}
                maxlength={8}
              />
            </div>
          </div>
        </div>
      )}

      {creatingProspectError && (
        <span>
          <hr />
          <Alert color="danger">
            Ocurrio un error creando el prospecto. Intente nuevamente.
          </Alert>
        </span>
      )}
    </form>
  );
};
