import React, { Component } from "react";
import ModalFrame from "../../../../../toolkit/modalFrame";
import DeportivaComisionLog from "../../../../../types/DeportivaComisionLog";

interface Props {
  logs: DeportivaComisionLog[];
  open: boolean;
  onClose: () => void;
}

interface State {}

export default class DAutorizacionLog extends Component<Props, State> {
  state = {};

  formatDate = (mysqlDate: string): string => {
    if (!mysqlDate) {
      return "-";
    }

    try {
      const [date, time] = mysqlDate.split(" ");
      const [year, month, day] = date.split("-");

      // Formato de la fecha:
      const fmtDate = `${day}/${month}/${year} `;
      const indexSeconds = time.lastIndexOf(":");
      const fmtTime =
        indexSeconds >= 4 ? time.substring(0, indexSeconds) : time;

      return fmtDate + fmtTime;
    } catch (error) {
      return mysqlDate;
    }
  };

  render() {
    const { logs, open, onClose } = this.props;
    const logsTable = logs.filter(l => !l.autorizacion);
    const autorizacion = logs.find(l => l.autorizacion);

    return (
      <ModalFrame
        title="Logs de comisión"
        isOpen={open}
        toggle={onClose}
        size="lg"
      >
        <div>
          <div className="modal-body">
            {autorizacion && (
              <div className="alert alert-success" role="alert">
                Comisión autorizada por {autorizacion.usuario} el{" "}
                {autorizacion.fecha}
              </div>
            )}
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Fecha</th>
                  <th scope="col">No. Empleado</th>
                  <th scope="col">Empleado</th>
                  <th scope="col">Acción</th>
                </tr>
              </thead>
              <tbody>
                {logsTable.map((log, index) => (
                  <tr key={index}>
                    <td>{this.formatDate(log.fecha)}</td>
                    <td>{log.numeroEmpleado}</td>
                    <td>{log.usuario}</td>
                    <td>{log.accion}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </ModalFrame>
    );
  }
}
