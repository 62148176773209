import {
  GetComisionesDeportivaCrm1Pendientes,
  PutComisionesDeportivaCrm1Procesar
} from "../../reducers/comisionesDeportivaCrm1";

// A partir de la fecha (21 de enero de 2025), ya no se procesan en CRM1
export interface ComisionesDeportivaPendientesStore
  extends GetComisionesDeportivaCrm1Pendientes,
    Omit<
      PutComisionesDeportivaCrm1Procesar,
      "comsionesDeportivaCrm1Procesadas"
    > {}

export const comisionesDeportivaPendientesStore: (
  state
) => ComisionesDeportivaPendientesStore = state => {
  const { comisionesDeportivaCrm1 } = state;
  return {
    comsionesDeportivaCrm1Pendientes:
      comisionesDeportivaCrm1.comsionesDeportivaCrm1Pendientes,
    comsionesDeportivaCrm1PendientesLoading:
      comisionesDeportivaCrm1.comsionesDeportivaCrm1PendientesLoading,
    comsionesDeportivaCrm1PendientesError:
      comisionesDeportivaCrm1.comsionesDeportivaCrm1PendientesError,
    comsionesDeportivaCrm1ProcesarLoading:
      comisionesDeportivaCrm1.comsionesDeportivaCrm1ProcesarLoading,
    comsionesDeportivaCrm1ProcesarError:
      comisionesDeportivaCrm1.comsionesDeportivaCrm1ProcesarError
  };
};
