import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Button, Row, Col, Container, Label, Input } from "reactstrap";
import ModalFrame from "../../../toolkit/modalFrame";

interface Props {
  selPostOpen: boolean;
  toggle: () => any;
  reimpresionTicket: () => any;
  numeroAutorizacion: string;
  showCheck: boolean;
  setTokenizar: () => any;
  forceTokenization: boolean;
  esLineaPago: boolean;
}

interface State {
  check: boolean;
}

class ReimpresionModal extends React.Component<Props, State> {
  state = {
    check: false
  };

  componentWillUnmount() {
    this.setState({ check: false });
  }

  render() {
    return (
      <Container className="p-0">
        <ModalFrame
          title={
            this.props.esLineaPago
              ? "Generar línea de pago"
              : "Reimpresión de ticket"
          }
          isOpen={this.props.selPostOpen}
          toggle={() => {}}
          size="lg"
        >
          <Row className={`${s.customDistancia}`}>
            <Col xs={4} md={4} lg={4}>
              <Label className={s.label} for="horaInicial">
                {this.props.esLineaPago ? (
                  <span style={{ fontWeight: "bold" }}>
                    Tokenizar tarjeta para envío a CAT
                  </span>
                ) : (
                  `Número de autorización de cargo: ${this.props.numeroAutorizacion}`
                )}
              </Label>
            </Col>
            {this.props.showCheck ? (
              <Col xs={4} md={4} lg={4}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={!!this.props.forceTokenization || this.state.check}
                    onChange={e => {
                      this.setState({ check: e.target.checked });
                      this.props.setTokenizar();
                    }}
                    disabled={!!this.props.forceTokenization}
                  />
                  {this.props.esLineaPago
                    ? "Registrar medio de pago principal desde línea"
                    : "Registrar tarjeta como medio de pago principal"}
                </Label>
              </Col>
            ) : null}
          </Row>
          <hr />
          <Row className={`${s.customDistancia}`}>
            <Col xs={6} md={6} lg={6}>
              {!this.props.esLineaPago && (
                <Button
                  block
                  color="secondary"
                  onClick={this.props.reimpresionTicket}
                >
                  REIMPRIMIR
                </Button>
              )}
            </Col>
            <Col xs={6} md={6} lg={6}>
              <Button block color="secondary" onClick={this.props.toggle}>
                {this.props.esLineaPago ? "GENERAR LÍNEA" : "GUARDAR CARGO"}
              </Button>
            </Col>
          </Row>
        </ModalFrame>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ReimpresionModal));
