import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import {
  Router,
  Styled,
  RouterProps
} from "../../../toolkit/decorators/Artifact";
import ConfirmModal from "../common/confirmModal/confirmModal";
import s from "./styles.scss";
import { ComisionesDeportivaMaquilaStore } from "../../containers/comisionesDeportivaMaquila/ComisionesDeportivaMaquila.store";
import { ComisionesDeportivaMaquilaDispatcher } from "../../containers/comisionesDeportivaMaquila/ComisionesDeportivaMaquila.dispatcher";
import MaquilaArchivo from "../../../types/MaquilaArchivo";
import constants from "../../../utils/constants";
import { MemoTable } from "./ComisionesDeportivaMaquila.table";
import { downloadRelacionArchivoMaquila } from "../../actions/comisionesMaquilaV2";

interface Props
  extends ComisionesDeportivaMaquilaStore,
    ComisionesDeportivaMaquilaDispatcher,
    RouterProps<any> {}

interface State {
  row: MaquilaArchivo;
  confirmarOpen: boolean;
  downloading: boolean;
}

/**
 * Gestión de archivos de nómina (maquila)
 */
@Router()
@Styled(s)
export default class ComisionesDeportivaMaquila extends Component<
  Props,
  State
> {
  state = {
    row: null,
    confirmarOpen: false,
    downloading: false
  };

  componentDidMount(): void {
    this.props.getPeriodoAplicable();
    this.props.getArchivosMaquila();
  }

  onDownload = (row: MaquilaArchivo) => {
    if (!row.storage) {
      errorNotification("Genere el archivo antes de descargarlo");
      return;
    }

    const url = `${constants.BASE_URL}/${constants.DOCUMENTS_BASE_URL}/${row.storage}`;
    window.open(url, "_blank");
  };

  onDownloadRelacion = (row: MaquilaArchivo) => {
    this.setState({ downloading: true });
    downloadRelacionArchivoMaquila(row.id, () =>
      this.setState({ downloading: false })
    );
  };

  onRegenerar = (row: MaquilaArchivo) => {
    this.setState({ row, confirmarOpen: true });
  };

  onGenerar = () => {
    this.generar(this.props.cmaqPeriodoAplicable.fechaInicio);
  };

  confirmar = () => {
    this.generar(this.state.row.fechaPeriodoInicio);
  };

  generar = async fechaPeriodo => {
    infoNotification("Generando archivo de nómina...");
    const preview = await this.props.postArchivoMaquila(fechaPeriodo);

    if (preview.fulfilled) {
      this.setState({
        row: null,
        confirmarOpen: false
      });

      this.props.getPeriodoAplicable();
      this.props.getArchivosMaquila();
      successNotification("Archivo generado correctamente");
    } else {
      errorNotification(preview.postingCMaqArchivoMaquilaError);
    }
  };

  loading = () => {
    return (
      this.props.gettingCMaqArchivosMaquila ||
      this.props.postingCMaqArchivoMaquila ||
      this.props.gettingCMaqPeriodoAplicable ||
      this.state.downloading
    );
  };

  render() {
    return (
      <Container className="mb-5">
        <ConfirmModal
          title="Generación"
          message="Confirme la generación del archivo de nómina (maquila)."
          isOpen={this.state.confirmarOpen}
          size="md"
          ok={this.confirmar}
          cancel={() => {
            this.setState({
              confirmarOpen: false
            });
          }}
          waiting={this.loading()}
        />

        {!!this.props.cmaqPeriodoAplicable && (
          <>
            <Row>
              <Col className="mt-3">
                <Button onClick={this.onGenerar} disabled={this.loading()}>
                  Generar archivo del periodo actual (
                  {this.props.cmaqPeriodoAplicable.descripcion})
                </Button>
              </Col>
            </Row>

            <hr className="row" />
          </>
        )}

        <MemoTable
          data={this.props.cmaqArchivosMaquila || []}
          loading={this.loading()}
          onDownload={this.onDownload}
          onDownloadRelacion={this.onDownloadRelacion}
          onRegenerar={this.onRegenerar}
        />
      </Container>
    );
  }
}
