import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import ITypeSelectBuilder from "../../../toolkit/baseForms2/iTypeSelectBuilder/ITypeSelectBuilder";
import IClubSelectorBuilder from "../../../toolkit/baseForms2Commons/iClubSelectorBuilder/IClubSelectorBuilder";

export type Inputs = "idClub" | "idPuesto" | "monto";
export type Buttons = "aceptar" | "cancelar";

export const formConfigClub: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "idClub",
      label: "* Club",
      bts: {
        mCol: 12
      },
      component: IClubSelectorBuilder
    },
    {
      name: "idPuesto",
      label: "* Puesto",
      bts: {
        mCol: 12
      },
      component: ITypeSelectBuilder
    },
    {
      name: "monto",
      label: "* Monto",
      bts: {
        mCol: 12
      },
      component: ITextBuilder
    }
  ],
  buttons: [
    {
      name: "aceptar",
      label: "Aceptar",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        mOff: 4,
        mCol: 4
      }
    },
    {
      name: "cancelar",
      label: "Cancelar",
      component: IButtonBuilder,
      bts: {
        mCol: 4
      }
    }
  ],
  validations: {
    idClub: {
      required: true
    },
    idPuesto: {
      required: true
    },
    monto: {
      required: true
    }
  },
  rows: [["idClub"], ["idPuesto"], ["monto"], ["aceptar", "cancelar"]]
};
