import React from "react";
import { ReduxConnect } from "../../../toolkit/decorators/Artifact";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import {
  ComisionesDeportivaMaquilaDispatcher,
  comisionesDeportivaMaquilaDispatcher
} from "./ComisionesDeportivaMaquila.dispatcher";
import {
  comisionesDeportivaMaquilaStore,
  ComisionesDeportivaMaquilaStore
} from "./ComisionesDeportivaMaquila.store";
import ComisionesDeportivaMaquila from "../../components/comisionesDeportivaMaquila";

interface Props
  extends ComisionesDeportivaMaquilaStore,
    ComisionesDeportivaMaquilaDispatcher {}

interface State {}

@ReduxConnect(
  comisionesDeportivaMaquilaStore,
  comisionesDeportivaMaquilaDispatcher
)
export default class ComisionesDeportivaMaquilaPage extends React.Component<
  Props,
  State
> {
  render() {
    return (
      <main>
        <Helmet title="Agreements" />
        <Layout>
          <ComisionesDeportivaMaquila {...this.props} />
        </Layout>
      </main>
    );
  }
}
