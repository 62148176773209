import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { Input, Button } from "reactstrap";
import ComisionDeportiva from "../../../types/ComisionDeportiva";
import s from "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";

export interface TableProps {
  data: ComisionDeportiva[];
  loading: boolean;
  onExport: () => void;
  onCheck: (id: number, check: boolean) => void;
  onCheckAll: (check: boolean) => void;
  processed: boolean;
  selected: number[];
}

export const MemoTable = React.memo(
  ({
    data,
    loading,
    onExport,
    onCheck,
    onCheckAll,
    processed,
    selected
  }: TableProps) => {
    const [checkAll, setCheckAll] = useState(false);
    const [columns, setColumns] = useState(
      columnsFormatter(onCheck, processed, selected)
    );

    // Actualización de estilos y checkboxes
    useEffect(() => {
      setColumns(columnsFormatter(onCheck, processed, selected));
    }, [processed, selected]);

    // Limpieza de checkbox para seleccionar todo, en nuevas búsquedas
    useEffect(() => {
      checkAll && setCheckAll(false);
    }, [data]);

    return (
      <>
        <div className="row">
          {/* Botón para exportar */}
          {!!data && !!data.length && (
            <>
              <div className="col-6 p-0" style={{ lineHeight: "40px" }}>
                <Input
                  type="checkbox"
                  style={{
                    marginLeft: "8px",
                    marginRight: "16px",
                    position: "unset"
                  }}
                  checked={checkAll}
                  onChange={e => {
                    const checked = e.target.checked;
                    setCheckAll(checked);
                    onCheckAll(checked);
                  }}
                  disabled={processed}
                />
                <span>
                  {!checkAll ? "Seleccionar" : "Descartar"} todos ({data.length}
                  )
                </span>
              </div>
              <div
                className="col-6 text-right p-0"
                style={{ lineHeight: "40px" }}
              >
                <span>Exportar a CSV: </span>
                <Button
                  id="btnReportExportCsv"
                  color="link"
                  type="button"
                  onClick={() => onExport()}
                >
                  <FontAwesomeIcon icon={faFileExport} color="black" />
                </Button>
              </div>
            </>
          )}
        </div>
        <div className="row p-0 mb-5">
          <ReactTable
            data={data || []}
            loading={loading}
            pageSize={data && data.length > 5 ? data.length : 5}
            columns={columns}
            sortable={false}
            resizable={true}
            showPagination={false}
            className={`w-100 -highlight -striped ${s.hidden}`}
          />
        </div>
      </>
    );
  }
);

const columnsFormatter = (onCheck, processed: boolean, selected) => [
  {
    Header: "",
    id: "checkbox",
    maxWidth: "48",
    className: s.tdClear,
    accessor: checkboxAccessor(onCheck, processed, selected)
  },
  {
    Header: "Id",
    id: "id",
    maxWidth: "96",
    className: s.tdClear,
    accessor: simpleAccessor("id", processed, selected)
  },
  {
    Header: "Club",
    id: "club",
    maxWidth: "160",
    className: s.tdClear,
    accessor: simpleAccessor("club", processed, selected)
  },
  {
    Header: "Empleado",
    id: "numeroEmpleado",
    maxWidth: "96",
    className: s.tdClear,
    accessor: simpleAccessor("numeroEmpleado", processed, selected)
  },
  {
    Header: "Nombre del empleado",
    id: "nombreEmpleado",
    maxWidth: "192",
    className: s.tdClear,
    accessor: simpleAccessor("nombreEmpleado", processed, selected)
  },
  {
    Header: "Monto",
    id: "montoComision",
    maxWidth: "96",
    className: s.tdClear,
    accessor: moneyAccessor("montoComision", processed, selected)
  },
  {
    Header: "Descripción",
    id: "descripcion",
    className: s.tdClear,
    accessor: simpleAccessor("descripcion", processed, selected),
    headerClassName: "text-left"
  }
];

const moneyFormatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN"
});

function fieldClassName(
  row: ComisionDeportiva,
  processed: boolean,
  selected: number[]
) {
  const enviada = processed && selected.includes(row.id);

  // Si la comisión fue enviada, se verifica si se procesó
  return enviada
    ? processed
      ? `${s.field} ${s.procesada}`
      : `${s.field} ${s.noProcesada}`
    : `${s.field}`;
}

function simpleAccessor(key: keyof ComisionDeportiva, processed, selected) {
  return (row: ComisionDeportiva) => {
    return (
      <div className={fieldClassName(row, processed, selected)}>
        {row[key]}&nbsp;
      </div>
    );
  };
}

function moneyAccessor(key, processed, selected) {
  return row => {
    return (
      <div className={fieldClassName(row, processed, selected) + " text-right"}>
        {moneyFormatter.format(+row[key] || 0)}&nbsp;
      </div>
    );
  };
}

function checkboxAccessor(
  onCheck: (id: number, check) => void,
  processed: boolean,
  selected: number[]
) {
  return row => (
    <div className={fieldClassName(row, processed, selected)}>
      &nbsp;
      <Input
        type="checkbox"
        style={{ left: "24px" }}
        checked={selected.includes(row.id)}
        onChange={e => onCheck(row.id, e.target.checked)}
        disabled={processed}
      />
    </div>
  );
}
