import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { Button, UncontrolledTooltip } from "reactstrap";
import s from "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEye,
  faFileExport,
  faRedo
} from "@fortawesome/free-solid-svg-icons";
import MaquilaArchivo from "../../../types/MaquilaArchivo";

export interface TableProps {
  data: MaquilaArchivo[];
  loading: boolean;
  onDownload: (row: MaquilaArchivo) => void;
  onDownloadRelacion: (row: MaquilaArchivo) => void;
  onRegenerar: (row: MaquilaArchivo) => void;
}

export const MemoTable = React.memo(
  ({
    data,
    loading,
    onDownload,
    onDownloadRelacion,
    onRegenerar
  }: TableProps) => {
    // State de las columnas de la tabla
    const [columns, setColumns] = useState(
      columnsFormatter(onDownload, onDownloadRelacion, onRegenerar, loading)
    );

    // Actualización de estilos
    useEffect(() => {
      setColumns(
        columnsFormatter(onDownload, onDownloadRelacion, onRegenerar, loading)
      );
    }, [data]);

    return (
      <>
        <div className="row mt-3">
          {/* Botón para exportar */}
          {!!data && !!data.length && (
            <>
              <div className="col-6 p-0" style={{ lineHeight: "40px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {data.length} archivos mostrados
                </span>
              </div>
              <div
                className="col-6 text-right p-0"
                style={{ lineHeight: "40px" }}
                hidden
              >
                <span>Exportar a CSV: </span>
                <Button
                  id="btnReportExportCsv"
                  color="link"
                  type="button"
                  onClick={() => ({})}
                >
                  <FontAwesomeIcon icon={faFileExport} color="black" />
                </Button>
              </div>
            </>
          )}
        </div>

        {/* Tabla */}
        <div className="row mt-2">
          <div style={{ width: "100%" }}>
            <ReactTable
              data={data || []}
              loading={loading}
              pageSize={data && data.length > 5 ? data.length : 5}
              columns={columns}
              sortable={false}
              resizable={true}
              showPagination={false}
              className={`w-100 -highlight -striped ${s.hidden}`}
            />
          </div>
        </div>
      </>
    );
  }
);

// TODO: Definir permisos para los botones
function columnsFormatter(
  onDownload: (row: MaquilaArchivo) => void,
  onDownloadRelacion: (row: MaquilaArchivo) => void,
  onRegenerar: (row: MaquilaArchivo) => void,
  loading: boolean
) {
  return [
    {
      Header: "Id",
      id: "id",
      width: 96,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("id", loading)
    },
    {
      Header: "Fecha de generación",
      id: "fechaGeneracion",
      width: 154,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: dateAccessor("fechaGeneracion", loading)
    },
    {
      Header: "Inicio de periodo",
      id: "fechaPeriodoInicio",
      width: 154,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: dateAccessor("fechaPeriodoInicio", loading)
    },
    {
      Header: "Fin de periodo",
      id: "fechaPeriodoFin",
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: dateAccessor("fechaPeriodoFin", loading)
    },
    {
      Header: "",
      id: "acciones",
      className: s.tdClear,
      width: 98,
      accessor: (row: MaquilaArchivo) => {
        return (
          <div className={`${fieldClassName(row, loading)} text-left`}>
            {renderBoton(
              row,
              `btn-download-relacion-${row.id}`,
              true,
              onDownloadRelacion,
              "Ver relación de comisiones",
              "#06c",
              faEye
            )}
            {renderBoton(
              row,
              `btn-download-${row.id}`,
              true,
              onDownload,
              "Descargar",
              "#06c",
              faDownload
            )}
            {renderBoton(
              row,
              `btn-regenerar-${row.id}`,
              true,
              onRegenerar,
              "Regenerar",
              "#06c",
              faRedo
            )}
          </div>
        );
      }
    }
  ];
}

function simpleAccessor(key: keyof MaquilaArchivo, loading) {
  return (row: MaquilaArchivo) => {
    return <div className={fieldClassName(row, loading)}>{row[key]}&nbsp;</div>;
  };
}

function dateAccessor(key: keyof MaquilaArchivo, loading) {
  return (row: MaquilaArchivo) => {
    return (
      <div className={fieldClassName(row, loading)}>
        {formatDate(row[key] as string)}&nbsp;
      </div>
    );
  };
}

function formatDate(isoDate: string): string {
  try {
    const [year, month, day] = isoDate.split("-");

    // Formato de la fecha:
    const fmtDate = `${day}/${month}/${year} `;
    return fmtDate;
  } catch (error) {
    return isoDate;
  }
}

function fieldClassName(row: MaquilaArchivo, loading: boolean) {
  return `${s.field}`;
}

function renderBoton(
  row: MaquilaArchivo,
  id,
  habilitado,
  action,
  tooltip,
  color,
  icon
) {
  if (habilitado) {
    return (
      <span id={id} onClick={() => action(row)} className={s.btn}>
        <FontAwesomeIcon icon={icon} color={color} />
        <UncontrolledTooltip placement="left" target={id}>
          {tooltip}
        </UncontrolledTooltip>
      </span>
    );
  } else {
    return (
      <span className={s.info} id={id}>
        <FontAwesomeIcon icon={icon} color={color} />
        <UncontrolledTooltip placement="left" target={id}>
          {tooltip}
        </UncontrolledTooltip>
      </span>
    );
  }
}
