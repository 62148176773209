import React, { Component } from "react";
import { Container } from "reactstrap";
import {
  errorNotification,
  infoNotification
} from "../../../utils/notifications";
import AgreementsReportStore from "../../containers/agreementsReport/AgreementsReport.store";
import AgreementsReportDispatcher from "../../containers/agreementsReport/AgreementsReport.dispatcher";
import { Router } from "../../../toolkit/decorators/Artifact";
import { formConfig, Inputs } from "./AgreementsReport.form";
import { deleteEmptyProps } from "../../../toolkit/utils/filter-utils";
import FormBuilder from "../../../toolkit/baseForms2/formBuilder/FormBuilder";
import { MemoTable } from "./AgreementsReport.table";
import { parseEmpresaConvenio } from "../../../toolkit/baseForms2Commons/iEmpresaConvenioSelector/IEmpresaConvenioSelectorConnected";
import { downloadConvenioMembresiasAsociadas } from "../../actions/convenios";

interface Props extends AgreementsReportStore, AgreementsReportDispatcher {}
interface State {
  downloading: boolean;
  filters: Inputs & {
    page: number;
    size: number;
  };
}

@Router()
export default class AgreementsReport extends Component<Props, State> {
  state = {
    downloading: false,
    filters: {
      page: 0,
      size: 20
    } as any
  };

  componentDidMount(): void {
    this.fetchData({} as any);
  }

  fetchData = async (
    { pageSize: tablePageSize, page: tablePage },
    _?: any,
    formFilters?
  ) => {
    const filters = formFilters || this.state.filters;
    const page = tablePage >= 0 ? tablePage : this.state.filters.page;
    const size = tablePageSize >= 0 ? tablePageSize : this.state.filters.size;
    const pageable = deleteEmptyProps({
      ...filters,
      page,
      size
    });

    this.setState({ filters: pageable });

    const preview = await this.props.getConvenioMembresiasAsociadas(pageable);
    if (!preview.fulfilled) {
      errorNotification(
        preview.membresiasAsociadasError || "Error al obtener las membresías"
      );
    }
  };

  submit = (values: any) => {
    const formValue = {
      ...values
    };

    if (values.empresa) {
      delete formValue.empresa;
      formValue.idEmpresa = parseEmpresaConvenio(values.empresa).idEmpresa;
    }

    // Búsqueda de datos en página 1
    this.fetchData({ page: 0 } as any, null, formValue);
  };

  onExport = async () => {
    this.setState({ downloading: true });
    infoNotification("Espere un momento por favor...");

    downloadConvenioMembresiasAsociadas(this.state.filters, () => {
      this.setState({ downloading: false });
    });
  };

  render() {
    return (
      <Container className="p-0 mt-3">
        <FormBuilder
          config={formConfig}
          submit={this.submit}
          loading={
            this.props.membresiasAsociadasLoading || this.state.downloading
          }
        />

        <hr className="row" />

        <MemoTable
          page={this.props.membresiasAsociadas}
          loading={
            this.props.membresiasAsociadasLoading || this.state.downloading
          }
          onFetchData={this.fetchData}
          onExport={this.onExport}
        />
      </Container>
    );
  }
}
