import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import ITypeSelectBuilder from "../../../toolkit/baseForms2/iTypeSelectBuilder/ITypeSelectBuilder";

export type Inputs = "idPuesto" | "monto";
export type Buttons = "aceptar" | "cancelar";

export const formConfigPuesto: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "idPuesto",
      label: "* Puesto",
      bts: {
        mCol: 12
      },
      component: ITypeSelectBuilder
    },
    {
      name: "monto",
      label: "* Monto",
      bts: {
        mCol: 12
      },
      component: ITextBuilder
    }
  ],
  buttons: [
    {
      name: "aceptar",
      label: "Aceptar",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        mOff: 4,
        mCol: 4
      }
    },
    {
      name: "cancelar",
      label: "Cancelar",
      component: IButtonBuilder,
      bts: {
        mCol: 4
      }
    }
  ],
  validations: {
    idPuesto: {
      required: true
    },
    monto: {
      required: true
    }
  },
  rows: [["idPuesto"], ["monto"], ["aceptar", "cancelar"]]
};
