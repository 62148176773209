import user from "./user";
import modals from "./modals";
import agreements from "./agreements";
import agreementGroups from "./agreementGroups";
import clubs from "./clubs";
import products from "./products";
import sellers from "./sellers";
import catalogs from "./catalogs";
import partners from "./partners";
import prospects from "./prospects";
import promotions from "./promotions";
import role from "./roles";
import auth from "./auth";
import surveys from "./surveys";
import persons from "./persons";
import documents from "./documents";
import sportsProducts from "./sportsProducts";
import banks from "./banks";
import annuities from "./annuities";
import goals from "./goals";
import benefitsSchemas from "./comissionsSchemas";
import periods from "./periods";
import reports from "./reports";
import inboxCalendar from "./inboxCalendar";
import sellsLte from "./sellsLte";
import billing from "./billing";
import discounts from "./discountCodes";
import demands from "./demands";
import lockers from "./lockers";
import sportsPrograms from "./sportsPrograms";
import specialGuests from "./specialGuests";
import terminal from "./terminal";
import dealers from "./dealers";
import convenios from "./convenios";
import companies from "./companies";
import empresasConvenio from "./empresaConvenio";
import personalDocumentsHistory from "./personalDocumentsHistory";
import binnacles from "./binnacles";
import expedientes from "./expedientes";
import comisionesDeportivaCrm1 from "./comisionesDeportivaCrm1";
import membresiasCrm1 from "./membresiasCrm1";
import notasCredito from "./notaCredito";
import invitados from "./invitados";
import comisionesV2 from "./comisionesV2";
import comisionesMaquilaV2 from "./comisionesMaquilaV2";

const homeReducers = {
  user,
  modals,
  agreements,
  agreementGroups,
  clubs,
  sellers,
  products,
  catalogs,
  prospects,
  promotions,
  role,
  auth,
  partners,
  surveys,
  persons,
  documents,
  sportsProducts,
  banks,
  annuities,
  goals,
  benefitsSchemas,
  periods,
  reports,
  inboxCalendar,
  sellsLte,
  billing,
  discounts,
  demands,
  lockers,
  sportsPrograms,
  specialGuests,
  terminal,
  dealers,
  convenios,
  companies,
  empresasConvenio,
  personalDocumentsHistory,
  binnacles,
  expedientes,
  comisionesDeportivaCrm1,
  membresiasCrm1,
  notasCredito,
  invitados,
  comisionesV2,
  comisionesMaquilaV2
};

export default homeReducers;
