import { GetConvenioMembresiasAsociadas } from "../../reducers/convenios";

export default interface AgreementsReportStore
  extends GetConvenioMembresiasAsociadas {}

export const agreementsReportStore: (
  state
) => AgreementsReportStore = state => {
  const { convenios } = state;
  return {
    membresiasAsociadas: convenios.membresiasAsociadas,
    membresiasAsociadasLoading: convenios.membresiasAsociadasLoading,
    membresiasAsociadasError: convenios.membresiasAsociadasError
  };
};
