import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import AgreementsReport from "../../components/agreementsReport";
import AgreementsReportStore, {
  agreementsReportStore
} from "./AgreementsReport.store";
import AgreementsReportDispatcher, {
  agreementsReportDispatcher
} from "./AgreementsReport.dispatcher";
import { ReduxConnect } from "../../../toolkit/decorators/Artifact";

interface Props extends AgreementsReportStore, AgreementsReportDispatcher {}
interface State {}

@ReduxConnect(agreementsReportStore, agreementsReportDispatcher)
export default class AgreementsReportPage extends Component<Props, State> {
  render() {
    return (
      <main>
        <Helmet title="Reporte de convenios" />
        <Layout>
          <AgreementsReport {...this.props} />
        </Layout>
      </main>
    );
  }
}
