import {
  CREATE_PROSPECT_ERROR,
  CREATE_PARTNER_ERROR,
  ERROR_SALE,
  errorNotification
} from "./notifications";
import moment from "moment";

export function getErrorMessage(payload: any, defaultError?: string): any {
  if (payload.response && payload.response.data) {
    return {
      message:
        payload.response.data.message ||
        defaultError ||
        "There was an error, please try again",
      body: payload.response.data.body || null,
      error_description:
        payload.response.data.error_description ||
        payload.response.data.message ||
        defaultError ||
        "There was an error, please try again"
    };
  }

  return {
    message: defaultError || "There was an error, please try again",
    error_description: defaultError || "There was an error, please try again"
  };
}

export function getErrorData(payload: any, defaultError?: string): any {
  return getErrorMessage(payload, defaultError);
}

export function getErrorMessageProspectCreation(payload: any): any {
  const { response } = payload;
  let error = CREATE_PROSPECT_ERROR;
  if (response) {
    const { status } = response;
    if (status === 400) {
      const { data } = response;
      if (data) {
        const { alreadyExistingPersonas } = data;
        if (alreadyExistingPersonas) {
          error = CREATE_PARTNER_ERROR;
        }
      }
    }
  }
  return error;
}

export function getErrorMessageSale(response) {
  let error = ERROR_SALE;

  if (response.data.message) {
    error = response.data.message;
  }

  return error;
}

export function getErrorMessagePartnerCreation(payload: any): string[] {
  const { response } = payload;
  let errores = [CREATE_PROSPECT_ERROR];
  if (response) {
    const { status } = response;
    if (status === 400) {
      const { data } = response;
      if (data) {
        const {
          alreadyExistingPersonas,
          errores: partnerErrors,
          message
        } = data;
        if (alreadyExistingPersonas) {
          errores = [CREATE_PARTNER_ERROR];
        } else if (partnerErrors) {
          errores = partnerErrors;
        } else if (message) {
          errores = [message];
        }
      }
    } else if (status === 412) {
      const { data } = response;
      if (data) {
        const { message } = data;
        if (message) {
          errores = [message];
        }
      }
    }
  }
  return errores;
}

// Check if a string is empty, null, undefined or contains only white-spaces
export function isEmptyString(string) {
  return !string || string.length === 0 || !string.trim();
}

export function getValueOrDefault(value, _default) {
  return value != null && typeof value != "undefined" ? value : _default;
}

/**
 * Function to validate mail
 */
export const ValidateEmail = mail => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
};

export const validDateGoals = (month, year) => {
  const LastDay = 3;

  const periodBeginning = moment()
    .set("month", month - 1)
    .set("year", year)
    .startOf("month")
    .startOf("day");

  const periodEnd = moment(periodBeginning)
    .add(1, "month")
    .set("date", LastDay)
    .endOf("day");

  return (
    moment().isBefore(periodBeginning) ||
    moment().isBetween(periodBeginning, periodEnd)
  );
};

export function parseArrayToDropdownOptions(
  array: any[],
  valueField?: string,
  labelField?: string,
  defaultItemValue?: string,
  defaultItemLabel?: string
) {
  const defValueField = valueField || "id";
  const defLabelField = labelField || "nombre";

  return [
    {
      value: defaultItemValue || "",
      label: !!defaultItemLabel || "Seleccionar"
    }
  ].concat(
    (array || []).map((x: any) => {
      return { value: x[defValueField], label: x[defLabelField] };
    })
  );
}

export function getEnumOptions(enumObject) {
  const options = [];
  for (const key in enumObject) {
    if (enumObject.hasOwnProperty(key)) {
      const element = enumObject[key];
      options.push({
        value: element,
        label: element
      });
    }
  }
  return options;
}

export function handleRequestError(err, f?: Function) {
  errorNotification(
    getErrorMessage(err, "Ocurrió un error al consultar los datos.").message
  );
  if (!!f) {
    f();
  }
}

export function divideArrayInSubArrays(
  _array: any[],
  itemsPerSubarray: number
) {
  if (!itemsPerSubarray || !_array || !_array.length) {
    return [];
  }

  const subarrays = [];
  let tempArray = [];

  for (let i = 1; i <= _array.length; i++) {
    tempArray.push(_array[i - 1]);

    if (i % itemsPerSubarray == 0) {
      subarrays.push([...tempArray]);
      tempArray = [];
    }
  }

  if (!!tempArray.length) {
    subarrays.push([...tempArray]);
  }

  return subarrays;
}

export function clubCatalogAsOptions(
  clubCatalog: { clubId: string; nombre: string }[]
) {
  return [{ value: "", label: "Seleccionar club" }].concat(
    (clubCatalog || []).map(option => ({
      value: option.clubId,
      label: option.nombre
    }))
  );
}

export function formatTime(dateTime?) {
  moment(dateTime)
    .utc()
    .format("YYYY-MM-DD");
}

export function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}
