import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { Button, UncontrolledTooltip, Input } from "reactstrap";
import s from "../../styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExchangeAlt,
  faFileExport,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import permissionCodes from "../../../../../utils/permissionCodes";
import { userHasPermission } from "../../../../../utils/accessTree";
import DeportivaComision from "../../../../../types/DeportivaComision";

const {
  COMISIONES_V2_AUTORIZAR: AUTORIZAR,
  COMISIONES_V2_RECHAZAR: RECHAZAR,
  COMISIONES_V2_REASIGNAR: REASIGNAR
} = permissionCodes;

export interface TableProps {
  data: DeportivaComision[];
  loading: boolean;
  onExport: () => void;
  onRechazar: (row: DeportivaComision) => void;
  onReasignar: (row: DeportivaComision) => void;
  onViewLog: (row: DeportivaComision) => void;
  onCheck: (id: number, check: boolean) => void;
  onCheckAll: (check: boolean) => void;
  selected: number[];
}

export const MemoTable = React.memo(
  ({
    data,
    loading,
    onExport,
    onRechazar,
    onReasignar,
    onViewLog,
    onCheck,
    onCheckAll,
    selected
  }: TableProps) => {
    const [checkAll, setCheckAll] = useState(false);

    // State de las columnas de la tabla
    const [columns, setColumns] = useState(
      columnsFormatter(
        onRechazar,
        onReasignar,
        onViewLog,
        onCheck,
        loading,
        selected
      )
    );

    // Actualización de estilos
    useEffect(() => {
      setColumns(
        columnsFormatter(
          onRechazar,
          onReasignar,
          onViewLog,
          onCheck,
          loading,
          selected
        )
      );
    }, [data, selected]);

    // Limpieza de checkbox para seleccionar todo, en nuevas búsquedas
    useEffect(() => {
      checkAll && setCheckAll(false);
    }, [data]);

    return (
      <>
        <div className="row">
          {/* Botón para exportar */}
          {!!data && !!data.length && (
            <>
              <div className="col-6 p-0" style={{ lineHeight: "40px" }}>
                <Input
                  type="checkbox"
                  style={{
                    marginLeft: "8px",
                    marginRight: "16px",
                    position: "unset"
                  }}
                  checked={checkAll}
                  onChange={e => {
                    const checked = e.target.checked;
                    setCheckAll(checked);
                    onCheckAll(checked);
                  }}
                  disabled={loading || !userHasPermission(AUTORIZAR)}
                />
                <span style={{ fontWeight: "bold" }}>
                  {!checkAll ? "Seleccionar" : "Descartar"} todos ({data.length}{" "}
                  registros mostrados)
                </span>
              </div>
              <div
                className="col-6 text-right p-0"
                style={{ lineHeight: "40px" }}
              >
                <span>Exportar a CSV: </span>
                <Button
                  id="btnReportExportCsv"
                  color="link"
                  type="button"
                  onClick={() => onExport()}
                >
                  <FontAwesomeIcon icon={faFileExport} color="black" />
                </Button>
              </div>
            </>
          )}
        </div>

        {/* Tabla */}
        <div className="row mt-2">
          <div style={{ width: "100%" }}>
            <ReactTable
              data={data || []}
              loading={loading}
              pageSize={data && data.length > 5 ? data.length : 5}
              columns={columns}
              sortable={false}
              resizable={true}
              showPagination={false}
              className={`w-100 -highlight -striped ${s.hidden}`}
            />
          </div>
        </div>
      </>
    );
  }
);

// TODO: Definir permisos para los botones
function columnsFormatter(
  onRechazar: (row: DeportivaComision) => void,
  onReasignar: (row: DeportivaComision) => void,
  onViewLog: (row: DeportivaComision) => void,
  onCheck: (id: number, check: boolean) => void,
  loading: boolean,
  selected: number[]
) {
  return [
    {
      Header: "",
      id: "checkbox",
      width: 24,
      className: s.tdClear,
      accessor: checkboxAccessor(onCheck, loading, selected)
    },
    {
      Header: "Id",
      id: "id",
      width: 64,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: linkAccessor("id", loading, selected, onViewLog)
    },
    {
      Header: "Empleado",
      id: "empleado",
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("empleado", loading, selected)
    },
    {
      Header: "Factura",
      id: "folioFactura",
      width: 88,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("folioFactura", loading, selected)
    },
    {
      Header: "Tipo",
      id: "tipoComision",
      width: 88,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: enumAccessor("tipoComision", loading, selected)
    },
    {
      Header: "Precio",
      id: "precioVenta",
      width: 88,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: moneyAccessor("precioVenta", loading, selected)
    },
    {
      Header: "Comisión",
      id: "montoComision",
      width: 88,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: moneyAccessor("montoComision", loading, selected)
    },
    {
      Header: "Producto",
      id: "producto",
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("producto", loading, selected)
    },
    {
      Header: "Estatus",
      id: "estatus",
      width: 88,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: enumAccessor("estatus", loading, selected)
    },
    {
      Header: "Registro",
      id: "fechaRegistro",
      width: 88,
      className: s.tdClear,
      accessor: simpleAccessor("fechaRegistro", loading, selected)
    },
    {
      Header: "Autorización",
      id: "fechaAutorizacion",
      width: 88,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("fechaAutorizacion", loading, selected)
    },
    {
      Header: "",
      id: "acciones",
      className: s.tdClear,
      width: 72,
      accessor: (row: DeportivaComision) => {
        return (
          <div
            className={`${fieldClassName(row, loading, selected)} text-left`}
          >
            {renderBotonRechazar(row, onRechazar)}
            {renderBotonReasignar(row, onReasignar)}
          </div>
        );
      }
    }
  ];
}

function simpleAccessor(key: keyof DeportivaComision, loading, selected) {
  return (row: DeportivaComision) => {
    return (
      <div className={fieldClassName(row, loading, selected)}>
        {row[key]}&nbsp;
      </div>
    );
  };
}

function linkAccessor(
  key: keyof DeportivaComision,
  loading,
  selected,
  onViewLog
) {
  return (row: DeportivaComision) => {
    return (
      <div className={fieldClassName(row, loading, selected)}>
        <a
          href="#"
          onClick={evt => {
            evt.preventDefault();
            onViewLog(row);
          }}
        >
          {row[key]}&nbsp;
        </a>
      </div>
    );
  };
}

function moneyAccessor(accessor: string, loading, selected) {
  return row => {
    const cantidad = Number(row[accessor]);

    return (
      <div className={fieldClassName(row, loading, selected)}>
        {!Number.isNaN(cantidad) && row[accessor] != null
          ? moneyFormatter.format(cantidad)
          : "-"}
      </div>
    );
  };
}

function checkboxAccessor(
  onCheck: (id: number, check) => void,
  loading: boolean,
  selected: number[]
) {
  return row => {
    if (!userHasPermission(AUTORIZAR) || row.estatus !== "PENDIENTE") {
      return (
        <div className={fieldClassName(row, loading, selected)}>&nbsp;-</div>
      );
    }

    return (
      <div className={fieldClassName(row, loading, selected)}>
        &nbsp;
        <Input
          type="checkbox"
          style={{ left: "24px" }}
          checked={selected.includes(row.id)}
          onChange={e => onCheck(row.id, e.target.checked)}
          disabled={!!loading}
        />
      </div>
    );
  };
}

function enumAccessor(accessor: string, loading, selected) {
  return row => {
    const str = row[accessor] || "";
    const sentence = str.toLowerCase().split("_");

    for (var i = 0; i < sentence.length; i++) {
      if (sentence[i]) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1) + " ";
      }
    }

    return (
      <div className={fieldClassName(row, loading, selected)}>{sentence}</div>
    );
  };
}

function fieldClassName(
  row: DeportivaComision,
  loading: boolean,
  selected: number[]
) {
  const enviada = loading && selected.includes(row.id);

  // Si la comisión fue enviada, se verifica si se procesó
  return enviada
    ? row.estatus === "AUTORIZADA"
      ? `${s.field} ${s.procesada}`
      : `${s.field} ${s.noProcesada}`
    : `${s.field}`;
}

function renderBotonReasignar(row: DeportivaComision, action) {
  const reasignable =
    row.estatus === "PENDIENTE" ||
    row.estatus === "AUTORIZADA" ||
    row.estatus === "ERRONEA";

  if (!userHasPermission(REASIGNAR) || !reasignable) {
    return false;
  }

  const idBoton = "id-" + row.id + "-reasignar";
  const tooltip = "Reasignar la comisión";
  return renderBoton(
    row,
    idBoton,
    reasignable,
    action,
    tooltip,
    "#06c",
    faExchangeAlt
  );
}

function renderBotonRechazar(row: DeportivaComision, action) {
  if (!userHasPermission(RECHAZAR) || row.estatus !== "PENDIENTE") {
    return false;
  }

  const idBoton = "id-" + row.id + "-rechazar";
  const tooltip = "Rechazar la comisión";
  return renderBoton(
    row,
    idBoton,
    true,
    action,
    tooltip,
    "#600",
    faTimesCircle
  );
}

function renderBoton(
  row: DeportivaComision,
  id,
  habilitado,
  action,
  tooltip,
  color,
  icon
) {
  if (habilitado) {
    return (
      <span id={id} onClick={() => action(row)} className={s.btn}>
        <FontAwesomeIcon icon={icon} color={color} />
        <UncontrolledTooltip placement="left" target={id}>
          {tooltip}
        </UncontrolledTooltip>
      </span>
    );
  } else {
    return (
      <span className={s.info} id={id}>
        <FontAwesomeIcon icon={icon} color={color} />
        <UncontrolledTooltip placement="left" target={id}>
          {tooltip}
        </UncontrolledTooltip>
      </span>
    );
  }
}

const moneyFormatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN"
});
