exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "span._2LUC0{cursor:pointer}span._2LUC0,span.vNY0r{font-size:16px;margin:0 8px}._7MokC{background:#f9fad2}._1ToD0{color:#700;font-weight:700;background:#f3d8d8}.OhPSm{padding:0!important}._1hQWu{padding:7px 3px}._1N0Qd div{overflow-x:hidden!important}._1N0Qd div div div div{overflow:hidden!important}", ""]);

// exports
exports.locals = {
	"btn": "_2LUC0",
	"info": "vNY0r",
	"noComisionable": "_7MokC",
	"errorComision": "_1ToD0",
	"tdClear": "OhPSm",
	"field": "_1hQWu",
	"hidden": "_1N0Qd"
};