import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import reduce from "lodash.reduce";
import isNil from "lodash.isnil";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Form
} from "reactstrap";
import ModalFrame from "../../../toolkit/modalFrame";
import s from "./styles.scss";
import ProductsList from "../sportsProductsNewQuote/ProductsList";
import BaseInput from "../../../toolkit/baseInput";
import { itemToProduct } from "../sportsProductsNewQuote";
import {
  OPTIONS_FORMA_PAGO,
  OPTIONS_FORMA_PAGO_EXTENDED,
  TARJETA_CREDITO,
  TARJETA_DEBITO,
  ID_CONTADO,
  ID_TRES_MESES,
  ID_SEIS_MESES,
  ID_NUEVE_MESES,
  ID_DOCE_MESES,
  ID_EFECTIVO,
  ID_CHEQUE,
  CONEKTA,
  FORMA_DE_PAGO,
  TARJETA_AMEX,
  LINEA_PAGO,
  ORIGEN_PAGO_TDC,
  POR_DEFINIR_99
} from "../../../utils/constants";
import PaymentsTable from "./PaymentsTable";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import ReimpresionModal from "./ReimpresionModal";
import { allowedTo } from "../../../utils/accessTree";
import accessCodes from "../../../utils/accessCodes";
import moment from "moment";
import {
  getMitTerminalCredentialsByUserIdRequest,
  saveMitTerminalCredentialsByUserIdRequest
} from "../../actions/users";
import { handleRequestError } from "../../../utils/helpers";
import {
  QUOTE,
  State,
  Props,
  mesesSinInteresesDefault,
  mesesSinInteresesMonths,
  AMEX_BANK_NAME,
  OrigenPlataformaVentaPago
} from "./interfaces";
import SelectFilter from "../common/SelectFilter";
import debounce from "lodash.debounce";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";
import MitTpvFrame from "./mitTpvFrame";
import { getMitTerminalCredentialsByClubIdRequest } from "../../actions/clubs";
import TextWithLineBreaks from "../common/TextWithLineBreaks";

const { PERMISSION_99 } = accessCodes;

const QUOTE_CREATED_STATUS = "CREADA";

const FORMA_PAGO_TOKENIZACION = [
  TARJETA_CREDITO,
  TARJETA_DEBITO,
  TARJETA_AMEX,
  LINEA_PAGO
];

const TOKENIZATION_ACTIVATION_PRODUCT_ID = 10005069;

const valueToField = (value): string => {
  return (
    (value === ID_TRES_MESES && "estatusTresMeses") ||
    (value === ID_SEIS_MESES && "estatusSeisMeses") ||
    (value === ID_NUEVE_MESES && "estatusNueveMeses") ||
    (value === ID_DOCE_MESES && "estatusDoceMeses")
  );
};

const getSubTotal = (products): number => {
  return reduce(products, (accum, { total }) => accum + total, 0);
};

const emptyFields = () => ({
  referencia: "",
  numeroAutorizacion: "",
  formaDePago: TARJETA_CREDITO,
  origenPago: ORIGEN_PAGO_TDC,
  mesesSinIntereses: ID_CONTADO,
  bancoId: "",
  monto: "0",
  numeroCheque: "",
  numeroCuenta: "",
  empresaEmisoraId: 0,
  tokenizar: false
});

const getLinkCaja = cajaId => `/productos/caja/${cajaId}`;

const getTokenReference = (): string => moment().format("YYMMDDhhmmss");

//VERSION TPV - COBRO POR PUERTO USB

class SportsProductsQuoteById extends React.Component<Props, State> {
  state = {
    mesesSinIntereses: "",
    optionsMonthsWithoutInterest: [
      ...mesesSinInteresesDefault,
      ...mesesSinInteresesMonths
    ],
    products: [],
    fields: emptyFields(),
    pagos: [],
    quote: null,
    isPending: false,
    newCart: null,
    freezeObject: {},
    reimpresionModal: false,
    objetoReimpresion: {},
    permission99: [],
    getReference: true,
    referenciaOtorgadaPorTesoreria: false,
    pagoRealizadoPorApp: false,
    readCard: {},
    showModalNoSale: false,
    userMitTerminalCredentials: null,
    tmpMitUser: null,
    tmpMitPassword: null,
    showModalUpdateMitCredentials: false,
    showModalDatosFactValidacion: false,
    acceptedModalDatosFactValidacion: false,
    applyingMainPayment: false,
    applyingCardPayment: false
  };

  channel = new MessageChannel();
  port1 = null;

  getOptionsMonthsWithoutInterestForPaymentLine() {
    const optionsMonthsWithoutInterest = [
      ...mesesSinInteresesDefault,
      ...mesesSinInteresesMonths
    ];
    optionsMonthsWithoutInterest.forEach(x => (x.active = true));
    return optionsMonthsWithoutInterest;
  }

  updateProducts = async (quote: QUOTE) => {
    const { productos, total, pagos: pagosQuote } = quote;
    const products = productos.map(itemToProduct);
    let pagos = [];
    if (pagosQuote != undefined) {
      pagos = pagosQuote.filter(pagos => pagos.estatus != "RECHAZADO");
    }
    const aPagar = (
      total - reduce(pagos, (acc, { monto }) => acc + monto, 0)
    ).toFixed(2);
    await this.setState({
      products,
      pagos: pagosQuote != undefined ? pagosQuote : [],
      quote,
      fields: {
        ...emptyFields(),
        monto: aPagar < 0 ? 0 : aPagar
      }
    });
  };

  async componentDidUpdate({ cashboxById: prevCashboxById }) {
    const { cashboxById, terminal } = this.props;
    if (
      cashboxById &&
      cashboxById.productos.length != 0 &&
      this.state.getReference &&
      terminal.ReferenceSantander.length == 0 &&
      !!cashboxById.sucursalClubId
    ) {
      this.props.getRefenceSantanderCargo(
        cashboxById.sucursalClubId.toString(),
        cashboxById.productos[0].itemId.toString(),
        "TDC"
      );
      this.props.getReferencesNotUsed(cashboxById.sucursalClubId.toString());
      this.setState({ getReference: false });
    }
    if (cashboxById && prevCashboxById !== cashboxById) {
      await this.updateProducts(cashboxById);

      /* //Si la caja debe forzar el pago por LINEA DE PAGO
      if (!!cashboxById && !!cashboxById.forzarLineaPago) {
        if (userHasPermission(permissionCodes.PAYMENT_LINEAS_DE_PAGO)) {
          const { fields } = this.state;
          fields.formaDePago = LINEA_PAGO;
          this.setState({
            mesesSinIntereses: "",
            optionsMonthsWithoutInterest: this.getOptionsMonthsWithoutInterestForPaymentLine(),
            permission99: [LINEA_PAGO],
            fields: { ...fields }
          });
        } else {
          this.setState({ permission99: [] });
        }
      } */
    }

    //Si el usuario no inicio sesión en algún club,
    if (
      !!cashboxById &&
      !cashboxById.sucursalClubId &&
      !this.state.showModalNoSale
    ) {
      this.setState({ showModalNoSale: true });
    }

    //Si hay errores de validacion de datos de facturacion
    if (
      !!cashboxById &&
      !!cashboxById.erroresDatosFacturacion &&
      !this.state.showModalDatosFactValidacion &&
      !this.state.acceptedModalDatosFactValidacion
    ) {
      this.setState({ showModalDatosFactValidacion: true });
    }
  }

  componentDidMount() {
    const { productsList } = this.props;

    let formasDePago = [...OPTIONS_FORMA_PAGO];

    //console.info("1.-", formasDePago);

    if (!userHasPermission(permissionCodes.CASHBOX_PAYMENT_FORM_99)) {
      formasDePago = formasDePago.filter(value => value != "POR_DEFINIR_99");
    }

    //console.info("2.-", formasDePago);

    if (!userHasPermission(permissionCodes.CASHBOX_PAYMENT_FORM_EFECTIVO)) {
      formasDePago = formasDePago.filter(value => value != "EFECTIVO");
    }

    //console.info("3.-", formasDePago);

    this.setState({ permission99: [...formasDePago] });

    this.setState({
      fields: {
        ...this.state.fields,
        monto: this.props.productsList.reduce((acc, e) => e.total + acc, 0)
      },
      newCart: !this.props.cartCreated
        ? {
            total: productsList.reduce((acc, e) => e.total + acc, 0),
            montoIva: productsList.reduce((acc, e) => e.montoIva + acc, 0),
            subTotal: productsList.reduce((acc, e) => e.subTotal + acc, 0)
          }
        : null,
      products: productsList
      // dataTerminal: objSave
    });

    //Se inicializa el puerto para comunicación con mit
    this.port1 = this.channel.port1;

    //Se obtienen las credenciales de terminal mit
    this.getUserTerminalCredentials();
  }

  componentWillUnmount() {
    this.setState({
      dataTerminal: {},
      referenciaOtorgadaPorTesoreria: false,
      pagoRealizadoPorApp: false,
      readCard: {}
    });
    this.props.clearLogueoRedux();
  }

  getUserTerminalCredentials = () => {
    if (!!this.props.userContext) {
      const { userId, clubSessionId } = this.props.userContext;

      getMitTerminalCredentialsByClubIdRequest(clubSessionId)
        .then(async ({ data }) => {
          console.info(data);
          if (!!data) {
            const { mitUser, mitPassword } = data;
            await this.setState({
              userMitTerminalCredentials: { ...data },
              tmpMitUser: mitUser,
              tmpMitPassword: mitPassword
            });

            console.info(this.props.terminal);
            if (!this.props.terminal || !this.props.terminal.Ambiente) {
              console.info("Timeout");
              setTimeout(() => {
                this.loginInTerminal(mitUser, mitPassword);
              }, 4000);
            } else {
              this.loginInTerminal(mitUser, mitPassword);
            }
          }
        })
        .catch(handleRequestError);
    }
  };

  onChangeBank = e => {
    e.preventDefault();
    const bancoId = e.target.value;
    const bank = this.props.banksMonthsWithoutInterest.find(
      banco => banco.bancoId == bancoId
    );
    const optionsMonthsWithoutInterest = bank
      ? this.getMonthWithoutInterestOptions(bank)
      : [...mesesSinInteresesDefault, ...mesesSinInteresesMonths];
    this.setState({
      ...this.state,
      mesesSinIntereses: "",
      optionsMonthsWithoutInterest,
      fields: {
        ...this.state.fields,
        bancoId
      }
    });
  };

  setFreezeObject = data => {
    this.setState({ freezeObject: data });
  };

  tieneCargosMembresia = () => {
    return (
      (this.state.products || []).filter(x => x.tipoItem == "MEMBRESIA")
        .length > 0
    );
  };

  shouldForceTokenization = () => {
    const { cashboxById } = this.props;
    if (!!cashboxById) {
      const partnerMembershipStatusForceTokenization = [
        "PENDIENTE",
        "PENDIENTE_REACTIVACION"
      ];

      const tieneCargosMembresia = this.tieneCargosMembresia();
      const tieneEstatusTokenizacionForzosa = partnerMembershipStatusForceTokenization.includes(
        cashboxById.membresiaSocioEstatus || ""
      );

      /*  console.info("Estatus:", cashboxById.membresiaSocioEstatus);
      console.info("Tiene cargos membresia:", tieneCargosMembresia);
      console.info(
        "Tiene estatus tokenizacion forzosa:",
        tieneEstatusTokenizacionForzosa
      );
      console.info(
        "Debe tokenizar:",
        tieneEstatusTokenizacionForzosa && tieneCargosMembresia
      );
 */
      return tieneEstatusTokenizacionForzosa && tieneCargosMembresia;
    }

    return false;
  };

  getMonthWithoutInterestOptions = bank => {
    const total = this.state.newCart
      ? this.state.newCart.total
      : this.props.cashboxById.productos
          .filter(x => x.tipoItem != "MANTENIMIENTO")
          .reduce((acum, { total }) => acum + total, 0.0);

    let options = [...mesesSinInteresesDefault];
    options.push({
      id: 3,
      nombre: "3 Meses sin intereses",
      active: bank.tresMeses <= total && !!bank.estatusTresMeses
    });
    options.push({
      id: 6,
      nombre: "6 Meses sin intereses",
      active: bank.seisMeses <= total && !!bank.estatusSeisMeses
    });
    options.push({
      id: 9,
      nombre: "9 Meses sin intereses",
      active: bank.nueveMeses <= total && !!bank.estatusNueveMeses
    });
    options.push({
      id: 12,
      nombre: "12 Meses sin intereses",
      active: bank.doceMeses <= total && !!bank.estatusDoceMeses
    });
    return options;
  };

  guardarCargo = () => {
    const { products } = this.state;
    let shouldTokenize = this.state.fields.tokenizar;

    //Si no se marco el check de tokenizar, se busca si la caja tiene un item del tipo "Activacion Tokenizacion" y si lo tiene, se marca para tokenizar

    /* if (!shouldTokenize && this.shouldForceTokenization()) {
      shouldTokenize = true;
    }*/

    if (!shouldTokenize) {
      shouldTokenize = products
        .map(({ sportsProductId }) => sportsProductId)
        .includes(TOKENIZATION_ACTIVATION_PRODUCT_ID);
    }

    const data = {
      ...this.state.fields,
      numeroAutorizacion: this.state.objetoReimpresion.numeroAutorizacion,
      referencia: this.props.terminal.ReferenceSantander,
      itemsIds: products.map(({ itemId }) => itemId),
      tokenizar: shouldTokenize,
      readCard: this.state.readCard || {},
      origenPlataforma: OrigenPlataformaVentaPago.TPV_ALAMBRICA
    };

    if (!data.numeroAutorizacion) {
      return;
    }

    this.setState({ objetoReimpresion: {}, getReference: true });
    this.props
      .payQuote(this.props.personaId, data)
      .then(({ value: { data: { carrito } } }) => {
        this.updateProducts(carrito);
      });
    this.props.clearReference();
  };

  debouncedGuardarCargo = debounce(this.guardarCargo, 3000);

  guardarStateDate = (objPrint, openModal) => {
    const { readCard } = this.state;
    readCard.token = objPrint.token;
    this.setState({
      objetoReimpresion: objPrint,
      reimpresionModal: openModal,
      readCard
    });
  };

  guardarDataCard = data => {
    this.setState({ readCard: data });
  };

  handleSubmit = () => {
    if (!this.props.cartCreated) {
      this.setState({ isPending: true });
      this.props
        .saveSPQuote(this.props.newCart)
        .then(async ({ value: { data: { cajaId, error, message } } }) => {
          if (error) {
            errorNotification(message);
          } else {
            if (this.props.productsList.length > 0) {
              await this.props.getCashboxById(cajaId);
              const data = {
                ...this.state.fields,
                monto:
                  this.props.amountPending !== -1
                    ? this.props.amountPending
                    : this.state.fields.monto,
                itemsIds: this.props.cashboxById.productos.map(
                  ({ itemId }) => itemId
                ),
                lineaPago: false
              };
              if (data.formaDePago == LINEA_PAGO) {
                data.formaDePago = ID_EFECTIVO;
                data.lineaPago = true;
              }
              this.props
                .payQuote(cajaId, data)
                .then(({ value: { data: { carrito } } }) => {
                  this.updateProducts(carrito);
                  this.props.history.push(getLinkCaja(cajaId));
                });
            }
          }
        });
    } else if (this.state.referenciaOtorgadaPorTesoreria) {
      const { products } = this.state;
      const data = {
        ...this.state.fields,
        itemsIds: products.map(({ itemId }) => itemId),
        lineaPago: false,
        origenPlataforma: OrigenPlataformaVentaPago.MANUAL_APP
      };
      if (data.formaDePago == LINEA_PAGO) {
        data.formaDePago = ID_EFECTIVO;
        data.lineaPago = true;
      }

      /* //Eliminar cuabdo se libere a prod nueva forma de venta
      //if (this.shouldForceTokenization()) {
        data.tokenizar = true;
        data.readCard = {
          maskPan: "493173******4436",
          name: "MURILLO DIAZ/ESTEFANIA",
          month: "02",
          year: 27,
          token: {
            tkn: data.referencia || "234872934565",
            tknReference: null,
            ccType: "CREDITO",
            ccMark: "VISA",
            ccBank: null
          }
        };
      //}*/
      this.props
        .payQuote(this.props.personaId, data)
        .then(({ value: { data: { carrito } } }) => {
          this.updateProducts(carrito);
          this.setState({
            applyingMainPayment: false,
            applyingCardPayment: false
          });
        });
      this.props.clearReference();
      this.setState({ getReference: true });
    } else if (this.state.pagoRealizadoPorApp) {
      const { products } = this.state;
      const data = {
        ...this.state.fields,
        itemsIds: products.map(({ itemId }) => itemId),
        lineaPago: false,
        origenPlataforma: OrigenPlataformaVentaPago.MANUAL_APP
      };

      if (
        !(
          this.state.fields.formaDePago == TARJETA_CREDITO ||
          this.state.fields.formaDePago == TARJETA_DEBITO ||
          this.state.fields.formaDePago == TARJETA_AMEX
        )
      ) {
        errorNotification(
          "Si el pago fue realizado por APP las forma de pago debe ser TDC, TDD o AMEX"
        );
        return;
      }

      /* //Eliminar cuabdo se libere a prod nueva forma de venta
      //if (this.shouldForceTokenization()) {
        data.tokenizar = true;
        data.readCard = {
          maskPan: "493173******4436",
          name: "MURILLO DIAZ/ESTEFANIA",
          month: "02",
          year: 27,
          token: {
            tkn: data.referencia || "234872934565",
            tknReference: null,
            ccType: "CREDITO",
            ccMark: "VISA",
            ccBank: null
          }
        };
      //} */

      this.props
        .payQuote(this.props.personaId, data)
        .then(({ value: { data: { carrito } } }) => {
          this.updateProducts(carrito);
          this.setState({
            applyingMainPayment: false,
            applyingCardPayment: false
          });
        });
      this.props.clearReference();
      this.setState({ getReference: true });
    } else {
      if (
        this.state.fields.formaDePago == TARJETA_CREDITO ||
        this.state.fields.formaDePago == TARJETA_DEBITO ||
        this.state.fields.formaDePago == TARJETA_AMEX ||
        this.state.fields.formaDePago == "CONEKTA"
      ) {
        //Se ejecuta el proceso de cobro con terminal
        this.mitPaymentProcessStart();
      } else if (
        !!this.props.terminal.ModeloTerminal &&
        this.state.fields.formaDePago == "EFECTIVO"
      ) {
        this.printCashVoucher();
      } else {
        if (this.state.fields.formaDePago == "EFECTIVO") {
          errorNotification("No se puede imprimir comprobante de pago");
        }
        const { products } = this.state;
        const data = {
          ...this.state.fields,
          referencia: this.props.terminal.ReferenceSantander,
          itemsIds: products.map(({ itemId }) => itemId),
          lineaPago: false
        };
        if (data.formaDePago == LINEA_PAGO) {
          data.formaDePago = ID_EFECTIVO;
          data.lineaPago = true;
        }
        this.props
          .payQuote(this.props.personaId, data)
          .then(({ value: { data: { carrito } } }) => {
            this.updateProducts(carrito);
            this.setState({
              applyingMainPayment: false,
              applyingCardPayment: false
            });
          });
        this.props.clearReference();
        this.setState({ getReference: true });
      }
    }
  };

  debouncedHandleSubmit = debounce(this.handleSubmit, 3000);

  ///Funciones para proceso de mit

  loginInTerminal = (mitUser, mitPassword) => {
    const { addLogueoRedux, clearLogueoRedux, terminal } = this.props;

    console.info("Terminal config:", this.props.terminal);

    if (!mitUser || !mitPassword) {
      return;
    }

    let objSave = {
      bs_branch: null,
      bs_company: null,
      bs_country: null,
      EMV: null,
      impresora: null,
      marca: null,
      modelo: null,
      serie: null,
      soportaCTLS: null,
      soportaFirma: null,
      versionApp: null,
      dataPpConfig: null,
      isLogguedInTerminal: false
    };

    const { Ambiente, isLogguedInTerminal } = terminal;
    const Usuario = mitUser;
    const Pass = mitPassword;

    //Si no se ha hecho sesión en la terminal,
    if (!isLogguedInTerminal) {
      //Se ejecuta inicio de sesión en la terminal
      infoNotification("Login con terminal...");

      //LOGIN

      let objDataLogin = {
        Ambiente: Ambiente,
        Usuario: Usuario,
        Pass: Pass,
        name: "login"
      };

      console.info("Tpv Login request: ", objDataLogin);

      var portL = this.port1;
      portL.postMessage(objDataLogin);
      portL.onmessage = event => {
        var response = event.data;
        var objLoginResponse = JSON.parse(response);

        console.info("Tpv Login response: ", objLoginResponse);

        if ((objLoginResponse.RESPUESTA || "") == "ok") {
          successNotification("Login de terminal Éxitoso...");

          objSave.bs_branch = objLoginResponse.bs_branch;
          objSave.bs_company = objLoginResponse.bs_company;
          objSave.bs_country = objLoginResponse.bs_country;

          //Get RSA KEYS

          infoNotification("Obteniendo llaves de terminal...");

          let objDataKeys = {
            Ambiente: Ambiente,
            Usuario: Usuario,
            Pass: Pass,
            Country: objLoginResponse.bs_country,
            IdBranch: objLoginResponse.bs_branch,
            IdCompany: objLoginResponse.bs_company,
            name: "getKeysRSA"
          };

          console.info("getKeysRSA request: ", objDataKeys);

          portL = this.port1;
          portL.postMessage(objDataKeys);
          portL.onmessage = event => {
            var response = event.data;
            var objKeysResponse = JSON.parse(response);

            console.info("getKeysRSA response: ", objKeysResponse);

            if (
              (objKeysResponse.RESPUESTA || "") == "ok" &&
              objKeysResponse.modelo.length > 1
            ) {
              successNotification(
                "LLaves de terminal obtenidas correctamente..."
              );

              objSave.EMV = objKeysResponse.EMV;
              objSave.impresora = objKeysResponse.impresora;
              objSave.marca = objKeysResponse.marca;
              objSave.modelo = objKeysResponse.modelo;
              objSave.serie = objKeysResponse.serie;
              objSave.soportaCTLS = objKeysResponse.soportaCTLS;
              objSave.soportaFirma = objKeysResponse.soportaFirma;
              objSave.versionApp = objKeysResponse.versionApp;
              objSave.dataPpConfig = objKeysResponse.dataPpConfig;
              objSave.isLogguedInTerminal = true;

              addLogueoRedux(objSave);

              //Init Values

              const objDataInitValues = {
                Ambiente,
                dataUserConfig: objLoginResponse.dataUserConfig,
                dataPpConfig: objKeysResponse.dataPpConfig,
                name: "initValues"
              };

              console.info("init values request: ", objDataInitValues);

              portL = this.port1;
              portL.postMessage(objDataInitValues);
              portL.onmessage = event => {
                const response = event.data;
                const objInitvaluesResponse = JSON.parse(response);

                console.info("init values response: ", objInitvaluesResponse);

                if ((objInitvaluesResponse.RESPUESTA || "") != "Success") {
                  clearLogueoRedux();
                  errorNotification(
                    objInitvaluesResponse.ERROR ||
                      "Error al inicializar los valores"
                  );
                }
              };
            } else {
              clearLogueoRedux();
              errorNotification(
                objKeysResponse.nb_error ||
                  "Las llaves de la terminal no pudieron obtenerse"
              );
            }
          };
        } else {
          clearLogueoRedux();
          errorNotification(
            objLoginResponse.nb_error || "No pudo realizarse el login"
          );
        }
      };
    }
  };

  validateTokenizationStatus = () => {
    const Ambiente = this.props.terminal.Ambiente;

    let objDataTokenizationStatus = {
      Ambiente: Ambiente,
      name: "getActivateToken"
    };

    //"2.- Lectura de los merchant"
    infoNotification("Validando estatus tokenización...");

    console.info("Validacion estatus tokenización - Request");
    console.info(objDataTokenizationStatus);

    var portL = this.port1;
    portL.postMessage(objDataTokenizationStatus);
    portL.onmessage = event => {
      const response = event.data;
      const objTokenizationStatusResponse = JSON.parse(response);

      console.info("Validacion estatus tokenización - Response");
      console.info(objTokenizationStatusResponse);
    };
  };

  reimpresionTicket = () => {
    const Ambiente = this.props.terminal.Ambiente;
    const VoucherComercio = this.state.objetoReimpresion.VoucherComercio;
    const VoucherCliente = this.state.objetoReimpresion.VoucherCliente;
    const ModeloTerminal = this.props.terminal.ModeloTerminal;

    let objPrint = {
      Ambiente: Ambiente,
      VoucherComercio: VoucherComercio,
      VoucherCliente: VoucherCliente,
      ModeloTerminal: ModeloTerminal,
      name: "printVoucher"
    };

    var portL = this.port1;
    portL.postMessage(objPrint);
    portL.onmessage = event => {
      const response = event.data;
      const objLogin = JSON.parse(response);
      if (objLogin.RESPUESTA && objLogin.RESPUESTA == "ok") {
        successNotification("Reimpresión exitosa");
      }
    };
  };

  printCashVoucher = () => {
    const guardarStateDate = this.guardarStateDate;
    const Ambiente = this.props.terminal.Ambiente;
    const ModeloTerminal = this.props.terminal.ModeloTerminal;
    const name_club_base = this.props.cashboxById.membresiaClub || "";
    const referencia = this.props.terminal.ReferenceSantander;
    const membresia = this.props.cashboxById.membresiaId || "";
    const name_cajero = this.props.cashboxById.sucursalCajero;
    const name_sucursal = this.props.cashboxById.sucursalClub;

    let monto = this.state.fields.monto;
    if (monto.indexOf(".") == -1) {
      monto = monto + ".00";
    } else if (
      monto.indexOf(".") > 0 &&
      monto.indexOf(".") == monto.length - 2
    ) {
      monto = monto + "0";
    }
    const dateTime = moment().format("DD/MM/YYYY hh:mm:ss");

    let objPrint: any = {
      Ambiente: Ambiente,
      VoucherComercio: `@cnb Sports World@cnn Comprobante de pagos en efectivo@cnn Operadora y Administradora@cnn SW SA de CV@br@cnb -C-O-M-E-R-C-I-O-@br@lnnClub base:    ${name_club_base.toUpperCase()}@lnnMembresia:    ${membresia}@lnnIDM:          ${referencia}@lnnImporte:      $${monto}@lnnFecha y hora: ${dateTime}@lnnCajero:       ${name_cajero.toUpperCase()}@lnnSucursal:     ${name_sucursal.toUpperCase()}@br@br@br@br@cnn ________________________________@cnnFirma del cliente@br@lnnEste comprobante no tiene efectos fiscales, favor de verificar que haya recibido su CFDI en su correo elecntronico. En caso de que el importe de este ticket no corresponda a su pago en efectivo, favor de comunicarse al numero 01 800 00 79 72 72 84@br@br`,
      VoucherCliente: `@cnb Sports World@cnn Comprobante de pagos en efectivo@cnn Operadora y Administradora@cnn SW SA de CV@br@cnb -C-L-I-E-N-T-E-@br@lnnClub base:    ${name_club_base.toUpperCase()}@lnnMembresia:    ${membresia}@lnnIDM:          ${referencia}@lnnImporte:      $${monto}@lnnFecha y hora: ${dateTime}@lnnCajero:       ${name_cajero.toUpperCase()}@lnnSucursal:     ${name_sucursal.toUpperCase()}@br@br@lnnEste comprobante no tiene efectos fiscales, favor de verificar que haya recibido su CFDI en su correo elecntronico. En caso de que el importe de este ticket no corresponda a su pago en efectivo, favor de comunicarse al numero 01 800 00 79 72 72 84@br@br`,
      ModeloTerminal: ModeloTerminal,
      name: "printVoucher"
    };

    var portL = this.port1;
    portL.postMessage(objPrint);
    portL.onmessage = event => {
      const response = event.data;
      const objLogin = JSON.parse(response);
      if (objLogin.RESPUESTA && objLogin.RESPUESTA == "ok") {
        successNotification("Reimpresión exitosa");
      }
    };

    objPrint.numeroAutorizacion = "";
    guardarStateDate(objPrint, true);
  };

  mitPaymentProcessStart = () => {
    this.mitPaymentProcessReadCard();
  };

  mitPaymentProcessReadCard = () => {
    const guardarDataCard = this.guardarDataCard;
    const mitPaymentProcessGetMerchants = this.mitPaymentProcessGetMerchants;
    const Ambiente = this.props.terminal.Ambiente;
    const ModeloTerminal = this.props.terminal.ModeloTerminal;
    const Currency = this.props.terminal.Currency;
    const CurrencyCode = this.props.terminal.CurrencyCode;
    const TimeOutPinPad = this.props.terminal.TimeOutPinPad;
    const MarcaTerminal = this.props.terminal.MarcaTerminal;
    const Amount = this.state.fields.monto;

    let objDataTRX = {
      Ambiente: Ambiente,
      Currency: Currency, //Currency
      CurrencyCode: CurrencyCode, //CurrencyCode
      Amount: Amount.toString(), //Amount
      TimeOutPinPad: TimeOutPinPad,
      MarcaTerminal: MarcaTerminal, //MarcaPinPad
      ModeloTerminal: ModeloTerminal, //ModeloTerminal
      name: "readCard"
    };

    console.info("Lectura tarjeta en terminal - Request");
    console.info(objDataTRX);

    //"1.- Lectura de la tarjeta"
    infoNotification("Leyendo tarjeta...");

    var portL = this.port1;
    portL.postMessage(objDataTRX);
    portL.onmessage = event => {
      const response = event.data;
      const objReadCardResponse = JSON.parse(response);

      console.info("Lectura tarjeta en terminal - Response");
      console.info(objReadCardResponse);

      if ((objReadCardResponse.RESPUESTA || "") == "ok") {
        successNotification("La tarjeta se leyó de manera correcta");
        guardarDataCard(objReadCardResponse);
        mitPaymentProcessGetMerchants(objReadCardResponse);
      } else {
        errorNotification(
          objReadCardResponse.nb_error ||
            objReadCardResponse.ERROR ||
            "No se puede leer la tarjeta"
        );
      }
    };
  };

  mitPaymentProcessGetMerchants = (readCardResponse: any) => {
    const mitPaymentProcessExecCharge = this.mitPaymentProcessExecCharge;
    const Ambiente = this.props.terminal.Ambiente;
    const Usuario = this.state.userMitTerminalCredentials.mitUser;
    const Currency = this.props.terminal.Currency;
    const TpOperation = this.props.terminal.TpOperation;

    let objDataMerchant = {
      Ambiente: Ambiente,
      BIN:
        (readCardResponse.maskPan || "").length > 6
          ? readCardResponse.maskPan.substring(0, 6)
          : null, //BIN
      User: Usuario, //User
      Currency: Currency, //Currency
      Tx_OperationType: TpOperation,
      name: "getMerchant"
    };

    console.info("Obtener promociones de la tarjeta - Request");
    console.info(objDataMerchant);

    //"2.- Lectura de los merchant"
    infoNotification("Obteniendo promociones...");

    var portL = this.port1;
    portL.postMessage(objDataMerchant);
    portL.onmessage = event => {
      const response = event.data;
      const objMerchantsResponse = JSON.parse(response);

      console.info("Obtener promociones de la tarjeta - Response");
      console.info(objMerchantsResponse);

      if ((objMerchantsResponse.nb_respuesta || "") == "OK") {
        successNotification("Promociones obtenidas correctamente...");
        mitPaymentProcessExecCharge(objMerchantsResponse);
      } else {
        errorNotification(
          objMerchantsResponse.nb_error ||
            "No se pueden obtener las promociones"
        );
      }
    };
  };

  mitPaymentProcessExecCharge = (merchantsResponse: any) => {
    const mitPaymentProcessSaveAndPrint = this.mitPaymentProcessSaveAndPrint;
    const mitPaymentProcessCancel = this.mitPaymentProcessCancel;
    const Ambiente = this.props.terminal.Ambiente;
    const Country = this.props.terminal.Country;
    const IdBranch = this.props.terminal.IdBranch;
    const IdCompany = this.props.terminal.IdCompany;
    const Pass = this.state.userMitTerminalCredentials.mitPassword;
    const Usuario = this.state.userMitTerminalCredentials.mitUser;
    const UserTRX = this.props.terminal.UserTRX;
    const EMV = this.props.terminal.EMV;
    const ModeloTerminal = this.props.terminal.ModeloTerminal;
    const SerieTerminal = this.props.terminal.SerieTerminal;
    const SoporteCTLS = this.props.terminal.SoporteCTLS;
    const Printer = this.props.terminal.Printer;
    const VersionTerminal = this.props.terminal.VersionTerminal;
    const TpOperation = this.props.terminal.TpOperation;
    const Currency = this.props.terminal.Currency;
    const Reverse = this.props.terminal.Reverse;
    const Amount = this.state.fields.monto;
    const ReferenceSantander = this.props.terminal.ReferenceSantander.toString();
    const GenerateToken = true;

    const pwdTkn = this.state.userMitTerminalCredentials.mitPassword;
    const UserTkn = this.state.userMitTerminalCredentials.mitUser;

    const IdCompanyTkn = this.props.terminal.IdCompanyTkn;
    const IdBranchTkn = this.props.terminal.IdBranchTkn;

    let Merchant = null;
    if (this.state.fields.mesesSinIntereses == 0) {
      Merchant = "PRODUCTOS MIT";
    } else if (this.state.fields.mesesSinIntereses == 3) {
      Merchant = "3m";
    } else if (this.state.fields.mesesSinIntereses == 6) {
      Merchant = "6m";
    } else if (this.state.fields.mesesSinIntereses == 9) {
      Merchant = "9m";
    } else if (this.state.fields.mesesSinIntereses == 12) {
      Merchant = "12m";
    }

    let objDataVenta = {
      Ambiente,
      Country,
      IdBranch,
      IdCompany,
      pwd: Pass,
      User: Usuario,
      UserTRX,
      EMV,
      ModeloTerminal,
      SerieTerminal,
      Printer,
      VersionTerminal,
      TpOperation,
      Reference: ReferenceSantander,
      Currency,
      GenerateToken,

      Amount: Amount.toString(),
      Merchant: null,
      name: "sndVentaDirectaToken"
    };

    /* 

     TknReference: getTokenReference(),
      UserTkn: Usuario,
      pwdTkn: Pass,
      IdCompanyTkn: IdCompany,
      IdBranchTkn: IdBranch,


        Reverse,
          TknReference: getTokenReference(),
          UserTkn,
          pwdTkn,
          IdCompanyTkn,
          IdBranchTkn, */

    if (Merchant == "PRODUCTOS MIT") {
      if (Array.isArray(merchantsResponse.contado.af)) {
        objDataVenta.Merchant = merchantsResponse.contado.af[0].merchant;
      } else {
        objDataVenta.Merchant = merchantsResponse.contado.af.merchant;
      }
    } else {
      let dataMerchant = merchantsResponse.msi.af.find(
        obj => obj.desc.toLowerCase().indexOf(Merchant) >= 0
      );
      //Si la promocion a meses no aplica para meses sin intereses, se cancela la operación
      if (!dataMerchant) {
        mitPaymentProcessCancel();
        return;
      } else {
        objDataVenta.Merchant = dataMerchant.merchant;
      }
    }

    console.info("Realizar cargo a tarjeta - Request");
    console.info(objDataVenta);

    infoNotification("Realizando cargo a tarjeta...");

    var portL = this.port1;
    portL.postMessage(objDataVenta);
    portL.onmessage = event => {
      const response = event.data;
      const objChargeResponse = JSON.parse(response);

      console.info("Realizar cargo a tarjeta - Response");
      console.info(objChargeResponse);

      if ((objChargeResponse.response || "") == "approved") {
        successNotification("Transaccion exitosa");
        mitPaymentProcessSaveAndPrint(objChargeResponse);
      } else {
        errorNotification(
          objChargeResponse.nb_error || "Transacción no exitosa"
        );
      }
    };
  };

  mitPaymentProcessSaveAndPrint = (objChargeResponse: any) => {
    const guardarStateDate = this.guardarStateDate;
    const Ambiente = this.props.terminal.Ambiente;
    const ModeloTerminal = this.props.terminal.ModeloTerminal;

    //Si el token no pudo consultarse, se envía error
    if (
      !objChargeResponse.tokenization ||
      (objChargeResponse.tokenization.codeResponse || "") != "00"
    ) {
      errorNotification("Error al consultar el token de la tarjeta");
    }

    let objPrint: any = {
      Ambiente: Ambiente,
      VoucherComercio: objChargeResponse.voucher_comercio,
      VoucherCliente: objChargeResponse.voucher_cliente,
      ModeloTerminal: ModeloTerminal,
      token:
        !!objChargeResponse.tokenization &&
        (objChargeResponse.tokenization.codeResponse || "") == "00"
          ? {
              tkn: objChargeResponse.tokenization.token,
              tknReference: objChargeResponse.tokenization.tkn_reference || "",
              ccType: objChargeResponse.cc_type.split("/")[0].toUpperCase(),
              ccMark: objChargeResponse.cc_type.split("/")[2].toUpperCase(),
              ccBank: objChargeResponse.tokenization.cc_bank || ""
            }
          : null,
      name: "printVoucher"
    };

    console.info("Result payment...");
    console.info(objPrint);

    var portL = this.port1;
    portL.postMessage(objPrint);
    portL.onmessage = event => {
      objPrint.numeroAutorizacion = objChargeResponse.auth;
      guardarStateDate(objPrint, true);
    };
  };

  mitPaymentProcessCancel = () => {
    const MarcaTerminal = this.props.terminal.MarcaTerminal; //MarcaPinPad

    var portL = this.port1;
    portL.postMessage({ ...MarcaTerminal, name: "cancelReadCard" });
    portL.onmessage = event => {
      const response = event.data;
      let objResponse = JSON.parse(response);
      errorNotification(
        `Esta tarjeta no participa con estos MSI (${objResponse.ERROR})`
      );
    };
  };

  ///-----------------------------

  onRadioBtnClick(mesesSinIntereses) {
    let products;

    if (!this.props.cartCreated) {
      products = [...this.props.productsList];
    } else {
      const {
        cashboxById: { productos }
      } = this.props;

      products = !isNil(productos) ? productos.map(itemToProduct) : [];
    }

    products = !!mesesSinIntereses
      ? (products || []).filter(x => x.tipoItem != "MANTENIMIENTO")
      : products;

    this.setState({
      ...this.state,
      products,
      fields: {
        ...this.state.fields,
        mesesSinIntereses,
        monto: getSubTotal(products)
      }
    });
  }

  onChangeField = field => {
    return e => {
      const value = e.target.value;
      const { cashboxById } = this.props;
      if (field == "formaDePago" && value == "POR_DEFINIR_99") {
        if (
          !this.state.referenciaOtorgadaPorTesoreria &&
          !this.state.pagoRealizadoPorApp
        ) {
          this.props.getRefenceSantanderComplemento(
            cashboxById.sucursalClubId.toString(),
            this.state.quote.total.toString(),
            value
          );
        }
        this.setState({
          ...this.state,
          fields: {
            ...this.state.fields,
            [field]: value,
            referencia: "",
            monto: this.state.quote.total
          }
        });
      } else if (field == "formaDePago" && value == LINEA_PAGO) {
        const optionsMonthsWithoutInterest = this.getOptionsMonthsWithoutInterestForPaymentLine();
        this.setState({
          mesesSinIntereses: "",
          optionsMonthsWithoutInterest,
          fields: {
            ...this.state.fields,
            [field]: value
          }
        });
      } else {
        if (
          !this.state.referenciaOtorgadaPorTesoreria &&
          !this.state.pagoRealizadoPorApp
        ) {
          if (field == "formaDePago") {
            this.props.getRefenceSantanderCargo(
              cashboxById.sucursalClubId.toString(),
              cashboxById.productos[0].itemId.toString(),
              value
            );
          }
        }
        this.setState({
          ...this.state,
          fields: {
            ...this.state.fields,
            [field]: value
          }
        });
      }
    };
  };

  onChangeAmount = e => {
    if (!this.props.cartCreated) this.props.pendingPayment(e.target.value);
    this.setState({
      fields: {
        ...this.state.fields,
        monto: e.target.value
      }
    });
  };

  disableButtonOption = value => {
    if (value == ID_CONTADO) {
      return false;
    } else if (this.state.fields.formaDePago == LINEA_PAGO) {
      return false;
    } else if (
      this.state.fields.formaDePago != TARJETA_CREDITO &&
      this.state.fields.formaDePago != TARJETA_AMEX
    ) {
      return true;
    } else if (this.state.fields.bancoId) {
      const banco = this.props.banksMonthsWithoutInterest.find(
        ({ bancoId }) => bancoId == this.state.fields.bancoId
      );
      //Regresa el estatus de los meses sin interes de acuerdo al # de meses sin intereses
      return !banco[valueToField(value)];
    }
    return true;
  };

  revision = () => {
    let valReturn = true;
    if (this.state.fields.formaDePago == LINEA_PAGO) {
      return false;
    } else if (
      this.state.referenciaOtorgadaPorTesoreria &&
      this.state.fields.referencia.length > 0
    ) {
      valReturn = false;
    } else if (
      this.state.pagoRealizadoPorApp &&
      !!(this.state.fields.formaDePago || null) &&
      !!(this.state.fields.bancoId || null) &&
      !!(this.state.fields.referencia || null) &&
      !!(this.state.fields.numeroAutorizacion || null)
    ) {
      valReturn = false;
    } else if (
      !this.state.referenciaOtorgadaPorTesoreria &&
      !this.state.pagoRealizadoPorApp &&
      this.props.terminal.ReferenceSantander.toString().length > 0
    ) {
      if (
        !!this.props.terminal.VersionTerminal &&
        this.state.fields &&
        (this.state.fields.formaDePago == "TDC" ||
          this.state.fields.formaDePago == "TDD" ||
          this.state.fields.formaDePago == "AMEX" ||
          this.state.fields.formaDePago == "CONEKTA") &&
        this.state.fields.bancoId.length > 0 &&
        parseInt(this.state.fields.bancoId) > 0 &&
        !!this.state.userMitTerminalCredentials
      ) {
        valReturn = false;
      } else if (
        this.state.fields &&
        this.state.fields.formaDePago == "EFECTIVO"
      ) {
        valReturn = false;
      } else if (
        this.state.fields &&
        this.state.fields.formaDePago == "POR_DEFINIR_99" &&
        this.state.fields.empresaEmisoraId > 0
      ) {
        valReturn = false;
      } else if (
        this.state.fields &&
        this.state.fields.formaDePago == "CHEQUE" &&
        this.state.fields.numeroCuenta.length > 0 &&
        this.state.fields.numeroCheque.length > 0 &&
        this.state.fields.bancoId.length > 0 &&
        parseInt(this.state.fields.bancoId) > 0
      ) {
        valReturn = false;
      }
    }
    return valReturn;
  };

  toggleModalNoSale = () => {
    this.setState({
      showModalNoSale: !this.state.showModalNoSale
    });
  };

  toggleModalUpdateMitCredentials = () => {
    this.setState({
      showModalUpdateMitCredentials: !this.state.showModalUpdateMitCredentials
    });
  };

  toggleModalDatosFactValidacion = () => {
    this.setState({
      showModalDatosFactValidacion: !this.state.showModalDatosFactValidacion
    });
  };

  acceptModalUpdateMitCredentials = () => {
    const { tmpMitUser, tmpMitPassword } = this.state;

    if (!tmpMitUser || !tmpMitPassword) {
      return;
    }

    const { userId } = this.props.userContext;

    saveMitTerminalCredentialsByUserIdRequest(
      userId,
      tmpMitUser,
      tmpMitPassword
    )
      .then(() => {
        successNotification("Credenciales actualizadas correctamente");
        this.setState({
          showModalUpdateMitCredentials: false
        });
        this.getUserTerminalCredentials();
      })
      .catch(handleRequestError);
  };

  cancelModalUpdateMitCredentials = () => {
    const { userMitTerminalCredentials } = this.state;

    this.setState({
      showModalUpdateMitCredentials: false,
      tmpMitUser:
        userMitTerminalCredentials != null
          ? userMitTerminalCredentials.mitUser
          : "",
      tmpMitPassword:
        userMitTerminalCredentials != null
          ? userMitTerminalCredentials.mitPassword
          : ""
    });
  };

  disablePayButtonIfForcePaymentMenthod = () => {
    const { products, referenciaOtorgadaPorTesoreria } = this.state;
    const selectedPaymentMethod = this.state.fields.formaDePago || "";

    if (
      !!referenciaOtorgadaPorTesoreria ||
      selectedPaymentMethod == POR_DEFINIR_99
    ) {
      return false;
    }

    const productWithDiscountPaymentMethod = (products || []).find(
      x =>
        !!x.formaPagoParaDescuento &&
        x.formaPagoParaDescuento != FORMA_DE_PAGO.MENSUAL
    );
    if (!!productWithDiscountPaymentMethod) {
      return (
        (productWithDiscountPaymentMethod.formaPagoParaDescuento ==
          FORMA_DE_PAGO.TDC &&
          !(
            selectedPaymentMethod == TARJETA_CREDITO ||
            selectedPaymentMethod == TARJETA_AMEX
          )) ||
        (productWithDiscountPaymentMethod.formaPagoParaDescuento ==
          FORMA_DE_PAGO.TDD &&
          selectedPaymentMethod != TARJETA_DEBITO)
      );
    }
    return false;
  };

  getBankOptions = () => {
    if (this.state.fields.formaDePago === TARJETA_AMEX) {
      return (this.props.banksMonthsWithoutInterest || [])
        .sort((a, b) => a.nombre.localeCompare(b.nombre))
        .filter(x => AMEX_BANK_NAME.includes(x.nombre))
        .map(option => ({
          value: option.bancoId,
          label: option.nombre
        }));
    } else {
      return (this.props.banksMonthsWithoutInterest || [])
        .sort((a, b) => a.nombre.localeCompare(b.nombre))
        .filter(x => !AMEX_BANK_NAME.includes(x.nombre))
        .map(option => ({
          value: option.bancoId,
          label: option.nombre
        }));
    }
  };

  render() {
    const erroresDatosFacturacion =
      this.props.cashboxById != null
        ? (this.props.cashboxById.erroresDatosFacturacion || "")
            .split("\n")
            .filter(x => !!x)
        : [];

    const { products } = this.state;
    const productWithDiscountPaymentMethod = (products || []).find(
      x =>
        !!x.formaPagoParaDescuento &&
        x.formaPagoParaDescuento != FORMA_DE_PAGO.MENSUAL
    );

    return (
      <Container className="w-100 p-0 my-3">
        <MitTpvFrame messageChannel={this.channel} />

        <ReimpresionModal
          numeroAutorizacion={this.state.objetoReimpresion.numeroAutorizacion}
          selPostOpen={this.state.reimpresionModal}
          esLineaPago={this.state.fields.formaDePago == LINEA_PAGO}
          toggle={async () => {
            const reimpresionModal = !this.state.reimpresionModal;
            if (this.state.fields.formaDePago == LINEA_PAGO) {
              infoNotification("Aplicando pago...");
              await this.setState({
                applyingMainPayment: true,
                applyingCardPayment: false,
                reimpresionModal
              });
              this.debouncedHandleSubmit();
            } else {
              infoNotification("Aplicando pago...");
              await this.setState({
                reimpresionModal,
                applyingCardPayment: true
              });
              this.debouncedGuardarCargo();
            }
          }}
          reimpresionTicket={() => this.reimpresionTicket()}
          showCheck={
            !this.shouldForceTokenization() &&
            FORMA_PAGO_TOKENIZACION.includes(this.state.fields.formaDePago) &&
            ((!!this.state.readCard && !!this.state.readCard.token) ||
              this.state.fields.formaDePago == LINEA_PAGO)
          }
          setTokenizar={() => {
            let { fields } = this.state;
            fields.tokenizar = !this.state.fields.tokenizar;
            this.setState({ fields });
          }}
        />

        <div className="form-row mt-1 mb-1">
          <div className="col">
            <BaseInput
              label="Forma de Pago"
              name="formaDePagoPagoProductos"
              type="select"
              id="formaDePagoPagoProductos"
              placeholder="Forma de Pago"
              options={this.state.permission99.map(option => ({
                value: option,
                label: option == "POR_DEFINIR_99" ? "99 - POR DEFINIR" : option
              }))}
              value={this.state.fields.formaDePago}
              onChange={this.onChangeField("formaDePago")}
            />
          </div>
          <div className="col">
            <BaseInput
              label="Banco"
              name="bancoId"
              type="select"
              id="bancoId"
              placeholder="Banco"
              value={this.state.fields.bancoId}
              size="sm"
              disabled={
                !(
                  this.state.fields.formaDePago === TARJETA_CREDITO ||
                  this.state.fields.formaDePago === TARJETA_DEBITO ||
                  this.state.fields.formaDePago === TARJETA_AMEX ||
                  this.state.fields.formaDePago === CONEKTA ||
                  this.state.fields.formaDePago === ID_CHEQUE
                )
              }
              onChange={this.onChangeBank}
              options={[
                { value: "", label: "Banco" },
                ...this.getBankOptions()
              ]}
            />
          </div>
        </div>
        {this.state.fields.formaDePago == "CHEQUE" ? (
          <div className="form-row mt-1 mb-1">
            <div className="col">
              <BaseInput
                label="Número de cheque"
                name="numeroCheque"
                type="text"
                id="numeroCheque"
                placeholder="Número de cheque"
                value={this.state.fields.numeroCheque}
                onChange={this.onChangeField("numeroCheque")}
              />
            </div>
            <div className="col">
              <BaseInput
                label="Número de cuenta"
                name="numeroCuenta"
                type="text"
                id="numeroCuenta"
                placeholder="Número de cuenta"
                value={this.state.fields.numeroCuenta}
                onChange={this.onChangeField("numeroCuenta")}
              />
            </div>
          </div>
        ) : null}
        <div className="form-row mt-1 mb-1">
          {this.state.fields.formaDePago == "POR_DEFINIR_99" ? (
            <div className="col">
              <BaseInput
                label="Empresa"
                name="empresaEmisoraId"
                type="select"
                id="empresaEmisoraId"
                placeholder="Empresa"
                options={[{ value: 0, label: "Seleccionar empresa" }].concat(
                  this.props.companiesTypes.map(option => ({
                    value: option.id,
                    label: option.nombre
                  }))
                )}
                value={this.state.fields.empresaEmisoraId}
                onChange={this.onChangeField("empresaEmisoraId")}
              />
            </div>
          ) : null}

          <div className="col">
            <Label check className={`${s.customDistanciaCheck}`}>
              <Input
                type="checkbox"
                checked={this.state.referenciaOtorgadaPorTesoreria}
                onChange={e =>
                  this.setState({
                    referenciaOtorgadaPorTesoreria: !this.state
                      .referenciaOtorgadaPorTesoreria
                  })
                }
              />
              Referencia otorgada por tesorería
            </Label>
          </div>

          <div className="col">
            <Label check className={`${s.customDistanciaCheck}`}>
              <Input
                type="checkbox"
                checked={this.state.pagoRealizadoPorApp}
                onChange={e =>
                  this.setState({
                    pagoRealizadoPorApp: !this.state.pagoRealizadoPorApp
                  })
                }
              />
              Pago realizado por App
            </Label>
          </div>

          <div className="col">
            {/* <Button
              style={{
                fontSize: "12px",
                marginTop: "20px",
                marginBottom: "20px"
              }}
              onClick={() => {
                this.setState({ showModalUpdateMitCredentials: true });
              }}
              block
            >
              Actualizar credenciales para cobro con terminal
            </Button> */}
          </div>
          <div className="col-2">
            <Button
              style={{
                fontSize: "12px",
                marginTop: "20px",
                marginBottom: "20px"
              }}
              onClick={() => {
                navigator.serial
                  .requestPort()
                  .then(async port => {
                    console.info(port.getInfo());
                    infoNotification("Puerto COM configurado correctamente");
                  })
                  .catch(function(error) {
                    if (error) {
                      errorNotification("Error al configurar el puerto COM");
                    }
                  });
              }}
              block
            >
              Config. puerto TPV
            </Button>
          </div>
        </div>
        <Row>
          <Col />
          <Col>
            <ButtonGroup>
              {(this.state.optionsMonthsWithoutInterest || []).map(x => (
                <Button
                  className={s.radioButton}
                  color="secondary"
                  onClick={() => this.onRadioBtnClick(x.id)}
                  active={this.state.fields.mesesSinIntereses === x.id}
                  disabled={!x.active || this.disableButtonOption(x.id)}
                >
                  {(x.nombre || "").toLocaleUpperCase()}
                </Button>
              ))}
            </ButtonGroup>
          </Col>
          <Col />
        </Row>

        <Row>
          <Col />
          <Col>
            {!!productWithDiscountPaymentMethod && (
              <div
                className="alert alert-warning mt-3"
                style={{ padding: "6px 8px" }}
                role="alert"
              >
                Hay cargos de mantenimiento marcados para pago con{" "}
                {productWithDiscountPaymentMethod.formaPagoParaDescuento} para
                tokenizar tarjeta
              </div>
            )}
          </Col>
          <Col />
        </Row>

        <Row style={{ minHeight: "300px" }} className="m-0">
          <ProductsList
            products={this.state.products}
            minRows={12}
            loading={this.props.saving || this.props.loading}
            freezeObject={this.state.freezeObject}
            setFreezeObject={this.setFreezeObject}
          />
        </Row>

        {this.shouldForceTokenization() && (
          <div className="alert alert-warning mt-4 mb-4">
            <TextWithLineBreaks
              text={
                "Se encuentra en un proceso de venta/reactivación de membresía socio.\nSe registrará como medio de pago para cargo automático la tarjeta con la cual se haya hecho el pago con el monto mas alto."
              }
            />
          </div>
        )}

        <div className="form-row mt-1">
          {this.state.referenciaOtorgadaPorTesoreria ? (
            <div className="col">
              <SelectFilter
                label="Referencia"
                name="referenciaPagoProductos"
                type="select"
                id="referenciaPagoProductos"
                placeholder="Referencia"
                value={{
                  label: this.state.fields.referencia,
                  value: this.state.fields.referencia
                }}
                isMulti={false}
                options={[{ value: 0, label: "Selecciona referencia" }].concat(
                  this.props.referencesNotUsed.map(option => ({
                    value: option.referencia.toString().toUpperCase(),
                    label: option.referencia.toString().toUpperCase()
                  }))
                )}
                onChange={({ value }) => {
                  const { fields } = this.state;
                  fields["referencia"] = value;
                  this.setState({
                    fields: {
                      ...fields
                    }
                  });
                }}
                loading={false}
                isAsync={false}
              />
            </div>
          ) : this.state.pagoRealizadoPorApp ? (
            <div className="col">
              <BaseInput
                label="Referencia"
                name="referenciaPagoProductos"
                type="text"
                id="referenciaPagoProductos"
                placeholder="Referencia"
                value={this.state.fields.referencia}
                onChange={this.onChangeField("referencia")}
              />
            </div>
          ) : (
            <div className="col">
              <BaseInput
                label="Referencia"
                name="referenciaPagoProductos"
                type="text"
                id="referenciaPagoProductos"
                placeholder="Referencia"
                value={
                  this.props.terminal.ReferenceSantander.toString().length > 0
                    ? this.props.terminal.ReferenceSantander.toString()
                    : this.state.fields.referencia
                }
                onChange={this.onChangeField("referencia")}
                disabled={
                  this.state.fields.formaDePago == "POR_DEFINIR_99" ||
                  this.props.terminal.ReferenceSantander.toString().length > 0
                }
              />
            </div>
          )}
          <div className="col">
            <BaseInput
              label="Nro Autorización"
              name="nroAutorizacionPagoProductos"
              type="text"
              id="nroAutorizacionPagoProductos"
              placeholder="Nro Autorizacion"
              value={this.state.fields.numeroAutorizacion}
              onChange={this.onChangeField("numeroAutorizacion")}
            />
          </div>
          <div className="col">
            <BaseInput
              label="Monto a Pagar"
              name="montoPagoProductos"
              type="number"
              id="montoPagoProductos"
              placeholder="Nro Autorizacion"
              value={this.state.fields.monto}
              disabled={
                this.state.fields.formaDePago == "POR_DEFINIR_99" ||
                this.state.fields.formaDePago == LINEA_PAGO
              }
              onChange={this.onChangeAmount}
            />
          </div>
        </div>
        <hr />
        <Row className="my-1">
          <Col>
            <PaymentsTable payments={this.state.pagos}></PaymentsTable>
          </Col>
        </Row>
        <Row className="m-0">
          <Col></Col>
          <Col xs={2} className="float-right pr-0 pt-2 m-0">
            {!!this.state.quote &&
              this.state.quote.estatus == QUOTE_CREATED_STATUS &&
              erroresDatosFacturacion.length == 0 && (
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton} float-right`}
                  onClick={async e => {
                    if (!!e) e.preventDefault();

                    // Tokenización por línea de pago permitida
                    if (this.state.fields.formaDePago == LINEA_PAGO) {
                      this.setState({
                        reimpresionModal: true
                      });
                    } else {
                      infoNotification("Aplicando pago...");
                      await this.setState({
                        applyingMainPayment: true,
                        applyingCardPayment: false
                      });
                      this.debouncedHandleSubmit();
                    }
                  }}
                  size="sm"
                  block
                  disabled={
                    this.props.saving ||
                    this.props.loading ||
                    this.disablePayButtonIfForcePaymentMenthod() ||
                    this.revision() ||
                    this.state.applyingMainPayment
                  }
                >
                  {this.state.fields.formaDePago == LINEA_PAGO
                    ? "Generar Línea de Pago"
                    : "Generar Pago"}
                </Button>
              )}
            {((!!this.state.quote &&
              this.state.quote.estatus != QUOTE_CREATED_STATUS) ||
              erroresDatosFacturacion.length > 0) && (
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton} float-right`}
                onClick={() => {
                  this.props.history.push("/inicio");
                }}
                size="sm"
                block
              >
                Regresar
              </Button>
            )}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.showModalNoSale}
          toggle={this.toggleModalNoSale}
          fade={true}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <ModalBody>
            <h4 className="text-danger">Caja</h4>
            <h5>
              No es posible continuar con la venta. <br />
              El usuario no tiene un club asignado en su sesión.
            </h5>
          </ModalBody>
          <ModalFooter>
            <Button
              className={`${s.secondaryButton} d-inline-block mr-2`}
              onClick={() => {
                this.props.history.push("/inicio");
              }}
            >
              SALIR
            </Button>
          </ModalFooter>
        </Modal>

        {!!erroresDatosFacturacion.length && (
          <Modal
            isOpen={this.state.showModalDatosFactValidacion}
            toggle={this.toggleModalDatosFactValidacion}
            fade={true}
            size="lg"
            backdrop="static"
            keyboard={false}
          >
            <ModalBody>
              <h4 className="text-danger">
                Validación datos de facturación del cliente
              </h4>
              <div>
                <ul>
                  {erroresDatosFacturacion
                    .filter(x => !!x)
                    .map(x => {
                      return <li>{x}</li>;
                    })}
                </ul>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                className={`${s.secondaryButton} d-inline-block mr-2`}
                onClick={() => {
                  this.setState({
                    acceptedModalDatosFactValidacion: true,
                    showModalDatosFactValidacion: false
                  });
                }}
              >
                ACEPTAR
              </Button>
            </ModalFooter>
          </Modal>
        )}

        {/*Modal para actualizar credenciales de terminal MIT*/}
        <ModalFrame
          title={"Actualizar credenciales para terminal MIT"}
          isOpen={this.state.showModalUpdateMitCredentials}
          toggle={this.toggleModalUpdateMitCredentials}
        >
          <Container>
            <Form>
              <BaseInput
                label={"Usuario:"}
                name={"utmcTmpUser"}
                id={"utmcTmpUser"}
                type="text"
                placeholder="Usuario"
                value={this.state.tmpMitUser}
                onChange={e => {
                  e.preventDefault();
                  const tmpMitUser = e.target.value;
                  this.setState({ tmpMitUser });
                }}
              />
              <BaseInput
                label={"Password:"}
                name={"utmcTmpPassword"}
                id={"utmcTmpPassword"}
                type="text"
                placeholder="Password"
                value={this.state.tmpMitPassword}
                onChange={e => {
                  e.preventDefault();
                  const tmpMitPassword = e.target.value;
                  this.setState({ tmpMitPassword });
                }}
              />
              <Row style={{ marginTop: "20px" }}>
                <Col>
                  <Button
                    className={`${s.buttonMarginTop} ${s.primaryButton}`}
                    onClick={e => {
                      e.preventDefault();
                      this.acceptModalUpdateMitCredentials();
                    }}
                    block
                  >
                    Guardar
                  </Button>
                </Col>
                <Col>
                  <Button
                    className={`${s.buttonMarginTop}`}
                    onClick={e => {
                      e.preventDefault();
                      this.cancelModalUpdateMitCredentials();
                    }}
                    block
                  >
                    Cancelar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </ModalFrame>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(SportsProductsQuoteById));
