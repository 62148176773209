import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import BaseInput from "../../../toolkit/baseInput";
import {
  Alert,
  Button,
  Table,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import s from "./styles.scss";
import { ProspectArguments, Prospect } from "../../../types/Prospects";
import DatePicker from "../common/DatePicker";
import reduce from "lodash.reduce";
import isBoolean from "lodash.isboolean";
import moment from "moment";
import { getValidarCurp } from "../../actions/persons";

const regexCurp = /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9A-Z]{1}$/;

interface Props {
  isFreePartner: boolean;
  isChangeFreePartner: boolean;
  changeFreePartnerData: any;
  isFreeChildPartner: boolean;
  persons: Prospect[];
  personByID: Prospect;
  history: any;
  match: any;
  partnerLinkError: any[];
  partnerLinking: boolean;
  membershipTypesMembers: {
    nombre: string;
    maximoIntegrantes: number;
    tipoIntegranteId: number;
  }[];
  searchClients: (args: ProspectArguments) => any;
  searchClientByID: (id: string) => any;
  linkPartner: (
    idMembresiaSocio: string,
    partnerType: number,
    id: string,
    isFreePartner: boolean,
    isFreeChildPartner: boolean,
    isChangeFreePartner: boolean,
    changeFreePartnerData: any
  ) => any;
  createPartner: (
    idMembresiaSocio: string,
    partnerType: number,
    partner: Partner,
    isFreePartner: boolean,
    isFreeChildPartner: boolean,
    isChangeFreePartner: boolean,
    changeFreePartnerData: any
  ) => any;
}

enum SCREEN_MODES {
  SEARCHING,
  CREATING
}

interface Partner {
  entidadNacimiento: string;
  curp: string;
  nombre: string;
  primerApellido: string;
  segundoApellido: string;
  fechaNacimiento: any;
  sexo: string;
  bloqueoMail: boolean;
  bloqueoCallCenter: boolean;
}

interface State {
  searchFields: {
    nombre: string;
    apellido: string;
    apellidoMaterno: string;
  };
  partnerFields: Partner;
  personaID: string;
  selectedPartner: string;
  screenMode: SCREEN_MODES;
  idTipoIntegrante: number;
  curpError: string;
  loading: boolean;
  gettingCurp: boolean;
  hasCurp: boolean;
}

const genreOptions = [
  { value: "FEMENINO", label: "Femenino" },
  { value: "MASCULINO", label: "Masculino" }
];

const entidadNacimientoOptions = [
  { value: "", label: "Ninguno" },
  { value: "NACIONAL", label: "Nacional" },
  { value: "EXTRANJERO", label: "Extranjero" }
];

const TIPO_INTEGRANTE_TITULAR = 1;
const TIPO_INTEGRANTE_USUARIO_GRATIS = 17;
const TIPO_INTEGRANTE_NINO_GRATIS = 18;

class LinkPartner extends React.Component<Props, State> {
  state = {
    searchFields: {
      nombre: "",
      apellido: "",
      apellidoMaterno: ""
    },
    partnerFields: {
      entidadNacimiento: "",
      curp: null,
      nombre: "",
      primerApellido: "",
      segundoApellido: "",
      fechaNacimiento: new Date(),
      sexo: "FEMENINO",
      bloqueoMail: false,
      bloqueoCallCenter: false
    },
    personaID: "",
    selectedPartner: null,
    screenMode: SCREEN_MODES.SEARCHING,
    idTipoIntegrante: !(
      this.props.isFreePartner ||
      this.props.isFreeChildPartner ||
      this.props.isChangeFreePartner
    )
      ? null
      : !!this.props.isFreePartner || !!this.props.isChangeFreePartner
      ? TIPO_INTEGRANTE_USUARIO_GRATIS
      : TIPO_INTEGRANTE_NINO_GRATIS,
    curpError: "",
    loading: false,
    gettingCurp: false,
    hasCurp: false
  };

  componentDidUpdate() {}

  onChangeSearchFieldsFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const searchFields = {
          ...state.searchFields,
          [fieldName]: value
        };

        return { ...state, searchFields };
      });
    };
  };

  onChangePartnerFieldsFactory = fieldName => {
    return event => {
      let value;
      if (fieldName === "fechaNacimiento") {
        value = event;
      } else {
        value = (event.target.value || "").toUpperCase();
      }
      this.setState(state => {
        const partnerFields = {
          ...state.partnerFields,
          [fieldName]: value
        };

        return { ...state, partnerFields };
      });
    };
  };

  getMemberTypesCatalog = () => {
    if (
      !(
        this.props.isFreePartner ||
        this.props.isChangeFreePartner ||
        this.props.isFreeChildPartner
      )
    ) {
      return [
        {
          nombre: "Seleccione...",
          tipoIntegranteId: null
        }
      ].concat(
        (this.props.membershipTypesMembers || []).filter(
          x =>
            !!x.maximoIntegrantes &&
            x.tipoIntegranteId != TIPO_INTEGRANTE_TITULAR
        )
      );
    } else if (!!this.props.isFreePartner || !!this.props.isChangeFreePartner) {
      return [
        {
          nombre: "USUARIO_GRATIS",
          tipoIntegranteId: TIPO_INTEGRANTE_USUARIO_GRATIS
        }
      ];
    } else if (!!this.props.isFreeChildPartner) {
      return [
        {
          nombre: "NIÑO_GRATIS",
          tipoIntegranteId: TIPO_INTEGRANTE_NINO_GRATIS
        }
      ];
    }

    return [];
  };

  getMemberTypeDefault = () => {
    let memberTypes = this.getMemberTypesCatalog();
    return !!memberTypes.length ? memberTypes[0].tipoIntegranteId : null;
  };

  searchClients = e => {
    e.preventDefault();
    const args = { ...this.state.searchFields };

    this.setState({ loading: true });

    if (this.state.personaID) {
      this.props.searchClientByID(this.state.personaID);
    } else {
      this.props.searchClients(args);
    }

    setTimeout(() => this.setState({ loading: false }), 1000);
    this.setState({ screenMode: SCREEN_MODES.SEARCHING });
  };

  newParter = e => {
    e.preventDefault();
    this.setState({ screenMode: SCREEN_MODES.CREATING });
  };

  onChangePersonaID = event => {
    const personaID = event.target.value;
    this.setState({ ...this.state, personaID });
  };

  onSubmit = e => {
    e.preventDefault();
  };

  selectPartner = id => {
    this.setState({ selectedPartner: id });
  };

  onChangePartnerType = event => {
    event.preventDefault();
    const idTipoIntegrante = event.target.value;
    this.setState({ idTipoIntegrante });
  };

  checkBlockMail = e => {
    const bloqueoMail = e.target.checked;
    this.setState(() => ({
      partnerFields: { ...this.state.partnerFields, bloqueoMail }
    }));
  };

  checkBlockCallCenter = e => {
    const bloqueoCallCenter = e.target.checked;
    this.setState(() => ({
      partnerFields: { ...this.state.partnerFields, bloqueoCallCenter }
    }));
  };

  getPersonsRender = () => {
    const personsToRender =
      ((this.state.searchFields.apellidoMaterno ||
        this.state.searchFields.apellido ||
        this.state.searchFields.nombre) &&
        this.props.persons &&
        this.props.persons.length &&
        this.props.persons) ||
      (this.state.personaID &&
        this.props.personByID && [this.props.personByID]);
    return personsToRender && personsToRender.length ? (
      <Table size="sm" hover className="table-borderless">
        <thead>
          <tr>
            <th>PersonaID</th>
            <th>Nombre</th>
            <th>Apellido P.</th>
            <th>Apellido M.</th>
            <th>Fecha de Nacimiento</th>
            <th>Teléfono</th>
            <th>Correo</th>
          </tr>
        </thead>
        <tbody>
          {" "}
          {personsToRender && personsToRender.length
            ? personsToRender.map(prospect => (
                <tr
                  className={s.partnerRow}
                  style={
                    prospect.personaId === this.state.selectedPartner
                      ? { backgroundColor: "rgba(0, 0, 0, 0.075)" }
                      : {}
                  }
                  onClick={() => this.selectPartner(prospect.personaId)}
                  key={prospect.personaId}
                >
                  <th scope="row">{prospect.personaId}</th>
                  <td>{prospect.nombre}</td>
                  <td>{prospect.primerApellido}</td>
                  <td>{prospect.segundoApellido}</td>
                  <td>{prospect.fechaNacimiento}</td>
                  <td>
                    {prospect.telefonos &&
                      prospect.telefonos.length &&
                      prospect.telefonos[0].numero}
                  </td>
                  <td>
                    {prospect.correosElectronicos &&
                      prospect.correosElectronicos.length &&
                      prospect.correosElectronicos[0].correo}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
    ) : null;
  };

  validCreatingForm = () => {
    const fieldsForValidation = {
      ...this.state.partnerFields,
      curp: "-" // Para constante hasValue
    };

    // CURP requerido solo para nacionales
    const validCurp = true;
    !!this.state.partnerFields.curp ||
      this.state.partnerFields.entidadNacimiento == "EXTRANJERO";
    const hasValue = reduce(
      fieldsForValidation,
      (accum, value) => accum && (isBoolean(value) || Boolean(value)),
      true
    );

    return validCurp && hasValue && !!this.state.idTipoIntegrante;
  };

  linkPartner = e => {
    e.preventDefault;
    const { idMembresiaSocio } = this.props.match.params;
    if (this.state.screenMode === SCREEN_MODES.SEARCHING) {
      this.props.linkPartner(
        idMembresiaSocio,
        this.state.idTipoIntegrante,
        this.state.selectedPartner,
        this.props.isFreePartner || false,
        this.props.isFreeChildPartner || false,
        this.props.isChangeFreePartner || false,
        this.props.changeFreePartnerData || null
      );
    } else {
      this.props.createPartner(
        idMembresiaSocio,
        this.state.idTipoIntegrante,
        {
          ...this.state.partnerFields,
          fechaNacimiento: moment(
            this.state.partnerFields.fechaNacimiento
          ).format("YYYY-MM-DD")
        },
        this.props.isFreePartner || false,
        this.props.isFreeChildPartner || false,
        this.props.isChangeFreePartner || false,
        this.props.changeFreePartnerData || null
      );
    }
  };

  render() {
    return (
      <div className={s.modalContainer}>
        <div className="h-100">
          <form className={s.basicDataClub}>
            <div className="form-row">
              {!(
                this.props.isFreePartner ||
                this.props.isFreeChildPartner ||
                this.props.isChangeFreePartner
              ) && (
                <div className="col-md-2">
                  <BaseInput
                    label={"Tipo de Socio"}
                    name="idTipoIntegrante"
                    type="select"
                    id="idTipoIntegrante"
                    placeholder="Tipo de Socio"
                    value={this.state.idTipoIntegrante}
                    options={this.getMemberTypesCatalog().map(
                      ({ tipoIntegranteId, nombre }) => ({
                        label: nombre,
                        value: tipoIntegranteId
                      })
                    )}
                    onChange={e => this.onChangePartnerType(e)}
                  />
                </div>
              )}

              <div className="col-md-2">
                <BaseInput
                  label={"Persona Id"}
                  name="personaID"
                  type="text"
                  id="personaID"
                  placeholder="Persona Id"
                  value={this.state.personaID}
                  onChange={e => this.onChangePersonaID(e)}
                  disabled={
                    this.state.searchFields.apellido ||
                    this.state.searchFields.nombre
                  }
                />
              </div>
              <div className="col">
                <BaseInput
                  label={"* Nombre"}
                  name="nombre"
                  type="text"
                  id="nombre"
                  placeholder="Nombre"
                  value={this.state.searchFields.nombre}
                  disabled={this.state.personaID}
                  onChange={this.onChangeSearchFieldsFactory("nombre")}
                />
              </div>
              <div className="col">
                <BaseInput
                  label={"* A. Paterno"}
                  name="apellido"
                  type="text"
                  id="apellido"
                  placeholder="A. Paterno"
                  value={this.state.searchFields.apellido}
                  disabled={this.state.personaID}
                  onChange={this.onChangeSearchFieldsFactory("apellido")}
                />
              </div>
              <div className="col">
                <BaseInput
                  type="text"
                  label={"A. Materno"}
                  name="apellidoMaterno"
                  id="apellidoapellidoMaterno"
                  placeholder="A. Materno"
                  value={this.state.searchFields.apellidoMaterno}
                  disabled={this.state.personaID}
                  onChange={this.onChangeSearchFieldsFactory("apellidoMaterno")}
                />
              </div>
              <div className="col-1">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                  onClick={e => this.searchClients(e)}
                  size="sm"
                  block
                  disabled={
                    (!this.state.personaID &&
                      !(
                        this.state.searchFields.apellido &&
                        this.state.searchFields.nombre
                      )) ||
                    this.state.loading
                  }
                >
                  {!this.state.loading ? "Buscar" : "Espere"}
                </Button>
              </div>
              <div className="col-1">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                  size="sm"
                  block
                  onClick={e => this.newParter(e)}
                >
                  {"Nuevo"}
                </Button>
              </div>
            </div>
          </form>
          <hr />
          {this.state.screenMode === SCREEN_MODES.SEARCHING ? (
            this.getPersonsRender()
          ) : this.state.screenMode === SCREEN_MODES.CREATING ? (
            <form className={s.basicDataClub}>
              <div className="form-row">
                <div className="col">
                  <BaseInput
                    label={"Entidad Nacimiento"}
                    name="entidadNacimiento"
                    type="select"
                    options={entidadNacimientoOptions}
                    id="entidadNacimiento"
                    placeholder="Entidad Nacimiento"
                    value={this.state.partnerFields.entidadNacimiento}
                    onChange={this.onChangePartnerFieldsFactory(
                      "entidadNacimiento"
                    )}
                  />
                </div>
                <div className="col">
                  <BaseInput
                    label={"CURP"}
                    name="curp"
                    id="curp"
                    placeholder="CURP"
                    value={this.state.partnerFields.curp}
                    onChange={this.onChangePartnerFieldsFactory("curp")}
                    maxlength={18}
                    onBlur={async evt => {
                      try {
                        const curp = evt.target.value;
                        if (!curp) {
                          this.setState({
                            hasCurp: false,
                            curpError: ""
                          });
                          return;
                        }

                        // Comprobación del CURP
                        if (!regexCurp.test(curp)) {
                          this.setState({
                            hasCurp: true,
                            curpError: "Curp inválido"
                          });
                          return;
                        }

                        this.setState({ gettingCurp: true });
                        const curpDataResponse = await getValidarCurp(
                          null,
                          curp
                        );
                        const curpData = curpDataResponse.data;

                        if (curpData.apellidoPaterno) {
                          this.setState({
                            curpError: "",
                            gettingCurp: false,
                            hasCurp: true,
                            partnerFields: {
                              ...this.state.partnerFields,
                              nombre: curpData.nombre,
                              primerApellido: curpData.apellidoPaterno,
                              segundoApellido: curpData.apellidoMaterno,
                              fechaNacimiento: curpData.fechaNacimiento
                            }
                          });
                        } else {
                          this.setState({
                            gettingCurp: false,
                            hasCurp: false,
                            curpError: ""
                          });
                        }
                        this.setState({ curpError: "" });
                      } catch (err) {
                        this.setState({
                          gettingCurp: false,
                          hasCurp: false,
                          curpError:
                            (err &&
                              err.response &&
                              err.response.data &&
                              err.response.data.message) ||
                            "Error al validar la CURP"
                        });
                      }
                    }}
                    errors={
                      this.state.curpError ? [this.state.curpError] : null
                    }
                    uppercase={true}
                    disabled={
                      this.state.partnerFields.entidadNacimiento ==
                        "EXTRANJERO" || this.state.gettingCurp
                    }
                  />
                </div>
                <div className="col">
                  <BaseInput
                    label={"Nombre"}
                    name="nombre"
                    type="text"
                    id="nombre"
                    placeholder="Nombre"
                    uppercase={true}
                    value={this.state.partnerFields.nombre}
                    onChange={this.onChangePartnerFieldsFactory("nombre")}
                    disabled={this.state.gettingCurp || this.state.hasCurp}
                  />
                </div>
                <div className="col">
                  <BaseInput
                    label={"Apellido Paterno"}
                    name="primerApellido"
                    type="text"
                    id="primerApellido"
                    placeholder="Apellido Paterno"
                    uppercase={true}
                    value={this.state.partnerFields.primerApellido}
                    onChange={this.onChangePartnerFieldsFactory(
                      "primerApellido"
                    )}
                    disabled={this.state.gettingCurp || this.state.hasCurp}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col">
                  <BaseInput
                    label={"Apellido Materno"}
                    name="segundoApellido"
                    type="text"
                    id="segundoApellido"
                    placeholder="Apellido Materno"
                    uppercase={true}
                    value={this.state.partnerFields.segundoApellido}
                    onChange={this.onChangePartnerFieldsFactory(
                      "segundoApellido"
                    )}
                    disabled={this.state.gettingCurp || this.state.hasCurp}
                  />
                </div>
                <div className="col">
                  <DatePicker
                    label="Fecha de Nacimiento"
                    name="fechaNacimiento"
                    id="fechaNacimiento"
                    selected={this.state.partnerFields.fechaNacimiento}
                    onChange={this.onChangePartnerFieldsFactory(
                      "fechaNacimiento"
                    )}
                    disabled={this.state.gettingCurp || this.state.hasCurp}
                  />
                </div>
                <div className="col">
                  <BaseInput
                    label={"Sexo"}
                    name="sexo"
                    type="select"
                    options={genreOptions}
                    id="sexo"
                    placeholder="Sexo"
                    value={this.state.partnerFields.sexo}
                    onChange={this.onChangePartnerFieldsFactory("sexo")}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col p-0">
                  <Form>
                    <FormGroup check className="form-check-inline float-right">
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={this.state.partnerFields.bloqueoMail}
                          onChange={e => this.checkBlockMail(e)}
                        />{" "}
                        Bloqueo de Mail
                      </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-inline float-right">
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={this.state.partnerFields.bloqueoCallCenter}
                          onChange={e => this.checkBlockCallCenter(e)}
                        />{" "}
                        Bloqueo de Call Center
                      </Label>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </form>
          ) : null}
        </div>
        <form>
          <div className="form-row p-0 mb-3">
            <div className="col-12">
              {this.props.partnerLinkError &&
                this.props.partnerLinkError.map((message, index) => {
                  return (
                    <div key={index} className="alert alert-danger mt-3">
                      {message}
                    </div>
                  );
                })}
            </div>
            <div className="col-1 float-right pr-0">
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                onClick={e => this.linkPartner(e)}
                size="sm"
                block
                disabled={
                  this.props.partnerLinking ||
                  (this.state.screenMode === SCREEN_MODES.SEARCHING &&
                    !this.state.selectedPartner) ||
                  (this.state.screenMode === SCREEN_MODES.CREATING &&
                    !this.validCreatingForm())
                }
              >
                {this.state.screenMode === SCREEN_MODES.SEARCHING
                  ? "Asociar"
                  : "Guardar"}
              </Button>
            </div>
          </div>
        </form>
        {false && (
          <span>
            <hr />
            <Alert color="danger">
              Ocurrio un error creando el club. Intente nuevamente.
            </Alert>
          </span>
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(s)(LinkPartner));
