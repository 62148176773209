import {
  GetCMaqGarantiasDeportivasPuestos,
  GetCMaqGarantiasDeportivasEmpleados,
  GetCMaqGarantiasDeportivasClubes,
  PostCMaqGarantiasDeportivasPuestos,
  PostCMaqGarantiasDeportivasEmpleados,
  PostCMaqGarantiasDeportivasClubes,
  DeleteCMaqGarantiasDeportivas
} from "../../reducers/comisionesMaquilaV2";

export interface ComisionesDeportivaGarantiasStore
  extends GetCMaqGarantiasDeportivasPuestos,
    GetCMaqGarantiasDeportivasEmpleados,
    GetCMaqGarantiasDeportivasClubes,
    PostCMaqGarantiasDeportivasPuestos,
    PostCMaqGarantiasDeportivasEmpleados,
    PostCMaqGarantiasDeportivasClubes,
    DeleteCMaqGarantiasDeportivas {
  catalogPostions: any[];
  gettingCatalogPostions: boolean;
}

export const comisionesDeportivaGarantiasStore: (
  state
) => ComisionesDeportivaGarantiasStore = state => {
  const { comisionesMaquilaV2, catalogs } = state;

  return {
    cmaqGarantiasDeportivasPuestos:
      comisionesMaquilaV2.cmaqGarantiasDeportivasPuestos,
    gettingCMaqGarantiasDeportivasPuestos:
      comisionesMaquilaV2.gettingCMaqGarantiasDeportivasPuestos,
    gettingCMaqGarantiasDeportivasPuestosError:
      comisionesMaquilaV2.gettingCMaqGarantiasDeportivasPuestosError,

    cmaqGarantiasDeportivasEmpleados:
      comisionesMaquilaV2.cmaqGarantiasDeportivasEmpleados,
    gettingCMaqGarantiasDeportivasEmpleados:
      comisionesMaquilaV2.gettingCMaqGarantiasDeportivasEmpleados,
    gettingCMaqGarantiasDeportivasEmpleadosError:
      comisionesMaquilaV2.gettingCMaqGarantiasDeportivasEmpleadosError,

    cmaqGarantiasDeportivasClubes:
      comisionesMaquilaV2.cmaqGarantiasDeportivasClubes,
    gettingCMaqGarantiasDeportivasClubes:
      comisionesMaquilaV2.gettingCMaqGarantiasDeportivasClubes,
    gettingCMaqGarantiasDeportivasClubesError:
      comisionesMaquilaV2.gettingCMaqGarantiasDeportivasClubesError,

    postingCMaqGarantiasDeportivasPuestos:
      comisionesMaquilaV2.postingCMaqGarantiasDeportivasPuestos,
    postingCMaqGarantiasDeportivasPuestosError:
      comisionesMaquilaV2.postingCMaqGarantiasDeportivasPuestosError,

    postingCMaqGarantiasDeportivasEmpleados:
      comisionesMaquilaV2.postingCMaqGarantiasDeportivasEmpleados,
    postingCMaqGarantiasDeportivasEmpleadosError:
      comisionesMaquilaV2.postingCMaqGarantiasDeportivasEmpleadosError,

    postingCMaqGarantiasDeportivasClubes:
      comisionesMaquilaV2.postingCMaqGarantiasDeportivasClubes,
    postingCMaqGarantiasDeportivasClubesError:
      comisionesMaquilaV2.postingCMaqGarantiasDeportivasClubesError,

    deletingCMaqGarantiasDeportivas:
      comisionesMaquilaV2.deletingCMaqGarantiasDeportivas,
    deletingCMaqGarantiasDeportivasError:
      comisionesMaquilaV2.deletingCMaqGarantiasDeportivasError,

    catalogPostions: catalogs.catalogPostions,
    gettingCatalogPostions: catalogs.gettingCatalogPostions
  };
};
