import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { Button, UncontrolledTooltip } from "reactstrap";
import s from "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faFileExport,
  faPlusCircle,
  faSave
} from "@fortawesome/free-solid-svg-icons";
import MaquilaGarantia from "../../../types/MaquilaGarantia";

export interface TableProps {
  data: MaquilaGarantia[];
  loading: boolean;
  onCreate: () => void;
  onUpdate: (
    row: MaquilaGarantia,
    tipo: "PUESTO" | "CLUB" | "EMPLEADO"
  ) => void;
  onDelete: (
    row: MaquilaGarantia,
    tipo: "PUESTO" | "CLUB" | "EMPLEADO"
  ) => void;
}

export const MemoTablePuesto = React.memo(
  ({ data, loading, onCreate, onUpdate, onDelete }: TableProps) => {
    // State de las columnas de la tabla
    const [columns, setColumns] = useState(
      columnsFormatter(onCreate, onUpdate, onDelete, loading)
    );

    // Actualización de estilos
    useEffect(() => {
      setColumns(columnsFormatter(onCreate, onUpdate, onDelete, loading));
    }, [data]);

    return (
      <>
        <div className="row mt-3">
          {/* Botón para exportar */}
          <div className="col-6 p-0" style={{ lineHeight: "40px" }}>
            <span style={{ fontWeight: "bold" }}>
              Garantías asociadas a puestos de trabajo
            </span>
          </div>
          <div
            className="col-6 text-right p-0"
            style={{ lineHeight: "40px" }}
            hidden
          >
            <span>Exportar a CSV: </span>
            <Button
              id="btnReportExportCsv"
              color="link"
              type="button"
              onClick={() => ({})}
            >
              <FontAwesomeIcon icon={faFileExport} color="black" />
            </Button>
          </div>
        </div>

        {/* Tabla */}
        <div className="row mt-2">
          <div style={{ width: "100%" }}>
            <ReactTable
              data={data || []}
              loading={loading}
              pageSize={data && data.length > 5 ? data.length : 5}
              columns={columns}
              sortable={false}
              resizable={true}
              showPagination={false}
              className={`w-100 -highlight -striped ${s.hidden}`}
            />
          </div>
        </div>
      </>
    );
  }
);

// TODO: Definir permisos para los botones
function columnsFormatter(
  onCreate: () => void,
  onUpdate: (
    row: MaquilaGarantia,
    tipo: "PUESTO" | "CLUB" | "EMPLEADO"
  ) => void,
  onDelete: (
    row: MaquilaGarantia,
    tipo: "PUESTO" | "CLUB" | "EMPLEADO"
  ) => void,
  loading: boolean
) {
  return [
    {
      Header: "Clave de Puesto",
      id: "puestoClave",
      width: 88,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("puestoClave", loading)
    },
    {
      Header: "Puesto",
      id: "puesto",
      width: 176,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: simpleAccessor("puesto", loading)
    },
    {
      Header: "Monto",
      id: "monto",
      width: 128,
      className: s.tdClear,
      headerClassName: "text-left",
      accessor: moneyAccessor("monto", loading)
    },
    {
      Header: () => {
        return (
          <div className={`text-left`}>
            {renderBoton(
              null,
              `btn-create-garantia--`,
              true,
              onCreate,
              "Agregar garantía",
              "#000",
              faPlusCircle
            )}
          </div>
        );
      },
      id: "acciones",
      className: s.tdClear,
      width: 72,
      accessor: (row: MaquilaGarantia) => {
        return (
          <div className={`${fieldClassName(row, loading)} text-left`}>
            {renderBoton(
              row,
              `btn-update-${row.idPuesto}-${row.idClub}-${row.idEmpleado}`,
              true,
              onUpdate,
              "Actualizar garantía",
              "#06c",
              faSave
            )}
            {renderBoton(
              row,
              `btn-delete-${row.idPuesto}-${row.idClub}-${row.idEmpleado}`,
              true,
              onDelete,
              "Eliminar garantía",
              "#900",
              faBan
            )}
          </div>
        );
      }
    }
  ];
}

function simpleAccessor(key: keyof MaquilaGarantia, loading) {
  return (row: MaquilaGarantia) => {
    return <div className={fieldClassName(row, loading)}>{row[key]}&nbsp;</div>;
  };
}

function moneyAccessor(key: keyof MaquilaGarantia, loading) {
  return (row: MaquilaGarantia) => {
    return (
      <div className={fieldClassName(row, loading)}>
        {moneyFormatter.format(row[key] as number)}&nbsp;
      </div>
    );
  };
}

const moneyFormatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN"
});

function fieldClassName(row: MaquilaGarantia, loading: boolean) {
  return `${s.field}`;
}

function renderBoton(
  row: MaquilaGarantia,
  id,
  habilitado,
  action,
  tooltip,
  color,
  icon
) {
  if (habilitado) {
    return (
      <span
        id={id}
        onClick={() => (row ? action(row, "PUESTO") : action())}
        className={s.btn}
      >
        <FontAwesomeIcon icon={icon} color={color} />
        <UncontrolledTooltip placement="left" target={id}>
          {tooltip}
        </UncontrolledTooltip>
      </span>
    );
  } else {
    return (
      <span className={s.info} id={id}>
        <FontAwesomeIcon icon={icon} color={color} />
        <UncontrolledTooltip placement="left" target={id}>
          {tooltip}
        </UncontrolledTooltip>
      </span>
    );
  }
}
