import { Preview } from "../../../types/IAction";
import {
  getArchivosMaquila,
  getPeriodoAplicable,
  postArchivoMaquila
} from "../../actions/comisionesMaquilaV2";
import {
  GetCMaqArchivosMaquila,
  GetCMaqPeriodoAplicable,
  PostCMaqArchivoMaquila
} from "../../reducers/comisionesMaquilaV2";

export interface ComisionesDeportivaMaquilaDispatcher {
  getPeriodoAplicable: () => Promise<Preview<GetCMaqPeriodoAplicable>>;
  getArchivosMaquila: () => Promise<Preview<GetCMaqArchivosMaquila>>;
  postArchivoMaquila: (
    fechaPeriodo: any
  ) => Promise<Preview<PostCMaqArchivoMaquila>>;
}

export const comisionesDeportivaMaquilaDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => ComisionesDeportivaMaquilaDispatcher = dispatch => ({
  getPeriodoAplicable: () => {
    return dispatch(getPeriodoAplicable());
  },
  getArchivosMaquila: () => {
    return dispatch(getArchivosMaquila());
  },
  postArchivoMaquila: (fechaPeriodo: any) => {
    return dispatch(postArchivoMaquila(fechaPeriodo));
  }
});
