import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  infoNotification,
  successNotification,
  errorNotification,
  ACTIVITY_CREATION_WAITING,
  ACTIVITY_CREATION_ERROR,
  ACTIVITY_CREATION,
  ERROR
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  showCreateProspectModal: boolean;
  showCreateAgreementModal: boolean;
  showConfirmBenefitsModal: boolean;
  showCreateClubModal: boolean;
  showActionsProspectModal: boolean;
  showActionsLogProspectModal: boolean;
  prospectToEdit: object;
  agreementToEdit: object;
  clubToEdit: object;
  showDeleteProspectModal: boolean;
  prospectToDelete: object;
  actionProspect: object;
  actionLogProspect: object;
  ProspectActionsLog: object;
  creatingActivityError: object;
  creatingActivity: boolean;
  showStatusProspectModal: boolean;
  statusProspect: object;
  openChangeStatusActivity: boolean;
  activityId: string;
  changingActivityStatus: boolean;
  changingActivityStatusError: object;
  createdAction: object;
  showPromotionDetailModal: boolean;
  promotion: object;
  promotionDetail: object;
  showLinkPartnerModal: boolean;
  showLinkPartnerModalAsFreePartner: boolean;
  showLinkPartnerModalAsChangeFreePartner: boolean;
  showLinkPartnerModalAsFreeChildPartner: boolean;
  linkPartnerTitle: string;
  showAddPersonModal: boolean;
  showAgreementDetailModal: boolean;
  agreement: object;
  agreementDetail: object;
  showCreateBankModal: boolean;
  bankToEdit: object;
  showEditBankMonthsWithoutInterestModal: boolean;
  isOpenAddBankCardModal: boolean;
  showEditSessionModal: boolean;
  editSession: any;
  controlKey: String;
  changeFreePartnerData: any;
}
const initialState: State = {
  showCreateProspectModal: false,
  showConfirmBenefitsModal: false,
  showActionsProspectModal: false,
  showCreateAgreementModal: false,
  showLinkPartnerModal: false,
  showLinkPartnerModalAsFreePartner: false,
  showLinkPartnerModalAsChangeFreePartner: false,
  showLinkPartnerModalAsFreeChildPartner: false,
  linkPartnerTitle: "Nuevo Socio",
  showAddPersonModal: false,
  showCreateClubModal: false,
  showActionsLogProspectModal: false,
  prospectToEdit: null,
  agreementToEdit: null,
  clubToEdit: null,
  prospectToDelete: null,
  actionProspect: null,
  actionLogProspect: null,
  ProspectActionsLog: null,
  showDeleteProspectModal: false,
  creatingActivityError: null,
  creatingActivity: false,
  showStatusProspectModal: false,
  statusProspect: null,
  openChangeStatusActivity: false,
  activityId: null,
  changingActivityStatus: false,
  changingActivityStatusError: null,
  createdAction: null,
  showPromotionDetailModal: false,
  promotion: null,
  promotionDetail: null,
  showAgreementDetailModal: false,
  agreement: null,
  agreementDetail: null,
  showCreateBankModal: false,
  bankToEdit: null,
  showEditBankMonthsWithoutInterestModal: false,
  isOpenAddBankCardModal: false,
  showEditSessionModal: false,
  editSession: null,
  controlKey: "",
  changeFreePartnerData: null
};

export default function modalReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.CREATE_PROSPECT}_FULFILLED`: {
      return {
        ...state,
        showCreateProspectModal: false
      };
    }
    case `${actionTypes.PATCH_PROSPECT}_FULFILLED`: {
      return {
        ...state,
        showStatusProspectModal: false
      };
    }
    case `${actionTypes.CREATE_ACTIVITY}_PENDING`: {
      infoNotification(ACTIVITY_CREATION_WAITING);
      return {
        ...state,
        creatingActivityError: null,
        creatingActivity: true
      };
    }
    case `${actionTypes.CREATE_ACTIVITY}_REJECTED`: {
      errorNotification(readMessageBack(action, ACTIVITY_CREATION_ERROR));
      return {
        ...state,
        creatingActivityError: action.payload.data,
        creatingActivity: false
      };
    }
    case `${actionTypes.CREATE_ACTIVITY}_FULFILLED`: {
      successNotification(ACTIVITY_CREATION);
      return {
        ...state,
        creatingActivityError: null,
        creatingActivity: false,
        showActionsProspectModal: false,
        actionProspect: null,
        createdAction: action.payload.data
      };
    }
    case `${actionTypes.EDIT_ACTIVITY_STATUS}_PENDING`: {
      return {
        ...state,
        changingActivityStatus: true
      };
    }
    case `${actionTypes.EDIT_ACTIVITY_STATUS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        changingActivityStatusError: action.payload,
        changingActivityStatus: false
      };
    }
    case `${actionTypes.EDIT_ACTIVITY_STATUS}_FULFILLED`: {
      return {
        ...state,
        changingActivityStatusError: null,
        changingActivityStatus: false,
        openChangeStatusActivity: false
      };
    }
    case actionTypes.CREATE_PROSPECT_TOGGLE: {
      return {
        ...state,
        showCreateProspectModal: !state.showCreateProspectModal,
        prospectToEdit: action.payload || null
      };
    }
    case actionTypes.SET_PROSPECT_TO_EDIT: {
      return {
        ...state,
        prospectToEdit: action.payload
      };
    }
    case actionTypes.CREATE_AGREEMENT_TOGGLE: {
      return {
        ...state,
        showCreateAgreementModal: !state.showCreateAgreementModal,
        agreementToEdit: action.payload || null
      };
    }
    case `${actionTypes.CREATE_AGREEMENT}_FULFILLED`: {
      return {
        ...state,
        showCreateAgreementModal: false
      };
    }
    case actionTypes.CREATE_CLUB_TOGGLE: {
      return {
        ...state,
        showCreateClubModal: !state.showCreateClubModal,
        clubToEdit: action.payload || null
      };
    }
    case actionTypes.STATUS_ACTIVITY_TOGGLE: {
      return {
        ...state,
        openChangeStatusActivity: !state.openChangeStatusActivity,
        activityId: action.payload || null
      };
    }
    case `${actionTypes.CREATE_CLUB}_FULFILLED`: {
      return {
        ...state,
        showCreateClubModal: false
      };
    }
    case actionTypes.ACTIONS_PROSPECT_TOGGLE: {
      return {
        ...state,
        showActionsProspectModal: !state.showActionsProspectModal,
        actionProspect: action.payload || null
      };
    }
    case actionTypes.CONFIRM_CHANGES_BENEFITS_MODAL: {
      return {
        ...state,
        showConfirmBenefitsModal: !state.showConfirmBenefitsModal
      };
    }
    case actionTypes.CONFIRM_BENEFITS_CHANGES: {
      return {
        ...state,
        showConfirmBenefitsModal: false
      };
    }
    case actionTypes.CANCEL_BENEFITS_CHANGES: {
      return {
        ...state,
        showConfirmBenefitsModal: false
      };
    }
    case actionTypes.ACTIONS_LOG_PROSPECT_TOGGLE: {
      return {
        ...state,
        showActionsLogProspectModal: !state.showActionsLogProspectModal,
        actionLogProspect: action.payload || null,
        ProspectActionsLog: null
      };
    }
    case actionTypes.STATUS_PROSPECT_TOGGLE: {
      return {
        ...state,
        showStatusProspectModal: !state.showStatusProspectModal,
        statusProspect: action.payload || null
      };
    }
    case actionTypes.DELETE_PROSPECT_TOGGLE: {
      return {
        ...state,
        showDeleteProspectModal: !state.showDeleteProspectModal,
        prospectToDelete: action.payload || null
      };
    }
    case `${actionTypes.DELETE_PROSPECT}_FULFILLED`: {
      return {
        ...state,
        showDeleteProspectModal: false
      };
    }
    case actionTypes.LINK_PARTNER_TOGGLE: {
      return {
        ...state,
        showLinkPartnerModal: !state.showLinkPartnerModal,
        showLinkPartnerModalAsFreePartner: false,
        showLinkPartnerModalAsChangeFreePartner: false,
        showLinkPartnerModalAsFreeChildPartner: false,
        linkPartnerTitle: "Nuevo Socio",
        changeFreePartnerData: null
      };
    }
    case actionTypes.LINK_FREE_PARTNER_TOGGLE: {
      return {
        ...state,
        showLinkPartnerModal: !state.showLinkPartnerModal,
        showLinkPartnerModalAsFreePartner: true,
        showLinkPartnerModalAsChangeFreePartner: false,
        showLinkPartnerModalAsFreeChildPartner: false,
        linkPartnerTitle: "Invitado gratis / Integrante de anualidad",
        changeFreePartnerData: null
      };
    }
    case actionTypes.LINK_CHANGE_FREE_PARTNER_TOGGLE: {
      return {
        ...state,
        showLinkPartnerModal: !state.showLinkPartnerModal,
        showLinkPartnerModalAsFreePartner: false,
        showLinkPartnerModalAsChangeFreePartner: true,
        showLinkPartnerModalAsFreeChildPartner: false,
        linkPartnerTitle: "Cambio de invitado gratis",
        changeFreePartnerData: { ...action.payload.changeData }
      };
    }
    case actionTypes.LINK_FREE_CHILD_PARTNER_TOGGLE: {
      return {
        ...state,
        showLinkPartnerModal: !state.showLinkPartnerModal,
        showLinkPartnerModalAsFreePartner: false,
        showLinkPartnerModalAsChangeFreePartner: false,
        showLinkPartnerModalAsFreeChildPartner: true,
        linkPartnerTitle: "Niño Gratis",
        changeFreePartnerData: null
      };
    }
    case actionTypes.ADD_PERSON_TOGGLE: {
      return {
        ...state,
        showAddPersonModal: !state.showAddPersonModal,
        controlKey: action.payload.controlKey
      };
    }
    case actionTypes.SET_SELECTED_PERSON: {
      return {
        ...state,
        showAddPersonModal: false
      };
    }
    case `${actionTypes.GET_PROSPECTS_ACTIONS_LOG}_PENDING`: {
      return {
        ...state,
        ProspectActionsLog: null
      };
    }
    case `${actionTypes.GET_PROSPECTS_ACTIONS_LOG}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        ProspectActionsLog: null
      };
    }
    case `${actionTypes.GET_PROSPECTS_ACTIONS_LOG}_FULFILLED`: {
      return {
        ...state,
        ProspectActionsLog: action.payload.data
      };
    }
    case actionTypes.SHOW_PROMOTION_DETAIL_TOGGLE: {
      return {
        ...state,
        showPromotionDetailModal: !state.showPromotionDetailModal,
        promotion: action.payload || null,
        promotionDetail: null
      };
    }
    case actionTypes.SHOW_AGREEMENT_DETAIL_TOGGLE: {
      return {
        ...state,
        showAgreementDetailModal: !state.showAgreementDetailModal,
        agreement: action.payload || null,
        agreementDetail: null
      };
    }
    case `${actionTypes.GET_PROMOTION_DETAIL}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        promotionDetail: null
      };
    }
    case `${actionTypes.GET_PROMOTION_DETAIL}_FULFILLED`: {
      return {
        ...state,
        promotionDetail: action.payload.data
      };
    }
    case `${actionTypes.GET_AGREEMENT_DETAIL}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        agreementDetail: null
      };
    }
    case `${actionTypes.GET_AGREEMENT_DETAIL}_FULFILLED`: {
      return {
        ...state,
        agreementDetail: action.payload.data
      };
    }
    case actionTypes.CREATE_BANK_TOGGLE: {
      return {
        ...state,
        showCreateBankModal: !state.showCreateBankModal,
        bankToEdit: action.payload || null
      };
    }
    case `${actionTypes.CREATE_BANK}_FULFILLED`: {
      return {
        ...state,
        showCreateBankModal: !state.showCreateBankModal,
        bankToEdit: null
      };
    }
    case `${actionTypes.CREATE_MONTHS_WITHOUT_INTEREST}_FULFILLED`: {
      return {
        ...state,
        showEditBankMonthsWithoutInterestModal: !state.showEditBankMonthsWithoutInterestModal,
        bankToEdit: null
      };
    }
    case actionTypes.EDIT_BANK_MONTHS_WITHOUT_INTEREST_TOGGLE: {
      return {
        ...state,
        showEditBankMonthsWithoutInterestModal: !state.showEditBankMonthsWithoutInterestModal,
        bankToEdit: action.payload || null
      };
    }
    case actionTypes.PARTNER_ADD_BANK_CARD_TOGGLE: {
      return {
        ...state,
        isOpenAddBankCardModal: !state.isOpenAddBankCardModal
      };
    }
    case `${actionTypes.PARTNER_SAVE_BANK_CARD}_FULFILLED`: {
      return {
        ...state,
        isOpenAddBankCardModal: false
      };
    }
    case actionTypes.EDIT_SESSION_MODAL: {
      return {
        ...state,
        showEditSessionModal: !state.showEditSessionModal,
        editSession: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export { initialState };
