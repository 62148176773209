import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { getErrorMessage } from "../../utils/helpers";
import { readMessageBack } from "../../utils/readMessageBack";
import { ERROR, errorNotification } from "../../utils/notifications";

export interface GetCatalogSellers {
  sellers: any[];
  gettingSellers: boolean;
  gettingSellersError: string;
}

export interface GetCatalogUsers {
  users: any[];
  loadingUsers: boolean;
  loadingUsersError: string;
}

export interface GetCatalogClubs {
  clubs: any[];
  gettingClubs: boolean;
  gettingClubsError: string;
}

interface State extends GetCatalogSellers, GetCatalogClubs, GetCatalogUsers {
  conceptos: object[];
  estatus: object[];
  tiposPrecio: object[];
  accountingAccounts: object[];
  gettingClubsMigradoInactivoError: object;
  gettingClubsMigradoInactivo: boolean;
  clubsMigradoInactivo: object[];
  gettingClubsMigradoActivoError: object;
  gettingClubsMigradoActivo: boolean;
  clubsMigradoActivo: object[];
  gettingAgreementsError: object;
  gettingCompaniesError: object;
  gettingAgreements: boolean;
  agreements: object[];
  gettingMembershipsTypesError: object;
  gettingMembershipsTypes: boolean;
  membershipsTypes: object[];
  gettingMaintenancesError: object;
  gettingMaintenances: boolean;
  maintenances: object[];
  gettingPromotionsError: object;
  gettingPromotions: boolean;
  promotions: object[];
  gettingRolesError: object;
  gettingRoles: boolean;
  gettingAccountingAccounts: boolean;
  roles: object[];
  gettingProductAccounts: boolean;
  gettingDomainsError: object;
  gettingDomains: boolean;
  domains: object[];
  gettingSurveyTypesError: object;
  gettingSurveyTypes: boolean;
  surveyTypes: object[];
  gettingSportsProductsTypesError: object;
  gettingSportsProductsTypes: boolean;
  sportsProductsTypes: object[];
  gettingSportsProductsError: object;
  gettingSportsProducts: boolean;
  sportsProducts: object[];
  personTypes: object;
  gettingSatProductsError: object;
  gettingSatProducts: boolean;
  satProducts: object[];
  gettingSatUnitsError: object;
  gettingSatUnits: boolean;
  satUnits: object[];
  productAccounts: object[];
  memberships: object[];
  gettingBanksMonthsWithoutInterestError: object;
  gettingBanksMonthsWithoutInterest: boolean;
  banksMonthsWithoutInterest: object[];
  gettingWaysPayError: object;
  gettingWaysPay: boolean;
  waysPay: object[];
  gettingBankPayTypesError: object;
  gettingBankPayTypes: boolean;
  bankPayTypes: object[];
  gettingCardTypesError: object;
  gettingCardTypes: boolean;
  cardTypes: object[];
  gettingBanksError: object;
  gettingBanks: boolean;
  banks: object[];
  gettingAnnuityTypesError: object;
  gettingAnnuityTypes: boolean;
  gettingCompaniesTypes: boolean;
  annuityTypes: object[];
  companiesTypes: object[];
  schemas: object[];
  loadingMemberships: boolean;
  catalogAccrual: object[];
  gettingCatalogAccrual: boolean;
  catalogPostions: object[];
  gettingCatalogPostions: boolean;
}

const initialState: State = {
  clubs: null,
  gettingClubs: false,
  gettingClubsError: null,

  gettingClubsMigradoInactivoError: null,
  gettingClubsMigradoInactivo: false,
  clubsMigradoInactivo: null,
  gettingClubsMigradoActivoError: null,
  gettingClubsMigradoActivo: false,
  clubsMigradoActivo: null,

  sellers: null,
  gettingSellers: false,
  gettingSellersError: null,

  conceptos: [],
  estatus: [],
  tiposPrecio: [],
  accountingAccounts: null,
  gettingCompaniesError: null,
  gettingMembershipsTypesError: null,
  gettingMembershipsTypes: false,
  membershipsTypes: null,
  gettingAgreementsError: null,
  gettingAgreements: false,
  agreements: null,
  gettingMaintenancesError: null,
  gettingMaintenances: false,
  maintenances: null,
  gettingPromotionsError: null,
  gettingPromotions: false,
  promotions: null,
  gettingDomainsError: null,
  gettingAccountingAccounts: false,
  gettingDomains: false,
  gettingProductAccounts: false,
  domains: null,
  gettingRolesError: null,
  gettingRoles: false,
  roles: null,
  gettingSurveyTypesError: null,
  gettingSurveyTypes: false,
  surveyTypes: null,
  gettingSportsProductsTypesError: null,
  gettingSportsProductsTypes: false,
  sportsProductsTypes: [],
  gettingSportsProductsError: null,
  gettingSportsProducts: false,
  sportsProducts: [],
  personTypes: null,
  gettingSatProductsError: null,
  gettingSatProducts: false,
  satProducts: null,
  gettingSatUnitsError: null,
  gettingSatUnits: false,
  satUnits: null,
  productAccounts: null,
  memberships: [],
  gettingBanksMonthsWithoutInterestError: null,
  gettingBanksMonthsWithoutInterest: false,
  banksMonthsWithoutInterest: [],
  gettingWaysPayError: null,
  gettingWaysPay: false,
  waysPay: null,
  gettingBankPayTypesError: null,
  gettingBankPayTypes: false,
  bankPayTypes: null,
  gettingCardTypesError: null,
  gettingCardTypes: false,
  cardTypes: null,
  gettingBanksError: null,
  gettingBanks: false,
  banks: null,
  gettingAnnuityTypesError: null,
  gettingAnnuityTypes: false,
  gettingCompaniesTypes: false,
  annuityTypes: null,
  companiesTypes: [],
  schemas: null,

  users: null,
  loadingUsers: false,
  loadingUsersError: null,

  loadingMemberships: false,
  catalogAccrual: [],
  gettingCatalogAccrual: false,

  catalogPostions: null,
  gettingCatalogPostions: false
};

export default function catalogsReducer(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  // Llamada a resolver de snapshot
  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(
  state: State = initialState,
  action: IAction
): Partial<State> {
  switch (action.type) {
    case `${actionTypes.GET_CATALOG_USERS}_PENDING`: {
      return {
        users: null,
        loadingUsers: true
      };
    }
    case `${actionTypes.GET_CATALOG_USERS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        users: null,
        loadingUsers: false
      };
    }
    case `${actionTypes.GET_CATALOG_USERS}_FULFILLED`: {
      return {
        users: action.payload.data,
        loadingUsers: false
      };
    }
    case `${actionTypes.GET_SCHEMAS}_PENDING`: {
      return {
        schemas: null
      };
    }
    case `${actionTypes.GET_SCHEMAS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        schemas: null
      };
    }
    case `${actionTypes.GET_SCHEMAS}_FULFILLED`: {
      return {
        schemas: action.payload.data
      };
    }
    case `${actionTypes.GET_CATALOG_CONCEPTS}_PENDING`: {
      return {
        conceptos: []
      };
    }
    case `${actionTypes.GET_CATALOG_CONCEPTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        conceptos: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.GET_CATALOG_CONCEPTS}_FULFILLED`: {
      return {
        conceptos: action.payload.data
      };
    }
    case `${actionTypes.GET_CATALOG_STATUS}_PENDING`: {
      return {
        ...state
      };
    }
    case `${actionTypes.GET_CATALOG_STATUS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        estatus: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.GET_CATALOG_STATUS}_FULFILLED`: {
      return {
        estatus: action.payload.data
      };
    }
    case `${actionTypes.GET_CATALOG_PAYMENT_TYPES}_PENDING`: {
      return {
        tiposPrecio: []
      };
    }
    case `${actionTypes.GET_CATALOG_PAYMENT_TYPES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        tiposPrecio: []
      };
    }
    case `${actionTypes.GET_CATALOG_PAYMENT_TYPES}_FULFILLED`: {
      return {
        tiposPrecio: action.payload.data
      };
    }
    case `${actionTypes.GET_CATALOG_ACCOUNTING_ACCOUNTS}_PENDING`: {
      return {
        accountingAccounts: null,
        gettingAccountingAccounts: true
      };
    }
    case `${actionTypes.GET_CATALOG_ACCOUNTING_ACCOUNTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        accountingAccounts: null,
        gettingAccountingAccounts: false
      };
    }
    case `${actionTypes.GET_CATALOG_ACCOUNTING_ACCOUNTS}_FULFILLED`: {
      return {
        accountingAccounts: action.payload.data,
        gettingAccountingAccounts: false
      };
    }
    case `${actionTypes.GET_CATALOG_ACCRUAL}_PENDING`: {
      return {
        catalogAccrual: null,
        gettingCatalogAccrual: true
      };
    }
    case `${actionTypes.GET_CATALOG_ACCRUAL}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        catalogAccrual: null,
        gettingCatalogAccrual: false
      };
    }
    case `${actionTypes.GET_CATALOG_ACCRUAL}_FULFILLED`: {
      return {
        catalogAccrual: action.payload.data,
        gettingCatalogAccrual: false
      };
    }
    case `${actionTypes.GET_CATALOG_PRODUCT_ACCOUNTS}_PENDING`: {
      return {
        productAccounts: null,
        gettingProductAccounts: true
      };
    }
    case `${actionTypes.GET_CATALOG_PRODUCT_ACCOUNTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        productAccounts: null,
        gettingProductAccounts: false
      };
    }
    case `${actionTypes.GET_CATALOG_PRODUCT_ACCOUNTS}_FULFILLED`: {
      return {
        productAccounts: action.payload.data,
        gettingProductAccounts: false
      };
    }
    case `${actionTypes.GET_CATALOG_CLUBS}_PENDING`: {
      return {
        gettingClubsError: null,
        gettingClubs: true
      };
    }
    case `${actionTypes.GET_CATALOG_CLUBS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingClubsError: getErrorMessage(action.payload),
        gettingClubs: false
      };
    }
    case `${actionTypes.GET_CATALOG_CLUBS}_FULFILLED`: {
      return {
        gettingClubsError: null,
        clubs: action.payload.data,
        gettingClubs: false
      };
    }
    case `${actionTypes.GET_CATALOG_CLUBS_MIGRADO_ACTIVO}_PENDING`: {
      return {
        gettingClubsMigradoActivoError: null,
        gettingClubsMigradoActivo: true
      };
    }
    case `${actionTypes.GET_CATALOG_CLUBS_MIGRADO_ACTIVO}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingClubsMigradoActivoError: getErrorMessage(action.payload),
        gettingClubsMigradoActivo: false
      };
    }
    case `${actionTypes.GET_CATALOG_CLUBS_MIGRADO_ACTIVO}_FULFILLED`: {
      return {
        gettingClubsMigradoActivoError: null,
        clubsMigradoActivo: action.payload.data,
        gettingClubsMigradoActivo: false
      };
    }
    case `${actionTypes.GET_CATALOG_CLUBS_MIGRADO_INACTIVO}_PENDING`: {
      return {
        gettingClubsMigradoInactivoError: null,
        gettingClubsMigradoInactivo: true
      };
    }
    case `${actionTypes.GET_CATALOG_CLUBS_MIGRADO_INACTIVO}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingClubsMigradoInactivoError: getErrorMessage(action.payload),
        gettingClubsMigradoInactivo: false
      };
    }
    case `${actionTypes.GET_CATALOG_CLUBS_MIGRADO_INACTIVO}_FULFILLED`: {
      return {
        gettingClubsMigradoInactivoError: null,
        clubsMigradoInactivo: action.payload.data,
        gettingClubsMigradoInactivo: false
      };
    }
    case `${actionTypes.GET_CATALOG_AGREEMENTS}_PENDING`: {
      return {
        gettingAgreementsError: null,
        gettingAgreements: true
      };
    }
    case `${actionTypes.GET_CATALOG_AGREEMENTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingAgreementsError: getErrorMessage(action.payload),
        gettingAgreements: false
      };
    }
    case `${actionTypes.GET_CATALOG_AGREEMENTS}_FULFILLED`: {
      return {
        gettingAgreementsError: null,
        agreements: action.payload.data,
        gettingAgreements: false
      };
    }
    case `${actionTypes.GET_CATALOG_SELLERS}_PENDING`: {
      return {
        gettingSellers: true,
        gettingSellersError: null
      };
    }
    case `${actionTypes.GET_CATALOG_SELLERS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingSellers: false,
        gettingSellersError: readMessageBack(action, ERROR)
      };
    }
    case `${actionTypes.GET_CATALOG_SELLERS}_FULFILLED`: {
      // TODO: Verificar salida de servicio
      const data: any[] =
        action.payload.data && Array.isArray(action.payload.data)
          ? (action.payload.data as any[]).filter(
              user => user.status != "SUSPENDED"
            )
          : action.payload.data;

      return {
        sellers: data,
        gettingSellers: false,
        gettingSellersError: null
      };
    }
    case `${actionTypes.GET_CATALOG_MEMBERSHIPS_TYPES}_PENDING`: {
      return {
        gettingMembershipsTypesError: null,
        gettingMembershipsTypes: true
      };
    }
    case `${actionTypes.GET_CATALOG_MEMBERSHIPS_TYPES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingMembershipsTypesError: getErrorMessage(action.payload),
        gettingMembershipsTypes: false
      };
    }
    case `${actionTypes.GET_CATALOG_MEMBERSHIPS_TYPES}_FULFILLED`: {
      return {
        gettingMembershipsTypesError: null,
        membershipsTypes: action.payload.data,
        gettingMembershipsTypes: false
      };
    }
    case `${actionTypes.GET_CATALOG_MEMBERSHIPS}_PENDING`: {
      return {
        memberships: null,
        loadingMemberships: true
      };
    }
    case `${actionTypes.GET_CATALOG_MEMBERSHIPS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        memberships: null,
        loadingMemberships: false
      };
    }
    case `${actionTypes.GET_CATALOG_MEMBERSHIPS}_FULFILLED`: {
      return {
        memberships: action.payload.data,
        loadingMemberships: false
      };
    }
    case `${actionTypes.GET_CATALOG_MAINTENANCES}_PENDING`: {
      return {
        gettingMaintenancesError: null,
        gettingMaintenances: true
      };
    }
    case `${actionTypes.GET_CATALOG_MAINTENANCES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingMaintenancesError: getErrorMessage(action.payload),
        gettingMaintenances: false
      };
    }
    case `${actionTypes.GET_CATALOG_MAINTENANCES}_FULFILLED`: {
      return {
        gettingMaintenancesError: null,
        maintenances: action.payload.data,
        gettingMaintenances: false
      };
    }
    case `${actionTypes.GET_CATALOG_PROMOTIONS}_PENDING`: {
      return {
        gettingPromotionsError: null,
        gettingPromotions: true
      };
    }
    case `${actionTypes.GET_CATALOG_PROMOTIONS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingPromotionsError: getErrorMessage(action.payload),
        gettingPromotions: false
      };
    }
    case `${actionTypes.GET_CATALOG_PROMOTIONS}_FULFILLED`: {
      return {
        gettingPromotionsError: null,
        promotions: action.payload.data,
        gettingPromotions: false
      };
    }
    case `${actionTypes.GET_CATALOG_ROLES}_PENDING`: {
      return {
        gettingRolesError: null,
        gettingRoles: true
      };
    }
    case `${actionTypes.GET_CATALOG_ROLES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingRolesError: getErrorMessage(action.payload),
        gettingRoles: false
      };
    }
    case `${actionTypes.GET_CATALOG_ROLES}_FULFILLED`: {
      return {
        gettingRolesError: null,
        roles: action.payload.data,
        gettingRoles: false
      };
    }
    case `${actionTypes.GET_CATALOG_DOMAINS}_PENDING`: {
      return {
        gettingDomainsError: null,
        gettingDomains: true
      };
    }
    case `${actionTypes.GET_CATALOG_DOMAINS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingDomainsError: getErrorMessage(action.payload),
        gettingDomains: false
      };
    }
    case `${actionTypes.GET_CATALOG_DOMAINS}_FULFILLED`: {
      return {
        gettingDomainsError: null,
        domains: action.payload.data,
        gettingDomains: false
      };
    }
    case `${actionTypes.GET_CATALOG_SURVEY_TYPES}_PENDING`: {
      return {
        gettingSurveyTypesError: null,
        gettingSurveyTypes: true
      };
    }
    case `${actionTypes.GET_CATALOG_SURVEY_TYPES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingSurveyTypesError: getErrorMessage(action.payload),
        gettingSurveyTypes: false
      };
    }
    case `${actionTypes.GET_CATALOG_SURVEY_TYPES}_FULFILLED`: {
      return {
        gettingSurveyTypesError: null,
        surveyTypes: action.payload.data,
        gettingSurveyTypes: false
      };
    }
    case `${actionTypes.GET_CATALOG_SPORTS_PRODUCTS_TYPES}_PENDING`: {
      return {
        gettingSportsProductsTypesError: null,
        gettingSportsProductsTypes: true
      };
    }
    case `${actionTypes.GET_CATALOG_SPORTS_PRODUCTS_TYPES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingSportsProductsTypesError: getErrorMessage(action.payload),
        gettingSportsProductsTypes: false
      };
    }
    case `${actionTypes.GET_CATALOG_SPORTS_PRODUCTS_TYPES}_FULFILLED`: {
      return {
        gettingSportsProductsTypesError: null,
        sportsProductsTypes: action.payload.data,
        gettingSportsProductsTypes: false
      };
    }
    case `${actionTypes.GET_CATALOG_SPORTS_PRODUCTS}_PENDING`: {
      return {
        gettingSportsProductsError: null,
        gettingSportsProducts: true
      };
    }
    case `${actionTypes.GET_CATALOG_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingSportsProductsError: getErrorMessage(action.payload),
        gettingSportsProducts: false
      };
    }
    case `${actionTypes.GET_CATALOG_SPORTS_PRODUCTS}_FULFILLED`: {
      return {
        gettingSportsProductsError: null,
        sportsProducts: action.payload.data,
        gettingSportsProducts: false
      };
    }
    case `${actionTypes.GET_CATALOG_PERSON_TYPES}_PENDING`: {
      return {
        personTypes: null
      };
    }
    case `${actionTypes.GET_CATALOG_PERSON_TYPES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        personTypes: null
      };
    }
    case `${actionTypes.GET_CATALOG_PERSON_TYPES}_FULFILLED`: {
      return {
        personTypes: action.payload.data
      };
    }
    case `${actionTypes.GET_CATALOG_SAT_PRODUCTS}_PENDING`: {
      return {
        gettingSatProductsError: null,
        gettingSatProducts: true
      };
    }
    case `${actionTypes.GET_CATALOG_SAT_PRODUCTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingSatProductsError: getErrorMessage(action.payload),
        gettingSatProducts: false
      };
    }
    case `${actionTypes.GET_CATALOG_SAT_PRODUCTS}_FULFILLED`: {
      return {
        gettingSatProductsError: null,
        satProducts: action.payload.data,
        gettingSatProducts: false
      };
    }
    case `${actionTypes.GET_CATALOG_SAT_UNITS}_PENDING`: {
      return {
        gettingSatUnitsError: null,
        gettingSatUnits: true
      };
    }
    case `${actionTypes.GET_CATALOG_SAT_UNITS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingSatUnitsError: getErrorMessage(action.payload),
        gettingSatUnits: false
      };
    }
    case `${actionTypes.GET_CATALOG_SAT_UNITS}_FULFILLED`: {
      return {
        gettingSatUnitsError: null,
        satUnits: action.payload.data,
        gettingSatUnits: false
      };
    }
    case `${actionTypes.GET_CATALOG_BANKS_MONTHS_WITHOUT_INTEREST}_PENDING`: {
      return {
        gettingBanksMonthsWithoutInterestError: null,
        gettingBanksMonthsWithoutInterest: true
      };
    }
    case `${actionTypes.GET_CATALOG_BANKS_MONTHS_WITHOUT_INTEREST}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingBanksMonthsWithoutInterestError: getErrorMessage(action.payload),
        gettingBanksMonthsWithoutInterest: false
      };
    }
    case `${actionTypes.GET_CATALOG_BANKS_MONTHS_WITHOUT_INTEREST}_FULFILLED`: {
      return {
        gettingBanksMonthsWithoutInterestError: null,
        banksMonthsWithoutInterest: action.payload.data,
        gettingBanksMonthsWithoutInterest: false
      };
    }
    case `${actionTypes.GET_CATALOG_WAYS_PAY}_PENDING`: {
      return {
        gettingWaysPayError: null,
        gettingWaysPay: true
      };
    }
    case `${actionTypes.GET_CATALOG_WAYS_PAY}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingWaysPayError: getErrorMessage(action.payload),
        gettingWaysPay: false
      };
    }
    case `${actionTypes.GET_CATALOG_WAYS_PAY}_FULFILLED`: {
      return {
        gettingWaysPayError: null,
        waysPay: action.payload.data,
        gettingWaysPay: false
      };
    }
    case `${actionTypes.GET_CATALOG_BANK_PAY_TYPES}_PENDING`: {
      return {
        gettingBankPayTypesError: null,
        gettingBankPayTypes: true
      };
    }
    case `${actionTypes.GET_CATALOG_BANK_PAY_TYPES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingBankPayTypesError: getErrorMessage(action.payload),
        gettingBankPayTypes: false
      };
    }
    case `${actionTypes.GET_CATALOG_BANK_PAY_TYPES}_FULFILLED`: {
      return {
        gettingBankPayTypesError: null,
        bankPayTypes: action.payload.data,
        gettingBankPayTypes: false
      };
    }
    case `${actionTypes.GET_CATALOG_CARD_TYPES}_PENDING`: {
      return {
        gettingCardTypesError: null,
        gettingCardTypes: true
      };
    }
    case `${actionTypes.GET_CATALOG_CARD_TYPES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingCardTypesError: getErrorMessage(action.payload),
        gettingCardTypes: false
      };
    }
    case `${actionTypes.GET_CATALOG_CARD_TYPES}_FULFILLED`: {
      return {
        gettingCardTypesError: null,
        cardTypes: action.payload.data,
        gettingCardTypes: false
      };
    }
    case `${actionTypes.GET_CATALOG_BANKS}_PENDING`: {
      return {
        gettingBanksError: null,
        gettingBanks: true
      };
    }
    case `${actionTypes.GET_CATALOG_BANKS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingBanksError: getErrorMessage(action.payload),
        gettingBanks: false
      };
    }
    case `${actionTypes.GET_CATALOG_BANKS}_FULFILLED`: {
      return {
        gettingBanksError: null,
        banks: action.payload.data,
        gettingBanks: false
      };
    }
    case `${actionTypes.GET_CATALOG_ANNUITY_TYPES}_PENDING`: {
      return {
        gettingAnnuityTypesError: null,
        gettingAnnuityTypes: true
      };
    }
    case `${actionTypes.GET_CATALOG_ANNUITY_TYPES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingAnnuityTypesError: getErrorMessage(action.payload),
        gettingAnnuityTypes: false
      };
    }
    case `${actionTypes.GET_CATALOG_ANNUITY_TYPES}_FULFILLED`: {
      return {
        gettingAnnuityTypesError: null,
        annuityTypes: action.payload.data,
        gettingAnnuityTypes: false
      };
    }
    case `${actionTypes.GET_CATALOG_COMPANIES}_PENDING`: {
      return {
        gettingCompaniesError: null,
        gettingCompaniesTypes: true
      };
    }
    case `${actionTypes.GET_CATALOG_COMPANIES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingCompaniesError: getErrorMessage(action.payload),
        gettingCompaniesTypes: false
      };
    }
    case `${actionTypes.GET_CATALOG_COMPANIES}_FULFILLED`: {
      return {
        gettingCompaniesError: null,
        companiesTypes: action.payload.data,
        gettingCompaniesTypes: false
      };
    }

    case `${actionTypes.GET_CATALOG_POSITIONS}_PENDING`: {
      return {
        catalogPostions: null,
        gettingCatalogPostions: true
      };
    }
    case `${actionTypes.GET_CATALOG_POSITIONS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        catalogPostions: null,
        gettingCatalogPostions: false
      };
    }
    case `${actionTypes.GET_CATALOG_POSITIONS}_FULFILLED`: {
      return {
        catalogPostions: action.payload.data,
        gettingCatalogPostions: false
      };
    }

    default: {
      return state;
    }
  }
}

export { initialState };
