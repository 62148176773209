import FormBuilderConfig from "../../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import IDateBuilder from "../../../../../toolkit/baseForms2/iDateBuilder/IDateBuilder";
import ISelectBuilder from "../../../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import ITextBuilder from "../../../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import IClubSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iClubSelectorBuilder/IClubSelectorBuilder";
import IUserSelectorBuilder from "../../../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";
import * as validators from "./CImparticion.validators";
import { userHasPermission } from "../../../../../utils/accessTree";
import permissionCodes from "../../../../../utils/permissionCodes";

const { COMISIONES_V2_MULTICLUB: MULTICLUB } = permissionCodes;

export type Inputs =
  | "tipoBusqueda"
  | "estatus"
  | "idClub"
  | "idProducto"
  | "fechaInicio"
  | "fechaFin"
  | "anio"
  | "mes"
  | "idEmpleado"
  | "folioFactura";
export type Buttons = "buscar";

const anioActual = new Date().getFullYear();

export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "tipoBusqueda",
      label: "* Tipo de búsqueda",
      bts: {
        mCol: 3
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Producto",
        data: [
          { value: "FECHA", label: "Fecha asignada (Clases P.)" },
          { value: "PERIODO", label: "Periodo (Programas Dep.)" },
          { value: "EMPLEADO", label: "Empleado" },
          { value: "FACTURA", label: "Factura" }
        ]
      }
    },
    {
      name: "estatus",
      label: "* Estatus",
      bts: {
        mCol: 3
      },
      component: ISelectBuilder,
      props: {
        hidden: validators.hiddenForEstatus,
        emptyLabel: "Asignada",
        data: [
          { value: "IMPARTIDA", label: "Impartida" },
          { value: "CANCELADA", label: "Cancelada" },
          {
            value: "FALTA_INSTRUCTOR",
            label: "Falta Instructor (Solo Clases)"
          },
          { value: "FALTA_CLIENTE", label: "Falta Cliente (Solo Clases)" },
          { value: "ELIMINADA", label: "Eliminada" }
        ]
      }
    },
    {
      name: "idClub",
      label: "* Club",
      bts: {
        mCol: 6
      },
      component: IClubSelectorBuilder,
      props: {
        hidden: validators.hiddenForClub,
        useSessionClub: true
      }
    },
    {
      name: "idProducto",
      label: "Producto",
      bts: {
        mCol: 6
      },
      props: {
        hidden: validators.hiddenForProducto
      }
    },
    {
      name: "fechaInicio",
      label: "* Fecha de inicio",
      bts: {
        mCol: 3
      },
      component: IDateBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaFecha
      }
    },
    {
      name: "fechaFin",
      label: "* Fecha de fin",
      bts: {
        mCol: 3
      },
      component: IDateBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaFecha
      }
    },
    {
      name: "anio",
      label: "* Año del Periodo",
      bts: {
        mCol: 3
      },
      component: ISelectBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaPeriodo,
        emptyLabel: "Año",
        data: [
          { value: anioActual - 1, label: anioActual - 1 },
          { value: anioActual, label: anioActual },
          { value: anioActual + 1, label: anioActual + 1 }
        ]
      }
    },
    {
      name: "mes",
      label: "* Mes del Periodo",
      bts: {
        mCol: 3
      },
      component: ISelectBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaPeriodo,
        data: [
          monthOption(-3),
          monthOption(-2),
          monthOption(-1),
          monthOption(0),
          monthOption(1)
        ]
      }
    },
    {
      name: "idEmpleado",
      label: "* Empleado",
      bts: {
        mCol: 3
      },
      component: IUserSelectorBuilder,
      props: {
        hidden: validators.hiddenIfNotBusquedaEmpleado
      }
    },
    {
      name: "folioFactura",
      label: "* Folio de factura",
      bts: {
        mCol: 3
      },
      component: ITextBuilder,
      props: {
        emptyLabel: "Seleccione una opción",
        data: [],
        hidden: validators.hiddenIfNotBusquedaFactura
      }
    }
  ],
  buttons: [
    {
      name: "buscar",
      label: "Buscar",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        mOff: 9,
        mCol: 3
      },
      props: {
        addTopMargin: false
      }
    }
  ],
  validations: {
    idClub: {
      requiredIfBusquedaProducto: validators.requiredIfBusquedaProducto,
      requiredIfBusquedaFecha: validators.requiredIfBusquedaFecha,
      requiredIfBusquedaPeriodo: validators.requiredIfBusquedaPeriodo
    },
    fechaInicio: {
      requiredIfBusquedaFecha: validators.requiredIfBusquedaFecha
    },
    fechaFin: {
      requiredIfBusquedaFecha: validators.requiredIfBusquedaFecha
    },
    anio: {
      requiredIfBusquedaPeriodo: validators.requiredIfBusquedaPeriodo
    },
    mes: {
      requiredIfBusquedaPeriodo: validators.requiredIfBusquedaPeriodo
    },
    idEmpleado: {
      requiredIfBusquedaEmpleado: validators.requiredIfBusquedaEmpleado
    },
    folioFactura: {
      requiredIfBusquedaFactura: validators.requiredIfBusquedaFactura
    }
  },
  rows: [
    ["tipoBusqueda", "estatus", "idClub", "idEmpleado", "folioFactura"],
    ["idProducto", "fechaInicio", "fechaFin", "anio", "mes"],
    ["buscar"]
  ]
};

function monthOption(months: number) {
  const fechaNueva = new Date();
  fechaNueva.setMonth(fechaNueva.getMonth() + months);
  const monthNumber = fechaNueva.getMonth() + 1;
  const value = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
  return {
    value,
    label: fechaNueva.toLocaleString("es-MX", { month: "long" }).toUpperCase()
  };
}
