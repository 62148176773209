import { Preview } from "../../../types/IAction";
import { getConvenioMembresiasAsociadas } from "../../actions/convenios";
import { GetConvenioMembresiasAsociadas } from "../../reducers/convenios";

export default interface AgreementsReportDispatcher {
  getConvenioMembresiasAsociadas: (
    params: any
  ) => Promise<Preview<GetConvenioMembresiasAsociadas>>;
}

export const agreementsReportDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => AgreementsReportDispatcher = dispatch => ({
  getConvenioMembresiasAsociadas: (params: any) => {
    return dispatch(getConvenioMembresiasAsociadas(params));
  }
});
