import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import IDateBuilder from "../../../toolkit/baseForms2/iDateBuilder/IDateBuilder";
import IClubSelectorBuilder from "../../../toolkit/baseForms2Commons/iClubSelectorBuilder/IClubSelectorBuilder";
import IEmpresaConvenioSelectorBuilder from "../../../toolkit/baseForms2Commons/iEmpresaConvenioSelectorBuilder/IEmpresaConvenioBuilder";

export type Inputs = "idClub" | "empresa" | "fechaInicio" | "fechaFin";
export type Buttons = "buscar";

export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "idClub",
      label: "Club",
      bts: {
        mCol: 3
      },
      component: IClubSelectorBuilder
    },
    {
      name: "empresa",
      label: "Empresa",
      bts: {
        mCol: 3
      },
      component: IEmpresaConvenioSelectorBuilder,
      props: {
        todas: true
      }
    },
    {
      name: "fechaInicio",
      label: "Fecha Inicio",
      bts: {
        mCol: 3
      },
      component: IDateBuilder
    },
    {
      name: "fechaFin",
      label: "Fecha Fin",
      bts: {
        mCol: 3
      },
      component: IDateBuilder
    }
  ],
  buttons: [
    {
      name: "buscar",
      label: "Buscar",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        mOff: 9,
        mCol: 3
      }
    }
  ],
  validations: {},
  rows: [["idClub", "empresa", "fechaInicio", "fechaFin"], ["buscar"]]
};
