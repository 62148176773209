import FileDownload from "js-file-download";
import { parse } from "json2csv";
import ComisionDeportiva from "../../types/ComisionDeportiva";

const fields = [
  { label: "Transacción", value: "id" },
  { label: "Club", value: "club" },
  { label: "No. empleado", value: "numeroEmpleado" },
  { label: "Empleado", value: "nombreEmpleado" },
  { label: "Descripción", value: "descripcion" },
  { label: "Tipo", value: "tipoComision" },
  { label: "Monto", value: "montoComision" },
  { label: "Factura", value: "factura" }
];

const opts = {
  fields,
  quotes: '"'
};

export default function comisionDeportivaCsv(data: ComisionDeportiva[]) {
  FileDownload(parse(data, opts), "ComisionesDeportivaPendientes.csv");
}
