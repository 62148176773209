import IAction from "../../types/IAction";
import { readMessageBack } from "../../utils/readMessageBack";
import actionTypes from "../actionTypes";

export interface GetCMaqPeriodoAplicable {
  cmaqPeriodoAplicable: any;
  gettingCMaqPeriodoAplicable: boolean;
  gettingCMaqPeriodoAplicableError: string;
}

export interface GetCMaqArchivosMaquila {
  cmaqArchivosMaquila: any[];
  gettingCMaqArchivosMaquila: boolean;
  gettingCMaqArchivosMaquilaError: string;
}

export interface PostCMaqArchivoMaquila {
  postingCMaqArchivoMaquila: boolean;
  postingCMaqArchivoMaquilaError: string;
}

export interface GetCMaqGarantiasDeportivasPuestos {
  cmaqGarantiasDeportivasPuestos: any[];
  gettingCMaqGarantiasDeportivasPuestos: boolean;
  gettingCMaqGarantiasDeportivasPuestosError: string;
}

export interface GetCMaqGarantiasDeportivasEmpleados {
  cmaqGarantiasDeportivasEmpleados: any[];
  gettingCMaqGarantiasDeportivasEmpleados: boolean;
  gettingCMaqGarantiasDeportivasEmpleadosError: string;
}

export interface GetCMaqGarantiasDeportivasClubes {
  cmaqGarantiasDeportivasClubes: any[];
  gettingCMaqGarantiasDeportivasClubes: boolean;
  gettingCMaqGarantiasDeportivasClubesError: string;
}

export interface PostCMaqGarantiasDeportivasPuestos {
  postingCMaqGarantiasDeportivasPuestos: boolean;
  postingCMaqGarantiasDeportivasPuestosError: string;
}

export interface PostCMaqGarantiasDeportivasEmpleados {
  postingCMaqGarantiasDeportivasEmpleados: boolean;
  postingCMaqGarantiasDeportivasEmpleadosError: string;
}

export interface PostCMaqGarantiasDeportivasClubes {
  postingCMaqGarantiasDeportivasClubes: boolean;
  postingCMaqGarantiasDeportivasClubesError: string;
}

export interface DeleteCMaqGarantiasDeportivas {
  deletingCMaqGarantiasDeportivas: boolean;
  deletingCMaqGarantiasDeportivasError: string;
}

interface State
  extends GetCMaqPeriodoAplicable,
    GetCMaqArchivosMaquila,
    PostCMaqArchivoMaquila,
    GetCMaqGarantiasDeportivasPuestos,
    GetCMaqGarantiasDeportivasEmpleados,
    GetCMaqGarantiasDeportivasClubes,
    PostCMaqGarantiasDeportivasPuestos,
    PostCMaqGarantiasDeportivasEmpleados,
    PostCMaqGarantiasDeportivasClubes,
    DeleteCMaqGarantiasDeportivas {}

const initialState: State = {
  cmaqPeriodoAplicable: null,
  gettingCMaqPeriodoAplicable: false,
  gettingCMaqPeriodoAplicableError: null,

  cmaqArchivosMaquila: [],
  gettingCMaqArchivosMaquila: false,
  gettingCMaqArchivosMaquilaError: null,

  postingCMaqArchivoMaquila: false,
  postingCMaqArchivoMaquilaError: null,

  cmaqGarantiasDeportivasPuestos: [],
  gettingCMaqGarantiasDeportivasPuestos: false,
  gettingCMaqGarantiasDeportivasPuestosError: null,

  cmaqGarantiasDeportivasEmpleados: [],
  gettingCMaqGarantiasDeportivasEmpleados: false,
  gettingCMaqGarantiasDeportivasEmpleadosError: null,

  cmaqGarantiasDeportivasClubes: [],
  gettingCMaqGarantiasDeportivasClubes: false,
  gettingCMaqGarantiasDeportivasClubesError: null,

  postingCMaqGarantiasDeportivasPuestos: false,
  postingCMaqGarantiasDeportivasPuestosError: null,

  postingCMaqGarantiasDeportivasEmpleados: false,
  postingCMaqGarantiasDeportivasEmpleadosError: null,

  postingCMaqGarantiasDeportivasClubes: false,
  postingCMaqGarantiasDeportivasClubesError: null,

  deletingCMaqGarantiasDeportivas: false,
  deletingCMaqGarantiasDeportivasError: null
};

export default function comisionesMaquilaV2Reducer(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  // Llamada a resolver de snapshot
  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(state: State = initialState, action: IAction) {
  switch (action.type) {
    // GET_COMISIONES_MAQUILA_V2_PERIODO_APLICABLE
    case `${actionTypes.GET_COMISIONES_MAQUILA_V2_PERIODO_APLICABLE}_PENDING`: {
      return {
        gettingCMaqPeriodoAplicable: true,
        gettingCMaqPeriodoAplicableError: null
      };
    }
    case `${actionTypes.GET_COMISIONES_MAQUILA_V2_PERIODO_APLICABLE}_REJECTED`: {
      return {
        cmaqPeriodoAplicable: null,
        gettingCMaqPeriodoAplicable: false,
        gettingCMaqPeriodoAplicableError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_COMISIONES_MAQUILA_V2_PERIODO_APLICABLE}_FULFILLED`: {
      return {
        cmaqPeriodoAplicable: action.payload.data,
        gettingCMaqPeriodoAplicable: false,
        gettingCMaqPeriodoAplicableError: null
      };
    }

    // GET_COMISIONES_MAQUILA_V2_ARCHIVOS
    case `${actionTypes.GET_COMISIONES_MAQUILA_V2_ARCHIVOS}_PENDING`: {
      return {
        gettingCMaqArchivosMaquila: true,
        gettingCMaqArchivosMaquilaError: null
      };
    }
    case `${actionTypes.GET_COMISIONES_MAQUILA_V2_ARCHIVOS}_REJECTED`: {
      return {
        cmaqArchivosMaquila: [],
        gettingCMaqArchivosMaquila: false,
        gettingCMaqArchivosMaquilaError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_COMISIONES_MAQUILA_V2_ARCHIVOS}_FULFILLED`: {
      return {
        cmaqArchivosMaquila: action.payload.data,
        gettingCMaqArchivosMaquila: false,
        gettingCMaqArchivosMaquilaError: null
      };
    }

    // POST_COMISIONES_MAQUILA_V2_ARCHIVO
    case `${actionTypes.POST_COMISIONES_MAQUILA_V2_ARCHIVO}_PENDING`: {
      return {
        postingCMaqArchivoMaquila: true,
        postingCMaqArchivoMaquilaError: null
      };
    }
    case `${actionTypes.POST_COMISIONES_MAQUILA_V2_ARCHIVO}_REJECTED`: {
      return {
        postingCMaqArchivoMaquila: false,
        postingCMaqArchivoMaquilaError: readMessageBack(action)
      };
    }
    case `${actionTypes.POST_COMISIONES_MAQUILA_V2_ARCHIVO}_FULFILLED`: {
      return {
        postingCMaqArchivoMaquila: false,
        postingCMaqArchivoMaquilaError: null
      };
    }

    // GET_COMISIONES_MAQUILA_V2_GARANTIAS_PUESTOS
    case `${actionTypes.GET_COMISIONES_MAQUILA_V2_GARANTIAS_PUESTOS}_PENDING`: {
      return {
        gettingCMaqGarantiasDeportivasPuestos: true,
        gettingCMaqGarantiasDeportivasPuestosError: null
      };
    }
    case `${actionTypes.GET_COMISIONES_MAQUILA_V2_GARANTIAS_PUESTOS}_REJECTED`: {
      return {
        cmaqGarantiasDeportivasPuestos: [],
        gettingCMaqGarantiasDeportivasPuestos: false,
        gettingCMaqGarantiasDeportivasPuestosError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_COMISIONES_MAQUILA_V2_GARANTIAS_PUESTOS}_FULFILLED`: {
      return {
        cmaqGarantiasDeportivasPuestos: action.payload.data,
        gettingCMaqGarantiasDeportivasPuestos: false,
        gettingCMaqGarantiasDeportivasPuestosError: null
      };
    }

    // GET_COMISIONES_MAQUILA_V2_GARANTIAS_EMPLEADOS
    case `${actionTypes.GET_COMISIONES_MAQUILA_V2_GARANTIAS_EMPLEADOS}_PENDING`: {
      return {
        gettingCMaqGarantiasDeportivasEmpleados: true,
        gettingCMaqGarantiasDeportivasEmpleadosError: null
      };
    }
    case `${actionTypes.GET_COMISIONES_MAQUILA_V2_GARANTIAS_EMPLEADOS}_REJECTED`: {
      return {
        cmaqGarantiasDeportivasEmpleados: [],
        gettingCMaqGarantiasDeportivasEmpleados: false,
        gettingCMaqGarantiasDeportivasEmpleadosError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_COMISIONES_MAQUILA_V2_GARANTIAS_EMPLEADOS}_FULFILLED`: {
      return {
        cmaqGarantiasDeportivasEmpleados: action.payload.data,
        gettingCMaqGarantiasDeportivasEmpleados: false,
        gettingCMaqGarantiasDeportivasEmpleadosError: null
      };
    }

    // GET_COMISIONES_MAQUILA_V2_GARANTIAS_CLUBES
    case `${actionTypes.GET_COMISIONES_MAQUILA_V2_GARANTIAS_CLUBES}_PENDING`: {
      return {
        gettingCMaqGarantiasDeportivasClubes: true,
        gettingCMaqGarantiasDeportivasClubesError: null
      };
    }
    case `${actionTypes.GET_COMISIONES_MAQUILA_V2_GARANTIAS_CLUBES}_REJECTED`: {
      return {
        cmaqGarantiasDeportivasClubes: [],
        gettingCMaqGarantiasDeportivasClubes: false,
        gettingCMaqGarantiasDeportivasClubesError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_COMISIONES_MAQUILA_V2_GARANTIAS_CLUBES}_FULFILLED`: {
      return {
        cmaqGarantiasDeportivasClubes: action.payload.data,
        gettingCMaqGarantiasDeportivasClubes: false,
        gettingCMaqGarantiasDeportivasClubesError: null
      };
    }

    // POST_COMISIONES_MAQUILA_V2_GARANTIAS_PUESTOS
    case `${actionTypes.POST_COMISIONES_MAQUILA_V2_GARANTIAS_PUESTOS}_PENDING`: {
      return {
        postingCMaqGarantiasDeportivasPuestos: true,
        postingCMaqGarantiasDeportivasPuestosError: null
      };
    }
    case `${actionTypes.POST_COMISIONES_MAQUILA_V2_GARANTIAS_PUESTOS}_REJECTED`: {
      return {
        postingCMaqGarantiasDeportivasPuestos: false,
        postingCMaqGarantiasDeportivasPuestosError: readMessageBack(action)
      };
    }
    case `${actionTypes.POST_COMISIONES_MAQUILA_V2_GARANTIAS_PUESTOS}_FULFILLED`: {
      return {
        postingCMaqGarantiasDeportivasPuestos: false,
        postingCMaqGarantiasDeportivasPuestosError: null
      };
    }

    // POST_COMISIONES_MAQUILA_V2_GARANTIAS_EMPLEADOS
    case `${actionTypes.POST_COMISIONES_MAQUILA_V2_GARANTIAS_EMPLEADOS}_PENDING`: {
      return {
        postingCMaqGarantiasDeportivasEmpleados: true,
        postingCMaqGarantiasDeportivasEmpleadosError: null
      };
    }
    case `${actionTypes.POST_COMISIONES_MAQUILA_V2_GARANTIAS_EMPLEADOS}_REJECTED`: {
      return {
        postingCMaqGarantiasDeportivasEmpleados: false,
        postingCMaqGarantiasDeportivasEmpleadosError: readMessageBack(action)
      };
    }
    case `${actionTypes.POST_COMISIONES_MAQUILA_V2_GARANTIAS_EMPLEADOS}_FULFILLED`: {
      return {
        postingCMaqGarantiasDeportivasEmpleados: false,
        postingCMaqGarantiasDeportivasEmpleadosError: null
      };
    }

    // POST_COMISIONES_MAQUILA_V2_GARANTIAS_CLUBES
    case `${actionTypes.POST_COMISIONES_MAQUILA_V2_GARANTIAS_CLUBES}_PENDING`: {
      return {
        postingCMaqGarantiasDeportivasClubes: true,
        postingCMaqGarantiasDeportivasClubesError: null
      };
    }
    case `${actionTypes.POST_COMISIONES_MAQUILA_V2_GARANTIAS_CLUBES}_REJECTED`: {
      return {
        postingCMaqGarantiasDeportivasClubes: false,
        postingCMaqGarantiasDeportivasClubesError: readMessageBack(action)
      };
    }
    case `${actionTypes.POST_COMISIONES_MAQUILA_V2_GARANTIAS_CLUBES}_FULFILLED`: {
      return {
        postingCMaqGarantiasDeportivasClubes: false,
        postingCMaqGarantiasDeportivasClubesError: null
      };
    }

    // DELETE_COMISIONES_MAQUILA_V2_GARANTIAS
    case `${actionTypes.DELETE_COMISIONES_MAQUILA_V2_GARANTIAS}_PENDING`: {
      return {
        deletingCMaqGarantiasDeportivas: true,
        deletingCMaqGarantiasDeportivasError: null
      };
    }
    case `${actionTypes.DELETE_COMISIONES_MAQUILA_V2_GARANTIAS}_REJECTED`: {
      return {
        deletingCMaqGarantiasDeportivas: false,
        deletingCMaqGarantiasDeportivasError: readMessageBack(action)
      };
    }
    case `${actionTypes.DELETE_COMISIONES_MAQUILA_V2_GARANTIAS}_FULFILLED`: {
      return {
        deletingCMaqGarantiasDeportivas: false,
        deletingCMaqGarantiasDeportivasError: null
      };
    }

    default: {
      return state;
    }
  }
}
