import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import constants from "../../utils/constants";
import { restActionFactory } from "../../toolkit/axiosActions/axiosActionsFactory";
import { deleteEmptyProps } from "../../toolkit/utils/filter-utils";
import MaquilaGarantia from "../../types/MaquilaGarantia";
import axios from "axios";
import FileDownload from "js-file-download";
import { errorNotification } from "../../utils/notifications";

const COMISIONES_MAQUILA_BASE_URL = "/api/comisiones-maquila";

export const getPeriodoAplicable = (): IAction => {
  return comisionesMaquilaRestAction({
    actionType: actionTypes.GET_COMISIONES_MAQUILA_V2_PERIODO_APLICABLE,
    urlComplement: "/periodos/periodo-aplicable",
    method: "get"
  });
};

export const getArchivosMaquila = (): IAction => {
  return comisionesMaquilaRestAction({
    actionType: actionTypes.GET_COMISIONES_MAQUILA_V2_ARCHIVOS,
    urlComplement: "",
    method: "get"
  });
};

export const postArchivoMaquila = (fechaPeriodo: any): IAction => {
  return comisionesMaquilaRestAction({
    actionType: actionTypes.POST_COMISIONES_MAQUILA_V2_ARCHIVO,
    urlComplement: `/${fechaPeriodo}`,
    method: "post"
  });
};

export const getGarantiasDeportivasPuestos = (): IAction => {
  return comisionesMaquilaRestAction({
    actionType: actionTypes.GET_COMISIONES_MAQUILA_V2_GARANTIAS_PUESTOS,
    urlComplement: "/garantias-deportivas/puestos",
    method: "get"
  });
};

export const getGarantiasDeportivasEmpleados = (): IAction => {
  return comisionesMaquilaRestAction({
    actionType: actionTypes.GET_COMISIONES_MAQUILA_V2_GARANTIAS_EMPLEADOS,
    urlComplement: "/garantias-deportivas/empleados",
    method: "get"
  });
};

export const getGarantiasDeportivasClubes = (): IAction => {
  return comisionesMaquilaRestAction({
    actionType: actionTypes.GET_COMISIONES_MAQUILA_V2_GARANTIAS_CLUBES,
    urlComplement: "/garantias-deportivas/clubes",
    method: "get"
  });
};

export const postGarantiasDeportivasPuestos = (body: any): IAction => {
  return comisionesMaquilaRestAction({
    actionType: actionTypes.POST_COMISIONES_MAQUILA_V2_GARANTIAS_PUESTOS,
    urlComplement: "/garantias-deportivas/puestos",
    method: "post",
    body: deleteEmptyProps(body)
  });
};

export const postGarantiasDeportivasEmpleados = (body: any): IAction => {
  return comisionesMaquilaRestAction({
    actionType: actionTypes.POST_COMISIONES_MAQUILA_V2_GARANTIAS_EMPLEADOS,
    urlComplement: "/garantias-deportivas/empleados",
    method: "post",
    body: deleteEmptyProps(body)
  });
};

export const postGarantiasDeportivasClubes = (body: any): IAction => {
  return comisionesMaquilaRestAction({
    actionType: actionTypes.POST_COMISIONES_MAQUILA_V2_GARANTIAS_CLUBES,
    urlComplement: "/garantias-deportivas/clubes",
    method: "post",
    body: deleteEmptyProps(body)
  });
};

export const deleteGarantiasDeportivas = (
  garantia: MaquilaGarantia,
  tipo: "PUESTO" | "CLUB" | "EMPLEADO"
): IAction => {
  let urlComplement = "/garantias-deportivas";
  switch (tipo) {
    case "PUESTO":
      urlComplement += `/puestos/${garantia.idPuesto}`;
      break;
    case "CLUB":
      urlComplement += `/clubes/${garantia.idClub}/${garantia.idPuesto}`;
      break;
    case "EMPLEADO":
      urlComplement += `/empleados/${garantia.idEmpleado}`;
  }

  return comisionesMaquilaRestAction({
    actionType: actionTypes.DELETE_COMISIONES_MAQUILA_V2_GARANTIAS,
    urlComplement,
    method: "delete"
  });
};

export const downloadRelacionArchivoMaquila = (
  idArchivoMaquila,
  callback: any
) => {
  return axios
    .get(
      `${constants.BASE_URL}${COMISIONES_MAQUILA_BASE_URL}/relacion/${idArchivoMaquila}`,
      {
        withCredentials: true,
        responseType: "blob"
      }
    )
    .then(({ data }) => {
      FileDownload(data, `RelacionArchivoMaquila.csv`);
      callback && callback();
    })
    .catch(error => {
      errorNotification("No se pudo descargar el reporte");
      callback && callback(error);
    });
};

const comisionesMaquilaRestAction = restActionFactory(
  `${constants.BASE_URL}${COMISIONES_MAQUILA_BASE_URL}`
);
