import { Preview } from "../../../types/IAction";
import MaquilaGarantia from "../../../types/MaquilaGarantia";
import { getPositions } from "../../actions/catalogs";
import {
  getGarantiasDeportivasPuestos,
  getGarantiasDeportivasEmpleados,
  getGarantiasDeportivasClubes,
  postGarantiasDeportivasPuestos,
  postGarantiasDeportivasEmpleados,
  postGarantiasDeportivasClubes,
  deleteGarantiasDeportivas
} from "../../actions/comisionesMaquilaV2";
import {
  GetCMaqGarantiasDeportivasPuestos,
  GetCMaqGarantiasDeportivasEmpleados,
  GetCMaqGarantiasDeportivasClubes,
  PostCMaqGarantiasDeportivasPuestos,
  PostCMaqGarantiasDeportivasEmpleados,
  PostCMaqGarantiasDeportivasClubes,
  DeleteCMaqGarantiasDeportivas
} from "../../reducers/comisionesMaquilaV2";

export interface ComisionesDeportivaGarantiasDispatcher {
  getGarantiasDeportivasPuestos: () => Promise<
    Preview<GetCMaqGarantiasDeportivasPuestos>
  >;
  getGarantiasDeportivasEmpleados: () => Promise<
    Preview<GetCMaqGarantiasDeportivasEmpleados>
  >;
  getGarantiasDeportivasClubes: () => Promise<
    Preview<GetCMaqGarantiasDeportivasClubes>
  >;
  postGarantiasDeportivasPuestos: (
    body: any
  ) => Promise<Preview<PostCMaqGarantiasDeportivasPuestos>>;
  postGarantiasDeportivasEmpleados: (
    body: any
  ) => Promise<Preview<PostCMaqGarantiasDeportivasEmpleados>>;
  postGarantiasDeportivasClubes: (
    body: any
  ) => Promise<Preview<PostCMaqGarantiasDeportivasClubes>>;
  deleteGarantiasDeportivas: (
    garantia: MaquilaGarantia,
    tipo: "PUESTO" | "CLUB" | "EMPLEADO"
  ) => Promise<Preview<DeleteCMaqGarantiasDeportivas>>;
  getPositions: () => Promise<Preview<any>>;
}

export const comisionesDeportivaGarantiasDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => ComisionesDeportivaGarantiasDispatcher = dispatch => ({
  getGarantiasDeportivasPuestos: () => {
    return dispatch(getGarantiasDeportivasPuestos());
  },
  getGarantiasDeportivasEmpleados: () => {
    return dispatch(getGarantiasDeportivasEmpleados());
  },
  getGarantiasDeportivasClubes: () => {
    return dispatch(getGarantiasDeportivasClubes());
  },
  postGarantiasDeportivasPuestos: (body: any) => {
    return dispatch(postGarantiasDeportivasPuestos(body));
  },
  postGarantiasDeportivasEmpleados: (body: any) => {
    return dispatch(postGarantiasDeportivasEmpleados(body));
  },
  postGarantiasDeportivasClubes: (body: any) => {
    return dispatch(postGarantiasDeportivasClubes(body));
  },
  deleteGarantiasDeportivas: (
    garantia: MaquilaGarantia,
    tipo: "PUESTO" | "CLUB" | "EMPLEADO"
  ) => {
    return dispatch(deleteGarantiasDeportivas(garantia, tipo));
  },
  getPositions: () => {
    return dispatch(getPositions());
  }
});
