import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import MaintenancesSelector from "../maintenancesSelector";
import {
  getMassivePricesDownload,
  getMassivePricesDownloadOnParts,
  sendPricesFileRequest
} from "../../actions/products";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import {
  getErrorData,
  getErrorMessage,
  handleRequestError
} from "../../../utils/helpers";
import { debounce } from "lodash";

interface FILE_DATA {
  maintenancesIds: string;
  clubsIds: string;
}
interface Props {
  history: any;
  clubs: { clubId: string; nombre: string }[];
  maintenances: { datosBasicosMantenimientoId: string; nombre: string }[];
  searchMaintenancesByAttributes: ({ text: string }) => any;
  getMassivePricesFile: (data: FILE_DATA) => any;
  sendPricesFile: (pricesFile: Blob, fileName: string) => any;
  setPricesFile: (pricesFile: Blob) => any;
  pricesFileLoaded: boolean;
  fileErrors: string[];
  pricesFile: Blob;
}

interface State {
  pricesFile: Blob;
  disabledButton: boolean;
  selectedMaintenances: object[];
  selectedClubs: object[];
  downloading: boolean;
  uploading: boolean;
  activeTab: string;
  fileErrors: String[];
}

class PricesMaintenancesMassiveLoad extends React.Component<Props, State> {
  state = {
    pricesFile: null,
    disabledButton: true,
    selectedMaintenances: [],
    selectedClubs: [],
    downloading: false,
    uploading: false,
    activeTab: "1",
    fileErrors: null
  };

  dataClubsLoaded = false;

  updateState = ({
    selectedMaintenances = this.state.selectedMaintenances,
    selectedClubs = this.state.selectedClubs
  }) => {
    this.setState({
      selectedMaintenances,
      selectedClubs,
      disabledButton:
        selectedClubs.length === 0 || selectedMaintenances.length === 0
    });
  };

  updateSelection = (mode: string) => {
    switch (mode) {
      case "maintenances":
        return ({ selected: selectedMaintenances }) =>
          this.updateState({ selectedMaintenances });
      case "clubs":
        return ({ selected: selectedClubs }) =>
          this.updateState({ selectedClubs });
      default:
        return () => {};
    }
  };

  getFile = e => {
    e.preventDefault();

    this.setState({ downloading: true });
    getMassivePricesDownloadOnParts(
      this.state.selectedMaintenances,
      this.state.selectedClubs,
      () => this.setState({ downloading: false })
    );
  };

  handleFileChosen = ev => {
    ev.preventDefault();
    this.setState({
      pricesFile: ev.target.files[0],
      uploading: false,
      downloading: false,
      fileErrors: null
    });
  };

  sendFile = () => {
    sendPricesFileRequest(this.state.pricesFile, "precios-mantenimientos.csv")
      .then(({ data }) => {
        this.setState({
          downloading: false,
          uploading: false,
          fileErrors: null,
          pricesFile: null
        });
        successNotification("Precios cargados correctamente");
      })
      .catch((err: any) => {
        const { message, body } = getErrorData(
          err,
          "Ocurrió un error al procesar el archivo."
        );
        this.setState({
          downloading: false,
          uploading: false,
          fileErrors: [...body]
        });
        errorNotification(message);
      });
  };

  debouncedSendFile = debounce(this.sendFile, 2500);

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  render() {
    return (
      <Container className="p-3">
        <Nav className={s.tabs} tabs>
          <NavItem>
            <NavLink
              className={`${this.state.activeTab === "1" ? "active" : ""} ${
                s.tab
              }`}
              onClick={() => {
                this.toggleTab("1");
              }}
            >
              Generar archivo
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={`${this.state.activeTab === "2" ? "active" : ""} ${
                s.tab
              }`}
              onClick={() => {
                this.toggleTab("2");
              }}
            >
              Carga de archivo
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="h-100" activeTab={this.state.activeTab}>
          <TabPane className="my-2" tabId="1">
            <Container className="p-0 m-3">
              <div className="my-1">
                <p className={`${s.primaryTitle} my-2`}>Seleccionar Clubes</p>
                <MaintenancesSelector
                  items={this.props.clubs}
                  selected={this.state.selectedClubs}
                  updateSelection={this.updateSelection("clubs")}
                  keyId="clubId"
                  keyName="nombre"
                />
              </div>
              <div className="my-1">
                <Row className="p-0 m-0">
                  <Col className="p-0 m-0">
                    <p className={`${s.primaryTitle} my-2`}>
                      Seleccionar Mantenimientos
                    </p>
                  </Col>
                </Row>
                <MaintenancesSelector
                  items={this.props.maintenances}
                  updateSelection={this.updateSelection("maintenances")}
                  searchByText={this.props.searchMaintenancesByAttributes}
                  keyId="datosBasicosMantenimientoId"
                  keyName="nombre"
                  selected={this.state.selectedMaintenances}
                />
                <Row className="p-0 m-0">
                  <Col />
                  <Col className="p-0 my-1" xs={2}>
                    <Button
                      className={`${s.buttonMarginTop} ${s.primaryButton}`}
                      onClick={this.getFile}
                      size="sm"
                      disabled={
                        this.state.disabledButton ||
                        this.state.downloading ||
                        this.state.uploading
                      }
                      block
                    >
                      {this.state.downloading || this.state.uploading
                        ? "Espere..."
                        : "Descargar Archivo"}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Container>
          </TabPane>
          <TabPane className="my-2" tabId="2">
            <Container className="p-0 m-3">
              <div className="mt-3 mb-3">
                <Row className={`p-0 m-0`}>
                  <Col xs={4} className="p-0 m-0">
                    <Input
                      type="file"
                      id="pricesFile"
                      accept=".csv"
                      onChange={e => this.handleFileChosen(e)}
                    />
                  </Col>
                  <Col />
                  <Col xs={2} className="p-0 m-0">
                    <Button
                      className={`${s.buttonMarginTop} ${s.primaryButton}`}
                      onClick={e => {
                        e.preventDefault();

                        if (!this.state.pricesFile) {
                          errorNotification("Debe seleccionar un archivo");
                        }

                        infoNotification("Cargando archivo");

                        this.setState({
                          downloading: false,
                          uploading: true,
                          fileErrors: null
                        });

                        this.debouncedSendFile();
                      }}
                      size="sm"
                      disabled={
                        !this.state.pricesFile ||
                        this.state.downloading ||
                        this.state.uploading
                      }
                      block
                    >
                      {this.state.downloading || this.state.uploading
                        ? "Espere..."
                        : " Cargar Archivo"}
                    </Button>
                  </Col>
                </Row>
                {this.state.fileErrors && (
                  <div className="alert alert-danger p-2 m-0 mt-3">
                    {this.state.fileErrors.map(error => (
                      <p className="my-1">{error}</p>
                    ))}
                  </div>
                )}
              </div>
            </Container>
          </TabPane>
        </TabContent>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(PricesMaintenancesMassiveLoad));
