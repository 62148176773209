import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";

export type Inputs = "monto";
export type Buttons = "aceptar" | "cancelar";

export const formConfigMonto: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "monto",
      label: "* Monto",
      bts: {
        mCol: 12
      },
      component: ITextBuilder
    }
  ],
  buttons: [
    {
      name: "aceptar",
      label: "Aceptar",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        mOff: 4,
        mCol: 4
      }
    },
    {
      name: "cancelar",
      label: "Cancelar",
      component: IButtonBuilder,
      bts: {
        mCol: 4
      }
    }
  ],
  validations: {
    monto: {
      required: true
    }
  },
  rows: [["monto"], ["aceptar", "cancelar"]]
};
