import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  errorNotification,
  infoNotification,
  REASIGN_MEMBERSHIP_SUCCESS,
  REPORTS_COMISSIONS_ERROR,
  REPORTS_COMISSIONS_PENDING,
  REASIGN_MEMBERSHIP_ERROR,
  REASIGN_MEMBERSHIP_PENDING,
  successNotification,
  AUTHORIZE_COMISSION_INVALID_STATUS,
  AUTHORIZE_COMISSION_ERROR,
  AUTHORIZE_COMISSION_FULFILLED,
  AUTHORIZE_COMISSION_PENDING,
  MESSAGE_REPORT_WAY_TO_PAY_PENDING,
  MESSAGE_REPORT_WAY_TO_PAY_SUCCESS,
  MESSAGE_GET_CASH_CUT_PENDING,
  MESSAGE_GET_CASH_CUT_ERROR,
  MESSAGE_GET_CASH_CUT_SUCCESS,
  MESSAGE_GET_SHIFT_DELIVERY_PENDING,
  MESSAGE_GET_SHIFT_DELIVERY_ERROR,
  MESSAGE_GET_SHIFT_DELIVERY_SUCCESS,
  MESSAGE_GET_CUSTOMIZABLE_RECEIPT_PENDING,
  MESSAGE_GET_CUSTOMIZABLE_RECEIPT_ERROR,
  MESSAGE_GET_CUSTOMIZABLE_RECEIPT_SUCCESS,
  HISTORICAL_REBILLING_SUCCESS,
  HISTORICAL_REBILLING_PENDING,
  HISTORICAL_REBILLING_ERROR,
  MESSAGE_REFUSE_CUSTOMIZABLE_RECEIPT_PENDING,
  MESSAGE_REFUSE_CUSTOMIZABLE_RECEIPT_ERROR,
  MESSAGE_REFUSE_CUSTOMIZABLE_RECEIPT_SUCCESS,
  MESSAGE_VALIDATE_CUSTOMIZABLE_RECEIPT_PENDING,
  MESSAGE_VALIDATE_CUSTOMIZABLE_RECEIPT_ERROR,
  MESSAGE_VALIDATE_CUSTOMIZABLE_RECEIPT_SUCCESS,
  MESSAGE_GET_SALES_PAYABLE_PENDING,
  MESSAGE_GET_SALES_PAYABLE_ERROR,
  MESSAGE_GET_SALES_PAYABLE_SUCCESS,
  MESSAGE_GET_CREDIT_NOTES_PENDING,
  MESSAGE_GET_CREDIT_NOTES_ERROR,
  MESSAGE_GET_CREDIT_NOTES_SUCCESS,
  MESSAGE_PROCESS_CREDIT_NOTE_PENDING,
  MESSAGE_PROCESS_CREDIT_NOTE_ERROR,
  MESSAGE_PROCESS_CREDIT_NOTE_SUCCESS,
  ERROR,
  GET_DEPOSITO_REFERENCIADO_FULFILLED,
  GET_DEPOSITO_REFERENCIADO_PENDING,
  GET_DEPOSITO_REFERENCIADO_REJECTED,
  MESSAGE_PDF_REFERENCED_DEPOSIT_PENDING,
  MESSAGE_PDF_REFERENCED_DEPOSIT_ERROR,
  MESSAGE_PDF_REFERENCED_DEPOSIT_SUCCESS,
  REJECT_CREDIT_NOTE_DEMAND_PENDING,
  REJECT_CREDIT_NOTE_DEMAND_ERROR,
  REJECT_CREDIT_NOTE_DEMAND_SUCCESS,
  MESSAGE_REFERENCE_SANTANDER_REPORT_SUCCESS,
  MESSAGE_REFERENCE_SANTANDER_REPORT_PENDING,
  MESSAGE_REFERENCE_SANTANDER_REPORT_ERROR,
  MESSAGE_AUTHORIZE_REFOUND_SUCCESS,
  MESSAGE_AUTHORIZE_REFOUND_PENDING,
  MESSAGE_AUTHORIZE_REFOUND_ERROR,
  MESSAGE_REFUSE_REFOUND_SUCCESS,
  MESSAGE_REFUSE_REFOUND_PENDING,
  MESSAGE_REFUSE_REFOUND_ERROR,
  MESSAGE_CHARGEBACK_REFOUND_SUCCESS,
  MESSAGE_CHARGEBACK_REFOUND_PENDING,
  MESSAGE_CHARGEBACK_REFOUND_ERROR,
  MESSAGE_REFERENCE_CONSECUTIVE_CLUB_SUCCESS,
  MESSAGE_REFERENCE_CONSECUTIVE_CLUB_PENDING,
  MESSAGE_REFERENCE_CONSECUTIVE_CLUB_ERROR,
  MESSAGE_CREATE_REFERENCES_BY_CLUB_SUCCESS,
  MESSAGE_CREATE_REFERENCES_BY_CLUB_PENDING,
  MESSAGE_CREATE_REFERENCES_BY_CLUB_ERROR,
  MESSAGE_GET_REFUNDS_REPORT_SUCCESS,
  MESSAGE_GET_REFUNDS_REPORT_PENDING,
  MESSAGE_GET_REFUNDS_REPORT_ERROR
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
import error from "../../toolkit/error";
interface State {
  reportComissions: object[];
  reportReactivationComissions: object[];
  loadingReport: boolean;
  reportMembershipSold: object[];
  sportsProgramCommisions: object[];
  reportAccountingAccount: object[];
  reportTotalSales: object[];
  reportAmountSales: object[];
  reportWayToPay: object[];
  loadingSportsProgramCommisions: boolean;
  loadingAccountingAccount: boolean;
  loadingTotalSales: boolean;
  loadingAmountSales: boolean;
  loadingWayToPay: boolean;
  reasignMembership: boolean;
  currentEstatus: String;
  loadingIngressByClubReport: boolean;
  ingressByClubReport: object[];
  loadingCashCut: boolean;
  reportCashCut: object[];
  loadingShiftDelivery: boolean;
  reportShiftDelivery: object[];
  loadingSalesPayable: boolean;
  reportSalesPayable: object[];
  loadingCreditNotes: boolean;
  reportCreditNotes: object[];
  loadingProcessCreditNote: boolean;
  reportProcessCreditNote: object[];
  loadingCustomizableReceipt: boolean;
  reportCustomizableReceipt: object[];
  loadingRefuseCustomReceipt: boolean;
  depositoReferenciado: object[];
  loadingDepositoReferenciado: boolean;
  listHistoricalRebilling: object[];
  loadingRFCR: boolean;
  loadingVCR: boolean;
  objRFCR: object[];
  objVCR: object[];
  rejectCreditNote: boolean;
  reportReference: object[];
  reportRefunds: object[];
  resAuthorizeRefund: object[];
  resRefuseRefund: object[];
  resChargebackefund: object[];
  referenceConsecutive: String;
  referencesByClub: object[];
  reportReferenceDA: object[];
  reportEmployees: object[];

  // Carga de comisiones de venta
  loadingSaleComissions: boolean;
  saleComissions: any[];

  // Datos de Reenvío de Solicitudes de Cancelación
  postReenvioSolicitud: any;
  postingReenvioSolicitud: boolean;
  postingReenvioSolicitudError: string;
}
const initialState: State = {
  reportComissions: null,
  reportReactivationComissions: null,
  loadingReport: false,
  reportMembershipSold: null,
  reportWayToPay: null,
  sportsProgramCommisions: null,
  reportAccountingAccount: null,
  reportTotalSales: null,
  reportAmountSales: null,
  loadingSportsProgramCommisions: false,
  loadingAccountingAccount: false,
  loadingTotalSales: false,
  loadingAmountSales: false,
  loadingWayToPay: false,
  reasignMembership: null,
  currentEstatus: null,
  loadingIngressByClubReport: false,
  ingressByClubReport: null,
  loadingCashCut: false,
  reportCashCut: null,
  loadingShiftDelivery: false,
  reportShiftDelivery: null,
  loadingSalesPayable: false,
  reportSalesPayable: null,
  loadingCreditNotes: false,
  reportCreditNotes: null,
  loadingProcessCreditNote: false,
  reportProcessCreditNote: null,
  loadingCustomizableReceipt: false,
  reportCustomizableReceipt: null,
  loadingRefuseCustomReceipt: false,
  depositoReferenciado: [],
  loadingDepositoReferenciado: false,
  listHistoricalRebilling: [],
  loadingRFCR: false,
  loadingVCR: false,
  objRFCR: [],
  objVCR: [],
  rejectCreditNote: false,
  reportReference: [],
  reportRefunds: [],
  resAuthorizeRefund: [],
  resRefuseRefund: [],
  resChargebackefund: [],
  referenceConsecutive: "",
  referencesByClub: [],
  reportReferenceDA: [],
  reportEmployees: [],
  loadingSaleComissions: false,
  saleComissions: [],

  // Datos de Reenvío de Solicitudes de Cancelación
  postReenvioSolicitud: null,
  postingReenvioSolicitud: false,
  postingReenvioSolicitudError: null
};

export default function reports(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.GET_REPORT_COMISSION}_PENDING`: {
      infoNotification("Consultando comisiones...");
      return {
        ...state,
        loadingReport: false,
        reportComissions: null
      };
    }
    case `${actionTypes.GET_REPORT_COMISSION}_REJECTED`: {
      errorNotification(readMessageBack(action, REPORTS_COMISSIONS_ERROR));
      return {
        ...state,
        loadingReport: true,
        reportComissions: null
      };
    }
    case `${actionTypes.GET_REPORT_COMISSION}_FULFILLED`: {
      successNotification("Comisiones consultadas correctamente");
      return {
        ...state,
        loadingReport: false,
        reportComissions: action.payload.data.content,
        currentEstatus: action.payload.data.estatus
      };
    }
    case `${actionTypes.GET_REPORT_COMISSION_SALE}_PENDING`: {
      infoNotification("Consultando comisiones...");
      return {
        ...state,
        loadingSaleComissions: true,
        saleComissions: []
      };
    }
    case `${actionTypes.GET_REPORT_COMISSION_SALE}_REJECTED`: {
      errorNotification(readMessageBack(action, REPORTS_COMISSIONS_ERROR));
      return {
        ...state,
        loadingSaleComissions: false,
        saleComissions: []
      };
    }
    case `${actionTypes.GET_REPORT_COMISSION_SALE}_FULFILLED`: {
      successNotification("Comisiones consultadas correctamente");
      return {
        ...state,
        loadingSaleComissions: false,
        saleComissions: action.payload.data
      };
    }
    case `${actionTypes.GET_REPORT_REACTIVATION_COMISSION}_PENDING`: {
      infoNotification("Consultando comisiones...");
      return {
        ...state,
        loadingReport: true,
        reportReactivationComissions: null
      };
    }
    case `${actionTypes.GET_REPORT_REACTIVATION_COMISSION}_REJECTED`: {
      errorNotification(readMessageBack(action, REPORTS_COMISSIONS_ERROR));
      return {
        ...state,
        loadingReport: false,
        reportReactivationComissions: null
      };
    }
    case `${actionTypes.GET_REPORT_REACTIVATION_COMISSION}_FULFILLED`: {
      successNotification("Comisiones consultadas correctamente");
      return {
        ...state,
        loadingReport: false,
        reportReactivationComissions: action.payload.data.content,
        currentEstatus: action.payload.data.estatus
      };
    }
    case `${actionTypes.CLEAR_REPORT_REACTIVATION_COMISSION}`: {
      return {
        ...state,
        loadingReport: false,
        reportReactivationComissions: null
      };
    }
    case `${actionTypes.GET_REPORT_MEMBERSHIP_SOLD}_PENDING`: {
      infoNotification("Consultando membresías vendidas...");
      return {
        ...state,
        loadingReport: false,
        reportMembershipSold: null
      };
    }
    case `${actionTypes.GET_REPORT_MEMBERSHIP_SOLD}_REJECTED`: {
      errorNotification(readMessageBack(action, REPORTS_COMISSIONS_ERROR));
      return {
        ...state,
        loadingReport: true,
        reportMembershipSold: null
      };
    }
    case `${actionTypes.GET_REPORT_MEMBERSHIP_SOLD}_FULFILLED`: {
      successNotification("Membresías consultadas correctamente");
      return {
        ...state,
        loadingReport: false,
        reportMembershipSold: action.payload.data
      };
    }

    case `${actionTypes.REASIGN_MEMBERSHIP}_PENDING`: {
      infoNotification(REASIGN_MEMBERSHIP_PENDING);
      return {
        ...state,
        loadingReport: false,
        reasignMembership: null
      };
    }
    case `${actionTypes.REASIGN_MEMBERSHIP}_REJECTED`: {
      errorNotification(readMessageBack(action, REASIGN_MEMBERSHIP_ERROR));
      return {
        ...state,
        loadingReport: true,
        reasignMembership: null
      };
    }
    case `${actionTypes.REASIGN_MEMBERSHIP}_FULFILLED`: {
      successNotification(REASIGN_MEMBERSHIP_SUCCESS);
      return {
        ...state,
        loadingReport: false,
        reasignMembership: true
      };
    }
    case `${actionTypes.CLEAR_REASIGN_MEMBERSHIP}`: {
      return {
        ...state,
        loadingReport: false,
        reasignMembership: null,
        reportMembershipSold: null,
        depositoReferenciado: [],
        reportCustomizableReceipt: [],
        reportCreditNotes: null,
        referenceConsecutive: "",
        referencesByClub: []
      };
    }
    case `${actionTypes.AUTHORIZE_COMISSION}_PENDING`: {
      infoNotification(AUTHORIZE_COMISSION_PENDING);
      return {
        ...state,
        loadingReport: true
      };
    }
    case `${actionTypes.AUTHORIZE_COMISSION}_REJECTED`: {
      errorNotification(readMessageBack(action, AUTHORIZE_COMISSION_ERROR));
      return {
        ...state,
        loadingReport: false
      };
    }
    case `${actionTypes.AUTHORIZE_COMISSION}_FULFILLED`: {
      if (action.payload.data.comisionesId.length === 0) {
        errorNotification(AUTHORIZE_COMISSION_INVALID_STATUS);
        return state;
      }
      successNotification(AUTHORIZE_COMISSION_FULFILLED);
      let newReportComissions = state.reportComissions;
      const { currentEstatus } = state;
      const { data } = action.payload;
      if (!!currentEstatus) {
        if (currentEstatus !== data.estatus)
          newReportComissions = newReportComissions.filter(
            comi => !data.comisionesId.includes(comi.id)
          );
      } else {
        for (let i = 0; i < newReportComissions.length; i++) {
          if (data.comisionesId.includes(newReportComissions[i].id))
            newReportComissions[i].estatus = data.estatus;
        }
      }
      return {
        ...state,
        loadingReport: false,
        reportComissions: newReportComissions
      };
    }
    case `${actionTypes.GET_REPORT_SPORTS_PROGRAM_COMMISSIONS}_PENDING`: {
      infoNotification(REPORTS_COMISSIONS_PENDING);
      return {
        ...state,
        loadingSportsProgramCommisions: false,
        sportsProgramCommisions: null
      };
    }
    case `${actionTypes.GET_REPORT_SPORTS_PROGRAM_COMMISSIONS}_FULFILLED`: {
      return {
        ...state,
        sportsProgramCommisions: action.payload.data,
        loadingSportsProgramCommisions: false
      };
    }
    case `${actionTypes.GET_REPORT_SPORTS_PROGRAM_COMMISSIONS}_REJECTED`: {
      errorNotification(readMessageBack(action, REPORTS_COMISSIONS_ERROR));
      return {
        ...state,
        loadingSportsProgramCommisions: true,
        sportsProgramCommisions: null
      };
    }
    case `${actionTypes.GET_DEPOSITO_REFERENCIADO}_PENDING`: {
      infoNotification(GET_DEPOSITO_REFERENCIADO_PENDING);
      return {
        ...state,
        loadingDepositoReferenciado: false,
        depositoReferenciado: []
      };
    }
    case `${actionTypes.GET_DEPOSITO_REFERENCIADO}_FULFILLED`: {
      // successNotification(GET_DEPOSITO_REFERENCIADO_FULFILLED);
      return {
        ...state,
        loadingDepositoReferenciado: false,
        depositoReferenciado: action.payload.data
      };
    }
    case `${actionTypes.GET_DEPOSITO_REFERENCIADO}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GET_DEPOSITO_REFERENCIADO_REJECTED)
      );
      return {
        ...state,
        depositoReferenciado: [],
        loadingDepositoReferenciado: true
      };
    }
    case `${actionTypes.GET_REPORT_ACCOUNTING_ACCOUNT}_PENDING`: {
      infoNotification(MESSAGE_REPORT_WAY_TO_PAY_PENDING);
      return {
        ...state,
        loadingAccountingAccount: true,
        reportAccountingAccount: null
      };
    }
    case `${actionTypes.GET_REPORT_ACCOUNTING_ACCOUNT}_REJECTED`: {
      errorNotification(readMessageBack(action, REPORTS_COMISSIONS_ERROR));
      return {
        ...state,
        loadingAccountingAccount: false,
        reportAccountingAccount: null
      };
    }
    case `${actionTypes.GET_REPORT_ACCOUNTING_ACCOUNT}_FULFILLED`: {
      successNotification(MESSAGE_REPORT_WAY_TO_PAY_SUCCESS);
      return {
        ...state,
        loadingAccountingAccount: false,
        reportAccountingAccount: action.payload ? action.payload.data : []
      };
    }
    case `${actionTypes.GET_TOTAL_SALES_REPORT}_PENDING`: {
      infoNotification(MESSAGE_REPORT_WAY_TO_PAY_PENDING);
      return {
        ...state,
        loadingTotalSales: true,
        reportTotalSales: null
      };
    }
    case `${actionTypes.GET_TOTAL_SALES_REPORT}_REJECTED`: {
      errorNotification(readMessageBack(action, REPORTS_COMISSIONS_ERROR));
      return {
        ...state,
        loadingTotalSales: false,
        reportTotalSales: null
      };
    }
    case `${actionTypes.GET_TOTAL_SALES_REPORT}_FULFILLED`: {
      successNotification(MESSAGE_REPORT_WAY_TO_PAY_SUCCESS);
      return {
        ...state,
        loadingTotalSales: false,
        reportTotalSales: action.payload ? action.payload.data : []
      };
    }
    case `${actionTypes.GET_CASH_CUT}_PENDING`: {
      infoNotification(MESSAGE_GET_CASH_CUT_PENDING);
      return {
        ...state,
        loadingCashCut: false,
        reportCashCut: null
      };
    }
    case `${actionTypes.GET_CASH_CUT}_REJECTED`: {
      errorNotification(readMessageBack(action, MESSAGE_GET_CASH_CUT_ERROR));
      return {
        ...state,
        loadingCashCut: true,
        reportCashCut: null
      };
    }
    case `${actionTypes.GET_CASH_CUT}_FULFILLED`: {
      successNotification(MESSAGE_GET_CASH_CUT_SUCCESS);
      return {
        ...state,
        loadingCashCut: false,
        reportCashCut: action.payload ? action.payload.data : []
      };
    }
    case `${actionTypes.CLEAR_CASH_CUT}`: {
      return {
        ...state,
        reportCashCut: null
      };
    }
    case `${actionTypes.PDF_CASH_CUT}_PENDING`: {
      infoNotification(MESSAGE_GET_CASH_CUT_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.PDF_CASH_CUT}_REJECTED`: {
      errorNotification(readMessageBack(action, MESSAGE_GET_CASH_CUT_ERROR));
      return {
        ...state
      };
    }
    case `${actionTypes.PDF_CASH_CUT}_FULFILLED`: {
      successNotification(MESSAGE_GET_CASH_CUT_SUCCESS);
      return {
        ...state
      };
    }
    case `${actionTypes.PDF_SHIFT_DELIVERY}_PENDING`: {
      infoNotification(MESSAGE_GET_CASH_CUT_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.PDF_SHIFT_DELIVERY}_REJECTED`: {
      errorNotification(readMessageBack(action, MESSAGE_GET_CASH_CUT_ERROR));
      return {
        ...state
      };
    }
    case `${actionTypes.PDF_SHIFT_DELIVERY}_FULFILLED`: {
      successNotification(MESSAGE_GET_CASH_CUT_SUCCESS);
      return {
        ...state
      };
    }

    case `${actionTypes.PDF_PROVITIONAL_RECEIPT}_PENDING`: {
      infoNotification(MESSAGE_GET_CASH_CUT_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.PDF_PROVITIONAL_RECEIPT}_REJECTED`: {
      errorNotification(readMessageBack(action, MESSAGE_GET_CASH_CUT_ERROR));
      return {
        ...state
      };
    }
    case `${actionTypes.PDF_PROVITIONAL_RECEIPT}_FULFILLED`: {
      successNotification(MESSAGE_GET_CASH_CUT_SUCCESS);
      return {
        ...state
      };
    }

    case `${actionTypes.PDF_REFERENCED_DEPOSIT}_PENDING`: {
      infoNotification(MESSAGE_PDF_REFERENCED_DEPOSIT_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.PDF_REFERENCED_DEPOSIT}_REJECTED`: {
      errorNotification(
        readMessageBack(action, MESSAGE_PDF_REFERENCED_DEPOSIT_ERROR)
      );
      return {
        ...state
      };
    }
    case `${actionTypes.REJECT_CREDIT_NOTE_DEMAND}_FULFILLED`: {
      successNotification(REJECT_CREDIT_NOTE_DEMAND_SUCCESS);
      return {
        ...state,
        rejectCreditNote: true
      };
    }
    case `${actionTypes.REJECT_CREDIT_NOTE_DEMAND}_PENDING`: {
      infoNotification(REJECT_CREDIT_NOTE_DEMAND_PENDING);
      return {
        ...state,
        rejectCreditNote: false
      };
    }
    case `${actionTypes.REJECT_CREDIT_NOTE_DEMAND}_REJECTED`: {
      errorNotification(
        readMessageBack(action, REJECT_CREDIT_NOTE_DEMAND_ERROR)
      );
      return {
        ...state,
        rejectCreditNote: false
      };
    }
    case `${actionTypes.RETRY_CREDIT_NOTE_REQUEST}_FULFILLED`: {
      successNotification(action.payload.data.message);
      return {
        ...state,
        postReenvioSolicitud: action.payload.data,
        postingReenvioSolicitud: false,
        postingReenvioSolicitudError: null
      };
    }
    case `${actionTypes.RETRY_CREDIT_NOTE_REQUEST}_PENDING`: {
      infoNotification("Enviando solicitud...");
      return {
        ...state,
        postReenvioSolicitud: null,
        postingReenvioSolicitud: true,
        postingReenvioSolicitudError: null
      };
    }
    case `${actionTypes.RETRY_CREDIT_NOTE_REQUEST}_REJECTED`: {
      const message = readMessageBack(
        action,
        "No se pudo enviar la solicitud, inténtelo más tarde"
      );
      errorNotification(message);
      return {
        ...state,
        postReenvioSolicitud: null,
        postingReenvioSolicitud: false,
        postingReenvioSolicitudError: message
      };
    }
    case `${actionTypes.PDF_REFERENCED_DEPOSIT}_FULFILLED`: {
      successNotification(MESSAGE_PDF_REFERENCED_DEPOSIT_SUCCESS);
      return {
        ...state
      };
    }
    case `${actionTypes.GET_SHIFT_DELIVERY}_PENDING`: {
      infoNotification(MESSAGE_GET_SHIFT_DELIVERY_PENDING);
      return {
        ...state,
        loadingShiftDelivery: false,
        reportShiftDelivery: null
      };
    }
    case `${actionTypes.GET_SHIFT_DELIVERY}_REJECTED`: {
      errorNotification(
        readMessageBack(action, MESSAGE_GET_SHIFT_DELIVERY_ERROR)
      );
      return {
        ...state,
        loadingShiftDelivery: true,
        reportShiftDelivery: null
      };
    }
    case `${actionTypes.GET_SHIFT_DELIVERY}_FULFILLED`: {
      successNotification(MESSAGE_GET_SHIFT_DELIVERY_SUCCESS);
      return {
        ...state,
        loadingShiftDelivery: false,
        reportShiftDelivery: action.payload ? action.payload.data : []
      };
    }

    case `${actionTypes.GET_CUSTOMIZABLE_RECEIPT}_PENDING`: {
      infoNotification(MESSAGE_GET_CUSTOMIZABLE_RECEIPT_PENDING);
      return {
        ...state,
        loadingCustomizableReceipt: false,
        reportCustomizableReceipt: null
      };
    }
    case `${actionTypes.GET_CUSTOMIZABLE_RECEIPT}_REJECTED`: {
      errorNotification(
        readMessageBack(action, MESSAGE_GET_CUSTOMIZABLE_RECEIPT_ERROR)
      );
      return {
        ...state,
        loadingCustomizableReceipt: true,
        reportCustomizableReceipt: null
      };
    }
    case `${actionTypes.GET_CUSTOMIZABLE_RECEIPT}_FULFILLED`: {
      successNotification(MESSAGE_GET_CUSTOMIZABLE_RECEIPT_SUCCESS);
      return {
        ...state,
        loadingCustomizableReceipt: false,
        reportCustomizableReceipt: action.payload ? action.payload.data : []
      };
    }

    case `${actionTypes.HISTORICAL_REBILLING}_PENDING`: {
      infoNotification(HISTORICAL_REBILLING_PENDING);
      return {
        ...state,
        listHistoricalRebilling: null
      };
    }
    case `${actionTypes.HISTORICAL_REBILLING}_REJECTED`: {
      errorNotification(readMessageBack(action, HISTORICAL_REBILLING_ERROR));
      return {
        ...state,
        listHistoricalRebilling: null
      };
    }
    case `${actionTypes.HISTORICAL_REBILLING}_FULFILLED`: {
      successNotification(HISTORICAL_REBILLING_SUCCESS);
      return {
        ...state,
        listHistoricalRebilling: action.payload ? action.payload.data : []
      };
    }

    case `${actionTypes.REFUSE_CUSTOMIZABLE_RECEIPT}_PENDING`: {
      infoNotification(MESSAGE_REFUSE_CUSTOMIZABLE_RECEIPT_PENDING);
      return {
        ...state,
        loadingRFCR: false,
        objRFCR: null
      };
    }
    case `${actionTypes.REFUSE_CUSTOMIZABLE_RECEIPT}_REJECTED`: {
      errorNotification(
        readMessageBack(action, MESSAGE_REFUSE_CUSTOMIZABLE_RECEIPT_ERROR)
      );
      return {
        ...state,
        loadingRFCR: false,
        objRFCR: null
      };
    }
    case `${actionTypes.REFUSE_CUSTOMIZABLE_RECEIPT}_FULFILLED`: {
      successNotification(MESSAGE_REFUSE_CUSTOMIZABLE_RECEIPT_SUCCESS);
      return {
        ...state,
        loadingRFCR: true,
        objRFCR: action.payload ? action.payload.data : []
      };
    }

    case `${actionTypes.VALIDATE_CUSTOMIZABLE_RECEIPT}_PENDING`: {
      infoNotification(MESSAGE_VALIDATE_CUSTOMIZABLE_RECEIPT_PENDING);
      return {
        ...state,
        loadingVCR: false,
        objVCR: null
      };
    }
    case `${actionTypes.VALIDATE_CUSTOMIZABLE_RECEIPT}_REJECTED`: {
      errorNotification(
        readMessageBack(action, MESSAGE_VALIDATE_CUSTOMIZABLE_RECEIPT_ERROR)
      );
      return {
        ...state,
        loadingVCR: false,
        objVCR: null
      };
    }
    case `${actionTypes.VALIDATE_CUSTOMIZABLE_RECEIPT}_FULFILLED`: {
      successNotification(MESSAGE_VALIDATE_CUSTOMIZABLE_RECEIPT_SUCCESS);
      return {
        ...state,
        loadingVCR: true,
        objVCR: action.payload ? action.payload.data : []
      };
    }

    case `${actionTypes.GET_CREDIT_NOTES}_PENDING`: {
      infoNotification(MESSAGE_GET_CREDIT_NOTES_PENDING);
      return {
        ...state,
        loadingCreditNotes: true,
        reportCreditNotes: null
      };
    }
    case `${actionTypes.GET_CREDIT_NOTES}_REJECTED`: {
      errorNotification(
        readMessageBack(action, MESSAGE_GET_CREDIT_NOTES_ERROR)
      );
      return {
        ...state,
        loadingCreditNotes: false,
        reportCreditNotes: null
      };
    }
    case `${actionTypes.GET_CREDIT_NOTES}_FULFILLED`: {
      successNotification(MESSAGE_GET_CREDIT_NOTES_SUCCESS);
      return {
        ...state,
        loadingCreditNotes: false,
        reportCreditNotes: action.payload ? action.payload.data : []
      };
    }

    case `${actionTypes.GET_SALES_PAYABLE}_PENDING`: {
      infoNotification(MESSAGE_GET_SALES_PAYABLE_PENDING);
      return {
        ...state,
        loadingSalesPayable: false,
        reportSalesPayable: null
      };
    }
    case `${actionTypes.GET_SALES_PAYABLE}_REJECTED`: {
      errorNotification(
        readMessageBack(action, MESSAGE_GET_SALES_PAYABLE_ERROR)
      );
      return {
        ...state,
        loadingSalesPayable: true,
        reportSalesPayable: null
      };
    }
    case `${actionTypes.GET_SALES_PAYABLE}_FULFILLED`: {
      successNotification(MESSAGE_GET_SALES_PAYABLE_SUCCESS);
      return {
        ...state,
        loadingSalesPayable: false,
        reportSalesPayable: action.payload ? action.payload.data : []
      };
    }

    case `${actionTypes.PROCESS_CREDIT_NOTE}_PENDING`: {
      infoNotification(MESSAGE_PROCESS_CREDIT_NOTE_PENDING);
      return {
        ...state,
        loadingProcessCreditNote: false,
        reportProcessCreditNote: null
      };
    }
    case `${actionTypes.PROCESS_CREDIT_NOTE}_REJECTED`: {
      errorNotification(
        readMessageBack(action, MESSAGE_PROCESS_CREDIT_NOTE_ERROR)
      );
      return {
        ...state,
        loadingProcessCreditNote: true,
        reportProcessCreditNote: null
      };
    }
    case `${actionTypes.PROCESS_CREDIT_NOTE}_FULFILLED`: {
      successNotification(MESSAGE_PROCESS_CREDIT_NOTE_SUCCESS);
      return {
        ...state,
        loadingProcessCreditNote: false,
        reportProcessCreditNote: action.payload ? action.payload.data : []
      };
    }

    case `${actionTypes.GET_AMOUNT_SALES_REPORT}_PENDING`: {
      infoNotification(MESSAGE_REPORT_WAY_TO_PAY_PENDING);
      return {
        ...state,
        loadingAmountSales: true,
        reportAmountSales: null
      };
    }
    case `${actionTypes.GET_AMOUNT_SALES_REPORT}_REJECTED`: {
      errorNotification(readMessageBack(action, REPORTS_COMISSIONS_ERROR));
      return {
        ...state,
        loadingAmountSales: false,
        reportAmountSales: null
      };
    }
    case `${actionTypes.GET_AMOUNT_SALES_REPORT}_FULFILLED`: {
      successNotification(MESSAGE_REPORT_WAY_TO_PAY_SUCCESS);
      return {
        ...state,
        loadingAmountSales: false,
        reportAmountSales: action.payload ? action.payload.data : []
      };
    }
    case `${actionTypes.GET_REPORT_WAY_TO_PAY}_PENDING`: {
      infoNotification(MESSAGE_REPORT_WAY_TO_PAY_PENDING);
      return {
        ...state,
        loadingWayToPay: false,
        reportWayToPay: null
      };
    }
    case `${actionTypes.GET_REPORT_WAY_TO_PAY}_REJECTED`: {
      errorNotification(readMessageBack(action, REPORTS_COMISSIONS_ERROR));
      return {
        ...state,
        loadingWayToPay: true,
        reportWayToPay: null
      };
    }
    case `${actionTypes.GET_REPORT_WAY_TO_PAY}_FULFILLED`: {
      successNotification(MESSAGE_REPORT_WAY_TO_PAY_SUCCESS);
      return {
        ...state,
        loadingWayToPay: false,
        reportWayToPay: action.payload.data
      };
    }
    case `${actionTypes.GET_INGRESS_BY_CLUB_REPORT}_PENDING`: {
      return {
        ...state,
        loadingIngressByClubReport: true,
        ingressByClubReport: null
      };
    }
    case `${actionTypes.GET_INGRESS_BY_CLUB_REPORT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        loadingIngressByClubReport: false,
        ingressByClubReport: null
      };
    }
    case `${actionTypes.GET_INGRESS_BY_CLUB_REPORT}_FULFILLED`: {
      return {
        ...state,
        loadingIngressByClubReport: false,
        ingressByClubReport: action.payload.data
      };
    }
    case `${actionTypes.CREATE_REFERENCES_BY_CLUB}_PENDING`: {
      infoNotification(MESSAGE_CREATE_REFERENCES_BY_CLUB_PENDING);
      return {
        ...state,
        referencesByClub: []
      };
    }
    case `${actionTypes.CREATE_REFERENCES_BY_CLUB}_REJECTED`: {
      errorNotification(
        readMessageBack(action, MESSAGE_CREATE_REFERENCES_BY_CLUB_ERROR)
      );
      return {
        ...state,
        referencesByClub: []
      };
    }
    case `${actionTypes.CREATE_REFERENCES_BY_CLUB}_FULFILLED`: {
      successNotification(MESSAGE_CREATE_REFERENCES_BY_CLUB_SUCCESS);
      return {
        ...state,
        referencesByClub: action.payload.data
      };
    }
    case `${actionTypes.GET_REFERENCE_CONSECUTIVE_CLUB}_PENDING`: {
      infoNotification(MESSAGE_REFERENCE_CONSECUTIVE_CLUB_PENDING);
      return {
        ...state,
        referenceConsecutive: ""
      };
    }
    case `${actionTypes.GET_REFERENCE_CONSECUTIVE_CLUB}_REJECTED`: {
      errorNotification(
        readMessageBack(action, MESSAGE_REFERENCE_CONSECUTIVE_CLUB_ERROR)
      );
      return {
        ...state,
        referenceConsecutive: ""
      };
    }
    case `${actionTypes.GET_REFERENCE_CONSECUTIVE_CLUB}_FULFILLED`: {
      successNotification(MESSAGE_REFERENCE_CONSECUTIVE_CLUB_SUCCESS);
      return {
        ...state,
        referenceConsecutive: action.payload.data
      };
    }
    case `${actionTypes.GET_REFERENCE_SANTANDER_REPORT}_PENDING`: {
      infoNotification(MESSAGE_REFERENCE_SANTANDER_REPORT_PENDING);
      return {
        ...state,
        reportReference: null
      };
    }
    case `${actionTypes.GET_REFERENCE_SANTANDER_REPORT}_REJECTED`: {
      errorNotification(
        readMessageBack(action, MESSAGE_REFERENCE_SANTANDER_REPORT_ERROR)
      );
      return {
        ...state,
        reportReference: null
      };
    }
    case `${actionTypes.GET_REFERENCE_SANTANDER_REPORT}_FULFILLED`: {
      successNotification(MESSAGE_REFERENCE_SANTANDER_REPORT_SUCCESS);
      return {
        ...state,
        reportReference: action.payload.data
      };
    }
    case `${actionTypes.GET_REFUNDS_REPORT}_PENDING`: {
      infoNotification(MESSAGE_GET_REFUNDS_REPORT_PENDING);
      return {
        ...state,
        reportRefunds: null
      };
    }
    case `${actionTypes.GET_REFUNDS_REPORT}_REJECTED`: {
      errorNotification(
        readMessageBack(action, MESSAGE_GET_REFUNDS_REPORT_ERROR)
      );
      return {
        ...state,
        reportRefunds: null
      };
    }
    case `${actionTypes.GET_REFUNDS_REPORT}_FULFILLED`: {
      successNotification(MESSAGE_GET_REFUNDS_REPORT_SUCCESS);
      return {
        ...state,
        reportRefunds: action.payload.data
      };
    }

    case `${actionTypes.AUTHORIZE_REFOUND}_PENDING`: {
      infoNotification(MESSAGE_AUTHORIZE_REFOUND_PENDING);
      return {
        ...state,
        resAuthorizeRefund: null
      };
    }
    case `${actionTypes.AUTHORIZE_REFOUND}_REJECTED`: {
      errorNotification(
        readMessageBack(action, MESSAGE_AUTHORIZE_REFOUND_ERROR)
      );
      return {
        ...state,
        resAuthorizeRefund: null
      };
    }
    case `${actionTypes.AUTHORIZE_REFOUND}_FULFILLED`: {
      successNotification(MESSAGE_AUTHORIZE_REFOUND_SUCCESS);
      return {
        ...state,
        resAuthorizeRefund: action.payload.data
      };
    }

    case `${actionTypes.REFUSE_REFOUND}_PENDING`: {
      infoNotification(MESSAGE_REFUSE_REFOUND_PENDING);
      return {
        ...state,
        resRefuseRefund: null
      };
    }
    case `${actionTypes.REFUSE_REFOUND}_REJECTED`: {
      errorNotification(readMessageBack(action, MESSAGE_REFUSE_REFOUND_ERROR));
      return {
        ...state,
        resRefuseRefund: null
      };
    }
    case `${actionTypes.REFUSE_REFOUND}_FULFILLED`: {
      successNotification(MESSAGE_REFUSE_REFOUND_SUCCESS);
      return {
        ...state,
        resRefuseRefund: action.payload.data
      };
    }

    case `${actionTypes.CHARGEBACK_REFOUND}_PENDING`: {
      infoNotification(MESSAGE_CHARGEBACK_REFOUND_PENDING);
      return {
        ...state,
        resChargebackefund: null
      };
    }
    case `${actionTypes.CHARGEBACK_REFOUND}_REJECTED`: {
      errorNotification(
        readMessageBack(action, MESSAGE_CHARGEBACK_REFOUND_ERROR)
      );
      return {
        ...state,
        resChargebackefund: null
      };
    }
    case `${actionTypes.CHARGEBACK_REFOUND}_FULFILLED`: {
      successNotification(MESSAGE_CHARGEBACK_REFOUND_SUCCESS);
      return {
        ...state,
        resChargebackefund: action.payload.data
      };
    }

    case `${actionTypes.GET_REFERENCE_SANTANDER_REPORT_DA}_PENDING`: {
      infoNotification(MESSAGE_REFERENCE_SANTANDER_REPORT_PENDING);
      return {
        ...state,
        reportReferenceDA: null
      };
    }
    case `${actionTypes.GET_REFERENCE_SANTANDER_REPORT_DA}_REJECTED`: {
      errorNotification(
        readMessageBack(action, MESSAGE_REFERENCE_SANTANDER_REPORT_ERROR)
      );
      return {
        ...state,
        reportReferenceDA: null
      };
    }
    case `${actionTypes.GET_REFERENCE_SANTANDER_REPORT_DA}_FULFILLED`: {
      successNotification(MESSAGE_REFERENCE_SANTANDER_REPORT_SUCCESS);
      return {
        ...state,
        reportReferenceDA: action.payload.data
      };
    }

    case `${actionTypes.GET_REPORT_EMPLOYEES}_PENDING`: {
      infoNotification(MESSAGE_REFERENCE_SANTANDER_REPORT_PENDING);
      return {
        ...state,
        reportEmployees: null
      };
    }
    case `${actionTypes.GET_REPORT_EMPLOYEES}_REJECTED`: {
      errorNotification(
        readMessageBack(action, MESSAGE_REFERENCE_SANTANDER_REPORT_ERROR)
      );
      return {
        ...state,
        reportEmployees: null
      };
    }
    case `${actionTypes.GET_REPORT_EMPLOYEES}_FULFILLED`: {
      successNotification(MESSAGE_REFERENCE_SANTANDER_REPORT_SUCCESS);
      return {
        ...state,
        reportEmployees: action.payload.data
      };
    }

    default: {
      return state;
    }
  }
}

export { initialState };
