import React, { Component } from "react";
import { Container } from "reactstrap";
import {
  errorNotification,
  successNotification
} from "../../../utils/notifications";
import {
  Router,
  Styled,
  RouterProps
} from "../../../toolkit/decorators/Artifact";
import s from "./styles.scss";
import { ComisionesDeportivaGarantiasStore } from "../../containers/comisionesDeportivaGarantias/ComisionesDeportivaGarantias.store";
import { ComisionesDeportivaGarantiasDispatcher } from "../../containers/comisionesDeportivaGarantias/ComisionesDeportivaGarantias.dispatcher";
import MaquilaGarantia from "../../../types/MaquilaGarantia";
import FormBuilderModal from "../common/formBuilderModal/formBuilderModal";
import { formConfigClub } from "./ComisionesDeportivaGarantiasClubes.form";
import ITypeSelectBuilder from "../../../toolkit/baseForms2/iTypeSelectBuilder/ITypeSelectBuilder";
import ConfirmModal from "../common/confirmModal/confirmModal";
import { formConfigMonto } from "./ComisionesDeportivaGarantiasMonto.form";
import { MemoTablePuesto } from "./ComisionesDeportivaGarantiasPuestos.table";
import { formConfigPuesto } from "./ComisionesDeportivaGarantiasPuestos.form";
import { MemoTableClub } from "./ComisionesDeportivaGarantiasClubes.table";
import { MemoTableEmpleado } from "./ComisionesDeportivaGarantiasEmpleados.table";
import { formConfigEmpleado } from "./ComisionesDeportivaGarantiasEmpleados.form";

interface Props
  extends ComisionesDeportivaGarantiasStore,
    ComisionesDeportivaGarantiasDispatcher,
    RouterProps<any> {}

interface State {
  row: MaquilaGarantia;
  puestoOpen: boolean;
  puestoInit: any;
  clubOpen: boolean;
  clubInit: any;
  empleadoOpen: boolean;
  empleadoInit: any;
  montoOpen: boolean;
  deleteOpen: boolean;
  tipo: "PUESTO" | "CLUB" | "EMPLEADO";
}

/**
 * Gestión de archivos de nómina (maquila)
 */
@Router()
@Styled(s)
export default class ComisionesDeportivaGarantias extends Component<
  Props,
  State
> {
  state = {
    row: null,
    puestoOpen: false,
    puestoInit: null,
    clubOpen: false,
    clubInit: null,
    empleadoOpen: false,
    empleadoInit: null,
    montoOpen: false,
    deleteOpen: false,
    tipo: null
  };

  componentDidMount(): void {
    this.props.getPositions();
    this.props.getGarantiasDeportivasPuestos();
    this.props.getGarantiasDeportivasClubes();
    this.props.getGarantiasDeportivasEmpleados();
  }

  onCreatePuesto = () => {
    this.setState({ row: null, puestoOpen: true });
  };

  onUpdatePuesto = (row: MaquilaGarantia) => {
    this.setState({ row, montoOpen: true, tipo: "PUESTO" });
  };

  savePuesto = async (row: MaquilaGarantia) => {
    const body = {
      ...(this.state.row || {}),
      ...row
    };
    const preview = await this.props.postGarantiasDeportivasPuestos(body);

    if (preview.fulfilled) {
      this.setState({
        row: null,
        puestoOpen: false,
        montoOpen: false
      });

      this.props.getGarantiasDeportivasPuestos();
      successNotification("Garantía de puesto configurada correctamente");
    } else {
      errorNotification(preview.postingCMaqGarantiasDeportivasPuestosError);
    }
  };

  onCreateClub = () => {
    this.setState({ row: null, clubOpen: true });
  };

  onUpdateClub = (row: MaquilaGarantia) => {
    this.setState({ row, montoOpen: true, tipo: "CLUB" });
  };

  saveClub = async (row: MaquilaGarantia) => {
    const body = {
      ...(this.state.row || {}),
      ...row
    };
    const preview = await this.props.postGarantiasDeportivasClubes(body);

    if (preview.fulfilled) {
      this.setState({
        row: null,
        clubOpen: false,
        montoOpen: false
      });

      this.props.getGarantiasDeportivasClubes();
      successNotification("Garantía de club configurada correctamente");
    } else {
      errorNotification(preview.postingCMaqGarantiasDeportivasClubesError);
    }
  };

  onCreateEmpleado = () => {
    this.setState({ row: null, empleadoOpen: true });
  };

  onUpdateEmpleado = (row: MaquilaGarantia) => {
    this.setState({ row, montoOpen: true, tipo: "EMPLEADO" });
  };

  saveEmpleado = async (row: MaquilaGarantia) => {
    const body = {
      ...(this.state.row || {}),
      ...row
    };
    const preview = await this.props.postGarantiasDeportivasEmpleados(body);

    if (preview.fulfilled) {
      this.setState({
        row: null,
        empleadoOpen: false,
        montoOpen: false
      });

      this.props.getGarantiasDeportivasEmpleados();
      successNotification("Garantía de empleado configurada correctamente");
    } else {
      errorNotification(preview.postingCMaqGarantiasDeportivasEmpleadosError);
    }
  };

  onDelete = (row: MaquilaGarantia, tipo: "PUESTO" | "CLUB" | "EMPLEADO") => {
    this.setState({ row, deleteOpen: true, tipo });
  };

  delete = async () => {
    const preview = await this.props.deleteGarantiasDeportivas(
      this.state.row,
      this.state.tipo
    );

    if (preview.fulfilled) {
      this.setState({
        row: null,
        tipo: null,
        deleteOpen: false
      });

      this.props.getGarantiasDeportivasPuestos();
      this.props.getGarantiasDeportivasClubes();
      this.props.getGarantiasDeportivasEmpleados();
      successNotification("Garantía eliminada correctamente");
    } else {
      errorNotification(preview.deletingCMaqGarantiasDeportivasError);
    }
  };

  loading = () => {
    return (
      this.props.gettingCatalogPostions ||
      this.props.gettingCMaqGarantiasDeportivasPuestos ||
      this.props.gettingCMaqGarantiasDeportivasClubes ||
      this.props.gettingCMaqGarantiasDeportivasEmpleados ||
      this.props.postingCMaqGarantiasDeportivasPuestos ||
      this.props.postingCMaqGarantiasDeportivasClubes ||
      this.props.postingCMaqGarantiasDeportivasEmpleados ||
      this.props.deletingCMaqGarantiasDeportivas
    );
  };

  render() {
    return (
      <Container className="mb-5">
        {/* Confirmación de eliminación */}
        <ConfirmModal
          isOpen={this.state.deleteOpen}
          title="Confirmación"
          message="Confirme la eliminación de la garantía"
          ok={this.delete}
          cancel={() =>
            this.setState({
              deleteOpen: false
            })
          }
          size={"md"}
          waiting={this.loading()}
        />

        {/* Actualización del monto */}
        <FormBuilderModal
          isOpen={this.state.montoOpen}
          title="Monto de la garantía"
          message="Configure el monto de la garantía"
          ok={formValue => {
            if (this.state.tipo === "PUESTO") {
              this.savePuesto(formValue);
            } else if (this.state.tipo === "CLUB") {
              this.saveClub(formValue);
            } else if (this.state.tipo === "EMPLEADO") {
              this.saveEmpleado(formValue);
            }
          }}
          cancel={() => this.setState({ montoOpen: false })}
          formConfig={formConfigMonto}
          values={this.state.row || null}
          size={"md"}
        />

        {/* Garantía por puesto */}
        <FormBuilderModal
          isOpen={this.state.puestoOpen}
          title="Garantía por puesto"
          message="Configure la garantía por puesto"
          ok={this.savePuesto}
          cancel={() => this.setState({ puestoOpen: false })}
          formConfig={formConfigPuesto}
          values={this.state.row || null}
          size={"md"}
        >
          <ITypeSelectBuilder
            name="idPuesto"
            data={this.props.catalogPostions || []}
            mapOption={p => ({
              value: p.positionId,
              label: `(${p.key}) ${p.name}`
            })}
          />
        </FormBuilderModal>

        <MemoTablePuesto
          data={this.props.cmaqGarantiasDeportivasPuestos || []}
          loading={this.loading()}
          onCreate={this.onCreatePuesto}
          onUpdate={this.onUpdatePuesto}
          onDelete={this.onDelete}
        />

        <hr className="row" />

        {/* Garantía por club y puesto */}
        <FormBuilderModal
          isOpen={this.state.clubOpen}
          title="Garantía por club y puesto"
          message="Configure la garantía por club y puesto"
          ok={this.saveClub}
          cancel={() => this.setState({ clubOpen: false })}
          formConfig={formConfigClub}
          values={this.state.row || null}
          size={"md"}
        >
          <ITypeSelectBuilder
            name="idPuesto"
            data={this.props.catalogPostions || []}
            mapOption={p => ({
              value: p.positionId,
              label: `(${p.key}) ${p.name}`
            })}
          />
        </FormBuilderModal>

        <MemoTableClub
          data={this.props.cmaqGarantiasDeportivasClubes || []}
          loading={this.loading()}
          onCreate={this.onCreateClub}
          onUpdate={this.onUpdateClub}
          onDelete={this.onDelete}
        />

        <hr className="row" />

        {/* Garantía por empleado */}
        <FormBuilderModal
          isOpen={this.state.empleadoOpen}
          title="Garantía por empleado"
          message="Configure la garantía por empleado"
          ok={this.saveEmpleado}
          cancel={() => this.setState({ empleadoOpen: false })}
          formConfig={formConfigEmpleado}
          values={this.state.row || null}
          size={"md"}
        >
          <ITypeSelectBuilder
            name="idPuesto"
            data={this.props.catalogPostions || []}
            mapOption={p => ({
              value: p.positionId,
              label: `(${p.key}) ${p.name}`
            })}
          />
        </FormBuilderModal>

        <MemoTableEmpleado
          data={this.props.cmaqGarantiasDeportivasEmpleados || []}
          loading={this.loading()}
          onCreate={this.onCreateEmpleado}
          onUpdate={this.onUpdateEmpleado}
          onDelete={this.onDelete}
        />
      </Container>
    );
  }
}
