import {
  faRecycle,
  faTimes,
  faWindowClose
} from "@fortawesome/free-solid-svg-icons";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import ReactTable from "react-table";
import { Button, Col, Container, Row, TabPane } from "reactstrap";
import BaseButtonWithIconAndTooltip from "../../../../toolkit/baseButtonWithIconAndTooltip";
import ModalFrame from "../../../../toolkit/modalFrame";
import { Club } from "../../../../types-business/Clubs";
import { userHasPermission } from "../../../../utils/accessTree";
import {
  MOTIVO_CANCELACION_CAT,
  PARTNER_MEMB_INACTIVE_STATUS,
  PARTNER_MEMB_PASIVE_STATUS
} from "../../../../utils/constants";
import { handleRequestError } from "../../../../utils/helpers";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../../utils/notifications";
import permissionCodes from "../../../../utils/permissionCodes";
import {
  deleteBankCardRequest,
  reactivateBankCardRequest
} from "../../../actions/partners";
import ConfirmModal from "../../common/confirmModal/confirmModal";
import { Partner } from "./../interfaces";
import ImportBankCardModal from "./importBankCardModal/importBankCardModal";
import s from "./tabBankCards.scss";

interface Props {
  partner: Partner;
  idMembresiaSocio: any;
  paymentMethods: any[];
  clubs: Club[];
  getPaymentMethods: () => any;
  refreshPartner: () => void;
  invalidateStateValue: (fieldName: string, value: any) => void;
}

const ESTATUS_MEMBRESIA_INACTIVA = [
  PARTNER_MEMB_PASIVE_STATUS,
  PARTNER_MEMB_INACTIVE_STATUS
];

class TabBankCards extends React.Component<Props> {
  state = {
    isOpenModal: false,
    idToDelete: null,
    confirmModalIsOpen: false,

    urlModalIsOpen: false,
    urlModalDeletePaymentMethod: null,

    razon: "FALTA_TIEMPO",
    questionResponse: null,
    sinEncuesta: false,

    importBankCardIsOpen: false,

    idToReactivate: null,
    reactivateConfirmModalIsOpen: false
  };

  isMembershipInactiva = () => {
    return (
      !!this.props.partner &&
      ESTATUS_MEMBRESIA_INACTIVA.includes(this.props.partner.estatusMembresia)
    );
  };

  deletePaymentMethod = (idToDelete, reason, questionResponse, sinEncuesta) => {
    const payload = {
      medioPagoId: idToDelete,
      razon: reason || null,
      respuestaPreguntaPermanencia: questionResponse || null,
      sinEncuesta
    };

    deleteBankCardRequest(payload)
      .then(() => {
        successNotification("Medio de pago eliminado correctamente!");

        this.setState({
          idToDelete: null,
          confirmModalIsOpen: false,
          questionResponse: null,
          sinEncuesta: false,
          urlModalIsOpen: false,
          urlModalDeletePaymentMethod: null
        });

        this.props.invalidateStateValue("paymentMethods", []);
        this.props.getPaymentMethods();
        this.props.refreshPartner();
      })
      .catch(handleRequestError);
  };

  reactivateBankcard = () => {
    const { idMembresiaSocio } = this.props;
    const { idToReactivate } = this.state;

    infoNotification("Reactivando medio de pago...");

    reactivateBankCardRequest(idMembresiaSocio, idToReactivate)
      .then(() => {
        successNotification("Medio de pago reactivado correctamente");
        this.setState({
          idToReactivate: null,
          reactivateConfirmModalIsOpen: false
        });

        this.props.invalidateStateValue("paymentMethods", []);
        this.props.getPaymentMethods();
        //this.props.refreshPartner();
      })
      .catch(handleRequestError);
  };

  render() {
    let tieneCargosEnProcesoMit = false;
    let diaPosterior20Mes = false;

    const firstPaymentMethod =
      !!this.props.paymentMethods && this.props.paymentMethods.length > 0
        ? this.props.paymentMethods[0]
        : null;

    if (!!firstPaymentMethod) {
      tieneCargosEnProcesoMit = !!firstPaymentMethod.tieneCargosEnProcesoMit;
      diaPosterior20Mes = !!firstPaymentMethod.diaPosterior20Mes;
    }

    const hasDeletePaymentMethodPermission = userHasPermission(
      permissionCodes.PARTNER_MEMBERSHIP_TAB_TOKEN_ALLOW_DELETE_PAYMENT_METHOD
    );

    const hasDeletePaymentMethodWithNoSurveyPermission = userHasPermission(
      permissionCodes.PARTNER_MEMBERSHIP_TAB_TOKEN_ALLOW_DELETE_PAYMENT_METHOD_NO_SURVEY
    );

    const hasForceDeletePaymentMethodPermission = userHasPermission(
      permissionCodes.PARTNER_MEMBERSHIP_TAB_TOKEN_FORCE_DELETE
    );
    const hasDeletePaymentMethodAfter20DaysPermission = userHasPermission(
      permissionCodes.PARTNER_MEMBERSHIP_TAB_TOKEN_ALLOW_DELETE_PAYMENT_METHOD_AFTER_20_DAYS
    );

    const hasReactivatePaymentMethodPermission = userHasPermission(
      permissionCodes.PARTNER_MEMBERSHIP_TAB_TOKEN_ALLOW_REACTIVATE_PAYMENT_METHOD
    );

    let allowDeletePaymentMethod =
      (!tieneCargosEnProcesoMit && hasDeletePaymentMethodPermission) ||
      (!!tieneCargosEnProcesoMit &&
        hasDeletePaymentMethodPermission &&
        hasForceDeletePaymentMethodPermission);

    if (!!diaPosterior20Mes) {
      allowDeletePaymentMethod =
        allowDeletePaymentMethod && hasDeletePaymentMethodAfter20DaysPermission;
    }

    const allowDeletePaymentMethodNoSurvey =
      allowDeletePaymentMethod && hasDeletePaymentMethodWithNoSurveyPermission;

    const columns = [
      { Header: "Nombre títular", accessor: "nombreTitular" },
      {
        Header: "Tipo",
        className: "text-center",
        accessor: "tipoMedioPago"
      },
      {
        Header: "Tipo tarjeta",
        className: "text-center",
        accessor: "tipoTarjeta"
      },
      {
        Header: "Estatus",
        className: "text-center",
        accessor: "estatus"
      },
      {
        Header: "Banco",
        className: "text-center",
        accessor: "banco.nombre"
      },
      {
        Header: "Número",
        className: "text-center",
        accessor: "numero"
      },
      {
        Header: "Fecha vencimiento",
        className: "text-center",
        accessor: "fechaVencimiento"
      },
      {
        Header: "Principal",
        accessor: "principal",
        Cell: ({ value }) => (!!value ? "SI" : "NO")
      }
    ];

    if (this.isMembershipInactiva() && !!hasReactivatePaymentMethodPermission) {
      columns.push({
        Header: "Motivo Eliminación",
        className: "text-wrap",
        accessor: "motivoEliminacion"
      });
      columns.push({
        Header: "Razón Eliminación",
        className: "text-wrap",
        accessor: "razon"
      });
      columns.push({
        Header: "Fecha Eliminación",
        className: "text-center",
        accessor: "fechaEliminacion"
      });
    }

    columns.push({
      Header: "Opciones",
      id: "id",
      accessor: bank => (
        <Container className="w-100 text-center">
          {allowDeletePaymentMethod &&
            bank.estatus == "ACTIVO" &&
            !bank.principal && (
              <BaseButtonWithIconAndTooltip
                id={`payment-method-${bank.id}`}
                icon={faTimes}
                tooltipTitle="Eliminar medio de pago"
                onClick={() => {
                  this.setState({
                    idToDelete: bank.id,
                    confirmModalIsOpen: true,
                    sinEncuesta: false
                  });
                }}
              />
            )}

          {allowDeletePaymentMethod &&
            bank.estatus == "ACTIVO" &&
            !!bank.principal && (
              <BaseButtonWithIconAndTooltip
                id={`payment-method-url-${bank.id}`}
                icon={faTimes}
                tooltipTitle="Eliminar medio de pago"
                onClick={() => {
                  this.setState({
                    urlModalDeletePaymentMethod: bank.urlEncuestaBajaMedioPago,
                    urlModalIsOpen: true,
                    idToDelete: bank.id,
                    sinEncuesta: false
                  });
                }}
              />
            )}

          {!!bank.principal &&
            allowDeletePaymentMethodNoSurvey &&
            bank.estatus == "ACTIVO" && (
              <BaseButtonWithIconAndTooltip
                id={`delete-no-survey-${bank.id}`}
                icon={faWindowClose}
                tooltipTitle="Eliminar medio de pago sin encuesta"
                onClick={() => {
                  this.setState({
                    idToDelete: bank.id,
                    confirmModalIsOpen: true,
                    sinEncuesta: true
                  });
                }}
              />
            )}

          {this.isMembershipInactiva() &&
            !!hasReactivatePaymentMethodPermission &&
            bank.estatus == "ELIMINADO" && (
              <BaseButtonWithIconAndTooltip
                id={`reactivate-payment-method-${bank.id}`}
                icon={faRecycle}
                tooltipTitle="Reactivar medio de pago cancelado por proceso CRM2"
                onClick={() => {
                  this.setState({
                    idToReactivate: bank.id,
                    reactivateConfirmModalIsOpen: true
                  });
                }}
              />
            )}
        </Container>
      )
    });

    return (
      <TabPane className="my-2" tabId="5">
        <Container className="p-0 m-0">
          {userHasPermission(
            permissionCodes.PARTNER_MEMBERSHIP_TAB_TOKEN_ALLOW_COPY_PAYMENT_METHOD
          ) && (
            <ImportBankCardModal
              clubs={this.props.clubs}
              idMembresiaSocio={this.props.idMembresiaSocio}
              isOpen={this.state.importBankCardIsOpen}
              cancel={() => {
                this.setState({ importBankCardIsOpen: false });
              }}
              success={() => {
                this.setState({ importBankCardIsOpen: false });
                this.props.invalidateStateValue("paymentMethods", []);
                this.props.getPaymentMethods();
              }}
            />
          )}

          <ModalFrame
            title="Eliminar medio de pago"
            isOpen={this.state.confirmModalIsOpen}
            toggle={() => {
              this.setState({
                idToDelete: null,
                confirmModalIsOpen: false
              });
            }}
          >
            <Container>
              <Row>
                <Col>Seleccione la razón de la eliminación de la tarjeta</Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <select
                    onChange={e =>
                      this.setState({
                        razon: e.target.value
                      })
                    }
                    value={this.state.razon}
                    style={{
                      width: "100%"
                    }}
                  >
                    {MOTIVO_CANCELACION_CAT.map((group, index) => {
                      return (
                        <optgroup key={index} label={group.label}>
                          {group.options &&
                            group.options.map((option, index) => {
                              return (
                                <option key={index} value={option.value}>
                                  {option.label}
                                </option>
                              );
                            })}
                        </optgroup>
                      );
                    })}
                  </select>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col>
                  Responde la pregunta:
                  <br /> ¿Qué necesitas para continuar en SportsWorld?
                </Col>
              </Row>
              <Row className="mt-2">
                <textarea
                  id="questionResponse"
                  name="questionResponse"
                  rows={6}
                  value={this.state.questionResponse}
                  className="form-control"
                  onChange={e =>
                    this.setState({
                      questionResponse: e.target.value
                    })
                  }
                />
              </Row>

              <Row className="mt-4">
                <Col xs={6} md={6} lg={6}>
                  &nbsp;
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <Button
                    block
                    className={`${s.primaryButton}`}
                    disabled={!this.state.razon || !this.state.questionResponse}
                    onClick={() => {
                      const {
                        idToDelete,
                        razon,
                        questionResponse,
                        sinEncuesta
                      } = this.state;
                      if (!razon) {
                        errorNotification("Debe seleccionar una razón");
                        return;
                      }
                      if (!questionResponse) {
                        errorNotification("Debe responder la pregunta");
                        return;
                      }
                      this.deletePaymentMethod(
                        idToDelete,
                        razon,
                        questionResponse,
                        sinEncuesta
                      );
                    }}
                  >
                    Eliminar
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalFrame>

          <ConfirmModal
            title="Baja de medio de pago principal"
            message={`Se enviará un correo al titular de la membresía para responder la encuesta de baja de medio de pago. Una vez respondida se completará la baja de tokenización`}
            isOpen={this.state.urlModalIsOpen}
            size="md"
            ok={() => {
              const {
                idToDelete,
                razon,
                questionResponse,
                sinEncuesta
              } = this.state;
              this.deletePaymentMethod(
                idToDelete,
                razon,
                questionResponse,
                sinEncuesta
              );
            }}
            cancel={() => {
              this.setState({
                urlModalIsOpen: false
              });
            }}
          />

          <ConfirmModal
            title="Reactivar medio de pago"
            message="Se reactivará un medio de pago cancelado por proceso CRM2 ¿Desea continuar?"
            isOpen={this.state.reactivateConfirmModalIsOpen}
            requireInput={false}
            ok={this.reactivateBankcard}
            cancel={() => {
              this.setState({
                idToReactivate: null,
                reactivateConfirmModalIsOpen: false
              });
            }}
          />

          {tieneCargosEnProcesoMit && (
            <Row>
              <Col sm="12">
                <div className="alert alert-warning mt-4 mb-4">
                  Hay cargos de mantenimiento en proceso activo de cargos
                  automáticos. No es posible dar de baja ningún medio de pago
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col md="6">
              <div className="m-4">
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_TOKEN_ALLOW_COPY_PAYMENT_METHOD
                ) && (
                  <Button
                    className="bg-info"
                    size="sm"
                    onClick={e => {
                      e.preventDefault();
                      this.setState({ importBankCardIsOpen: true });
                    }}
                  >
                    Importar medio de pago desde otra membresía socio
                  </Button>
                )}
              </div>
            </Col>
          </Row>

          <Row className="p-0 m-0">
            <ReactTable
              manual
              data={this.props.paymentMethods || []}
              columns={columns}
              className="w-100 -striped -highlight"
              style={{
                maxHeight: "400px"
              }}
              sortable={false}
              showPagination={false}
              defaultPageSize={3}
            />
          </Row>
        </Container>
      </TabPane>
    );
  }
}
export default withStyles(s)(TabBankCards);
