import { Preview } from "../../../types/IAction";
import {
  putComisionesDeportivaCrm1Procesar,
  getComisionesDeportivaCrm1Pendientes
} from "../../actions/comisionesDeportivaCrm1";
import {
  GetComisionesDeportivaCrm1Pendientes,
  PutComisionesDeportivaCrm1Procesar
} from "../../reducers/comisionesDeportivaCrm1";

export interface ComisionesDeportivaPendientesDispatcher {
  getComisionesDeportivaCrm1Pendientes: (
    params?: any
  ) => Promise<Preview<GetComisionesDeportivaCrm1Pendientes>>;
  putComisionesDeportivaCrm1Procesar: (
    body: any
  ) => Promise<
    Preview<
      Omit<
        PutComisionesDeportivaCrm1Procesar,
        "comsionesDeportivaCrm1Procesadas"
      >
    >
  >;
}

export const comisionesDeportivaPendientesDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => ComisionesDeportivaPendientesDispatcher = dispatch => ({
  getComisionesDeportivaCrm1Pendientes: (params?: any) => {
    return dispatch(getComisionesDeportivaCrm1Pendientes(params));
  },
  putComisionesDeportivaCrm1Procesar: (body: any) => {
    return dispatch(putComisionesDeportivaCrm1Procesar(body));
  }
});
